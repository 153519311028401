import React, { useEffect } from 'react'
import { Input, Form, DatePicker, Select } from 'antd'
import Icon from '@/components/Icon'
import './search.less'
import moment from 'moment'
import { connect } from 'react-redux'
const Search = Input.Search
const { Option } = Select
const orderClass = [
  {
    label: '收银系统',
    value: 'CRS',
  },
  {
    label: '小程序',
    value: 'MP',
  },
]
function Index(props) {
  const [form] = Form.useForm()

  const onSearch = (value) => {
    props.onSearch({
      ...form.getFieldsValue(),
      keyWord: value,
    })
  }

  const onStartChange = (current) => {
    form.setFieldsValue({
      appointStartTime:
        current && moment(current && current.format('YYYY-MM-DD')),
    })
  }
  const onEndChange = (current) => {
    form.setFieldsValue({
      appointEntTime:
        current && moment(current && current.format('YYYY-MM-DD')),
    })
  }
  useEffect(() => {
    form.setFieldsValue({
      ...props.params
    })
  }, [props.params])
  return (
    <div className="a-l-search">
      <Form
        className="form"
        form={form}
        preserve={false}
      >
        <Form.Item label="" name="keyWord">
          <Search
            placeholder="会员名称或手机号"
            allowClear
            className="search-button"
            prefix={<Icon type="iconsousuo" />}
            enterButton="搜索"
            onSearch={onSearch}
          />
        </Form.Item>
        <Form.Item
          label="起始时间"
          name="appointStartTime"
          colon={false}
          className="form-item"
        >
          <DatePicker
            bordered={false}
            onChange={onStartChange}
            format="YYYY-MM-DD"
            placeholder="请选择起始时间"
          />
        </Form.Item>
        <Form.Item
          label="结束时间"
          name="appointEntTime"
          colon={false}
          className="form-item"
        >
          <DatePicker
            bordered={false}
            onChange={onEndChange}
            format="YYYY-MM-DD"
            placeholder="请选择结束时间"
          />
        </Form.Item>
        <Form.Item
          label="订单来源"
          name="orderSource"
          colon={false}
          className="form-item"
        >
          <Select
            className="select"
            placeholder="请选择订单来源"
            allowClear={false}
            bordered={false}
          >
            {orderClass.map((iv, index) => (
              <Option key={index} value={iv.value}>
                {iv.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  )
}
export default connect(state => ({ params: state.appointment.params }))(Index)