import React, { useState, useEffect } from 'react'
import './adjust.less'
import { InputNumber, DatePicker } from 'antd'
import { getAppointTemplateAfterToDay } from '@/redux/actions/user'
import { connect } from 'react-redux'
import moment from 'moment'

function Adjust(props) {
  const [chooseTimeSlotList1, setChooseTimeSlotList1] = useState([])
  const [chooseTimeSlotList2, setChooseTimeSlotList2] = useState([])
  const [timeSlotList, setTimeSlotList] = useState([])
  const [timeAdjust, setTimeAdjust] = useState()

  const handleHT = (val) => {
    if (!val) return
    const ar = val.split(':')
    if (ar.length > 1) {
      return `${ar[0]}:${ar[1]}`
    }
    return val
  }

  const init = () => {
    props
      .getAppointTemplateAfterToDay({
        days: 30,
      })
      .then((data) => {
        const list = data || []
        setTimeSlotList(list)
        const time = moment(new Date()).format('YYYY-MM-DD')
        setTimeAdjust(moment(new Date()))
        changePopTime(time, list)
      })
  }
  // 选择日期
  const changePopTime = (val, timeSlotList) => {
    const params = {
      appointTemplateVO: [],
      date: val,
    }
    setChooseTimeSlotList1([])
    setChooseTimeSlotList2([])
    if (val) {
      const chooseTimeSlot = timeSlotList[val].appointTemplateDetailVOList
      const chooseTimeSlotList1 = chooseTimeSlot.slice(
        0,
        chooseTimeSlot.length / 2 + 1
      )
      const chooseTimeSlotList2 = chooseTimeSlot.slice(
        chooseTimeSlot.length / 2 + 1
      )
      setChooseTimeSlotList1([...chooseTimeSlotList1])
      setChooseTimeSlotList2([...chooseTimeSlotList2])
      params.appointTemplateVO = timeSlotList[val]
      props.setParamsAdjust(params)
    } else {
      props.setParamsAdjust(params)
    }
  }
  // 修改日期人数
  const onChangeItem = (val, index, type) => {
    if (!val) val = 0
    if (type === 'left') {
      chooseTimeSlotList1[index].peopleNum = val
      setChooseTimeSlotList1([...chooseTimeSlotList1])
    } else {
      chooseTimeSlotList2[index].peopleNum = val
      setChooseTimeSlotList2([...chooseTimeSlotList2])
    }
    const time = timeAdjust.format('YYYY-MM-DD')
    const appointTemplateVO = timeSlotList[time]
    appointTemplateVO.appointTemplateDetailVOList = [
      ...chooseTimeSlotList1,
      ...chooseTimeSlotList2,
    ]
    const params = {
      appointTemplateVO,
      date: time,
    }
    console.log(params)
    props.setParamsAdjust(params)
  }
  const onDatePickerChange = (val) => {
    const time = val.format('YYYY-MM-DD')
    setTimeAdjust(val)
    changePopTime(time, timeSlotList)
  }
  const disabledDate = (current) => {
    const start = moment(Date.now() - 8.64e7)
    const end = moment(Date.now() + 29 * 8.64e7)
    return current < start || current > end
  }

  const limitDecimalsNumber = (value) => {
    if (value <= 0) return 0
    return value.replace(/^(0)|[^\d]+/g, '')
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div className="pop_main">
      <div className="la_control">
        <span className="label">
          <span className="mrE">日</span>期
        </span>
        ：
        <div className="time">
          <DatePicker
            allowClear={false}
            className="date popperClass"
            disabledDate={disabledDate}
            onChange={onDatePickerChange}
            defaultValue={moment(new Date(), 'YYYY-MM-DD')}
            value={timeAdjust}
          />
        </div>
      </div>
      <div className="la_control2">
        <div className="t">
          <span className="label">时 间 段：</span>
        </div>
        <div className="info">
          <ul>
            {chooseTimeSlotList1.map((item, index) => (
              <li className="item" key={index}>
                <span className="ti">
                  {handleHT(item.appointStartTime)}-
                  {handleHT(item.appointEndTime)}
                </span>
                <InputNumber
                  className="elIt"
                  min={0}
                  defaultValue={item.peopleNum}
                  onChange={(val) => onChangeItem(val, index, 'left')}
                  value={item.peopleNum}
                  formatter={limitDecimalsNumber}
                  parser={limitDecimalsNumber}
                />
                &nbsp;
                <span className="ml10">人</span>
              </li>
            ))}
          </ul>
          <ul>
            {chooseTimeSlotList2.map((item, index) => (
              <li className="item" key={index}>
                <span className="ti">
                  {handleHT(item.appointStartTime)}-
                  {handleHT(item.appointEndTime)}
                </span>
                <InputNumber
                  className="elIt"
                  min={0}
                  step={1}
                  defaultValue={item.peopleNum}
                  onChange={(val) => onChangeItem(val, index, 'right')}
                  value={item.peopleNum}
                  formatter={limitDecimalsNumber}
                  parser={limitDecimalsNumber}
                />
                &nbsp;
                <span className="ml10">人</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({}), {
  getAppointTemplateAfterToDay,
})(Adjust)
