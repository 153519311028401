import request from '../../utils/request'
// 检查更新
export const queryCheckUpdate = () => {
  return new Promise((resolve, reject) => {
    request({
      url: `/version/getLatestReleaseNote`,
      method: 'get',
    })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// 获取账号设置详情
export const getAdminInfo = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `/crs/admin/get/${params.adminId}`,
        method: 'get',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const refundFrameInfo = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `/refund/refundFrameInfo`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
