import React, { useState, useEffect, useMemo } from 'react'
import Icon from '../../Icon'
import { Button, Space, Table, Modal, Input, Empty, Image, message } from 'antd'
import './index.less'
import moment from 'moment'
import BoardRefund from '@/components/Board/Refund'
import { refundStatus } from '../../../utils/enum'
import defaultLogo from '@/assets/menu/defaultLogo.png'
import defaultUserLogo from '@/assets/member/people.png'
const { TextArea } = Input
export default function RefundDetail(props) {
  const [orderDetail, setOrderDetail] = useState({})
  const [boardOrderDTO, setBoardOrderDTO] = useState({})
  const [refundOrderDTO, setRefundOrderDTO] = useState({})
  const [data, setData] = useState({})
  const [columns, setColumns] = useState({})
  // 弹窗数据
  // 退款数据
  const [refundDetail, setRefundDetail] = useState({})
  const [isShowBoardRefund, setIsShowBoardRefund] = useState(false)
  const [refundVisible, setRefundVisible] = useState(false)
  const [refundLoading, setRefundLoading] = useState(false)
  const [refuseVisible, setRefuseVisible] = useState(false)
  const [refuseLoading, setRefuseLoading] = useState(false)
  // 预约退款数据
  const [appointParams, setAppointParams] = useState({
    adminRemark: undefined,
  })
  // 寄养退款数据
  const [boardParams, setBoardParams] = useState({
    refundOrderId: undefined,
    refundOrderNo: undefined,
    refundDays: undefined,
    refundExplain: undefined,
  })
  // 拒绝数据
  const [refuseParams, setRefuseParams] = useState({
    refundOrderNo: undefined,
    adminRemark: undefined,
  })
  const isAppoint = useMemo(
    () =>
      props.orderDetail.orderNo &&
      props.orderDetail.orderNo.substring(0, 1) === 'A',
    [props.orderDetail]
  )

  // 生命周期start
  useEffect(() => {
    const tempOrderDetail = props.orderDetail
    if (!tempOrderDetail) {
      return
    }
    const boardOrderDTO = tempOrderDetail.boardOrderDTO || {}
    setOrderDetail(tempOrderDetail)
    setRefundOrderDTO({ ...tempOrderDetail.refundOrderDTO })
    if (isAppoint) {
      setData([...tempOrderDetail.appointDetailSkuV2VOList])
      setBoardOrderDTO({ ...boardOrderDTO })
    } else {
      setData([
        {
          ...boardOrderDTO,
          title: boardOrderDTO.spuTitle + ' ' + boardOrderDTO.skuTitle,
        },
      ])
      setBoardOrderDTO({ ...boardOrderDTO })
    }
  }, [props.orderDetail, isAppoint])
  useEffect(() => {
    if (isAppoint) {
      setColumns([...appointColumns])
    } else {
      setColumns([...boardColumns])
    }
  }, [isAppoint])
  // 生命周期end

  // 请求退款数据
  useEffect(() => {
    if (!refundVisible) return
    if (!refundOrderDTO.orderNo) return
    props
      .refundFrameInfo({
        orderNo: refundOrderDTO.orderNo,
      })
      .then((res) => {
        setRefundDetail({
          ...res.data,
          status: orderDetail.status,
          id: refundOrderDTO.id,
          refundOrderNo: refundOrderDTO.refundOrderNo,
        })
        if (res.data.surplusNum > 0) {
          setBoardParams({
            ...boardParams,
            refundDays: res.data.surplusNum,
          })
        } else {
          setBoardParams({ ...boardParams, refundDays: res.data.totalNum })
        }
      })
  }, [refundVisible])

  // 详情数据变化是，初始化寄养的初始值
  useEffect(() => {
    setBoardParams({
      refundOrderId: refundDetail.id,
      refundOrderNo: refundDetail.refundOrderNo,
      refundDays: undefined,
      refundExplain: undefined,
    })
  }, [orderDetail])
  // 退款
  const refundOk = (formData) => {
    setRefundLoading(true)
    let params = {
      refundExplain: appointParams.adminRemark || formData.refundExplain,
      refundAmount: formData.refundAmount,
      refundOrderId: refundOrderDTO.id,
      refundOrderNo: refundOrderDTO.refundOrderNo,
    }
    if (refundOrderDTO.orderType === 'APPOINT') {
      params.refundDays = 1
    }
    props
      .refund(params)
      .then((res) => {
        props.reload('refund', params.refundOrderNo, params.refundExplain)
        setOrderDetail({ ...orderDetail, status: 1200 })
        // 手动修改当前详情的退款备注
        setRefundOrderDTO({
          ...refundOrderDTO,
          adminRemark: params.refundExplain,
        })
        // 重置数据
        if (refundOrderDTO.orderType === 'APPOINT') {
          setAppointParams({
            adminRemark: undefined,
          })
          setRefundVisible(false)
        } else {
          setBoardParams({
            refundOrderId: undefined,
            refundOrderNo: undefined,
            refundDays: undefined,
            refundExplain: undefined,
          })
          setIsShowBoardRefund(false)
        }
        message.success('退款成功')
      })
      .finally(() => {
        setRefundLoading(false)
      })
  }
  const refundCancel = () => {
    setRefundVisible(false)
  }
  // 拒绝
  const refuseOk = () => {
    setRefuseLoading(true)
    props
      .refuse(refuseParams)
      .then((res) => {
        console.log(res)
        props.reload(
          'refuse',
          refuseParams.refundOrderNo,
          refuseParams.adminRemark
        )
        setOrderDetail({ ...orderDetail, status: 1400 })
        // 手动设置当前详情的备注
        setRefundOrderDTO({
          ...refundOrderDTO,
          adminRemark: refuseParams.adminRemark,
        })
        setRefuseVisible(false)
        // 重置数据
        setRefuseParams({
          refundOrderNo: undefined,
          adminRemark: undefined,
        })
      })
      .finally(() => {
        setRefuseLoading(false)
      })
  }
  const refuseCanel = () => {
    setRefuseVisible(false)
  }
  const handleRefund = () => {
    if (refundOrderDTO.orderType === 'APPOINT') {
      setRefundVisible(true)
    } else {
      setIsShowBoardRefund(true)
    }
  }
  const onBoardRefundSuccess = () => {
    setBoardParams({
      refundOrderId: undefined,
      refundOrderNo: undefined,
      refundDays: undefined,
      refundExplain: undefined,
    })
    setIsShowBoardRefund(false)
  }
  // 拒绝的数据操作
  useEffect(() => {
    setRefuseParams({
      ...refuseParams,
      refundOrderNo: refundOrderDTO.refundOrderNo,
    })
  }, [refundOrderDTO])
  // 表格
  const appointColumns = [
    // 预约
    {
      title: '服务名称',
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: '服务项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '美容师',
      dataIndex: 'workerName',
      key: 'workerName',
      width: 120,
    },
    {
      title: '预约时间',
      dataIndex: 'appointStartTime',
      key: 'appointStartTime',
      render: (text, record) =>
        record.appointStartTime
          ? moment(record.appointStartTime).format('YYYY-MM-DD hh:mm:ss')
          : '',
      width: 170,
    },
    {
      title: '单价（元）',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 120,
    },
    {
      title: '小计（元）',
      dataIndex: 'payPrice',
      key: 'payPrice',
      width: 120,
    },
    {
      title: '实付金额(元)',
      dataIndex: 'payPrice',
      key: 'payPrice',
      width: 150,
    },
  ]
  // 寄养
  const boardColumns = [
    {
      title: '寄养房间',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '寄养时间',
      dataIndex: 'boardTime',
      key: 'boardTime',
      width: 200,
    },
    {
      title: '均价（元）',
      dataIndex: 'averagePrice',
      key: 'averagePrice',
      width: 120,
    },
    {
      title: '小计（元）',
      dataIndex: 'averagePriceSubtotal',
      key: 'averagePriceSubtotal',
      width: 120,
    },
  ]
  return (
    <div className="refundDetail-right-con">
      {orderDetail.refundOrderNo ? (
        <div className="refundDetail-right-con">
          <div className="head">
            <div className="top">
              <div>
                <span className="margin-r8">订单编号</span>
                <span>{orderDetail.refundOrderNo || '--'}</span>
              </div>
              <div>
                <span className="margin-r8">处理时间</span>
                <Icon type="iconshijian" />
                <span className="margin-l5">
                  {refundOrderDTO.gmtTenantProcess
                    ? moment(refundOrderDTO.gmtTenantProcess).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : '--'}
                </span>
              </div>
            </div>
            <div className="con">
              <div className="item">
                <span className="label">退款状态</span>
                <span className="value">
                  {refundStatus[orderDetail.status] || '--'}
                </span>
              </div>
              <div className="item">
                <span className="label">退款金额</span>
                <span className="value">
                  {refundOrderDTO.actualRefundAmount
                    ? `￥${refundOrderDTO.actualRefundAmount}`
                    : '￥0'}
                </span>
              </div>
              <div className="item item-row">
                <span className="label">备 注</span>
                <span className="value" title={refundOrderDTO.adminRemark}>
                  {refundOrderDTO.adminRemark || '--'}
                </span>
              </div>
              <div className="item">
                <span className="label">订单来源</span>
                <span className="value">
                  {orderDetail.orderSource === 'CRS' ? '收银系统' : '小程序'}
                </span>
              </div>
            </div>
          </div>
          <div className="petMaster">
            <div className="line-title">
              <div className="line"></div>
              <span>宠物主及宠物信息</span>
            </div>
            <div className="info">
              <div className="box master">
                <div className="top-title"></div>
                <strong className="txt">用户信息</strong>
                <div className="con">
                  <img src={orderDetail.avatarUrl || defaultUserLogo} alt="" />
                  <div className="detail">
                    <p>
                      <span className="username">
                        {orderDetail.userName || '--'}
                      </span>
                      {orderDetail.vipLevel && (
                        <strong>{orderDetail.vipLevel}</strong>
                      )}
                    </p>
                    <p>{orderDetail.phone || '--'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="line-title">
              <div className="line"></div>
              <span>申请信息</span>
            </div>
            <div className="con">
              <div className="item">
                <span className="label">订单编号</span>
                <span className="value">{refundOrderDTO.orderNo || '--'}</span>
              </div>
              <div className="item">
                <span className="label">申请金额</span>
                <span className="value">{`￥${
                  refundOrderDTO.applyRefundAmount || 0
                }`}</span>
              </div>
              <div className="item">
                <span className="label">申请时间</span>
                <span className="value">
                  {refundOrderDTO.gmtCreate
                    ? moment(refundOrderDTO.gmtCreate).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : '--'}
                </span>
              </div>
              <div className="item">
                <span className="label">服务店员</span>
                <span className="value">
                  {orderDetail.bonusAdminList &&
                  orderDetail.bonusAdminList.length > 0
                    ? orderDetail.bonusAdminList
                        .map((iv) => iv.username)
                        .join('、')
                    : '--'}
                </span>
              </div>
              <div className="item item-txt">
                <span className="label long">退款申请原因</span>
                <span className="value">
                  {refundOrderDTO.refundReason || '--'}
                </span>
              </div>
              <div className="item item-txt">
                <span className="label">退款凭证</span>
                <span className="value">
                  <Space>
                    {orderDetail.imgList && orderDetail.imgList.length > 0
                      ? orderDetail.imgList.map((item, index) => {
                          return <Image src={item} key={item} />
                        })
                      : '--'}
                  </Space>
                </span>
              </div>
              <div className="item-row">
                {data && data.length > 0 ? (
                  <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    pagination={false}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {orderDetail.status === 1000 || orderDetail.status === 1100 ? (
            <div className="footer">
              <Space>
                <Button
                  className="btnStyle"
                  onClick={(e) => setRefuseVisible(true)}
                >
                  拒绝
                </Button>
                {orderDetail.status === 1000 ? (
                  <Button
                    className="btnStyle"
                    type="primary"
                    onClick={handleRefund}
                  >
                    退款
                  </Button>
                ) : (
                  <></>
                )}
              </Space>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="empty">
          <Empty
            imageStyle={{
              marginTop: 50,
              height: 120,
            }}
            description={<span>{props.emptyText || '暂无数据'}</span>}
          ></Empty>
        </div>
      )}

      {/* 退款 */}
      <Modal
        className="ant-modal-content-custom"
        visible={refundVisible}
        title="订单退款"
        okText="退款"
        cancelText="关闭"
        onOk={refundOk}
        onCancel={refundCancel}
        confirmLoading={refundLoading}
        width="630px"
        centered
      >
        <div className="appoint">
          <div className="row">
            <div className="label">
              <div className="box">
                <span>退预约</span>
                <span>款</span>
              </div>
            </div>
            <div className="value">
              <strong className="price">
                ¥{refundDetail.actualPrice || refundDetail.payPrice}
              </strong>
              注:退款将按原路返回
            </div>
          </div>
          <div className="row row-padding">
            <div className="label">退款说明</div>
            <div className="value">
              <TextArea
                placeholder="请输入退款说明"
                bordered={false}
                maxLength="200"
                rows={4}
                value={appointParams.adminRemark}
                onChange={(e) =>
                  setAppointParams({
                    ...appointParams,
                    adminRemark: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal>
      <BoardRefund
        orderNo={refundOrderDTO.orderNo}
        orderId={refundOrderDTO.id}
        disabled={true}
        visible={isShowBoardRefund}
        handleConfirm={refundOk}
        onSuccess={onBoardRefundSuccess}
        onCancel={() => setIsShowBoardRefund(false)}
      />
      {/* 拒绝 */}
      <Modal
        className="ant-modal-content-custom"
        visible={refuseVisible}
        title="订单退款"
        okText={props.okText || '确定'}
        cancelText="关闭"
        onOk={refuseOk}
        onCancel={refuseCanel}
        confirmLoading={refuseLoading}
        width="630px"
        centered
      >
        <div className="row" style={{ height: 0, border: 'none' }}></div>
        <div className="row row-padding">
          <div className="label">拒绝说明</div>
          <div className="value">
            <TextArea
              placeholder="请填写内容，不超过200字"
              bordered={false}
              rows={4}
              maxLength="200"
              value={refuseParams.adminRemark}
              onChange={(e) =>
                setRefuseParams({
                  ...refuseParams,
                  adminRemark: e.target.value,
                })
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
