const userAgent = navigator.userAgent.toLowerCase()

export const inApp = /zhuayinpos\/mobileapp/.test(userAgent)
/**
 * 检测是否是内置小票机的机型
 * @returns boolean
 */
export const checkTicketInside = () => {
  try {
    return /^t1$|^t2$|^t2s$|^t2s\s?lite$|^t2\s?mini$|^d2s\s?combo$|^s2$/.test(
      api.deviceModel.toLowerCase()
    )
  } catch (error) {
    return false
  }
}

export const inElectron = !!window.electron

export const canUseClinetDisplay = () => {
  try {
    // eslint-disable-next-line
    new window.escpos.Serial('COM2')
    return true
  } catch (error) {
    console.log('Serial error', error)
    return false
  }
}
