import React, { useEffect, useMemo, useState } from 'react'
import './index.less'
import { inElectron } from '@/utils/device'
String.prototype.trim = function () {
  return this.replace(/(^\s*)|(\s*$)/g, '')
}
export default function RightClickMenu() {
  const [isShow, setIsShow] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [copyElem, setCopyElem] = useState(null)

  useEffect(() => {
    const appendClipboardByRightClick = () => {
      window.removeEventListener('contextmenu', handleContentMenu)
      window.removeEventListener('click', hideRightClickMenuElem)

      window.addEventListener('contextmenu', handleContentMenu)
      window.addEventListener('click', hideRightClickMenuElem)
    }
    const handleContentMenu = (e) => {
      setCopyElem(e.target)
      showRightClickMenuElem(e.clientX, e.clientY)
    }
    const hideRightClickMenuElem = () => {
      setIsShow(false)
    }
    const showRightClickMenuElem = (x, y) => {
      setLeft(x)
      setTop(y)
      setIsShow(true) // window.getSelection().type === 'Range' || this.copyElem.nodeName === 'INPUT'
    }
    inElectron && appendClipboardByRightClick()
  })

  const targetNodeType = useMemo(
    () => (copyElem ? copyElem.nodeName : ''),
    [copyElem]
  )

  const pasteText = () => {
    copyElem.focus()
    document.execCommand('paste')
    // navigator.clipboard.readText().then(text => (this.copyElem.value = text))
  }
  const getSelectText = () => {
    try {
      var selecter = window.getSelection().toString()
      if (selecter != null && selecter.trim() != '') {
        return selecter
      }
    } catch (err) {
      var selecter = document.selection.createRange()
      var s = selecter.text
      if (s != null && s.trim() != '') {
        return s
      }
    }
  }
  const copyText = (text) => {
    if (navigator.clipboard) {
      // clipboard api 复制
      navigator.clipboard.writeText(text)
    } else {
      var textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      // 隐藏此输入框
      textarea.style.position = 'fixed'
      textarea.style.clip = 'rect(0 0 0 0)'
      textarea.style.top = '-1000px'
      // 赋值
      textarea.value = text
      // 选中
      textarea.select()
      // 复制
      document.execCommand('copy', true)
      // 移除输入框
      document.body.removeChild(textarea)
    }
  }
  const handleCopy = () => {
    const text = getSelectText()
    copyText(text)
  }
  return (
    isShow && (
      <div
        className="right-click-menu"
        style={{ top: `${top}px`, left: `${left}px` }}
      >
        <a href="javascript:;" className="item" onClick={handleCopy}>
          复制
        </a>
        <a
          href="javascript:;"
          className={`item ${targetNodeType !== 'INPUT' && 'disabled'}`}
          onClick={pasteText}
        >
          粘贴
        </a>
      </div>
    )
  )
}
