import React, { useEffect, useMemo, useState } from 'react'
import Icon from '@/components/Icon'
import PropTypes from 'prop-types'

import PayStatusModal from '@/components/PayStatusModal'
import { onInputByNumberInput, onDeleteByNumberInput } from '@/utils'
import {
  rechargeAmount,
  getRechargeList,
  getRechargeDetail,
} from '@/redux/actions/order'
import { getMemberDetail } from '@/redux/actions/user'
import PubSub from 'pubsub-js'
import './index.less'
import { connect, useDispatch } from 'react-redux'
import { message, Input, Checkbox, Button } from 'antd'
import { setOrderStatus } from '../../../redux/actions/order'
import AdminIdSelect from '@/components/MySelect/AdminIdSelect'
import { canUseClinetDisplay } from '@/utils/device'
import { USER_IS_NOT_PRINT } from '@/utils/constant'
import * as printer from '@/utils/print'
import SmallKeyboard from '@/components/SmallKeyboard'
import ClientDisplay from '@/utils/ClientDisplay'
import { checkPayCode, getLabelByValueFromEnum } from '@/utils/index'
import { PAY_TYPE } from '@/utils/enum'

const payTypeList = [
  {
    icon: 'iconweixin',
    title: '微信',
    value: 'WXCASH',
    type: 'offline',
    scan: false,
  },
  {
    icon: 'iconzhifubao',
    title: '支付宝',
    value: 'ALICASH',
    type: 'offline',
    scan: false,
  },
  {
    icon: 'iconxianjin',
    title: '现金支付',
    value: 'CASH',
    type: 'offline',
    scan: false,
  },
]

let keydownToken
let scanToken
function Recharge(props) {
  const dispatch = useDispatch()
  const [payIndex, setPayIndex] = useState(0)
  const [payAmount, setPayAmount] = useState('0') // 实收
  const [giveAmount, setGiveAmount] = useState('0')
  const [inputIndex, setInputIndex] = useState(-1)
  const [isPaying, setIsPaying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [packageSelectIndex, setPackageSelectIndex] = useState(null)
  const [packageList, setPackageList] = useState([])

  // 服务人员
  const [adminIds, setAdminIds] = useState(undefined)
  const [settleRemark, setSettleRemark] = useState('')

  const [orderNo, setOrderNo] = useState('')
  const [memberDetail, setMemberDetail] = useState({})
  // 打印
  const [clientDisplay, setClientDisplay] = useState(null)
  const [isPrintTicket, setIsPrintTicket] = useState(false)
  const handlePrintTicketCheck = (e) => {
    const checked = e.target.checked
    setIsPrintTicket(checked)
    if (!checked) {
      window.localStorage.setItem(USER_IS_NOT_PRINT, 'true')
    } else {
      window.localStorage.removeItem(USER_IS_NOT_PRINT)
    }
  }
  const checkPrintStatus = () => {
    const printType = printer.getPrintType()
    const userIsNotPrint =
      window.localStorage.getItem(USER_IS_NOT_PRINT) === 'true'
    if (printType === 'app-inside') {
      try {
        const sunmiPrinter = api.require('sunmiPrinter')
        sunmiPrinter.startService(function (ret, err) {
          const status = ret.status || (err && err.msg === '打印机服务已启动')
          setIsPrintTicket(status && !userIsNotPrint)
        })
      } catch (error) {}
    } else {
      const printName = printer.getPrintName()
      printer.checkPrintStatus(printName).then((status) => {
        setIsPrintTicket(status && !userIsNotPrint)
      })
    }
  }
  const currentPayType = useMemo(() => payTypeList[payIndex], [payIndex])
  /**
   * 打印小票
   */
  const startPrintTicket = (orderno, member) => {
    // 获取订单详情
    props
      .getRechargeDetail({
        orderNo: orderno ? orderno : orderNo,
      })
      .then((res) => {
        const orderDetail = res.data
        const ticketDetail = printer.orderDetail2PrintData({
          ...props,
          skuTotalPrice: payAmount, // 总金额
          actualAmount: payAmount,
          isCombPay: false,
          payAmount,
          payAmount2: 0,
          outAmount: 0,
          orderDetail,
          memberDetail: member ? member : memberDetail,
          printInfo: props.printInfo,
          orderNo: orderDetail.orderNo,
          deskList: [
            {
              price: orderDetail.payAmount,
              amount: orderDetail.payAmount,
              spuTitle: '',
              title: `充${orderDetail.payAmount}送${orderDetail.giftAmount}`,
              num: 1,
              discountedPrice: 0,
            },
          ],
          deductionPrice: 0, // 美容卡抵扣
          receivablePrice: payAmount, // 应收金额
          payTitle: getLabelByValueFromEnum(currentPayType.value, PAY_TYPE),
        })
        console.log('ticketDetail', JSON.stringify(ticketDetail))
        printer.print(ticketDetail)
      })
  }

  const handleDelete = () => {
    if (inputIndex === 0) {
      let m = onDeleteByNumberInput(payAmount)
      setPayAmount(m)
    } else if (inputIndex === 1) {
      let m = onDeleteByNumberInput(giveAmount)
      setGiveAmount(m)
    }
  }

  const handleInput = (text) => {
    if (inputIndex === 0) {
      let m = onInputByNumberInput(payAmount, text)
      setPayAmount(m)
    } else if (inputIndex === 1) {
      let m = onInputByNumberInput(giveAmount, text)
      setGiveAmount(m)
    }
  }

  const handleChange = (text) => {
    if (inputIndex === 0) {
      setPayAmount(text)
    } else if (inputIndex === 1) {
      setGiveAmount(text)
    }
  }
  const handleConfirm = () => {
    if (Number(payAmount) === 0) {
      message.error('请输入充值金额')
      return
    }
    if (loading) {
      return
    }

    if (currentPayType.type === 'online') {
      dispatch(setOrderStatus(null))
    } else {
      dispatch(setOrderStatus(20))
      handleRecharge()
    }
    setIsPaying(true)
  }
  // 结算
  const handleRecharge = (authCode = '') => {
    if (loading) return
    setLoading(true)
    const payChannel = currentPayType.value

    props
      .rechargeAmount({
        assistantId: adminIds, // 服务人员
        remark: settleRemark, // 备注
        giveAmount,
        payAmount,
        payChannel,
        authCode,
        userId: props.userId,
      })
      .then((res) => {
        setTimeout(() => {
          setIsPaying(false)
          props.onSuccess && props.onSuccess()
        }, 1000)
        setOrderNo(res.data.orderNo)
        // 获取会员详情
        if (props.userId) {
          props.getMemberDetail(props.userId).then((data) => {
            setMemberDetail(data)
            // 服务端支付回调比较慢
            setTimeout(() => {
              isPrintTicket && startPrintTicket(res.data.orderNo, data)
            }, 3000)
          })
        }
      })
      .catch(() => {
        setIsPaying(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //
  const handleAdminIdsChange = (values) => {
    setAdminIds(values)
  }

  const handleNumberConfirm = () => {
    setInputIndex(-1)
  }

  const getRechargeList = () => {
    props
      .getRechargeList({
        pageNo: 1,
        pageSize: 99999,
      })
      .then((res) => {
        if (res.data) {
          const list = res.data.items || []
          setPackageList(list)
        }
      })
  }

  useEffect(() => {
    const pg = packageList[packageSelectIndex]
    if (pg) {
      setPayAmount(pg.rechargeAmount)
      setGiveAmount(pg.giveAmount)
    }
  }, [packageSelectIndex])

  useEffect(() => {
    getRechargeList()
    // 检查是否连接打票机
    checkPrintStatus()

    if (canUseClinetDisplay()) {
      setClientDisplay(new ClientDisplay())
    }
  }, [])

  useEffect(() => {
    clientDisplay &&
      clientDisplay.open().then(() => {
        console.log('客显串口已连接')
      })
    return () => {
      clientDisplay && clientDisplay.clear()
    }
  }, [clientDisplay])

  useEffect(() => {
    if (clientDisplay) {
      clientDisplay.setPrice(payAmount)
      clientDisplay.setType(3)
    }
  }, [payAmount, giveAmount])

  useEffect(() => {
    keydownToken && PubSub.unsubscribe(keydownToken)
    keydownToken = PubSub.subscribe('GLOBAL_KEYDOWN', (msg, data) => {
      // 退格键
      if (data.code === 8) {
        handleDelete()
      } else if (data.code === 13) {
        handleConfirm()
      } else if (data.isNumberKey) {
        handleInput(data.text)
      }
    })

    // 监听扫码，用于微信支付宝扫码支付
    scanToken && PubSub.unsubscribe(scanToken)
    scanToken = PubSub.subscribe('GLOBAL_SCAN', (msg, data) => {
      const code = data.text
      if (code && isPaying) {
        // 判断扫码是否是当前支付方式
        const payCodeType = checkPayCode(code)
        if (currentPayType.value === payCodeType) {
          handleRecharge(code)
        } else {
          message.error(`请扫描${currentPayType.title}的支付码`)
        }
      }
    })

    return () => {
      keydownToken && PubSub.unsubscribe(keydownToken)
      scanToken && PubSub.unsubscribe(scanToken)
    }
  }, [payAmount, giveAmount, inputIndex, loading, payIndex, isPaying])
  useEffect(() => {
    /**
     * 是否包含className
     * @param {element} e click返回的元素对象
     * @param {string} classNames 类名
     * @returns
     */
    function hasClassNameElem(e, classNames) {
      let elem = e.target
      let res = false
      while (elem && elem.className && typeof elem.className === 'string') {
        if (classNames.some((item) => elem.className.indexOf(item) > -1)) {
          res = true
          break
        }
        elem = elem.parentNode
      }
      return res
    }
    function handleDocumentClick(e) {
      if (!hasClassNameElem(e, ['input-item', 'number-keyboard-con'])) {
        // if (inputIndex !== -1) return
        setInputIndex(-1)
      }
    }
    document.addEventListener('click', handleDocumentClick, false)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  return (
    <div className="member-recharge-con">
      <div className="m-r-c-container">
        <div className="input-box">
          <div className="input-item" onClick={() => setInputIndex(0)}>
            <div className="title">充值金额</div>
            <div className={`input ${inputIndex === 0 && 'focus'}`}>
              <span className="unit">¥</span>
              <p className="amount">{payAmount}</p>
            </div>
          </div>
          <div className="input-item" onClick={() => setInputIndex(1)}>
            <div className="title">赠送金额</div>
            <div
              className={`input primary-color ${inputIndex === 1 && 'focus'}`}
            >
              <span className="unit">¥</span>
              <p className="amount">{giveAmount}</p>
            </div>
          </div>
        </div>
        <div className="price-package">
          <div className="p-p-t">充值套餐</div>
          <div className="p-p-container">
            <div className="p-list">
              {packageList &&
                packageList.map((iv, index) => (
                  <div
                    key={index}
                    className={`p-item ${
                      index === packageSelectIndex ? 'active' : ''
                    }`}
                    onClick={() => setPackageSelectIndex(index)}
                  >
                    充{iv.rechargeAmount}送{iv.giveAmount}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="block-con">
          <div className="pay-type-con">
            <div className="pay-type-title-list">
              <div className="title">支付方式</div>
              <p>收款记账</p>
            </div>
            <div className="pay-type">
              {payTypeList.map((item, index) =>
                item.value === 'BALANCE' ? null : (
                  <>
                    <div
                      className={`pay-item-con ${
                        index === 0 && !props.userId ? 'disabled' : ''
                      } pay-item-con-${item.value}`}
                      key={item.value}
                      onClick={() => setPayIndex(index)}
                    >
                      <div
                        className={`pay-item ${
                          payIndex === index && 'selected'
                        }`}
                      >
                        <Icon className="icon" type={item.icon} />
                        <span>{item.title}</span>
                      </div>
                    </div>
                    {item.value === 'ALI' && <br />}
                  </>
                )
              )}
            </div>
          </div>
        </div>
        <div className="service-con" style={{ marginBottom: 0 }}>
          <span className="title">服务店员</span>
          <AdminIdSelect
            allowClear
            className="select-con"
            bordered={false}
            showArrow
            placeholder="请选择店员"
            onChange={handleAdminIdsChange}
            value={adminIds}
          ></AdminIdSelect>
        </div>
        <div className="remark-con">
          <span className="title">备注</span>
          <Input.TextArea
            className="textarea"
            placeholder="请输入备注，可输入100字"
            maxLength={100}
            value={settleRemark}
            onChange={(e) => setSettleRemark(e.target.value)}
            autoSize
          ></Input.TextArea>
        </div>
        <SmallKeyboard
          handleInput={handleInput}
          handleDelete={handleDelete}
          handleNumberConfirm={handleNumberConfirm}
          handleChange={handleChange}
          inputIndex={inputIndex}
          value={[payAmount, giveAmount]}
          selector=".input.focus"
        />
      </div>
      <div className="footer-con">
        <Checkbox checked={isPrintTicket} onChange={handlePrintTicketCheck}>
          打印小票
        </Checkbox>
        <div className="r">
          <div className="amount">
            实收总额 <span>¥{payAmount}</span>
          </div>
          <Button type="primary" className="btn" onClick={handleConfirm}>
            完成结算
          </Button>
        </div>
      </div>
      <PayStatusModal
        visible={isPaying}
        handleClose={() => setIsPaying(false)}
      />
    </div>
  )
}

Recharge.propTypes = {
  userId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
}

export default connect(
  (state) => ({
    printInfo: state.user.printInfo,
    state,
  }),
  {
    rechargeAmount,
    getRechargeList,
    getRechargeDetail,
    getMemberDetail,
  }
)(Recharge)
