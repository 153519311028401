import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal, Button, Spin } from 'antd'
import guangxian from '@/assets/common/guangxian.png'
import zhifuchenggong from '@/assets/common/zhifuchenggong.png'
import shoukuanzhong from '@/assets/common/shoukuanzhong.png'
import guanbi from '@/assets/common/guanbi.png'

import './index.less'
function PayStatusModal(props) {
  return (
    <Modal
      visible={props.visible}
      modalRender={(model) => model}
      wrapClassName="scan-modal-wrap"
      closable={false}
      footer={null}
    >
      {props.order.orderStatus === 30 ? (
        <div className="pay-success">
          <img src={zhifuchenggong} alt="" />
          <p>支付成功</p>
        </div>
      ) : props.order.orderStatus === 20 ? (
        <div className="paying-con">
          <div className="paying">
            <img src={shoukuanzhong} alt="" />
          </div>
          <div className="text">
            <Spin className="spin" />
            <span>支付中</span>
          </div>
          <div className="close" onClick={props.handleClose}>
            <img src={guanbi} alt="" />
          </div>
        </div>
      ) : (
        <div className="scan-con">
          <div className="scan">
            <img src={guangxian} alt="" />
          </div>
          <p>请扫描客户付款码</p>
          <Button type="primary" className="btn" onClick={props.handleClose}>
            返回
          </Button>
        </div>
      )}
    </Modal>
  )
}

PayStatusModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}

export default connect((state) => ({
  order: state.order,
}))(PayStatusModal)
