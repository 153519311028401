import React, { useMemo } from 'react'
import Icon from '@/components/Icon'
import { Image } from 'antd'
import './item.less'
import defaultImg from '@/assets/menu/defaultLogo.png'
import seal from '@/assets/appointment/seal.png'
import { SERVICE_TYPE_MAP, EMUN_APPOINT_STATUS } from '@/utils/enum'
export default function CItem(props) {
  const spuListElem = useMemo(() => {
    let res = []
    if (!Array.isArray(props.appointSectionInfoSkuDTOList)) {
      return null
    }

    if (props.appointSectionInfoSkuDTOList) {
      res = props.appointSectionInfoSkuDTOList.slice(0, 2).map((item, index) => (
        <h2 className="sput" key={index}>
          {item.spuTitle}-{item.skuTitle}
        </h2>
      ))
    }
    if (props.appointSectionInfoSkuDTOList.length > 2) {
      res.push(<h2 className="sput" key={props.appointSectionInfoSkuDTOList.length}>...</h2>)
    }
    return res
  }, [props.appointSectionInfoSkuDTOList])
  return (
    <div className="c-item" onClick={() => props.openOrderDetail(props)}>
      <div className="seal">
        {props.status === 500 ? (
          <Image className="img" src={seal} preview={false} fallback={seal} />
        ) : (
          ''
        )}
      </div>
      <div className="title">
        <span className="t" title={props.nickname}>
          {props.nickname}
        </span>
        <div className="s">
          {props.status === 100 ? (
            <>
              <Icon
                className="icon"
                type={props.orderSource === 'MP' ? 'iconshouji' : 'icondiannao'}
              />
              &nbsp;
            </>
          ) : (
            ''
          )}
          {props.goRefund ? (
            <span className="status s-600">{EMUN_APPOINT_STATUS()[600]}</span>
          ) : (
            <span className={`status s-${props.status}`}>
              {EMUN_APPOINT_STATUS()[props.status]}
            </span>
          )}
        </div>
      </div>
      <p className="phone">{props.phone}</p>
      {spuListElem}
      <div className="row">
        <Image
          className="petImg"
          src={props.petImage || defaultImg}
          fallback={defaultImg}
          preview={false}
        />
        <div className={`r-s ${props.serviceType > 0 ? 's2' : ''}`}>
          {SERVICE_TYPE_MAP[props.serviceType] &&
            SERVICE_TYPE_MAP[props.serviceType].label}
        </div>
      </div>
    </div>
  )
}
