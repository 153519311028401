import React, { useEffect, useState } from 'react'
import { Modal, InputNumber, message } from 'antd'
import AdminIdSelect from '@/components/MySelect/AdminIdSelect'
import { getAdminAll } from '@/redux/actions/user'
import './Refund.less'
import { connect } from 'react-redux'

const Refund = (props) => {
  const { orderDetail = {} } = props
  const [adminIds, setAdminIds] = useState('')
  const [admin, setAdmin] = useState(null)
  const [backPrice, setBackPrice] = useState('')
  const [adminIdOptions, setAdminIdOptions] = useState([])

  const handleConfirm = () => {
    if (backPrice === '') return message.warning('请输入退款金额')
    props.onOk &&
      props.onOk({
        backAdminId: admin && admin.id,
        backAdminName: admin && admin.username,
        backPrice,
        id: orderDetail.id,
      })
  }

  const handleCancel = () => {
    setBackPrice('')
    setAdminIds('')
    props.onCancel && props.onCancel()
  }
  const handleChange = (values) => {
    const adminobj = adminIdOptions.find((iv) => iv.id === values)
    console.log(adminobj)
    setAdmin(adminobj)
    setAdminIds(values)
  }
  const limitDecimals = (value) => {
    const reg = /^(-)*(\d+)\.(\d\d).*$/
    if (typeof value === 'string') {
      return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
    } else if (typeof value === 'number') {
      return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
    } else {
      return ''
    }
  }
  useEffect(() => {}, [orderDetail])

  useEffect(() => {
    getAdminAll()().then((res) => {
      setAdminIdOptions(res.data)
    })
  }, [])
  return (
    <Modal
      visible={props.visible}
      onCancel={handleCancel}
      onOk={handleConfirm}
      destroyOnClose={true}
      title="退卡操作"
      wrapClassName="ant-modal-content-custom"
      centered="center"
      cancelText="关闭"
      okText="退款"
      confirmLoading={props.loading}
    >
      <div className="timeCard-refund-modal-con">
        <div className="c-row">
          <div className="label">
            <span className="r-t">订单信息</span>
          </div>
          <div className="value">
            <span className="v-col">
              <span className="v-c-t">实付款</span>¥
              {orderDetail.payPrice || '0'}
            </span>
          </div>
        </div>
        <div className="c-row">
          <div className="label">
            <span className="r-t">退款金额</span>
          </div>
          <div className="value">
            <span className="price">
              ¥{' '}
              <InputNumber
                value={backPrice}
                min={0}
                autoFocus
                formatter={limitDecimals}
                parser={limitDecimals}
                onChange={(val) => {
                  if (val > orderDetail.payPrice) {
                    setBackPrice(orderDetail.payPrice)
                  } else {
                    setBackPrice(val)
                  }
                }}
                style={{ width: 60 }}
                bordered={false}
              />
            </span>
            <span>注:退款金额将原路返回支付账户</span>
          </div>
        </div>
        <div className="c-row">
          <div className="label">
            <span className="r-t">服务人员</span>
          </div>
          <div className="value">
            <AdminIdSelect
              style={{ flex: '1' }}
              allowClear
              className="select-con"
              bordered={false}
              showArrow
              placeholder="请选择店员"
              onChange={handleChange}
              value={adminIds}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default connect(null, {})(Refund)
