import React, { useState, useEffect } from 'react'
import './home.less'
import HomeMember from '@/components/Home/Member'
import HomeGoods from '@/components/Home/Goods'
import HomeCashier from '@/components/Home/Cashier'
import HomeWithdrawal from '@/components/Home/Withdrawal'
import Cash from '@/components/Cash'
import { Drawer } from 'antd'
import { addGoodsCashier } from '@/redux/actions/goods'
import { setMemberAll } from '@/redux/actions/home'
import {
  getCrsOrderGetCalculateAmount,
  deleteStoreOrder,
  deductionCardService,
} from '@/redux/actions/order'
import { connect } from 'react-redux'
import { HomeContext } from '@/components/Home/context-manager'
import { useHome } from '@/components/Home/hooks/useHome'

function Home(props) {
  // 取单
  const [withdrawalVisible, setWithdrawalVisible] = useState(false)
  // 是否扫码
  const [disableSearchScan, setDisableSearchScan] = useState(false)
  // 是否添加商品
  const [isAddItem, setIsAddItem] = useState(false)

  const [isClearMemberList, setIsClearMemberList] = useState(false)

  // 1.2021年6月16日16:05:50 后台金额刷新问题
  const [userClear, setUserClear] = useState(null)
  // 2.2021年6月16日16:05:50 后台金额刷新问题
  const [userAdd, setUserAdd] = useState(null)

  const [pendingOrderId, setPendingOrderId] = useState(null)

  const [orderNo, setOrderNo] = useState('')
  const [userId, setUserId] = useState('')
  const [cashVisible, setCashVisible] = useState(false)
  const useHomeHook = useHome(props)

  const onClose = () => {
    setWithdrawalVisible(false)
    props.addGoodsCashier([])
    setDisableSearchScan(false)
  }

  const getCrsOrderGetCalculateAmount = () => {
    const params = {
      userId: props.isMember
        ? props.memberDetail
          ? props.memberDetail.id
          : ''
        : null,
      skus: props.deskList.map((iv) => {
        if (!iv.isNoCodeGoods) {
          iv.isNoCodeGoods = false
        }
        return iv
      }),
      // pendingOrderId, // 2021年8月20日16:15:01 #ZYCRS-1005
      userClear,
      userAdd,
    }
    props
      .getCrsOrderGetCalculateAmount(params)
      .then((res) => {
        const skus = res.data.calculateAmountVOs
        props.addGoodsCashier(skus)
        useHomeHook.deductionCardService(skus)
        setPendingOrderId(null)
        setUserClear(null)
        setUserAdd(null)
      })
      .finally(() => {
        setIsAddItem(false)
      })
  }

  const init = () => {
    if (props.deskList.length > 0) {
      getCrsOrderGetCalculateAmount()
    }
  }

  const handleCashSuccess = () => {
    setCashVisible(false)
    props.addGoodsCashier([])
    props.setMemberAll({
      memberList: [],
      memberDetail: null,
      historyList: [],
      isMember: false,
    })
    setDisableSearchScan(false)
    setIsClearMemberList(true)
  }
  const handleCashClose = () => {
    console.log('handleCashClose')
    props.deleteStoreOrder({ id: orderNo }).finally((res) => {
      setCashVisible(false)
      setDisableSearchScan(false)
    })
  }

  useEffect(() => {
    // 没有会员,收银台商品 userDeductionId 删除
    if (!props.isMember) {
      const newDeskList = props.deskList.map((iv) => ({
        ...iv,
        userDeductionId: undefined,
      }))
      props.addGoodsCashier(newDeskList)
    }
    init()
  }, [props.isMember])
  useEffect(() => {
    if (isAddItem) {
      init()
    }
  }, [isAddItem])

  return (
    <HomeContext.Provider
      value={{
        disableSearchScan,
        setDisableSearchScan,
        isAddItem,
        setIsAddItem,
        isClearMemberList,
        setIsClearMemberList,
        pendingOrderId,
        setPendingOrderId,
        setUserClear,
        userClear,
        setUserAdd,
      }}
    >
      <div className="home">
        <HomeMember />
        <HomeGoods />
        <HomeCashier
          openWithdrawal={() => setWithdrawalVisible(true)}
          setCashVisible={setCashVisible}
          setUserId={setUserId}
          setOrderNo={setOrderNo}
        />
        <Drawer
          title="取单"
          placement="right"
          width="auto"
          destroyOnClose={true}
          onClose={onClose}
          visible={withdrawalVisible}
        >
          <HomeWithdrawal setWithdrawalVisible={setWithdrawalVisible} />
        </Drawer>
        <Drawer
          title="结算"
          placement="right"
          width="auto"
          onClose={handleCashClose}
          visible={cashVisible}
          destroyOnClose={true}
        >
          <Cash
            userId={userId}
            orderNo={orderNo}
            deskList={props.deskList}
            onSuccess={handleCashSuccess}
          />
        </Drawer>
      </div>
    </HomeContext.Provider>
  )
}

export default connect(
  (state) => ({
    isMember: state.home.isMember,
    memberDetail: state.home.memberDetail,
    deskList: state.goods.goodsCashier,
  }),
  {
    addGoodsCashier,
    getCrsOrderGetCalculateAmount,
    deleteStoreOrder,
    setMemberAll,
    deductionCardService,
  }
)(Home)
