import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// 子组件
import RefundDetail from '../../components/Refund/RefundDetail'
import { connect } from 'react-redux'
import {
  getList,
  refundDisagree,
  agreeRefund,
  refundFrameInfo,
} from '../../redux/actions/refund'
import Icon from '../../components/Icon'
import { Input, Select, DatePicker, Row } from 'antd'
import InfiniteScrollList from '../../components/InfiniteScrollList'
import {
  PAY_TYPE,
  refundStatus,
  shopColumns,
  sourceColumns2,
} from '../../utils/enum'
import './index.less'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
const Search = Input.Search
function Refund(props) {
  // 组件数据
  const [pagin, setPagin] = useState({
    pageNo: 1,
    pageSize: 10
  })
  const [params, setParams] = useState({
    endDate: null,
    startDate: null,
    search: '',
    orderType: 'APPOINT',
    orderSource: '',
  })
  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [orderDetail, setOrderDetail] = useState({})
  const [selectIndex, setSelectIndex] = useState(0)
  const [defaultType, setDefaultType] = useState(undefined)
  const [defaultSource, setDefaultSource] = useState(undefined)
  const [startTime, setStartTime] = useState(undefined)
  const [endTime, setEndTime] = useState(undefined)
  const history = useHistory()
  // 解构props
  const { getList, refundDisagree, agreeRefund, refundFrameInfo } = props

  // 生命周期
  useEffect(() => {
    if (history.location.state) {
      const { orderType } = history.location.state
      setDefaultType(orderType)
    } else {
      setDefaultType('APPOINT')
      setDefaultSource(undefined)
    }
  }, [history.location.state])

  // 设置开始日期禁用事件
  const disabledDateStart = (current) => {
    if (endTime) {
      return current >= endTime
    }
  }
  // 设置结束日期禁用事件
  const disabledDateEnd = (current) => {
    return current && current <= startTime
  }
  // 事件
  // 获取数据
  const getData = (params) => {
    if (loading) return

    setLoading(true)
    let tempParams = { ...params, ...pagin, startDate: startTime, endDate: endTime }
    if (history.location.state) {
      tempParams = {
        ...tempParams,
        ...history.location.state,
        ...pagin,
        search: history.location.state.orderPhone,
      }
    }
    getList(tempParams)
      .then((res) => {
        const data = res.data
        const itemList = data.items
        if (data.pageNo === 1 && itemList.length > 0) {
          selectDetail(itemList, 0)
        }
        if (pagin.pageNo === 1) {
          setList(itemList)
        } else {
          setList(list.concat(itemList))
        }
        if (history.location.state) {
          history.replace({ ...history.location, state: undefined })
        }
        const totalPageNo = Math.ceil(data.count / pagin.pageSize)
        setHasMore(pagin.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
        tempParams = {}
      })
  }
  // 初始化数据
  const init = () => {
    setSelectIndex(0)
    setHasMore(true)
    setOrderDetail({
      payChannel: '',
      skus: [],
      totalSkuNum: 0,
      orderNo: '0',
      skuTotalPrice: 0,
      payAmount: 0,
      discountAmount: 0,
      gmtPay: '--',
    })
  }
  // 上拉加载更多
  const handleLoadMore = () => {
    if (!hasMore) return
    setPagin({
      ...pagin,
      pageNo: pagin.pageNo + 1
    })
  }
  // 格式化日期
  const formatDate = (date) => {
    return date
      ? moment(date).format('YYYY-MM-DD HH:mm:ss')
      : '---------------------'
  }
  // 切换详情
  const changeDetail = (index) => {
    setSelectIndex(index)
    setOrderDetail(list[index])
  }
  // 获取详情
  const selectDetail = (itemList, index) => {
    if (itemList.length === 0) {
      return
    }
    setOrderDetail({
      ...itemList[index],
      payChannelText: PAY_TYPE[orderDetail.payChannel],
    })
  }
  // 传递给子组件的方法，当子组件操作拒绝或退款时需通知父组件刷新一下
  const isGetList = (type, refundOrderNo, adminRemark) => {
    const index = list.findIndex((item) => item.refundOrderNo === refundOrderNo)
    const newList = cloneDeep(list)
    if (type === 'refund' && newList[index].status) {
      newList[index].status = 1200
    } else {
      newList[index].status = 1400
    }
    newList[index].refundOrderDTO.adminRemark = adminRemark
    setList(newList)
  }
  const handleSearch = (text) => {
    init()
    setParams({ ...params, search: text })
    setPagin({
      ...pagin,
      pageNo: 1,
    })
  }
  // 检测到搜索的那块区域数据发生变化执行的
  useEffect(() => {
    getData(params)
  }, [params, pagin])
  // 页面渲染
  return (
    <div className="refund-main">
      <div className="left">
        <div className="left-con">
          <div className="search">
            <Row className="search-input">
              <Search
                placeholder="会员手机号"
                allowClear
                className="search-button"
                prefix={<Icon type="iconsousuo" />}
                enterButton="搜索"
                onSearch={handleSearch}
              />
            </Row>
            <Row className="row-item">
              <p>起始时间</p>
              <DatePicker
                placeholder="请选择起始时间"
                format="YYYY-MM-DD 00:00"
                disabledDate={disabledDateStart}
                onChange={(date, dateString) => {
                  setStartTime(new Date(dateString).getTime())
                }}
              />
            </Row>
            <Row className="row-item">
              <p>起始时间</p>
              <DatePicker
                placeholder="请选择起始时间"
                format="YYYY-MM-DD 23:59"
                disabledDate={disabledDateEnd}
                onChange={(date, dateString) => {
                  setEndTime(new Date(dateString).getTime())
                }}
              />
            </Row>
            <Row className="row-item">
              <p>订单类型</p>
              <Select
                placeholder="请选择订单类型"
                onChange={(val) => {
                  setParams({ ...params, pageNo: 1, orderType: val })
                  init()
                }}
                key={defaultType}
                defaultValue={defaultType}
              >
                {shopColumns.map((item) => {
                  return (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  )
                })}
              </Select>
            </Row>
            <Row className="row-item">
              <p>订单来源</p>
              <Select
                placeholder="请选择订单来源"
                allowClear
                onChange={(val) => {
                  setParams({ ...params, pageNo: 1, orderSource: val })
                  init()
                }}
                key={defaultSource}
                defaultValue={defaultSource}
              >
                {sourceColumns2.map((item) => {
                  return (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  )
                })}
              </Select>
            </Row>
          </div>
          <InfiniteScrollList
            className="info"
            handleOnLoad={handleLoadMore}
            hasMore={hasMore}
            loading={loading}
          >
            {list.map((item, index) => (
              <div
                className={`item ${selectIndex === index ? 'active' : ''}`}
                key={item.refundOrderNo}
                onClick={changeDetail.bind(this, index)}
              >
                <div className="order">{item.refundOrderNo}</div>
                <div className="status">{refundStatus[item.status]}</div>
                <p>
                  <span className="date">
                    {formatDate(
                      item.appointStartTime ||
                        item.gmtCreate ||
                        item.gmtPay ||
                        item.createTime
                    )}
                  </span>
                  <span className="price">{item.amount || '0'}</span>
                </p>
              </div>
            ))}
          </InfiniteScrollList>
        </div>
      </div>
      <div className="right">
        <RefundDetail
          orderDetail={orderDetail}
          refuse={refundDisagree}
          refund={agreeRefund}
          refundFrameInfo={refundFrameInfo}
          reload={isGetList}
        />
      </div>
    </div>
  )
}

export default connect(null, {
  getList,
  refundDisagree,
  agreeRefund,
  refundFrameInfo,
})(Refund)
