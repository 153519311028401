import React from 'react'
import './baofu.less'

export default function Index() {
  return (
    <div className="global-notice-container">
      <h1>青松管线下收款码政策变动相关情况公告</h1>
      <p className="subtitle">
        为更好维护广大用户的实际权益，根据《中国人民银行关于加强支付受理终端及相关业务管理的通知》对商户收款码进行管理，特做出以下调整：
      </p>
      <div className="item-list">
        <div className="item-con">
          <div className="item-num">1</div>
          <div className="item-content">
            <h2>
              快捷支付“支付宝”功能延期至2022年3月7日停止（“微信支付”功能于2022年3月3号停止）
            </h2>
            <p className="subtitle">
              为了使用青松管收银系统的商户能有充足的时间进行商户收款码的调整，特跟有关部门协商青松管收银系统快捷支付“支付宝”功能延期至2022年3月7日停止（“微信支付”功能于2022年3月3号停止），在此期间相对应的快捷支付“支付宝”功能可以正常使用。
            </p>
          </div>
        </div>
        <div className="item-con">
          <div className="item-num">2</div>
          <div className="item-content">
            <h2>
            青松管线上小程序支付功能、青松管收银系统的记账功能不受本次政策调整所影响
            </h2>
            <p className="subtitle">
            青松管线上小程序支付功能、青松管收银系统的记账功能可以正常使用，不受本次政策调整所影响。请各位用户放心使用。
            </p>
          </div>
        </div>
        <div className="item-con">
          <div className="item-num">3</div>
          <div className="item-content">
            <h2>
            在停用前尽快申请商户收款码
            </h2>
            <p className="subtitle">
            请各位商户在停用前尽快申请商户收款码，可以通过支付宝、微信申请个人经营收款码或商户收款码，如有疑问请联系青松管客服。
            </p>
          </div>
        </div>
      </div>
      <div className="right-content">
        <h2>杭州青松管科技有限公司</h2>
        <p className="date">2022.03.02</p>
      </div>
    </div>
  )
}
