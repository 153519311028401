import React from 'react'
import debounce from 'lodash/debounce'
import { Spin, Select } from 'antd'
/**
 * 防抖远程搜索select，用于搜索会员
 * @returns
 */
export default function DebounceSelect ({
  fetchOptions,
  debounceTimeout = 300,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const fetchRef = React.useRef(0)
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  return (
    <Select
      allowClear={props.allowClear}
      onClear={props.onClear}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
} // Usage of DebounceSelect

DebounceSelect.defaultProps = {
  onClear: () => ({}),
  allowClear: false
}
