import { ADD_GOODS_CASHIER } from '../constant'

const initState = { goodsCashier: [] }
export const createGoods = (perState = initState, action) => {
  
  const { type, data } = action
  switch (type) {
    case ADD_GOODS_CASHIER:
      return { ...perState, goodsCashier: data }
    default:
      return perState
  }
}
export default createGoods