import React, { useState, useEffect } from 'react'
import './cars.less'
import { Row, Col, InputNumber, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Icon from '../../../Icon'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import ChooseTimeCard from '@/components/Home/ChooseTimeCard'
function Cars(props) {
  const [cardVisible, setCardVisible] = useState(false)
  const [chooseItem, setChooseItem] = useState(null)
  const [chooseItemIndex, setChooseItemIndex] = useState('')
  const [userId, setUserId] = useState(null)
  const limitDecimals = (value) => {
    const reg = /^(-)*(\d+)\.(\d\d).*$/
    if (typeof value === 'string') {
      return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : ''
    } else if (typeof value === 'number') {
      return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : ''
    } else {
      return ''
    }
  }

  // 删除
  const onDelete = (index) => {
    props.onDelete(index)
  }

  // 添加
  const onAdd = (iv, index) => {
    props.onAdd(iv, index)
  }

  // 减少
  const onReduce = (iv, index) => {
    iv.num = iv.num > 1 ? iv.num - 1 : 1
    props.onReduce(iv, index)
  }
  // 修改数量
  const onChangeNum = (num, skuNumber, index) => {
    if (!num) num = 1
    props.onChangeNum(num, skuNumber, index)
  }
  // 修改价格
  const updateAsyncDeskItem = (val, item, key, index) => {
    if (!val) val = 0
    props.updateAsyncDeskItem(val, item, key, index)
  }
  // 选择美容卡
  const chooseTimeCard = (item, index) => {
    props.chooseTimeCard(item, index)
  }

  const limitDecimalsNumber = (value) => {
    return value.replace(/^(0+)|[^\d]+/g, '')
  }
  // 美容卡
  const handlClickItem = (iv, index) => {
    const item = { ...chooseItem }
    if (!item.userDeductionId) {
      item.userDeductionId = iv.id
      chooseTimeCard(item, chooseItemIndex)
    } else {
      if (item.userDeductionId === iv.id) {
        delete item.userDeductionId
        chooseTimeCard(item, chooseItemIndex)
      } else {
        item.userDeductionId = iv.id
        chooseTimeCard(item, chooseItemIndex)
      }
    }
    setCardVisible(false)
  }

  const openChooseCard = (iv, index) => {
    if (userId) {
      setCardVisible(true)
      setChooseItem(iv)
      setChooseItemIndex(index)
    }
  }

  useEffect(() => {
    setUserId(props.memberDetail?.id || props?.userId)
  }, [props.userId, props.memberDetail])

  useEffect(() => {}, [props.initData])

  return (
    <>
      <div className="c-cars">
        {props.deskList.map((iv, index) => (
          <div className="item" key={index}>
            <div className="title">
              <h2 className="t">{`${iv.status === 0 ? '(已下架) ' : ''}${
                iv.spuTitle || ''
              } ${iv.title || ''}`}</h2>
              <CloseOutlined
                className="close"
                onClick={() => onDelete(index)}
              />
            </div>
            {iv.type === 'SERVICE' && userId && iv.hasCard ? (
              <Row className="c-row">
                <Col span={9} className="c-col">
                  零售价 ¥{iv.price}
                </Col>
                <Col span={6} className="c-col"></Col>
                <Col span={9} className="c-col">
                  <span
                    style={{
                      width: 48,
                      marginRight: 5,
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    折扣价
                  </span>
                  {props.isAction ? (
                    <InputNumber
                      max={999999}
                      min={0}
                      formatter={limitDecimals}
                      parser={limitDecimals}
                      value={iv.discountedPrice}
                      defaultValue={iv.discountedPrice}
                      onChange={(val) =>
                        updateAsyncDeskItem(val, iv, 'discountedPrice', index)
                      }
                    />
                  ) : (
                    iv.discountedPrice
                  )}
                </Col>
                <Row className="c-row" style={{ width: '100%' }}>
                  {iv.hasCard ? (
                    <Col span={6} className="c-col">
                      {iv.userDeductionId ? (
                        <Button
                          type="primary"
                          onClick={() => openChooseCard(iv, index)}
                          ghost
                        >
                          <Icon type="iconxuanzhong1"></Icon>
                          美容卡
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => openChooseCard(iv, index)}
                        >
                          美容卡
                        </Button>
                      )}
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col span={9} className="c-col">
                    <Icon
                      className="action r"
                      type="iconshanjian"
                      onClick={() => onReduce(iv, index)}
                    />
                    <InputNumber
                      className="nflex"
                      value={iv.num}
                      onChange={(val) => onChangeNum(val, iv.skuNumber, index)}
                      min={1}
                      formatter={limitDecimalsNumber}
                      parser={limitDecimalsNumber}
                      // onBlur={() => onChangeNum}
                    />
                    <Icon
                      className="action l"
                      type="iconzengjia"
                      onClick={() => onAdd(iv, index)}
                    />
                  </Col>
                  <Col span={9} className="c-col">
                    <span
                      style={{
                        width: 48,
                        marginRight: 5,
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      小&nbsp;&nbsp;&nbsp;计
                    </span>
                    {props.isAction ? (
                      <InputNumber
                        max={999999}
                        min={0}
                        formatter={limitDecimals}
                        parser={limitDecimals}
                        value={iv.subtotal}
                        defaultValue={iv.subtotal}
                        onChange={(val) =>
                          updateAsyncDeskItem(val, iv, 'subtotal', index)
                        }
                      />
                    ) : (
                      iv.subtotal
                    )}
                  </Col>
                </Row>
              </Row>
            ) : (
              <Row className="c-row">
                <Col span={12} className="c-col">
                  零售价 ¥{iv.price}
                </Col>
                <Col span={12} className="c-col">
                  <span
                    style={{
                      width: 48,
                      marginRight: 5,
                      textAlign: 'right',
                      display: 'inline-block',
                    }}
                  >
                    折扣价
                  </span>
                  {props.isAction ? (
                    <InputNumber
                      max={999999}
                      min={0}
                      formatter={limitDecimals}
                      parser={limitDecimals}
                      value={iv.discountedPrice}
                      defaultValue={iv.discountedPrice}
                      onChange={(val) =>
                        updateAsyncDeskItem(val, iv, 'discountedPrice', index)
                      }
                    />
                  ) : (
                    iv.discountedPrice
                  )}
                </Col>
                <Col span={12} className="c-col inputNumber">
                  <Icon
                    className="action r"
                    type="iconshanjian"
                    onClick={() => onReduce(iv, index)}
                  />
                  <InputNumber
                    className="nflex"
                    value={iv.num}
                    onChange={(val) => onChangeNum(val, iv.skuNumber, index)}
                    min={1}
                    formatter={limitDecimalsNumber}
                    parser={limitDecimalsNumber}
                    // onBlur={() => onChangeNum}
                  />
                  <Icon
                    className="action l"
                    type="iconzengjia"
                    onClick={() => onAdd(iv, index)}
                  />
                </Col>
                <Col span={12} className="c-col">
                  <span
                    style={{
                      width: 48,
                      marginRight: 5,
                      display: 'inline-block',
                      textAlign: 'right',
                    }}
                  >
                    小&nbsp;&nbsp;&nbsp;计
                  </span>
                  {props.isAction ? (
                    <InputNumber
                      max={999999}
                      min={0}
                      formatter={limitDecimals}
                      parser={limitDecimals}
                      value={iv.subtotal}
                      defaultValue={iv.subtotal}
                      onChange={(val) =>
                        updateAsyncDeskItem(val, iv, 'subtotal', index)
                      }
                    />
                  ) : (
                    iv.subtotal
                  )}
                </Col>
              </Row>
            )}
          </div>
        ))}
      </div>
      <ChooseTimeCard
        visible={cardVisible}
        handleClose={() => setCardVisible(false)}
        onClick={handlClickItem}
        userId={userId}
        chooseGoodsItem={chooseItem}
        type="choose"
      />
    </>
  )
}

Cars.defaultProps = {
  isAction: true,
  deskList: [],
}
Cars.propTypes = {
  onAdd: propTypes.func.isRequired,
  onReduce: propTypes.func.isRequired,
  onChangeNum: propTypes.func.isRequired,
}
export default connect(
  (state) => ({
    memberDetail: state.home.memberDetail,
  }),
  {}
)(Cars)
