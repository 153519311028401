import React, { useState, useEffect } from 'react'
import { Input, Row, Col, Button } from 'antd'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import { connect } from 'react-redux'
import Icon from '@/components/Icon'
import TimeCardItem from '@/components/Home/TimeCardItem'
import './index.less'

const Search = Input.Search

export const TimeCardList = (props) => {
  // 搜索条件
  const [keyword, setKeyword] = useState('')
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 20,
  })
  // 搜索
  const onSearch = async (keyword) => {
    // if (keyword === '') {
    //   setLoading(false)
    //   setHasMore(false)
    //   setKeyword('')
    //   setList([])
    //   return
    // }
    setHasMore(true)
    setSearchLoading(true)
    setKeyword(keyword)
    setParams({
      pageNo: 1,
      pageSize: 20,
    })
  }
  const handleLoadMore = () => {
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }
  const queryList = () => {
    if (!hasMore) return
    setLoading(true)
    props
      .fetchOption({
        ...params,
        keyword,
      })
      .then((res) => {
        const newList = res.list
        const pageObj = res.pageObj
        if (params.pageNo === 1) {
          setList(newList)
        } else {
          setList(list.concat(newList))
        }
        setHasMore(params.pageNo < pageObj.totalPageNo)
      })
      .finally((res) => {
        setLoading(false)
        setSearchLoading(false)
      })
  }

  useEffect(() => {
    // 服务端退款延迟
    if (!props.refundVisible) {
      setTimeout(queryList, 1000)
      setTimeout(queryList, 2000)
      setTimeout(queryList, 3000)
      return
    }
    queryList()
  }, [params, props.refundVisible])

  useEffect(() => {
    if (props.params) {
      setHasMore(true)
      setParams({
        ...params,
        ...props.params,
      })
    }
  }, [props.params])
  return (
    <div className={`choose-time-card ${props.type ? props.type : ''}`}>
      {props.type !== 'detail' ? (
        <div className="search">
          <Search
            placeholder="请输入美容卡名称"
            allowClear
            className="search-button"
            prefix={<Icon type="iconsousuo" />}
            enterButton="搜索"
            onSearch={onSearch}
            defaultValue={props.keyword}
            value={props.keyword}
            // onChange={(e) => onSearchChange(e.target.value)}
            loading={searchLoading}
          />
        </div>
      ) : (
        <></>
      )}
      <Row className="row">
        <InfiniteScrollList
          className="m-row"
          hasMore={hasMore}
          handleOnLoad={handleLoadMore}
          loading={loading}
        >
          {list.map((iv, index) => (
            <Col
              span={24}
              className={`m-col ${
                props.type === 'choose'
                  ? 'choose'
                  : props.currentIndex === index
                  ? 'base active'
                  : 'base'
              }`}
              key={index}
              onClick={() => props.type === 'base' && props.onClick(iv, index)}
            >
              <TimeCardItem {...iv} />
              {props.type === 'choose' ? (
                <div className="m-info-btn">
                  {props.currentIndex === index ||
                  props.currentIndex === iv.id ? (
                    <Button
                      type="primary"
                      style={{ width: 'auto', padding: '0 12px', minWidth: 90 }}
                      ghost
                      onClick={() => props.onClick(iv, index)}
                    >
                      <Icon type="iconxuanzhong1"></Icon>
                      取消选择
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      style={{ width: 'auto', padding: '0 12px', minWidth: 90 }}
                      onClick={() => props.onClick(iv, index)}
                    >
                      使用美容卡
                    </Button>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Col>
          ))}
        </InfiniteScrollList>
      </Row>
    </div>
  )
}

export default connect((state) => ({}), {})(TimeCardList)
