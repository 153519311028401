import React, { useEffect, useState } from 'react'
import { Modal, Input } from 'antd'
import './Refund.less'

export default function Refund (props) {

  const { orderDetail = {} } = props
  const [refundExplain, setRefundExplain] = useState('')

  const handleConfirm = () => {
    props.onOk &&
      props.onOk({
        adminRemark: refundExplain,
        orderId: orderDetail.id
      })
  }

  const handleCancel = () => {
    props.onCancel && props.onCancel()
  }

  useEffect(() => {
  }, [orderDetail])

  return (
    <Modal
      visible={props.visible}
      onCancel={handleCancel}
      onOk={handleConfirm}
      destroyOnClose={true}
      title="订单退款"
      wrapClassName="ant-modal-content-custom"
      centered="center"
    >
      <div className="appiont-refund-modal-con">
        <div className="row">
          <div className="label"><span className="r-t">退</span>款</div>
          <div className="value">
            <span className="price">¥{orderDetail.actualPrice || orderDetail.payPrice || '0'}</span>
            注:退款将按原路返回
          </div>
        </div>
        <div className="row row-padding">
          <div className="label">退款说明</div>
          <div className="value">
            <Input.TextArea
              placeholder="请输入退款说明"
              bordered={false}
              rows={4}
              maxLength="200"
              onChange={(e) => setRefundExplain(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
