export const categoryType = ['GOODS', 'SERVICE']
export const genderType = ['未知', '男', '女']
export const genderTypeTabVal = [
  {
    label: '未知',
    value: 0,
  },
  {
    label: '男',
    value: 1,
  },
  {
    label: '女',
    value: 2,
  },
]
export const levelType = ['一级会员', '二级会员', '三级会员']
export const sourceType = ['--', '小程序', '朋友介绍', '美团/大众点评', '其他']
export const sourceColumns = [
  {
    label: '小程序',
    value: 1,
  },
  {
    label: '朋友介绍',
    value: 2,
  },
  {
    label: '美团/大众',
    value: 3,
  },
  {
    label: '其他',
    value: 5,
  },
]
export const PAY_TYPE = [
  {
    label: '余额支付',
    value: 'BALANCE',
  },
  {
    label: '微信支付',
    value: 'WX',
  },
  {
    label: '微信支付记账',
    value: 'WXCASH',
  },
  {
    label: '支付宝',
    value: 'ALI',
  },
  {
    label: '支付宝记账',
    value: 'ALICASH',
  },
  {
    label: '现金支付',
    value: 'CASH',
  },
  {
    label: '积分全额抵扣',
    value: 'COMMISSION',
  },
  {
    label: '组合支付',
    value: 'COMBINE',
  },
  {
    label: 'POS机',
    value: 'POS',
  },
]

export const discountConvert = (dis) => (dis === 1 ? '不打折' : `${dis * 10}折`)

// export const statusMap = {
//   500: '已核销',
//   700: '已取消',
//   800: '已取消',
//   900: '已取消'
// }
/*
  UNPAY(100, "未付款"),
  GROUP_SHOP_WAIT(120, "等待团购活动结束"),
  PAID(200, "已支付（待使用）"),
  WAIT_APPRAISE(400, "待评价"),
  COMPLETE(500, "交易成功"),
  REFUNDING(600, "退款中"),
  REFUNDED(700, "交易关闭（已退款）"),
  CANCELED(800, "交易关闭（已取消）"),
  CANCELED_SYS(900, "交易关闭（已超时）");
*/
export const statusMap = (type) => {
  return {
    100: '待支付',
    200: '待服务',
    500: '交易成功',
    600: '售后处理中',
    700: `交易关闭${type === 2 ? '（已退款）' : ''}`,
    800: `交易关闭${type === 2 ? '（已取消）' : ''}`,
    900: `交易关闭${type === 2 ? '（已超时）' : ''}`,
  }
}

export const orderSource = {
  MP: '小程序',
  CRS: '收银',
}

export const refundStatus = {
  1000: '售后处理中',
  1100: '退款中',
  1200: '退款成功',
  1300: '退款失败',
  1400: '商家已拒绝',
  1500: '退款关闭',
}

export const messageType = {
  NEW_MP_APPOINT_ORDER: '小程序美容服务订单',
  NEW_MP_BOARD_ORDER: '小程序寄养服务订单',
  MP_BOARD_WILL_START: '寄养即将开始',
  MP_APPOINT_WILL_START: '预约即将开始',
}

export const appointStatusMap = {
  100: '待付款',
  150: '待服务（到店付款）',
  200: '待使用',
  400: '交易完成',
  500: '交易完成',
  700: '交易关闭（已退款）',
  800: '交易关闭（已取消）',
  900: '交易关闭（已超时）',
}

export const shopColumns = [
  {
    label: '商品订单',
    value: 'GOODS',
  },
  {
    label: '预约订单',
    value: 'APPOINT',
  }
]
export const sourceColumns2 = [
  {
    label: '收银系统',
    value: 'CRS',
  },
  {
    label: '小程序',
    value: 'MP',
  },
]
export const newShopColumns = [
  {
    label: '商品订单',
    value: 'STORE',
  },
  {
    label: '预约订单',
    value: 'APPOINT',
  },
  {
    label: '美容卡订单',
    value: 'DEDUCTION',
  },
]
/**
 * 订单来源
 */
export const ORDER_SOURCE_MAP = [
  {
    value: 'CRS',
    label: '收银系统',
  },
  {
    value: 'MP',
    label: '小程序',
  },
]

/**
 * 订单类型
 */
export const ORDER_TYPE_MAP = [
  {
    value: 'STORE',
    label: '商品订单',
  },
  {
    value: 'APPOINT',
    label: '预约订单',
  }
]
export const ORDER_TYPE = {
  STORE: '商品订单',
  APPOINT: '预约订单',
  DEDUCTION: '美容卡订单',
}

/**
 * 性别
 */
export const GENDER_MAP = [
  {
    value: 0,
    label: '未知',
  },
  {
    value: 1,
    label: '男',
  },
  {
    value: 2,
    label: '女',
  },
]
/**
 * 宠物性别
 */
export const PET_GENDER_MAP = [
  {
    value: 0,
    label: '公',
  },
  {
    value: 1,
    label: '母',
  },
]
/**
 * 宠物血统
 */
export const PET_LINEAGE_MAP = [
  {
    value: '单血统',
    label: '单血统',
  },
  {
    value: '双血统',
    label: '双血统',
  },
  {
    value: '宠物级',
    label: '宠物级',
  },
]

/**
 * 宠物品种
 */
export const PET_BREED_MAP = [
  {
    value: 'DOG',
    label: '狗狗',
  },
  {
    value: 'CAT',
    label: '猫咪',
  },
  {
    value: 'OTHER',
    label: '其他',
  },
]
/**
 * 宠物体型
 */
export const PET_BODY_TYPE_MAP = [
  {
    value: '小型',
    label: '小型',
  },
  {
    value: '中型',
    label: '中型',
  },
  {
    value: '大型',
    label: '大型',
  }
]
/**
 * 宠物是否绝育
 */
export const PET_NEUTER_STATUS_MAP = [
  {
    value: 0,
    label: '否',
    title: '未绝育',
  },
  {
    value: 1,
    label: '是',
    title: '已绝育',
  }
]
/**
 * 服务方式
 */
export const SERVICE_TYPE_MAP = [
  {
    label: '到店服务',
    value: '0',
  },
  {
    label: '上门服务',
    value: '1',
  },
  {
    label: '到店服务',
    value: '2',
  },
]
/**
 * 会员来源
 */
export const MEMBER_SOURCE_MAP = [
  {
    label: '小程序',
    value: 1,
  },
  {
    label: '朋友介绍',
    value: 2,
  },
  {
    label: '美团/大众',
    value: 3,
  },
  {
    label: '收银一体机',
    value: 5,
  },
  {
    label: '其他',
    value: 4,
  },
]
/**
 * 会员状态
 */
export const MEMBER_STATUS_MAP = [
  {
    label: '激活',
    value: 1,
  },
  {
    label: '冻结',
    value: 0,
  },
]

export const BOARD_ORDER_STATUS_MAP = [
  {
    label: '待支付',
    value: 2000,
  },
  {
    label: '待寄养',
    value: 2100,
  },
  {
    label: '寄养中',
    value: 2200,
  },
  {
    label: '寄养结束',
    value: 2300,
  },
  {
    label: '交易完成',
    value: 2400,
  },
  {
    label: '售后处理中',
    value: 2500,
  },
  {
    label: '交易关闭(已退款)',
    value: 2600,
  },
  {
    label: '交易关闭(已取消)',
    value: 2700,
  },
  {
    label: '交易关闭(已超时)',
    value: 2800,
  },
]

export const EMUN_APPOINT_STATUS = (type) => {
  return {
    100: '待支付',
    150: '待服务(到店付款)',
    200: '待服务',
    300: '待确认',
    500: '交易成功',
    600: `${type === 2 ? '退款中' : '售后处理中'}`,
    700: `交易关闭${type === 2 ? '（已退款）' : ''}`,
    800: `交易关闭${type === 2 ? '（已取消）' : ''}`,
    900: `交易关闭${type === 2 ? '（已超时）' : ''}`,
  }
}
// 美容卡状态（ 0 未生效 ，1 生效中，2 已过期 ，3 已失效 ）
export const timeCardStaus = {
  0: '未生效',
  1: '生效中',
  2: '已失效',
  3: '已失效',
}
// 美容卡有效时间类型（ 0 永久有效 ， 1 时间段内有效 ， 2 截止日期有效 ）
export const timeCardValidity = {
  0: '永久有效',
  1: '时间段内有效',
  2: '截止日期有效',
}
