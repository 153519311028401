import React from 'react'
import moment from 'moment'
import { fixAmount } from '@/utils'
import './coupon-item.less'
export default function Index(props) {
  
  const handleClick = React.useCallback((couponId, status) => {
    if (status) {
      props.onClick && props.onClick(couponId)
    } else {
      props.onClick && props.onClick(null)
    }
  }, [])
  const couponType = React.useMemo(
    () => (props.detail.type === 1 ? '现金券' : '满减券'),
    [props.detail.type]
  )
  const effectScopeLabel = React.useMemo(() => {
    if (typeof props.detail.effectScope === 'number') {
      return ['', '全部商品可用', '限特定商品可用', '限特定服务可用'][props.detail.effectScope]
    }
    return ''
  }, [props.detail])
  const date = React.useMemo(() => {
    if (props.detail.gmtStart) {
      return (
        moment(props.detail.gmtStart).format('YYYY.MM.DD') +
        '-' +
        moment(props.detail.gmtEnd).format('YYYY.MM.DD')
      )
    }
    return ''
  }, [props.detail])
  return (
    <div className={`coupon-item-component ${'type-' + props.detail.type}`}>
      <div className="value-con">
        <span className="circle circle-top"></span>
        <span className="circle circle-bottom"></span>
        <h1>
          <span>¥</span>
          {fixAmount(props.detail.discount)}
        </h1>
        <p>
          {props.detail.min === 0 ? '全场无门槛' : `满${fixAmount(props.detail.min)}元可用`}
        </p>
      </div>
      <div className="content">
        <h1 className="title">
          {props.detail.title} <span className="tag">{couponType}</span>
        </h1>
        <p className="tip">{effectScopeLabel}</p>
        <p className="tip">{date}</p>
        <span className="circle circle-top"></span>
        <span className="circle circle-bottom"></span>
      </div>
      <div className="btn-con">
        {props.couponId === props.detail.id ? (
          <div className="btn" onClick={() => handleClick(null, 0)}>
            取消使用
          </div>
        ) : (
          <div
            className="btn primary-color"
            onClick={() => handleClick(props.detail.id, 1)}
          >
            去使用
          </div>
        )}
      </div>
    </div>
  )
}
