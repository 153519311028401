// 需要有权限的路由

import Home from '../views/Home/index.jsx'
import Board from '../views/Board'
import Refund from '../views/Refund'
import Appointment from '../views/Appointment'
import Cashier from '../views/Cashier'
import Settings from '../views/Settings'

const routeList = [
  {
    path: '/',
    name: '收银服务',
    icon: 'iconshouyin',
    selectIcon: 'iconshouyinselect1',
    component: Home,
    exact: true,
    permission: ['cashier']
  },
  {
    path: '/appointment',
    name: '美容服务',
    icon: 'iconyuyue',
    selectIcon: 'iconyuyueselect',
    exact: true,
    component: Appointment,
    permission: ['appoint']
  },
  {
    path: '/refund',
    name: '退款服务',
    icon: 'icontuikuan',
    selectIcon: 'icontuikuanselect',
    component: Refund,
    exact: true,
    permission: ['refund']
  },
  {
    path: '/cashier',
    name: '收银单据',
    icon: 'iconshouyindanju',
    selectIcon: 'icona-shouyindanjuselect',
    component: Cashier,
    exact: true,
    permission: ['receipts']
  },
  {
    path: '/settings',
    name: '系统管理',
    selectIcon: 'iconxitongselect',
    icon: 'iconxitong',
    component: Settings,
    exact: true,
    permission: ['system']
  }
]

export default routeList
