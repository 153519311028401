import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Timeline, Image, message } from 'antd'
import './index.less'
import bianji from '@/assets/board/bianji.svg'
import { PET_GENDER_MAP, PET_NEUTER_STATUS_MAP } from '@/utils/enum'
import { getLabelByValueFromEnum, dateFormat } from '@/utils'
import {
  getBoardDynamicList,
  deleteBoardDynamicList,
} from '@/redux/actions/order'
import TimeLinePublish from '../TimeLinePublish'
import defaultImage from '@/assets/menu/defaultLogo.png'

export default function MyTimeLine(props) {
  const [isShowTimeLinePublish, setIsShowTimeLinePublish] = useState(false)
  const [dynamicDetail, setDynamicDetail] = useState({})
  const { orderDetail = {} } = props
  const handleSuccess = () => {
    setIsShowTimeLinePublish(false)
    getBoardDynamicList({
      orderNo: orderDetail.orderNo,
    })().then((res) => {
      setDynamicDetail(res.data)
    })
  }
  const dynamicList = useMemo(() => dynamicDetail.boardDynamicDTOList, [dynamicDetail])

  const deleteDynamicItem = (dynamicId, index) => {
    Modal.confirm({
      title: '确认删除这条宠物动态？',
      onOk() {
        return new Promise((resolve) => {
          deleteBoardDynamicList({
            dynamicId,
          })()
            .then(() => {
              message.success('删除成功')
              getDetail()
              resolve()
            })
            .catch(() => {
              reject()
            })
        })
      },
    })
  }
  const getDetail = () => {
    if (!orderDetail.orderNo || orderDetail.status !== 2200) {
      return
    }
    getBoardDynamicList({
      orderNo: orderDetail.orderNo,
    })().then((res) => {
      setDynamicDetail(res.data)
    })
  }

  useEffect(() => {
    getDetail()
  }, [orderDetail])
  return (
    <>
      <Modal
        width="38.485vw"
        wrapClassName="ant-modal-content-custom board-timeline-modal"
        visible={props.visible}
        title="寄养动态"
        footer={null}
        onCancel={props.onCancel}
      >
        <div className="content">
          <div className="l">
            <div className="pet-info">
              <div className="title-con pet">宠物信息</div>
              <div className="pet-image">
                <Image src={orderDetail.petImage || defaultImage} />
              </div>
              <div className="pet-name">{orderDetail.petName}</div>
              <div className="pet-type">{orderDetail.petBreed}</div>
              <div className="lines">
                <div className="line">
                  <span>宠物性别</span>
                  <p>
                    {getLabelByValueFromEnum(
                      orderDetail.petGender,
                      PET_GENDER_MAP
                    )}
                  </p>
                </div>
                <div className="line">
                  <span>是否绝育</span>
                  <p>
                    {getLabelByValueFromEnum(
                      orderDetail.petGender,
                      PET_NEUTER_STATUS_MAP
                    )}
                  </p>
                </div>
                <div className="line">
                  <span>宠物毛色</span>
                  <p>{orderDetail.petHairColor}</p>
                </div>
                <div className="line">
                  <span>宠物体型</span>
                  <p>{orderDetail.petBodyType}</p>
                </div>
              </div>
            </div>
            <div className="spu-info">
              <div className="title-con spu">寄养信息</div>
              <div className="lines">
                <div className="line">
                  <span>寄养类型</span>
                  <p>{orderDetail.spuTitle}</p>
                </div>
                <div className="line">
                  <span>寄养房间</span>
                  <p>{orderDetail.skuTitle}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="r">
            <p className="title">
              {dynamicDetail.date}剩余{' '}
              <span className="primary-color">
                {orderDetail.surplusNum}
              </span>{' '}
              天寄养结束
            </p>
            <div className="timeline-list">
              <Timeline>
                <Timeline.Item>
                  <Button
                    type="link"
                    icon={<img src={bianji} alt="" />}
                    onClick={() => setIsShowTimeLinePublish(true)}
                  >
                    点击记录宠物动态
                  </Button>
                </Timeline.Item>
                {dynamicList &&
                  dynamicList.map((item, index) => (
                    <Timeline.Item key={item.gmtCreate}>
                      <p className="date">{dateFormat(item.gmtCreate)}</p>
                      <div className="text">{item.dynamic}</div>
                      <div className="images">
                        <Image.PreviewGroup>
                          {item.imgList &&
                            item.imgList.map((img, y) => (
                              <Image
                                width="3.5vw"
                                key={y}
                                height="3.5vw"
                                src={img}
                              />
                            ))}
                        </Image.PreviewGroup>
                      </div>
                      <Button
                        type="link"
                        onClick={() => deleteDynamicItem(item.id, index)}
                      >
                        删除
                      </Button>
                    </Timeline.Item>
                  ))}
              </Timeline>
            </div>
          </div>
        </div>
      </Modal>
      <TimeLinePublish
        visible={isShowTimeLinePublish}
        orderDetail={props.orderDetail}
        onSuccess={handleSuccess}
        onCancel={() => setIsShowTimeLinePublish(false)}
      />
    </>
  )
}
