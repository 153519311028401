import request from '../../utils/request'
const area = '/crs'
export const getList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/list`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
export const refundDisagree = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/disagree`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const agreeRefund = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/agree`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const refundFrameInfo = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/refundFrameInfo`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

