import moment from 'moment'
import { store } from '../redux/store'
import { getLabelByValueFromEnum, getOrderTypeByOrderNo } from './index'
import {
  getBoardOrderDetail,
  getAppointOrderDetail,
  getCrsOrderDetail,
} from '@/redux/actions/order'
import { getDetailData as getSettlementOrderDetail } from '@/redux/actions/cashier'
import { inApp, inElectron, checkTicketInside } from './device'
import {
  PRINT_WIDTH,
  SAVE_KEY,
  LINE_HEIGHT_KEY,
  APP_USB_ADDRESS,
  NOT_PRINT_QRCODE
} from '@/utils/constant'
import TicketPrinter from './TicketPrinter'
import { PAY_TYPE } from './enum'

export function getPrintWidth() {
  const printWidth = window.localStorage.getItem(PRINT_WIDTH) || 58
  return Number(printWidth)
}
/**
 * 获取打印机列表
 */
export function getPrintList() {
  if (!window.electron) {
    return []
  }
  const remote = window.electron.remote
  const webContents = remote.getCurrentWebContents()
  // 屏蔽不相关的打印机或者传真设备
  const blackList = ['Fax', 'WPS', 'PDF', 'Microsoft', 'Windows 10']
  return webContents
    .getPrinters()
    .filter((item) => item.status === 0)
    .filter(
      (item) =>
        blackList.findIndex(
          (blackItem) => item.name.indexOf(blackItem) > -1
        ) === -1
    )
}
export function getUsbPrintersAddressInApp() {
  const printModule = api.require('posPrinter')
  return new Promise((resolve, reject) => {
    printModule.getUsbPrinters(function (ret, err) {
      const printerAddressList = ret.filter(
        (item) => item.address.toLowerCase().indexOf(':printer') > -1
      )
      try {
        resolve(printerAddressList)
      } catch (error) {
        reject(err)
      }
    })
  })
}

export async function checkPrintStatus(printName) {
  if (inApp) {
    const printerAddress = getPrintUSBAddressInAppByLocal()
    const printerAddressList = await getUsbPrintersAddressInApp()
    if (printerAddressList.length) {
      return (
        printerAddressList.findIndex(
          (item) => item.address === printerAddress
        ) > -1
      )
    }
    return false
  }
  if (canUseTicketPrintByUSB()) {
    return true
  }
  if (!canUseTicketPrint()) {
    return false
  }
  const printList = getPrintList()
  return printList.findIndex((item) => item.name === printName) > -1
}
export function setPrintLineHeight(lineHeight) {
  window.localStorage.setItem(LINE_HEIGHT_KEY, lineHeight)
}
export function getPrintLineHeight() {
  const lineHeight = window.localStorage.getItem(LINE_HEIGHT_KEY)
  console.log('lineHeight', lineHeight)
  return lineHeight ? Number(lineHeight) : 35
}

export function getPrintType() {
  try {
    if (checkTicketInside()) {
      return 'app-inside' // Android内置打票机
    } else if (inApp) {
      return 'app-usb' // Android USB外接打票
    } else if (canUseTicketPrintByUSB()) {
      return 'electron-usb' // PC使用escpos协议打印
    } else if (canUseTicketPrint()) {
      return 'electron-html' // PC使用网页打印
    }
  } catch (error) {}
  return null
}

export function getPrintName() {
  // 如果是app中，返回打票机usb地址
  if (inApp) {
    return getPrintUSBAddressInAppByLocal()
  }
  return window.localStorage.getItem(SAVE_KEY)
}
export function setPrintName(printName) {
  window.localStorage.setItem(SAVE_KEY, printName)
}
export function removePrint() {
  window.localStorage.removeItem(SAVE_KEY)
}
/**
 * 设置usb地址
 * @param {string} address usb地址
 */
export function setPrintUSBAddressInAppByLocal(address) {
  window.localStorage.setItem(APP_USB_ADDRESS, address)
}
/**
 * 获取保存的usb地址
 */
export function getPrintUSBAddressInAppByLocal() {
  return window.localStorage.getItem(APP_USB_ADDRESS) || ''
}
/**
 * 打印小票
 * @param {object | string} ticketDetail 打印数据
 */
export function print(ticketDetail) {
  const ticketPrinter = new TicketPrinter()
  ticketPrinter.ready(async () => {
    if (ticketDetail === '测试打印') {
      ticketPrinter.selfChecking()
      return
    }
    await ticketPrinter.text(ticketDetail.storeTitle, 'C')
    await ticketPrinter.feed()
    await ticketPrinter.text(`收银员: ${ticketDetail.casherNumber}`)
    await ticketPrinter.text(`结算单号: ${ticketDetail.orderNo}`)
    await ticketPrinter.text(`时间: ${ticketDetail.datetime}`)
    // 会员信息
    if (ticketDetail.memberLevel) {
      await ticketPrinter.text(`会员名: ${ticketDetail.memberName}`)
      await ticketPrinter.text(`会员级别: ${ticketDetail.memberLevel}`)
      await ticketPrinter.text(`会员余额: ${ticketDetail.memberMoney}`)
    }
    await ticketPrinter.drawLine()
    await ticketPrinter.tableCustom([
      { text: '名称', align: 'LEFT', width: 0.3125 },
      { text: '数量', align: 'RIGHT', width: 0.125 },
      { text: '单价', align: 'RIGHT', width: 0.3125 },
      { text: '金额', align: 'RIGHT', width: 0.25 },
    ])
    for (let index = 0; index < ticketDetail.deskList.length; index++) {
      const element = ticketDetail.deskList[index]
      await ticketPrinter.tableCustom([
        { text: element.name, align: 'LEFT', width: 0.3125 },
        { text: element.num, align: 'RIGHT', width: 0.125 },
        {
          text: `${element.price}\n折${element.discountedPrice}`,
          align: 'RIGHT',
          width: 0.3125,
        },
        {
          text: `${element.amount}\n折${element.discountedAmount}`,
          align: 'RIGHT',
          width: 0.25,
        },
      ])
    }
    await ticketPrinter.drawLine()
    await ticketPrinter.tableCustom([
      { text: `数量: ${ticketDetail.totalNum}`, align: 'LEFT', width: 0.5 },
      {
        text: `总金额: ${ticketDetail.totalAmount}`,
        align: 'RIGHT',
        width: 0.5,
      },
    ])
    await ticketPrinter.tableCustom([
      { text: ``, align: 'LEFT', width: 0.5 },
      {
        text: `折后总额: ${ticketDetail.actualAmount}`,
        align: 'RIGHT',
        width: 0.5,
      },
    ])
    await ticketPrinter.drawLine()
    await ticketPrinter.tableCustom([
      {
        text: `商家优惠: ${ticketDetail.printStoreDiscountAmount}`,
        align: 'LEFT',
        width: 0.5,
      },
      {
        text: `实收: ${ticketDetail.actualAmount}`,
        align: 'RIGHT',
        width: 0.5,
      },
    ])
    await ticketPrinter.tableCustom([
      {
        text: `会员优惠: ${ticketDetail.printMemberDiscountAmount}`,
        align: 'LEFT',
        width: 0.5,
      },
      {
        text: `${ticketDetail.payList[0].name}: ${ticketDetail.payList[0].amount}`,
        align: 'RIGHT',
        width: 0.5,
      },
    ])
    await ticketPrinter.tableCustom([
      {
        text: `美容卡抵扣: ${ticketDetail.printDeductionPrice}`,
        align: 'LEFT',
        width: 0.5,
      },
      {
        text:
          ticketDetail.payList.length === 2
            ? `${ticketDetail.payList[1].name}: ${ticketDetail.payList[1].amount}`
            : '',
        align: 'RIGHT',
        width: 0.5,
      },
    ])
    ticketDetail.couponPrice &&
      (await ticketPrinter.tableCustom([
        {
          text: `优惠券抵扣: ${ticketDetail.couponPrice}`,
          align: 'LEFT',
          width: 0.5,
        },
        { text: ``, align: 'RIGHT', width: 0.5 },
      ]))
    ticketDetail.commissionDeduct &&
      (await ticketPrinter.tableCustom([
        {
          text: `积分抵扣: ${ticketDetail.commissionDeduct}`,
          align: 'LEFT',
          width: 0.5,
        },
        { text: ``, align: 'RIGHT', width: 0.5 },
      ]))
    if (
      typeof ticketDetail.outAmount === 'string' &&
      Number(ticketDetail.outAmount) > 0
    ) {
      await ticketPrinter.text(`找零: ${ticketDetail.outAmount}`)
    }
    await ticketPrinter.drawLine()
    await ticketPrinter.text('谢谢惠顾，欢迎再次光临', 'C')
    if (ticketDetail.address) {
      await ticketPrinter.text(`地址：${ticketDetail.address}`, 'C')
    }
    if (ticketDetail.contactMobile) {
      await ticketPrinter.text(`电话：${ticketDetail.contactMobile}`, 'C')
    }
    await ticketPrinter.feed()
    await ticketPrinter.text(`温馨提醒：商品一经售出，非质量问题不退不换`, 'C')
    await ticketPrinter.feed()
    try {
      if (window.localStorage.getItem(NOT_PRINT_QRCODE) !== 'true' && ticketDetail.qrUrl) {
        await ticketPrinter.image(ticketDetail.qrUrl)
      }
    } catch (error) {
      console.error('qr', error)
    }
    await ticketPrinter.feed()
    await ticketPrinter.text('印入生活  一起成长', 'C')
    await ticketPrinter.text('杭州青松管科技支持', 'C')
    await ticketPrinter.feed()
    await ticketPrinter.cut()
    await ticketPrinter.close()
  })
}

export const canUseTicketPrint = () => inApp || inElectron

export const canUseTicketPrintByUSB = () => {
  try {
    const device = new window.escpos.USB()
    device.open()
    return true
  } catch (error) {
    console.error('不支持escpos模式打印')
    return false
  }
}

export const usbListener = (cb) => {
  if (window.usbT) {
    window.usbT.on('attach', function (device) {
      cb && cb()
    })
    window.usbT.on('detach', function (device) {
      cb && cb()
    })
  }
}

const getDeskItemTotalNum = (deskList) => {
  if (deskList.length === 0) {
    return 0
  }
  const arr = deskList.map((item) => (item.status === 0 ? 0 : item.num))
  return arr.reduce((prev, curr) => prev + curr)
}

function calcDiscountAmountByDeskList(deskList, actualAmount, receivablePrice) {
  return deskList.map((item) => {
    const discountedPrice = Number(item.discountedAmount / item.num).toFixed(2)
    return {
      ...item,
      discountedPrice, // 折后单价
      discountedAmount: Number(item.discountedAmount).toFixed(2), // 折后金额
    }
  })
}
/**
 * 将订单参数转为打票机需要的参数
 * @returns
 */
export const orderDetail2PrintData = ({
  orderDetail = {},
  printInfo = {},
  outAmount = 0,
  deskList = [],
  orderNo = '',
  receivablePrice, // 应收金额
  deductionPrice, // 美容卡抵扣
  memberMoney = '', // 会员余额
  payTime, // 支付时间
  memberDetail,
  skuTotalPrice, // 合计金额
  actualAmount, // 实付金额
  payAmount, // 支付金额
  payAmount2, // 组合支付金额2
  isCombPay, // 是否组合支付
  payTitle, // 支付类型名
  couponPrice = 0, // 优惠券抵扣金额
  commissionDeduct = 0, // 积分抵扣
}) => {
  let _deskList = []
  if (orderDetail.orderType === 'BOARD') {
    _deskList = [
      {
        name: orderDetail.spuTitle,
        num: orderDetail.totalNum,
        price: Number(orderDetail.averagePrice).toFixed(2),
        amount: orderDetail.skuTotalPrice.toFixed(2),
        discountedAmount: orderDetail.payAmount - orderDetail.serviceDoorPrice, // 折后总价
      },
    ]
  } else {
    _deskList = deskList.map((item) => ({
      name:
        item.spuTitle === item.title
          ? item.spuTitle
          : item.spuTitle + item.title,
      num: item.num,
      price: Number(item.price).toFixed(2),
      amount: (item.price * item.num).toFixed(2),
      discountedAmount: item.discountedPrice,
    }))
  }
  _deskList = calcDiscountAmountByDeskList(
    _deskList,
    Number(actualAmount),
    Number(skuTotalPrice)
  )
  const totalNum = getDeskItemTotalNum(_deskList)
  // 会员优惠
  const printMemberDiscountAmount = Number(
    Number(skuTotalPrice) -
      Number(receivablePrice || 0) -
      Number(deductionPrice) +
      Number(orderDetail.serviceDoorPrice || 0)
  ).toFixed(2)
  // 美容卡抵扣
  const printDeductionPrice = Number(deductionPrice).toFixed(2)
  // 积分抵扣
  const printCommissionDeduct = commissionDeduct
    ? Number(commissionDeduct).toFixed(2)
    : 0
  // 优惠券抵扣
  const printCouponPrice = couponPrice ? Number(couponPrice).toFixed(2) : 0
  // 商家优惠
  const printStoreDiscountAmount = Number(
    Number(receivablePrice) -
      Number(actualAmount) -
      Number(commissionDeduct) -
      Number(couponPrice)
  ).toFixed(2)
  const printTotalAmount = Number(skuTotalPrice).toFixed(2)
  const printDiscountAmount = Number(skuTotalPrice - actualAmount).toFixed(2)
  const printActualAmount = Number(actualAmount).toFixed(2)
  const storeTitle = printInfo.shortName
  const serviceDoorPrice =
    orderDetail.serviceDoorPrice > 0
      ? Number(orderDetail.serviceDoorPrice).toFixed(2)
      : null
  // 收银员名
  const casherNumber = printInfo.adminName || ''
  // let qrUrl = 'https://mp.weixin.qq.com/a/~~wu3hXzBSt64~plUyoOB9Iyf8mEHP9BrkLA~~';
  const qrUrl = printInfo.qrcodeUrl ? `${printInfo.qrcodeUrl}?x-oss-process=image/resize,w_326,m_fill` : ''
  const address = printInfo.address || printInfo.contactAddress || ''
  const contactMobile = printInfo.phone || printInfo.contactMobile || ''
  let payList = [
    {
      name: payTitle,
      amount: Number(payAmount).toFixed(2),
    },
  ]
  let memberName = ''
  let memberLevel = ''
  // 组合支付
  if (isCombPay) {
    payList = [
      {
        name: '余额支付',
        amount: Number(payAmount).toFixed(2),
      },
      {
        name: payTitle,
        amount: Number(payAmount2).toFixed(2),
      },
    ]
  }
  // 会员信息
  if (memberDetail) {
    memberLevel = memberDetail.levelName
    memberName = memberDetail.realname || memberDetail.nickname
    memberMoney = memberDetail.walletBalance
  }
  return {
    memberMoney,
    memberName,
    memberLevel,
    totalNum,
    payList,
    orderNo,
    storeTitle,
    casherNumber,
    qrUrl,
    contactMobile,
    address,
    serviceDoorPrice,
    printDeductionPrice,
    printStoreDiscountAmount,
    printMemberDiscountAmount,
    deskList: _deskList,
    commissionDeduct: printCommissionDeduct,
    couponPrice: printCouponPrice,
    outAmount: Number(outAmount).toFixed(2),
    totalAmount: printTotalAmount,
    discountAmount: printDiscountAmount,
    actualAmount: printActualAmount,
    datetime: moment(payTime).format('MM-DD HH:mm'),
  }
}
const fns = {
  APPOINT: getAppointOrderDetail,
  BOARD: getBoardOrderDetail,
  DEDUCTION: getSettlementOrderDetail,
  STORE: getCrsOrderDetail,
  GOODS: getCrsOrderDetail,
}

export const printByOrderDetail = async ({
  orderNo,
  orderDetail,
  memberDetail = {},
}) => {
  const orderType = getOrderTypeByOrderNo(orderNo) || 'store'
  if (!orderDetail) {
    console.log('orderType', orderType)
    const fn = fns[orderType]
    const res = await fn({
      orderNo: orderNo,
      orderType: orderType,
    })()
    orderDetail = res.data
  }
  let deskList = orderDetail.skus
  
  const payChannel = Array.isArray(orderDetail.paymentRecords)
    ? orderDetail.paymentRecords.map((item) => getLabelByValueFromEnum(item.payChannel, PAY_TYPE))
    : ['支付']
  const couponPrice = orderDetail.couponPrice
  const printInfo = store.getState().user.printInfo
  let skuTotalPrice = orderDetail.skuTotalPrice,
    actualAmount = orderDetail.actualAmount,
    isCombPay = payChannel.length > 1,
    payAmount = orderDetail.paymentRecords[0].amount,
    payAmount2 = 0,
    outAmount = 0,
    payTitle = payChannel[payChannel.length - 1],
    deductionPrice = orderDetail.deductionPrice,
    receivablePrice = orderDetail.discountedPrice,
    commissionDeduct = orderDetail.commissionDeduct

  if (isCombPay) {
    payAmount2 = orderDetail.paymentRecords[1].amount
  }
  if (orderType === 'STORE' || orderType === 'GOODS') {
    // 商品订单
    deskList = orderDetail.skus.map((item) => ({
      ...item,
      discountedPrice: item.actualPriceSubtotal, // sku折后总额
    }))
  } else if (orderType === 'APPOINT') {
    skuTotalPrice = orderDetail.skuTotalPrice
    actualAmount = orderDetail.actualPrice
    receivablePrice =
      orderDetail.actualPrice +
      orderDetail.businessDiscount +
      couponPrice +
      commissionDeduct
    deskList = orderDetail.appointDetailSkuV2VOList.map((item) => ({
      title: item.projectName,
      spuTitle: item.serviceName,
      price: item.totalPrice,
      discountedPrice: item.actualPriceSubtotal,
      num: 1,
      priceSubtotal: item.totalPrice,
    }))
  } else if (orderType === 'BOARD') {
    deductionPrice = 0
    skuTotalPrice = orderDetail.skuTotalPrice
    actualAmount = orderDetail.payAmount
    receivablePrice =
      orderDetail.payAmount +
      orderDetail.businessDiscount +
      deductionPrice +
      couponPrice +
      commissionDeduct
  } else if (orderType === 'DEDUCTION') {
    // 购买美容卡
    const sku = orderDetail.skus[0]
    deskList = [
      {
        title: '',
        spuTitle: sku.spuTitle,
        price: sku.price,
        num: 1,
        discountedPrice: sku.price,
      },
    ]
  }
  const ticketDetail = orderDetail2PrintData({
    orderNo,
    printInfo,
    skuTotalPrice, // 总金额
    actualAmount,
    isCombPay,
    payAmount,
    payAmount2,
    outAmount,
    orderDetail,
    memberDetail,
    payTitle,
    deskList,
    couponPrice, // 优惠券抵扣
    deductionPrice, // 美容卡抵扣
    receivablePrice, // 应收金额
    commissionDeduct, // 积分抵扣
  })
  console.log('ticketDetail', JSON.stringify(ticketDetail))
  print(ticketDetail)
}
