import React from 'react'
import { Tabs, Button } from 'antd'
import { connect } from 'react-redux'
import './task.less'
function Task(props) {
  const { TabPane } = Tabs
  const handleDelete = () => {
    props.handleDelete && props.handleDelete(props.todoDetails)
  }
  return (
    <div className="task-model">
      <Tabs defaultActiveKey="1">
        <TabPane tab="待办服务" key="1">
          <div className="task-model-body">
            <div className="task-con">
              <div className="task-row">
                <p className="task-lable">姓名</p>
                <p className="task-content">{props.todoDetails.name}</p>
              </div>
              <div className="task-row">
                <p className="task-lable">电话</p>
                <p className="task-content">{props.todoDetails.phone}</p>
              </div>
              <div className="task-row">
                <p className="task-lable">宠物</p>
                <p className="task-content">{props.todoDetails.petName}</p>
              </div>
              <div className="task-row">
                <p className="task-lable">服务</p>
                <p className="task-content">{props.todoDetails.serviceName}</p>
              </div>
              <div className="task-row">
                <p className="task-lable">时间</p>
                <p className="task-content">{props.todoDetails.backlogTime}</p>
              </div>
              <div className="task-mark task-row">
                <p className="task-lable">备注</p>
                <p className="task-note-info">{props.todoDetails.remark}</p>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
      <div className="setButtonClass" style={{ width: '100%' }}>
        <Button
          type="primary"
          ghost
          className="ghost-button"
          onClick={handleDelete}
        >
          删除待办
        </Button>
      </div>
    </div>
  )
}

export default connect(null, {})(Task)
