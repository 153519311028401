import React, { useState, useEffect, useMemo } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  message,
  InputNumber,
} from 'antd'
import {
  petUpdate,
  petCreate,
  getPetDetail,
  getPetBreed,
} from '@/redux/actions/user'
import {
  PET_GENDER_MAP,
  PET_BREED_MAP,
  PET_LINEAGE_MAP,
  PET_BODY_TYPE_MAP,
  PET_NEUTER_STATUS_MAP,
} from '@/utils/enum'
import moment from 'moment'
import PetUpload from './PetUpload'
import { connect } from 'react-redux'
import './index.less'

function Create(props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [petBreedList, setPetBreedList] = useState([])
  const isEdit = useMemo(() => !!props.petId, [props.petId])
  const handleCancel = () => {
    typeof props.handleCancel === 'function' && props.handleCancel()
  }
  const handleSubmit = () => {
    form
      .validateFields()
      .then((fields) => {
        if (Array.isArray(fields.imgList)) {
          const imgList = fields.imgList.map((item) =>
            item.response ? item.response.data : item.url
          )
          fields.imgList = imgList
        }

        if (Array.isArray(fields.image) && fields.image.length > 0) {
          const item = fields.image[0]
          fields.image = item.response ? item.response.data : item.url
        } else if (fields.image && fields.image.length === 0) {
          fields.image = ''
        }
        fields.birthday = fields.birthday
          ? fields.birthday.format('YYYY-MM-DD')
          : undefined
        setLoading(true)
        if (isEdit) {
          return props.petUpdate({
            ...fields,
            userId: props.userId,
          })
        }
        return props.petCreate({
          ...fields,
          userId: props.userId,
        })
      })
      .then(() => {
        message.success(isEdit ? '编辑成功' : '添加成功')
        form.resetFields()
        typeof props.onSuccess === 'function' && props.onSuccess()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const birthdayDisabledDate = (current) => {
    if (current > moment(Date.now())) {
      return true
    }
  }

  const handleChangePetType = (val) => {
    getPetBreed(val)
    form.setFields([
      {
        name: 'breed',
        value: undefined,
      },
    ])
  }

  const getUserPetDetail = () => {
    props
      .getPetDetail({
        id: props.petId,
      })
      .then((res) => {
        const data = res.data
        if (data) {
          console.log('data', data)
          form.setFieldsValue({
            ...data,
            birthday: !data.birthday ? undefined : moment(data.birthday),
            imgList:
              Array.isArray(data.imgList) && data.imgList.length
                ? data.imgList.map((iv, index) => {
                    return {
                      url: iv,
                      uid: index,
                    }
                  })
                : [],
            image: data.image
              ? [
                  {
                    url: data.image,
                    uid: 0,
                  },
                ]
              : [],
          })
          getPetBreed(data.type)
        }
      })
  }
  const getPetBreed = (type) => {
    if (!type) {
      return
    }
    props.getPetBreed().then((res) => {
      if (res.data) {
        setPetBreedList(res.data[type])
      }
    })
  }
  useEffect(() => {
    if (props.petId) {
      getUserPetDetail()
    }
  }, [props.petId])

  return (
    <div className="pet-edit-con">
      <div className="block-con">
        <Form
          form={form}
          initialValues={{
            gender: PET_GENDER_MAP[0].value,
            level: 0,
            status: 1,
            blood: PET_LINEAGE_MAP[0].value,
            bodyType: PET_BODY_TYPE_MAP[0].value,
            neuterStatus: PET_NEUTER_STATUS_MAP[0].value,
          }}
        >
          <Form.Item
            label="id"
            name="id"
            className="form-item disflex"
            style={{ display: 'none' }}
            hidden
          >
            <Input
              className="form-item-right"
              bordered={false}
              name="id"
            ></Input>
          </Form.Item>
          <Form.Item
            label="宠物头像"
            name="image"
            className="form-item disflex"
          >
            <PetUpload
              showUploadList={true}
              fileList={form.getFieldValue('image')}
            />
          </Form.Item>
          <Form.Item
            label="宠物昵称"
            name="name"
            className="form-item"
            rules={[{ required: true, message: '请输入宠物昵称' }]}
          >
            <Input
              className="form-item-right"
              bordered={false}
              placeholder="请输入宠物昵称"
              maxLength={25}
            ></Input>
          </Form.Item>
          <Form.Item label="宠物性别" className="form-item" name="gender">
            <Select
              className="form-item-right"
              bordered={false}
              placeholder="请选择性别"
            >
              {PET_GENDER_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="出生日期" className="form-item" name="birthday">
            <DatePicker
              className="form-item-right"
              bordered={false}
              placeholder="请选择出生日期"
              disabledDate={birthdayDisabledDate}
            ></DatePicker>
          </Form.Item>
          <Form.Item label="宠物品种" className="form-item">
            <Form.Item name="type" noStyle>
              <Select
                style={{ width: '80px', marginRight: 10 }}
                placeholder="请选择"
                onChange={handleChangePetType}
              >
                {PET_BREED_MAP.map((item) => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="breed" noStyle>
              <Select style={{ width: '120px' }} placeholder="请选择宠物品种">
                {petBreedList.map((item, index) => (
                  <Select.Option value={item} key={index}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="体重(kg)"
            className="form-item"
            name="weight"
            validateTrigger={['onChange', 'onBlur']}
          >
            <InputNumber
              className="form-item-right"
              bordered={false}
              placeholder="请输入体重"
              max={9999.99}
            />
          </Form.Item>
          <Form.Item label="宠物血统" className="form-item" name="blood">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择宠物血统"
              bordered={false}
            >
              {PET_LINEAGE_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="宠物体型" className="form-item" name="bodyType">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择宠物体型"
              bordered={false}
            >
              {PET_BODY_TYPE_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="宠物绝育" className="form-item" name="neuterStatus">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择是否绝育"
              bordered={false}
            >
              {PET_NEUTER_STATUS_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="宠物毛色" name="hairColor" className="form-item">
            <Input
              className="form-item-right"
              bordered={false}
              placeholder="请输入宠物毛色"
              maxLength={6}
            ></Input>
          </Form.Item>
          <Form.Item label="宠物图片" name="imgList" className="form-item">
            <PetUpload
              multiple
              showUploadList={true}
              fileList={form.getFieldValue('imgList')}
            />
          </Form.Item>
          <Form.Item
            label={
              <div className="i-flex">
                <span>留</span>
                <span>言</span>
              </div>
            }
            className="form-item"
            name="message"
          >
            <Input.TextArea
              className="form-item-right textArea"
              type="text"
              bordered={false}
              placeholder="请填写备注，不超过200字"
              maxLength={200}
              autoSize
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </div>
      <div className="handle-con">
        <Button className="btn btn-cancel" onClick={handleCancel}>
          取消
        </Button>
        <Button
          type="primary"
          className="btn btn-submit"
          onClick={handleSubmit}
          loading={loading}
        >
          提交
        </Button>
      </div>
    </div>
  )
}

export default connect(null, {
  getPetDetail,
  getPetBreed,
  petUpdate,
  petCreate,
})(Create)
