import React, { useState, useEffect, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import { Input, Row, Col, Button } from 'antd'
import Icon from '../../../Icon'
import './search.less'
import memberImg from '@/assets/member/people.png'
import { getAsyncMemberList } from '@/redux/actions/user'
import PubSub from 'pubsub-js'
import { checkScanKey } from '@/utils'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import { HomeContext } from '@/components/Home/context-manager'
let keydownToken
let scanToken

const Search = Input.Search
export function MSearch(props) {
  // 搜索条件
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const { disableSearchScan, setUserAdd } = useContext(HomeContext)
  const keywordRef = useRef('')
  // 搜索
  const onSearch = async (keyword) => {
    setLoading(true)
    setHasMore(true)
    if (keyword === '') {
      setLoading(false)
      setHasMore(false)
      props.setKeyword('')
      props.setMemberList([])
      return
    }
    let kw = keyword
    if (keyword.substring(0, 2) === 'HY') {
      const scanKeyObj = checkScanKey(keyword)
      if (scanKeyObj && scanKeyObj.type === 'member') {
        kw = scanKeyObj.value
      }
    }
    props.setKeyword(kw)
    props
      .getAsyncMemberList({
        keyword: kw,
        pageNo: 1,
        pageSize: 999,
      })
      .then((res) => {
        if (res && res.length === 1) {
          setUserAdd(true)
        }
        props.setMemberList(res)
      })
      .finally(() => {
        setLoading(false)
        setHasMore(false)
      })
  }
  const openDetail = async (val) => {
    props.openMemberDetail(val.id)
    setUserAdd(true)
  }
  // 防抖
  const debounce = (fn, delay) => {
    let timer = null
    return function (value) {
      props.setKeyword(value)
      keywordRef.current = value
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn(value)
      }, delay)
    }
  }
  const fn = debounce((value) => {
    if (value.substring(0, 2) === 'HY' && value.length > 11) {
      let kw = ''
      const scanKeyObj = checkScanKey(value)
      if (scanKeyObj && scanKeyObj.type === 'member') {
        kw = scanKeyObj.value
      }
      props.setKeyword(kw)
      if (keywordRef.current === value) {
        onSearch(kw)
      }
    }
  }, 300)

  const onSearchChange = fn

  useEffect(() => {
    // 监听扫码，用于微信支付宝扫码支付
    scanToken && PubSub.unsubscribe(scanToken)
    scanToken = PubSub.subscribe('GLOBAL_SCAN', (msg, data) => {
      if (disableSearchScan) return
      if (data.text) {
        const scanKeyObj = checkScanKey(data.text)
        if (scanKeyObj && scanKeyObj.type === 'member') {
          props.setKeyword(scanKeyObj.value)
          onSearch(scanKeyObj.value)
        }
      }
    })

    return () => {
      keydownToken && PubSub.unsubscribe(keydownToken)
      scanToken && PubSub.unsubscribe(scanToken)
    }
  }, [disableSearchScan])

  return (
    <div className="m-search">
      <div className="search">
        <Search
          placeholder="手机号或会员名"
          allowClear
          className="search-button"
          prefix={<Icon type="iconsousuo" />}
          enterButton="搜索"
          onSearch={onSearch}
          defaultValue={props.keyword}
          value={props.keyword}
          onChange={(e) => onSearchChange(e.target.value)}
          loading={loading}
        />
      </div>
      <Row className="row">
        <InfiniteScrollList
          className="m-row"
          hasMore={hasMore}
          handleOnLoad={() => ({})}
          loading={loading}
        >
          {props.memberList.map((iv, index) => (
            <Col
              span={24}
              className="m-col"
              key={index}
              onClick={() => openDetail(iv)}
            >
              <div className="info" title={iv.realname || iv.nickname}>
                <img
                  className="img"
                  src={iv.avatarUrl ? iv.avatarUrl : memberImg}
                  alt=""
                />
                {iv.realname || iv.nickname}
              </div>
              <div className="phone">{iv.phone}</div>
            </Col>
          ))}
        </InfiniteScrollList>
      </Row>
      <div className="m-add">
        <Button
          type="primary"
          className="btn"
          onClick={props.addMember}
        >
          添加会员
        </Button>
      </div>
    </div>
  )
}
export default connect((state) => ({}), {
  getAsyncMemberList,
})(MSearch)
