import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getListData } from '../../redux/actions/cashier'
import Icon from '../../components/Icon'
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Table,
  Drawer,
  message,
} from 'antd'
import { newShopColumns, ORDER_TYPE, PAY_TYPE } from '../../utils/enum'
import { getLabelByValueFromEnum } from '@/utils'
import './index.less'
import moment from 'moment'
// 子组件
import Detail from './Detail'
const { Column } = Table
function Cashier(props) {
  // state
  console.log('props+', props)
  const history = useHistory()
  const [form] = Form.useForm()
  const [listQuery, setListQuery] = useState({
    pageNo: 1,
    pageSize: 10,
    word: '',
    orderType: '',
    start: undefined,
    end: undefined,
    total: 0,
  })
  const [data, setData] = useState([{}])
  const [visible, setVisible] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [tempOrder, setTempOrder] = useState({})
  // 默认搜索值
  const [defaultSearch, setDefaultSearch] = useState('')
  const [defaultType, setDefaultType] = useState('STORE')
  // 解构props
  const { getListData } = props

  // 生命周期
  useEffect(() => {
    if (history.location.state) {
      const { word, orderType } = history.location.state
      setDefaultSearch(word)
      setDefaultType(orderType)
    }
  }, [])
  // 生命周期
  useEffect(() => {
    getList()
  }, [listQuery.pageNo])

  // methods
  // 获取数据
  const getList = () => {
    const values = form.getFieldsValue()
    let newVal = {
      ...values,
      orderType: values.orderType === 'STORE' ? undefined : values.orderType,
      start: values.start
        ? moment(values.start).format('YYYY-MM-DD 00:00:00')
        : undefined,
      end: values.end
        ? moment(values.end).format('YYYY-MM-DD 23:59:59')
        : undefined,
    }
    if (history.location.state) {
      newVal.word = history.location.state.word
      newVal.orderType =
        history.location.state.orderType === 'STORE'
          ? undefined
          : history.location.state.orderType
    }
    if (newVal.start) {
      if (!newVal.end) {
        message.warning('请选择结束时间')
        return
      }
    }
    if (newVal.end) {
      if (!newVal.start) {
        message.warning('请选择起始时间')
        return
      }
    }
    console.log(newVal, history.location)
    setTableLoading(true)
    getListData({
      ...listQuery,
      ...newVal,
    })
      .then((res) => {
        setData([...res.data.items])
        setListQuery({
          ...listQuery,
          total: res.data.count,
        })
      })
      .finally(() => {
        setTableLoading(false)
      })
  }
  // 搜索
  const handleSearch = () => {
    if (history.location.state) {
      history.replace({ ...history.location, state: undefined })
      setDefaultSearch('')
    }
    if (listQuery.pageNo === 1) {
      getList()
    } else {
      setListQuery({ ...listQuery, pageNo: 1 })
    }
  }
  // 设置开始日期禁用事件
  const disabledDateStart = (current) => {
    const endTime = form.getFieldValue('end')
    if (endTime) {
      return current >= endTime
    }
  }
  // 设置结束日期禁用事件
  const disabledDateEnd = (current) => {
    return current && current <= form.getFieldValue('start')
  }
  // 详情打开抽屉
  const handleDetail = (record) => {
    setTempOrder(record)
    setVisible(true)
  }
  // 关闭详情抽屉
  const handleClose = () => {
    setVisible(false)
  }
  // 页面渲染
  return (
    <div className="cashier-main">
      <div className="cashier-search">
        <div className="search-title">收银单据</div>
        <Form className="search-form" form={form} name="search" layout="inline">
          <Form.Item name="word">
            <Input
              placeholder="请输入订单号或手机号"
              allowClear
              className="search-button"
              prefix={<Icon type="iconsousuo" />}
              key={defaultSearch}
              defaultValue={defaultSearch}
            />
          </Form.Item>
          <Form.Item name="orderType">
            <Select
              placeholder="请选择订单类型"
              className="search-orderType"
              key={defaultType}
              defaultValue={defaultType}
            >
              {newShopColumns.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name="orderSource">
            <Select
              placeholder="请选择订单来源"
              className="search-orderSource"
              // key={defaultSource}
              // defaultValue={defaultSource}
            >
              <Select.Option value="MP">小程序</Select.Option>
              <Select.Option value="CRS">收银系统</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => handleSearch()}>
              搜索
            </Button>
          </Form.Item>
        </Form>
        <Form
          className="search-form btn-box"
          form={form}
          name="search"
          layout="inline"
        >
          <Form.Item name="start">
            <DatePicker
              placeholder="请选择起始时间"
              format="YYYY-MM-DD 00:00:00"
              disabledDate={disabledDateStart}
            />
          </Form.Item>
          <div className="center-txt">至</div>
          <Form.Item name="end">
            <DatePicker
              placeholder="请选择结束时间"
              format="YYYY-MM-DD 23:59:59"
              disabledDate={disabledDateEnd}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="cashier-content">
        <Table
          rowKey="orderId"
          dataSource={data}
          loading={tableLoading}
          pagination={{
            current: listQuery.pageNo,
            pageSize: listQuery.pageSize,
            total: listQuery.total,
            showSizeChanger: false,
            onChange: (current) =>
              setListQuery({
                ...listQuery,
                pageNo: current,
              }),
          }}
        >
          <Column title="订单编号" dataIndex="orderNo" key="orderNo" />
          <Column
            title="消费用户"
            dataIndex="realname"
            key="realname"
            render={(text, record) => (text ? text : record.nickname || '散客')}
          />
          <Column
            title="手机号"
            dataIndex="phone"
            key="phone"
            render={(text, record) => text || '-'}
          />
          <Column
            title="订单类型"
            dataIndex="orderType"
            key="orderType"
            width={100}
            render={(text, record) => ORDER_TYPE[text] || '-'}
          />
          <Column
            title="订单总金额（元）"
            dataIndex="totalPrice"
            width={160}
            key="totalPrice"
          />
          <Column
            title="实付款（元）"
            width={120}
            dataIndex="actualPrice"
            key="actualPrice"
          />
          <Column
            title="消费时间"
            dataIndex="gmtPay"
            key="gmtPay"
            render={(text, record) =>
              text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
            }
          />
          <Column
            title="支付方式"
            dataIndex="payChannel"
            width={100}
            key="payChannel"
            render={(text, record) => (
              <div>
                {record.payChannels &&
                  record.payChannels
                    .map((ele) => {
                      return getLabelByValueFromEnum(ele, PAY_TYPE)
                    })
                    .join(',')}
              </div>
            )}
          />
          <Column
            title="订单来源"
            dataIndex="orderSource"
            width={100}
            key="orderSource"
            render={(text, record) =>
              record.orderSource === 'MP' ? (
                <div>小程序</div>
              ) : (
                <div>收银系统</div>
              )
            }
          />
          <Column
            title="操作"
            key="action"
            render={(text, record) => (
              <Button type="primary" onClick={() => handleDetail(record)}>
                详情
              </Button>
            )}
          />
        </Table>
      </div>
      {/* 单据详情 */}
      <Drawer
        title="收银单据详情"
        placement="right"
        onClose={handleClose}
        destroyOnClose={true}
        width="556"
        visible={visible}
      >
        <Detail tempOrder={tempOrder} />
      </Drawer>
    </div>
  )
}

export default connect(null, { getListData })(Cashier)
