// 1.ESC  @初始化命令
// ASCII码 格式：ESC  @
// 十进制   格式：[027][064]
// 	十六进制 格式：[1BH][40H]
// 说明：恢复到上电开机时的状态。

// 2.CLR清屏命令
// ASCII码 格式：CLR
// 十进制   格式：[012]
// 十六进制 格式：[0CH]
// 	说明：清除屏幕上的所有字符。

// 3.ESC  Q  A  d1d2d3…dn  CR送显示数据命令
// ASCII码 格式：ESC  Q  A  d1d2d3…dn  CR
// 	十进制   格式：[027][081][065]d1d2d3…dn[013]     48<=dn<=57或dn=45或dn=46
// 	十六进制 格式：[1BH][51H][41H]d1d2d3…dn[0DH]
//                                          30H<=dn<=39H或dn=2DH或dn=2EH
// 	说明：
// a.执行该命令时，会以覆盖模式送要显示的数据，这样就不需要在每次送显示数据前都去执行CAN清除光标行命令了。
// b.显示的d1…dn没有小数点时1<=n<=8。
// c.显示的d1…dn有小数点时1<=n<=15（8位数值+7位小数点）。
// d.显示的内容可用CLR或CAN命令清除。

// 4.ESC  s  n设置 “单价”、“总计”、“收款”、“找零”字符显示状态命令
// ASCII码 格式：ESC  s  n            0<=n<=4
// 	十进制   格式：[027][115] n           48<=n<=52
// 	十六进制 格式：[1BH][73H] n          30H<=n<=34H
// 	说明：(1)当 n=0，四种灯 全暗。
// (2)当 n=1，“单价”灯 亮，其它三种 暗。
// (3)当 n=2，“总计”灯 亮，其它三种 暗。
// (4)当 n=3，“收款”灯 亮。其它三种 暗。
// (5)当 n=4，“找零”灯 亮。其它三种 暗。
// const device = new window.escpos.Serial("COM2", {
//   baudRate: 2400, //波特率
//   autoOpen: false, //不自动打开
// });
class ClinetDisplay {
  constructor(name = 'COM2', options) {
    options = options || {
      baudRate: 2400, // 波特率
      autoOpen: false // 不自动打开
    }
    this.device = null
    try {
      this.device = new window.escpos.Serial(name, { ...options })
    } catch (error) {
      console.log('ClinetDisplay error: ', error)
    }

    this.isOpen = false
  }

  _send(message) {
    if (!this.isOpen) {
      console.error('设备未打开')
      return
    }
    if (!this.device) {
      console.error('找不到设备')
      return
    }
    this.device.write(message)
  }

  close() {
    this.device && this.device.close()
  }

  open() {
    return new Promise((resolve, reject) => {
      if (!this.device) {
        return reject(new Error('未找到设备'))
      }
      this.device.open(() => {
        this.isOpen = true
        console.log('串口连接成功')
        return resolve()
      })
    })
  }

  /**
   * 初始化客显
   */
  init() {
    const message = `${String.fromCodePoint(27)}${String.fromCodePoint(64)}`
    this._send(message)
  }

  /**
   * 清屏
   */
  clear() {
    const message = `${String.fromCodePoint(12)}`
    this._send(message)
  }

  /**
   * 设置价格
   * @param {string | number} price 显示的价格（8位数值或者7位数值+小数点）
   */
  setPrice(price) {
    const message = `${String.fromCodePoint(27)}${String.fromCodePoint(81)}${String.fromCodePoint(65)}${price}${String.fromCodePoint(13)}`
    this._send(message)
  }

  /**
   *
   * (1)当 type=0，四种灯 全暗。
   * (2)当 type=1，“单价”灯 亮，其它三种 暗。
   * (3)当 type=2，“总计”灯 亮，其它三种 暗。
   * (4)当 type=3，“收款”灯 亮。其它三种 暗。
   * (5)当 type=4，“找零”灯 亮。其它三种 暗。
   * @param {string | number} type
   */
  setType(type) {
    if (Number(type) > 4 || Number(type) < 0) {
      throw Error('type输入错误')
    }
    const message = `${String.fromCodePoint(27)}${String.fromCodePoint(115)}${type}`
    this._send(message)
  }
}

export default ClinetDisplay