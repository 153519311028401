import React, { useState, useEffect, useMemo } from 'react'
import {
  Input,
  Divider,
  Space,
  DatePicker,
  Select,
  Spin,
  Button,
  Empty,
  Drawer,
} from 'antd'
import { connect } from 'react-redux'
import { ORDER_SOURCE_MAP, ORDER_TYPE_MAP } from '@/utils/enum'
import { dateFormat, fixAmount } from '@/utils'

import {
  getListUserConsumeHistory,
  getAppointOrderDetail,
  getBoardOrderDetail,
  getCrsOrderDetail,
  updateBonusAdmin,
} from '@/redux/actions/order'
import { getMemberDetail } from '@/redux/actions/user'
import * as printer from '@/utils/print'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import Icon from '@/components/Icon'
import './index.less'
import moment from 'moment'
import LogisticsDetail from '../../Logistics/Detail'

function ShoppingHistory(props) {
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [detailLoading, setDetailLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [memberDetailLoading, setMemberDetailLoading] = useState(false)
  const [isShowLogisticsDetail, setIsShowLogisticsDetail] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [orderDetail, setOrderDetail] = useState({})
  const [params, setParams] = useState({
    spuTitle: '',
    pageNo: 1,
    pageSize: 10,
    userId: props.userId,
    gmtPayLower: '', // 开始时间
    gmtPayUpper: '', // 结束时间
    orderType: ORDER_TYPE_MAP[0].value,
    orderSource: ORDER_SOURCE_MAP[0].value,
  })

  const onChangeParams = (value, type) => {
    params[type] = value
    // 修改这两个属性自动搜索一次
    if (type === 'orderType' || type === 'orderSource') {
      onSearch(params.spuTitle)
    }
  }

  const handleLoadMore = () => {
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }

  const loadData = () => {
    if (!hasMore) return

    setLoading(true)
    props
      .getListUserConsumeHistory({
        ...params,
      })
      .then((res) => {
        const data = res.data
        setOrderList(orderList.concat(data.items))
        const totalPageNo = Math.ceil(data.count / params.pageSize)
        setHasMore(params.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadDetailUrlByOrderType = {
    [ORDER_TYPE_MAP[0].value]: 'getCrsOrderDetail',
    [ORDER_TYPE_MAP[1].value]: 'getAppointOrderDetail'
  }
  const loadDetailData = (orderNo) => {
    const url = loadDetailUrlByOrderType[params.orderType]
    setDetailLoading(true)
    props[url]({
      orderNo,
    })
      .then((res) => {
        let data = res.data
        console.log('params.orderType', params.orderType)
        if (params.orderType === 'APPOINT') {
          data = dealAppointOrderDetail(data)
        } else if (params.orderType === 'BOARD') {
          data = dealBoardOrderDetail(data)
        } else {
          data = dealGoodsOrderDetail(data)
        }
        setOrderDetail(data)
      })
      .finally(() => {
        setDetailLoading(false)
      })
  }
  const dealAppointOrderDetail = (data) => {
    return {
      ...data,
      skus: data.appointDetailSkuV2VOList.map((item) => ({
        title: item.projectName,
        spuTitle: item.serviceName,
        price: item.totalPrice,
        discountedPrice: item.actualPriceSubtotal,
        num: 1,
        priceSubtotal: item.payPrice,
      })),
      servicePrice: data.serviceDoorPrice,
      discountAmount: data.discountPrice || 0,
      totalSkuNum: 1,
      bonusAdminText:
        Array.isArray(data.bonusAdminList) && data.bonusAdminList.length
          ? data.bonusAdminList
              .map((bonusAdmin) => bonusAdmin.username)
              .join('、')
          : '无',
    }
  }
  const dealBoardOrderDetail = (data) => {
    return {
      ...data,
      skus: data.dateModelList.map((item) => ({
        title: item.skuTitle,
        spuTitle: item.spuTitle,
        price: item.price,
        boardTime: item.boardTime,
        priceSubtotal: item.price,
        type: 'board',
      })),
      servicePrice: data.serviceDoorPrice,
      skuTotalPrice: data.skuTotalPrice || '0',
      discountAmount: data.discountedAmount || '0',
      totalSkuNum: data.totalNum,
      bonusAdminText:
        Array.isArray(data.bonusAdminList) && data.bonusAdminList.length
          ? data.bonusAdminList
              .map((bonusAdmin) => bonusAdmin.username)
              .join('、')
          : '无',
    }
  }
  const dealGoodsOrderDetail = (data) => {
    return {
      ...data,
      skus: data.skus.map((item) => ({
        ...item,
        priceSubtotal: item.price * item.num,
      })),
      bonusAdminText:
        Array.isArray(data.bonusAdminList) && data.bonusAdminList.length
          ? data.bonusAdminList
              .map((bonusAdmin) => bonusAdmin.username)
              .join('、')
          : '无',
    }
  }

  const onSearch = (value) => {
    setOrderList([])
    setSelectedIndex(0)
    setHasMore(true)
    setParams({
      ...params,
      spuTitle: value,
      pageNo: 1,
    })
  }
  const handleChangeStartTime = (date) => {
    setOrderList([])
    setSelectedIndex(0)
    setHasMore(true)
    setParams({
      ...params,
      gmtPayLower: date && date.valueOf(),
    })
  }
  const handleChangeEndTime = (date) => {
    setOrderList([])
    setSelectedIndex(0)
    setHasMore(true)
    setParams({
      ...params,
      gmtPayUpper: date && date.valueOf(),
    })
  }
  const handleShipDetail = () => {
    setIsShowLogisticsDetail(true)
  }
  const handlePrint = () => {
    setMemberDetailLoading(true)
    props
      .getMemberDetail(props.userId)
      .then((memberDetail) => {
        printer.printByOrderDetail({
          orderDetail,
          memberDetail,
          orderNo: orderDetail.orderNo,
        })
      })
      .finally(() => {
        setMemberDetailLoading(false)
      })
  }

  const disabledStartDate = (current) =>
    params.gmtPayUpper ? moment(params.gmtPayUpper).isBefore(current) : false

  const disabledEndDate = (current) =>
    params.gmtPayLower ? moment(params.gmtPayLower).isAfter(current) : false
  // 总金额
  const skuTotalPrice = useMemo(() => {
    if (params.orderType === 'APPOINT') {
      return orderDetail.skuTotalPrice || '0'
    } else if (params.orderType === 'BOARD') {
      return orderDetail.receivableAmount || '0'
    }
    return orderDetail.skuTotalPrice || '0'
  }, [orderDetail])
  const depositPriceElem = useMemo(() => {
    if (orderDetail.depositPrice > 0) {
      return (
        <div className="line">
          <p className="title">定金</p>
          <p className="value">¥{orderDetail.depositPrice}</p>
        </div>
      )
    }
    return null
  }, [orderDetail])
  // 实付金额
  const actualAmount = useMemo(() => {
    if (params.orderType === 'APPOINT') {
      return orderDetail.payPrice || '0'
    } else if (params.orderType === 'BOARD') {
      return orderDetail.payPrice || '0'
    }
    return orderDetail.actualAmount || '0'
  }, [orderDetail])
  const actualAmountElem = useMemo(() => {
    if (orderDetail.status === 150 || orderDetail.waitPayPrice > 0) {
      return (
        <div className="line">
          <p className="title b">待付款</p>
          <p className="value b">
            <span>共{orderDetail.totalSkuNum}件</span> ¥
            {orderDetail.waitPayPrice}
          </p>
        </div>
      )
    }
    return (
      <div className="line">
        <p className="title b">实付款</p>
        <p className="value b">
          <span>共{orderDetail.totalSkuNum}件</span> ¥{actualAmount}
        </p>
      </div>
    )
  }, [orderDetail, actualAmount])
  const isShowPrintButton = useMemo(() => {
    // 寄养 - 定金支付，还未寄养结束
    if (orderDetail.waitPayPrice > 0) {
      return false
    }
    // 预约 - 到店付款
    if (orderDetail.status === 150) {
      return false
    }
    return params.orderSource === 'CRS' || params.orderSource === 'MP'
  }, [params, orderDetail])
  const isShowShipButton = useMemo(
    () => params.orderSource === 'MP' && orderDetail.shipNo,
    [orderDetail]
  )
  // discountAmount
  // 会员优惠
  const memberDiscountAmount = useMemo(() => {
    const deductionPrice = orderDetail.deductionPrice || 0
    const discountAmount = orderDetail.discountAmount
    if (params.orderType === 'APPOINT') {
      return discountAmount
    }
    return fixAmount(discountAmount - deductionPrice) || 0
  }, [orderDetail, params])
  // 商家优惠
  const businessDiscountAmount = useMemo(() => {
    if (typeof orderDetail.businessDiscount === 'number') {
      return orderDetail.businessDiscount
    }
    let amount = fixAmount(
      orderDetail.discountedPrice -
        orderDetail.actualAmount -
        orderDetail.commissionDeduct -
        orderDetail.couponPrice
    )
    return amount < 0 ? 0 : amount
  }, [orderDetail])
  useEffect(() => {
    loadData()
  }, [params])

  useEffect(() => {
    if (orderList.length) {
      if (selectedIndex > orderList.length - 1) {
        setSelectedIndex(0)
        loadDetailData(orderList[0].orderNo)
      } else {
        loadDetailData(orderList[selectedIndex].orderNo)
      }
    }
  }, [selectedIndex, orderList])

  return (
    <div className="shopping-history-con">
      <div className="l">
        <Space direction="vertical" className="filter-con">
          <Input.Search
            prefix={<Icon type="iconsousuo" />}
            className="search-button"
            placeholder="商品/服务名称"
            enterButton="搜索"
            allowClear
            onSearch={onSearch}
          ></Input.Search>
          <div className="filter-item">
            <span>起始时间</span>
            <DatePicker
              className="com"
              placeholder="请选择起始时间"
              format="YYYY-MM-DD 00:00:00"
              bordered={false}
              disabledDate={disabledStartDate}
              onChange={handleChangeStartTime}
            />
          </div>
          <div className="filter-item">
            <span>结束时间</span>
            <DatePicker
              className="com"
              placeholder="请选择结束时间"
              format="YYYY-MM-DD 23:59:59"
              disabledDate={disabledEndDate}
              bordered={false}
              onChange={handleChangeEndTime}
            />
          </div>
          <div className="filter-item">
            <span>订单来源</span>
            <Select
              className="com"
              placeholder="请选择订单来源"
              bordered={false}
              onChange={(value) => onChangeParams(value, 'orderSource')}
              defaultValue={params.orderSource}
            >
              {ORDER_SOURCE_MAP.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item">
            <span>订单类型</span>
            <Select
              className="com"
              placeholder="请选择订单类型"
              bordered={false}
              onChange={(value) => onChangeParams(value, 'orderType')}
              defaultValue={params.orderType}
            >
              {ORDER_TYPE_MAP.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Space>
        <InfiniteScrollList
          className="order-list"
          handleOnLoad={handleLoadMore}
          hasMore={hasMore}
          loading={loading}
        >
          {orderList.map((item, index) => (
            <div
              className={`item ${selectedIndex === index ? 'selected' : ''}`}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              <h2 className="orderno">{item.orderNo}</h2>
              <div className="item-bottom">
                <p className="date">{dateFormat(item.gmtPay)}</p>
                <p className="price">¥{item.actualAmount}</p>
              </div>
            </div>
          ))}
        </InfiniteScrollList>
      </div>
      {/* 订单详情 */}
      <div className="r">
        <Spin spinning={detailLoading} delay={300}>
          {orderList.length ? (
            <>
              <div className="order-info">
                <div className="orderno">
                  <span>订单编号</span>
                  <p>{orderDetail.orderNo}</p>
                </div>
                <div className="date">
                  <Icon type="iconriqi" />
                  <p>{dateFormat(orderDetail.gmtPay)}</p>
                </div>
              </div>
              <div
                className={`detail-list ${
                  params.orderSource === 'CRS' && 'has-print-ticket'
                } ${params.orderType !== 'STORE' && 'no-freight-price'}`}
              >
                {orderDetail.skus &&
                  orderDetail.skus.map((sku, index) => (
                    <div className="detail-item" key={index}>
                      <h2 className="title">
                        {sku.spuTitle} {sku.title}
                      </h2>
                      <div className="detail-info">
                        <p>零售价 ¥{sku.price}</p>
                        {sku.type !== 'board' ? (
                          <p>折扣价 ¥{sku.discountedPrice}</p>
                        ) : (
                          <p className="board-time">{sku.boardTime}</p>
                        )}
                        {sku.type !== 'board' && <p>x{sku.num}</p>}
                        <p>
                          金额 <span>¥{sku.priceSubtotal}</span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="bill-con">
                <div className="line">
                  <p className="title">总金额</p>
                  <p className="value">¥{skuTotalPrice}</p>
                </div>
                {orderDetail.servicePrice > 0 && (
                  <div className="line">
                    <p className="title">接送费用</p>
                    <p className="value">¥{orderDetail.servicePrice}</p>
                  </div>
                )}
                {depositPriceElem}
                <div className="line">
                  <p className="title">会员优惠</p>
                  <p className="value">-¥{memberDiscountAmount}</p>
                </div>
                <div className="line">
                  <p className="title">优惠券</p>
                  <p className="value">-¥{orderDetail.couponPrice || '0'}</p>
                </div>
                <div className="line">
                  <p className="title">积分折扣</p>
                  <p className="value">
                    -¥{orderDetail.commissionDeduct || '0'}
                  </p>
                </div>
                <div className="line">
                  <p className="title">美容卡抵扣</p>
                  <p className="value">
                    -¥
                    {orderDetail.deductionPrice || '0'}
                  </p>
                </div>
                <div className="line">
                  <p className="title">商家优惠</p>
                  <p className="value">
                    -¥
                    {businessDiscountAmount}
                  </p>
                </div>
                {params.orderType === 'STORE' && (
                  <div className="line">
                    <p className="title">运费</p>
                    <p className="value">¥{orderDetail.freightPrice || 0}</p>
                  </div>
                )}
                <Divider />
                <div className="line">
                  <p className="title">支付方式</p>
                  <p className="value">{orderDetail.payChannelDescription}</p>
                </div>
                {actualAmountElem}
                {params.orderType === 'STORE' && (
                  <div
                    className={`sale-con ${
                      params.orderType === 'STORE' && 'is-store-order'
                    }`}
                  >
                    <span>
                      {params.orderType === 'STORE' ? '销售人员' : '服务人员'}
                    </span>
                    <p>{orderDetail.bonusAdminText}</p>
                  </div>
                )}
              </div>
              {(isShowPrintButton || isShowShipButton) && (
                <div className="print-ticket-con">
                  {isShowPrintButton && (
                    <Button
                      className="btn"
                      type="primary"
                      ghost
                      onClick={handlePrint}
                      loading={memberDetailLoading}
                    >
                      打印小票
                    </Button>
                  )}
                  {isShowShipButton && (
                    <Button
                      className="btn"
                      type="primary"
                      onClick={handleShipDetail}
                    >
                      查看物流
                    </Button>
                  )}
                </div>
              )}
            </>
          ) : (
            <Empty
              style={{ paddingTop: '150px' }}
              imageStyle={{
                height: 120,
              }}
              description={<span>暂无数据</span>}
            ></Empty>
          )}
        </Spin>
      </div>
      <Drawer
        title="物流信息"
        placement="right"
        width="auto"
        onClose={() => setIsShowLogisticsDetail(false)}
        visible={isShowLogisticsDetail}
      >
        <LogisticsDetail
          orderNo={orderDetail.orderNo}
          shipNo={orderDetail.shipNo}
        />
      </Drawer>
    </div>
  )
}

export default connect(
  (state) => ({
    printInfo: state.user.printInfo,
  }),
  {
    getListUserConsumeHistory,
    getAppointOrderDetail,
    getCrsOrderDetail,
    getBoardOrderDetail,
    updateBonusAdmin,
    getMemberDetail,
  }
)(ShoppingHistory)
