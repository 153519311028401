import { GET_MEMBER_LIST, GET_MEMBER_DETAIL, GET_MEMBER_HISTORY, IS_SET_MEMBER, SET_ALL_MEMBER_DATA, SET_IS_ACTION } from '../constant'
import request from '../../utils/request'
// 获取会员列表
export const getMemberList = data => ({ type: GET_MEMBER_LIST, data })
// 会员详情
export const setMemberDetail = data => ({ type: GET_MEMBER_DETAIL, data })
// 会员历史
export const setMemberHistory = data => ({ type: GET_MEMBER_HISTORY, data })

export const setISMember = data => ({ type: IS_SET_MEMBER, data })

export const setMemberAll = data => ({ type: SET_ALL_MEMBER_DATA, data })

export const setIsAction = data => ({ type: SET_IS_ACTION, data })

// 获取会员历史记录
export const getMemberHistory = (params) => {
  return async (dispatch) => {
    try {
      const res = await request({
        url: `/crs/crsOrder/getConsumeHistory`,
        method: 'get',
        params
      })
      dispatch(setMemberHistory(res.data))
      return Promise.resolve(res.data)
    } catch (error) {
      dispatch(setMemberHistory(null))
      return Promise.resolve(null)
    }
  }
}
