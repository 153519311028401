import request from '../../utils/request'
const area = '/crs'
// 获取收银单据列表
export const getListData = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/settlementOrder/page`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 根据收银单据id查询详情
export const getDetailData = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/settlementOrder/settlementOrderDetail`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 商品单退款
export const agreeRefund = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/refund`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 寄养预约单退款
export const accordRefund = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/refund/accord`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
