import shanchuIcon from '@/assets/appointment/shanchu.png'
import Cash from '@/components/Cash'
import DebounceSelect from '@/components/DebounceSelect'
import { useHome } from '@/components/Home/hooks/useHome'
import Icon from '@/components/Icon'
import {
  getAppointmentCategory,
  getAppointmentProject,
  getAppointmentType,
  getWorkerList,
  getServeList,
} from '@/redux/actions/goods'
import {
  appointOrderDelete,
  calcAppointOrderAmount,
  calculatePrice,
  deductionCardService,
  getAppointOrderDate,
  getAppointmentTime,
  submitOrder,
  takeAppointOrder,
} from '@/redux/actions/order'
import { configAppoint, getUserPetInfo } from '@/redux/actions/user'
import { Button, Drawer, Form, Image, Input, Select, message } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import BoardDatePicker from './DatePicker'
import Card from './appointmentCard'
import './create.less'

let searchMemberList = []

function Create(props) {
  const [form] = Form.useForm()
  const [selectedMember, setSelectedMember] = useState({
    petInfos: [],
  })
  const [spuList, setSpuList] = useState([
    {
      spuId: undefined,
      skuList: [{ skuId: undefined, userDeductionId: '', title: '' }],
    },
  ])
  const [pickConfig, setPickConfig] = useState({ pickUp: false })
  const [cardIsShow, setCardIsShow] = useState(false)
  const [spuOptions, setSpuOptions] = useState([]) // 预约类目
  const [categoryList, setCategoryList] = useState([]) // 预约类目
  const [cashVisible, setCashVisible] = useState(false)
  const [userId, setUserId] = useState('')
  const [orderNo, setOrderNo] = useState(null)
  const [appointDate, setAppointDate] = useState('')
  const [appointmentTime, setAppointmentTime] = useState([])
  const [loading, setLoading] = useState(false)
  const [skuOptions, setSkuOptions] = useState({}) // 服务项目
  const [isPickUp, setIsPickUp] = useState(false) // 是否上门
  const [userAddress, setUserAddress] = useState('') // 地址
  const [cardList, setCardList] = useState([]) // 地址
  const [workerList, setWorkerList] = useState([])
  const [cashInfo, setCashInfo] = useState({
    skuPrice: 0,
    actualPrice: 0,
    servePrice: 0,
    pickUpPrice: 0,
  })
  const useHomeHook = useHome({
    ...props,
  })
  const appointType = useMemo(() => (isPickUp ? '2' : '0'), [isPickUp])
  // 美容卡
  const handleOpenCard = () => {
    setCardIsShow(true)
  }
  // 添加服务
  const handleAddserve = () => {
    setSpuList([
      ...spuList,
      {
        spuId: undefined,
        skuList: [
          { skuId: undefined, userDeductionId: undefined, title: undefined },
        ],
      },
    ])
  }
  const onChangeCategory = (value, index) => {
    console.log('value', value)
    props
      .getAppointmentType({
        category: value,
      })
      .then((res) => {
        setSpuOptions(res.data)
      })
  }
  // 选择项目
  const onChangeSpu = (value, index) => {
    let spuListTemp = [...spuList]
    const spuItem = spuListTemp[index]
    spuItem.skuList = [
      { skuId: undefined, userDeductionId: undefined, title: undefined },
    ]
    spuItem.spuId = value
    props.getServeList({ spuId: spuItem.spuId }).then((res) => {
      setSkuOptions({
        ...skuOptions,
        [spuItem.spuId]: res.data,
      })
    })
  }
  const onSkuChange = (value, option, spuIndex, skuIndex) => {
    const spuItem = spuOptions.find((item) => item.spuId === value)
    const skuItem = spuItem.appointmentProjectVOList[0]
    let spuListTemp = [...spuList]
    spuListTemp[spuIndex].skuList[skuIndex].skuId = skuItem.skuId
    spuListTemp[spuIndex].skuList[skuIndex].title = skuItem.skuTitle
    console.log('spuListTemp', spuListTemp)
    setSpuList(spuListTemp)
  }

  // 删除服务
  const handleDeleteSpuItem = (item, index) => {
    const spuListTemp = [...spuList]
    if (spuListTemp.length === 1) return
    spuListTemp.splice(index, 1)
    setSpuList(spuListTemp)
  }
  // 添加项目
  const handleAddSkuItem = (item, index, i) => {
    const spuListTemp = [...spuList]
    if (spuListTemp[index].skuList.length >= 6) return
    spuListTemp.splice(index, 1, {
      ...item,
      skuList: [
        ...item.skuList,
        { skuId: undefined, userDeductionId: undefined, title: undefined },
      ],
    })
    setSpuList(spuListTemp)
  }
  // 删除项目
  const handleDeleteSkuItem = (index, childIndex) => {
    const spuListTemp = [...spuList]
    spuListTemp[index].skuList.splice(childIndex, 1)
    setSpuList(spuListTemp)
  }

  /**
   * 搜索会员
   */
  const handleSearchChange = (memberId) => {
    const memberItem = searchMemberList.find((item) => item.id === memberId)
    if (!memberItem) return
    memberItem.nickName = memberItem.realName || memberItem.nickName
    setUserId(memberId)
    console.log('memberItem', memberItem)
    setSelectedMember(memberItem)
    form.setFieldsValue({
      ...memberItem,
      petBodyType: null,
      petBreed: null,
      petGender: null,
      petHairColor: null,
      petId: null,
      petName: null,
      petNeuterStatus: null,
      petWeight: null,
    })
    const skuId = form.getFieldValue('skuId')
    if (skuId) {
      deductionCardService(skuId)
    }
  }
  /**
   * 清除会员
   */
  const handleSearchClear = () => {
    form.resetFields()
  }

  async function fetchUserList(value) {
    if (value === '') {
      return new Promise((resolve) => {
        resolve([])
      })
    }
    return props
      .getUserPetInfo({
        userNameOrPhone: value,
      })
      .then((res) => {
        searchMemberList = cloneDeep(res.data)
        return res.data.map((item) => ({
          label: `${item.realName || item.nickName}(${item.phone})`,
          value: item.id,
        }))
      })
  }
  const userDeductionSkuROList = useMemo(() => {
    const spuListResult = spuList
      .reduce((prev, current) => prev.concat(current.skuList), [])
      .filter((v) => v.skuId)
    return spuListResult.map((spuListItem) => {
      const cardItem =
        cardList.find((card) => card.skuId === spuListItem.skuId) || {}
      return {
        ...spuListItem,
        userDeductionId: cardItem.userDeductionId,
      }
    })
  }, [spuList, cardList])
  // 是否禁用select中的spuId
  const isCategoryOptionDisabled = useCallback(
    (categoryList) => spuList.some((spu) => spu.categoryList === categoryList),
    [categoryList]
  )
  // 是否禁用select中的spuId
  const isSpuItemDisabled = useCallback(
    (spuId) => spuList.some((spu) => spu.spuId === spuId),
    [spuList]
  )
  // 结算时获取优惠券用到
  const deskList = useMemo(
    () =>
      spuList.reduce(
        (prev, next) =>
          prev.concat(
            next.skuList
              .filter((item) => item.skuId)
              .map((item) => ({ skuId: item.skuId, num: 1 }))
          ),
        []
      ),
    [spuList]
  )
  // 是否禁用select中的skuId
  const isSkuOptionDisabled = useCallback(
    (spuId, skuId) => {
      const currentSpuItem = spuList.find((item) => item.spuId === spuId)
      if (currentSpuItem) {
        return currentSpuItem.skuList.some((sku) => sku.skuId === skuId)
      }
      return false
    },
    [spuList]
  )

  // start 预约时间
  const handleDateClick = (val, isFirst = false) => {
    props
      .getAppointmentTime({
        dateStr: val.format('YYYY-MM-DD'),
      })
      .then((res) => {
        const list = res.data || []
        setAppointmentTime([...list])
        if (list.length > 0 && isFirst) {
          const index = list.findIndex((iv) => iv.appoint === true)
          if (list[index]) {
            setAppointDate(list[index].timeStr)
            let startDate = form.getFieldsValue().startDate
            startDate = `${startDate ? startDate.format('YYYY-MM-DD') : ''} ${
              list[index].timeStr
            }`
            startDate = moment(startDate)
            form.setFieldsValue({
              startDate,
            })
          }
        }
      })
  }

  const handleDateFocus = () => {
    const startDate = form.getFieldsValue().startDate
    if (startDate) {
      handleDateClick(moment(startDate))
    } else {
      let current = moment(Date.now()).format('YYYY-MM-DD') + ' 00:00:00'
      current = moment(current)
      form.setFieldsValue({
        startDate: current,
      })
      handleDateClick(current, true)
    }
  }

  const handleDateBlur = () => {
    if (appointmentTime.length === 0) {
      setAppointDate('')
      form.setFieldsValue({
        startDate: '',
      })
    }
    if (!appointDate) {
      form.setFieldsValue({
        startDate: '',
      })
    }
    setAppointmentTime([])
  }
  // 查看是否有美容卡
  const deductionCardService = (skuId) => {
    const skuObj = {
      skuId,
      type: 'SERVICE',
    }
    const skus = [skuObj]
    form.setFields([
      {
        name: 'userDeductionId',
        value: null,
      },
    ])
    useHomeHook.deductionCardService(skus, userId)
  }

  const onOpenChange = (open) => {}

  const handleOnOk = (val) => {
    if (val === undefined) {
      setAppointDate('')
      form.setFieldsValue({
        startDate: '',
      })
      return
    }
    let startDate = form.getFieldsValue().startDate
    startDate = `${startDate ? startDate.format('YYYY-MM-DD') : ''} ${
      val.timeStr
    }`
    startDate = moment(startDate)
    if (startDate) {
      setAppointDate(val.timeStr)
      form.setFieldsValue({
        startDate,
      })
      calcOrderAmount()
    } else {
      setAppointDate('')
    }
  }
  // end
  // 计算金额
  const calcOrderAmount = () => {
    const formData = form.getFieldsValue()
    console.log('formData', formData);
    if (!formData.skuId) return
    let params = {
      ...formData,
      // startDate: dateArray[0],
      channel: 'CRS',
    }
    if (formData.startDate) {
      let startDate = moment(formData.startDate).format('YYYY-MM-DD HH:mm:ss')
      params.startDate = startDate
    }
    props.calcAppointOrderAmount(params).then((res) => {
      const { receivableAmount, discountedAmount } = res.data
      setCashInfo({
        skuPrice: receivableAmount,
        actualPrice: discountedAmount,
      })
    })
  }

  // 结算
  const handleCreateOrder = () => {
    form.validateFields().then(() => {
      if (isPickUp && !userAddress) {
        message.warning('请输入会员地址')
        return
      }
      if (categoryList.some((item) => typeof item.categoryId === 'undefined')) {
        message.warning('请选择服务类目')
        return
      }
      if (
        userDeductionSkuROList.some((item) => typeof item.skuId === 'undefined')
      ) {
        message.warning('请选择服务项目')
        return
      }
      setLoading(true)
      const formData = form.getFieldsValue()
      let params = {
        ...formData,
        channel: 'CRS',
        orderSource: 'CRS',
        appointType: appointType,
        address: userAddress,
        userDeductionSkuROList,
      }
      // return false
      if (formData.startDate) {
        let startDate = moment(formData.startDate).format('YYYY-MM-DD HH:mm:ss')
        params.startDate = startDate
      }
      props
        .submitOrder(params)
        .then((res) => {
          setOrderNo(res.data.orderNo)
          setCashVisible(true)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const handleCashSuccess = () => {
    setCashVisible(false)
    props.onSuccess && props.onSuccess()
  }

  const onCashClose = () => {
    props.onClose && props.onClose()
    // // 未支付返回后删除订单,接口提示 [订单状态异常] 所以注释了
    // props.appointOrderDelete({
    //   orderNo
    // })
    setCashVisible(false)
  }

  const handleCardIsShow = () => {
    setCardIsShow(false)
  }

  const handleCardId = (list) => {
    console.log('card list', list)
    setCardList(list)
    handleCardIsShow()
  }
  const selectedCard = useMemo(() => cardList.length > 0, [cardList])

  useEffect(() => {
    // 获取预约类型
    props.getAppointmentCategory().then((res) => {
      setCategoryList(res.data)
    })
    props.getWorkerList().then(res => {
      setWorkerList(res.data)
    })
  }, [])

  useEffect(() => {
    if (!userDeductionSkuROList || !userDeductionSkuROList.length) {
      return
    }
    let params = {
      userDeductionSkuROList,
      appointType: appointType,
      userId: userId,
    }

    props.calculatePrice(params).then((res) => {
      const {
        receivableAmount,
        discountedAmount,
        servicePrice,
        skuTotalPrice,
      } = res.data
      setCashInfo({
        skuPrice: receivableAmount,
        actualPrice: discountedAmount,
        servePrice: skuTotalPrice,
        pickUpPrice: servicePrice,
      })
    })
  }, [isPickUp])

  useEffect(() => {
    let params = {
      userId: userId,
      appointType: appointType,
      userDeductionSkuROList,
    }
    if (
      userDeductionSkuROList &&
      userDeductionSkuROList[0] &&
      userDeductionSkuROList[0].skuId
    ) {
      props.calculatePrice(params).then((res) => {
        const {
          receivableAmount,
          discountedAmount,
          servicePrice,
          skuTotalPrice,
        } = res.data
        setCashInfo({
          skuPrice: receivableAmount,
          actualPrice: discountedAmount,
          servePrice: skuTotalPrice,
          pickUpPrice: servicePrice,
        })
      })
    }
  }, [userDeductionSkuROList])

  useEffect(() => {
    props.configAppoint().then((res) => {
      setPickConfig(res.data)
    })
  }, [])

  return (
    <div className="appoint-create-con">
      <Form form={form} className="form">
        {/* 账号信息 */}
        <div className="cell-con">
          <div className="cell-title">账号信息</div>
          <div className="search-con">
            <Form.Item name="userId">
              <DebounceSelect
                showSearch
                placeholder="请输入会员名称或手机号"
                fetchOptions={fetchUserList}
                onChange={handleSearchChange}
                onClear={handleSearchClear}
                allowClear={true}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </div>
          <div className="form-group">
            <div className="form-line">
              <Form.Item
                label="姓 名"
                name="nickName"
                rules={[{ required: true, message: '请输入会员姓名' }]}
              >
                <Input placeholder="请输入姓名" bordered={false} />
              </Form.Item>
              <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: '请输入格式正确的手机号',
                  },
                ]}
              >
                <Input placeholder="请输入手机号" type="tel" bordered={false} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="cell-con">
          {/* 预约信息 */}
          <div className="cell-title">预约信息</div>
          <div className="form-group form-group-line">
            <div className="form-line ">
              <Form.Item
                label="预约时间"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: '请选择预约时间',
                  },
                ]}
              >
                <BoardDatePicker
                  value={appointDate}
                  disabledBoardDateArray={[]}
                  appointmentTime={appointmentTime}
                  onClick={handleDateClick}
                  onBlur={handleDateBlur}
                  onFocus={handleDateFocus}
                  onOpenChange={onOpenChange}
                  handleOnOk={handleOnOk}
                />
              </Form.Item>
              <Form.Item
                label="美容师"
                name="workId"
                rules={[
                  {
                    required: true,
                    message: '请选择美容师',
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="请选择美容师"
                >
                  {workerList.map((item) => (
                    <Select.Option value={item.id}>
                      {item.realname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {spuList.map((spuItem, spuIndex) => (
              <div className="addForm-serve">
                <div className="addform-space-spu">
                  {spuIndex > 0 && (
                    <Button
                      type="primary"
                      onClick={() => handleDeleteSpuItem(spuItem, spuIndex)}
                      ghost
                      style={{ marginRight: '10px' }}
                    >
                      删除
                    </Button>
                  )}
                  <Form.Item label="服务类目">
                    <Select
                      bordered={false}
                      placeholder="请选择服务类目"
                      onChange={(value) => onChangeCategory(value, spuIndex)}
                      value={spuList[spuIndex].spuId}
                    >
                      {categoryList.map((item) => (
                        <Select.Option
                          value={item.categoryId}
                          disabled={isCategoryOptionDisabled(item.categoryId)}
                        >
                          {item.categoryTitle}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="addform-space-sku">
                  {spuItem.skuList.map((sku, skuIndex) => (
                    <div className="addform-space-last">
                      <Form.Item label="服务项目">
                        <div>
                          <div className="addform-title-row">
                            <Select
                              placeholder="请选择服务项目"
                              bordered={false}
                              onChange={(value, option) =>
                                onSkuChange(value, option, spuIndex, skuIndex)
                              }
                              value={spuOptions.spuId}
                            >
                              {spuOptions.map((spuItem) => (
                                <Select.Option
                                  key={spuItem.spuId}
                                  value={spuItem.spuId}
                                  disabled={isSpuItemDisabled(spuItem.spuId)}
                                >
                                  {spuItem.spuTitle}
                                </Select.Option>
                              ))}
                            </Select>
                            {skuIndex === 0 && spuItem.skuList.length !== 6 ? (
                              <Button
                                type="primary"
                                onClick={() =>
                                  handleAddSkuItem(spuItem, spuIndex, skuIndex)
                                }
                              >
                                添加
                              </Button>
                            ) : (
                              <div className="del-con">
                                <Image
                                  src={shanchuIcon}
                                  preview={false}
                                  className="del-icon"
                                  onClick={() =>
                                    handleDeleteSkuItem(spuIndex, skuIndex)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <Button
              type="primary"
              ghost
              icon={<Icon className="icon" type="icontianjia" />}
              onClick={handleAddserve}
            >
              添加服务
            </Button>
            <div className="form-line oneline">
              <Form.Item label="备注" name="mono">
                <Input.TextArea
                  bordered={false}
                  placeholder="请输入备注"
                  maxLength={200}
                ></Input.TextArea>
              </Form.Item>
            </div>
            <Form.Item
              label="userDeductionId"
              name="userDeductionId"
              hidden
              style={{ display: 'none' }}
            >
              <Input bordered={false} />
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="block-con">
        <div className="order-info">
          <div className="l">
            <span>预约总额：¥{cashInfo.skuPrice}</span>
            <span>服务金额：¥{cashInfo.servePrice}</span>
          </div>
          <div className="r">
            <p>
              &nbsp;&nbsp; 待付款{' '}
              <span className="price primary-color">
                ¥{cashInfo.actualPrice}
              </span>
            </p>
          </div>
        </div>
        <div className="handle-con">
          <Button
            type="primary"
            className="classCart"
            loading={loading}
            ghost={selectedCard}
            onClick={handleOpenCard}
          >
            {selectedCard && <Icon type="iconxuanzhong1"></Icon>}
            美容卡
          </Button>
          <Button
            type="primary"
            className="submit"
            loading={loading}
            onClick={handleCreateOrder}
          >
            去结算
          </Button>
        </div>
      </div>

      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={onCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={userId}
          orderNo={orderNo}
          deskList={deskList}
          orderType="APPOINT"
          onSuccess={handleCashSuccess}
          destroyOnClose={false}
        />
      </Drawer>

      <Card
        visible={cardIsShow}
        type="choose"
        userId={userId}
        userDeductionSkuROList={userDeductionSkuROList}
        handleClose={handleCardIsShow}
        handleCardId={handleCardId}
      />
    </div>
  )
}

Create.propTypes = {
  onSuccess: PropTypes.func,
}

export default connect(null, {
  getUserPetInfo,
  getAppointmentCategory,
  calcAppointOrderAmount,
  takeAppointOrder,
  getAppointmentType,
  getAppointmentProject,
  getAppointOrderDate,
  getAppointmentTime,
  deductionCardService,
  getServeList,
  getWorkerList,
  calculatePrice,
  submitOrder,
  appointOrderDelete,
  configAppoint,
})(Create)
