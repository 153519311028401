import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import moment from 'moment'
import NoticeBaofu from './baofu'
import './index.less'
const localStorgeKey = 'global-notice-baofu-visibled'
export default function Index() {
  const defaultVisible =
    moment().isBefore(moment('2022-03-09')) &&
    window.localStorage.getItem(localStorgeKey) !== 'true'
  const [visible, setVisible] = useState(defaultVisible)

  const handleConfirm = () => {
    window.localStorage.setItem(localStorgeKey, 'true')
    setVisible(false)
  }

  return (
    <Modal
      visible={visible}
      width="682px"
      wrapClassName="global-notice-modal"
      closable={false}
      maskClosable={false}
      keyboard={false}
      onCancel={handleConfirm}
      footer={
        <Button type="primary" className="btn" onClick={handleConfirm}>
          我知道了
        </Button>
      }
    >
      <NoticeBaofu />
    </Modal>
  )
}
