import React, { useEffect, useState } from 'react'
import { Image, Spin, Tabs, Button, Drawer, Empty } from 'antd'
import { getLabelByValueFromEnum, dateFormat, discountConvert } from '@/utils'
import { getMemberDetail, getUserPetList } from '@/redux/actions/user'
import { getDeductionUserList } from '@/redux/actions/order'
import './index.less'

import TimeCardItem from '@/components/Home/TimeCardItem'
import TimeCardDetail from '@/components/Home/TimeCardDetail'
import defaultPeople from '@/assets/member/people.png'
import tcEmpty from '@/assets/home/tc1.png'


// image
import amountIcon from '@/assets/member/yue.png'
import pointsIcon from '@/assets/member/jifen.png'
import emptyIcon from '@/assets/common/moren.png'
import { connect } from 'react-redux'
import {
  GENDER_MAP,
  MEMBER_SOURCE_MAP,
} from '../../../utils/enum'
import PetDetail from './PetDetail'
import MemberPetEdit from '../PetEdit'
const { TabPane } = Tabs

function Detail(props) {
  const [loading, setLoading] = useState(true)
  const [timeCardDetailVisible, setTimeCardDetailVisible] = useState(false)
  const [timeCardType, setTimeCardType] = useState('base')
  const [timeCardList, setTimeCardList] = useState([])
  const [petList, setPetList] = useState([])
  const [deductionId, setDeductionId] = useState('')
  const [memberDetail, setMemberDetail] = useState({
    commissionAvailable: '--',
    contactAddress: '--',
    email: '--',
    levelName: '--',
    avatarUrl: '',
    nickname: '--',
    phone: '--',
    realname: '--',
    tenantRemark: '--',
    walletBalance: '--',
    pets: [],
  })
  const [expandPetIds, setExpandPetIds] = useState([])
  const [showPetEdit, setShowPetEdit] = useState(false)
  const [petId, setPetId] = useState(null)

  // 开通美容卡
  const openCard = () => {
    setTimeCardType('base')
    setTimeCardDetailVisible(true)
  }

  // 美容卡详情
  const openCardDetail = (item) => {
    setTimeCardType('detail')
    setTimeCardDetailVisible(true)
    setDeductionId(item.id)
  }

  // 打开宠物编辑弹框
  const handleOpenPetEdit = (item) => {
    setShowPetEdit(true)
    setPetId(item.id)
  }

  const handleClosePetEdit = () => {
    setShowPetEdit(false)
  }
  const handleSuccess = () => {
    getUserAllPetList(memberDetail.id)
    handleClosePetEdit()
  }

  const getUserTimeCard = (userId) => {
    props
      .getDeductionUserList({
        pageNo: 1,
        pageSize: 999,
        item: {
          userId,
        },
      })
      .then((res) => {
        if (res.data) {
          const data = res.data
          const list = data.items || []
          setTimeCardList(list)
        }
      })
  }

  const getUserAllPetList = (userId) => {
    props
      .getUserPetList({
        userId,
      })
      .then((res) => {
        if (res.data) {
          setPetList(res.data)
        }
      })
  }

  const handleTabsChange = (val) => {}
  const handleCloseCard = () => {
    setTimeCardDetailVisible(false)
    init()
  }
  const handleShowCreatePet = () => {
    setPetId(null)
    setShowPetEdit(true)
  }
  const init = () => {
    setLoading(true)
    props
      .getMemberDetail(props.userId)
      .then((data) => {
        data && setMemberDetail(data)
        getUserTimeCard(data.id)
        getUserAllPetList(data.id)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="member-detail-con">
        <div className="userinfo">
          <div className="l">
            <Image
              className="head-image"
              src={memberDetail.avatarUrl || defaultPeople}
              preview={false}
              fallback={defaultPeople}
            />
            <div className="content">
              <div className="title">
                {memberDetail.realname || memberDetail.nickname}{' '}
                <span className="tag">{memberDetail.levelName}</span>
              </div>
              <p className="phone">{memberDetail.phone}</p>
            </div>
          </div>
          <div className="r">
            <div className="amount bg">
              <img className="icon" src={amountIcon} alt="" />
              <span>余额</span>
              <p>
                <b>¥</b>
                {memberDetail.walletBalance}
              </p>
            </div>
            <div className="points bg">
              <img className="icon" src={pointsIcon} alt="" />
              <span>积分</span>
              <p>{memberDetail.commissionAvailable}</p>
            </div>
          </div>
        </div>
        <div className="m-d-container">
          <Tabs defaultActiveKey="1" onChange={handleTabsChange}>
            <TabPane tab="用户信息" key={1}>
              {/* 会员信息 */}
              <div className="detail">
                <div className="title">会员信息</div>
                <div className="info">
                  <div className="item">
                    <span className="item-title">姓 名</span>
                    <p className="item-value">{memberDetail.realname}</p>
                  </div>
                  <div className="item">
                    <span className="item-title">性 别</span>
                    <p className="item-value">
                      {getLabelByValueFromEnum(memberDetail.gender, GENDER_MAP)}
                    </p>
                  </div>
                  <div className="item">
                    <span className="item-title">编 号</span>
                    <p className="item-value">{memberDetail.id}</p>
                  </div>
                  <div className="item">
                    <span className="item-title">注册时间</span>
                    <p className="item-value">
                      {dateFormat(memberDetail.gmtCreate)}
                    </p>
                  </div>
                  <div className="item">
                    <span className="item-title">商品消费折扣</span>
                    <p className="item-value primary-color">
                      {discountConvert(memberDetail.goodsDiscount)}
                    </p>
                  </div>
                  <div className="item">
                    <span className="item-title">服务消费折扣</span>
                    <p className="item-value primary-color">
                      {discountConvert(memberDetail.serviceDiscount)}
                    </p>
                  </div>
                  <div className="item">
                    <span className="item-title">邮箱地址</span>
                    <p className="item-value">{memberDetail.email}</p>
                  </div>
                  <div className="item">
                    <span className="item-title">会员来源</span>
                    <p className="item-value">
                      {memberDetail.sourceStr
                        ? memberDetail.sourceStr
                        : getLabelByValueFromEnum(
                            memberDetail.source,
                            MEMBER_SOURCE_MAP
                          )}
                    </p>
                  </div>
                  <div className="item oneline">
                    <span className="item-title">联系地址</span>
                    <p className="item-value">{memberDetail.contactAddress}</p>
                  </div>
                  <div className="item oneline">
                    <span className="item-title">备 注</span>
                    <p className="item-value">{memberDetail.tenantRemark}</p>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="会员美容卡" key={2}>
              <div className="timeCard-container">
                <Button type="primary" className="btn-add" onClick={openCard}>
                  开通美容卡
                </Button>
                {
                  <div className="timeCard-list">
                    <div
                      className={`detail timeCard ${
                        timeCardList.length === 0 ? ' empty' : ''
                      }`}
                    >
                      <div className="info">
                        {timeCardList.length > 0 ? (
                          timeCardList.map((iv, index) => (
                            <TimeCardItem
                              key={index}
                              {...iv}
                              className="card-item"
                              onClick={() => openCardDetail(iv)}
                            />
                          ))
                        ) : (
                          <Empty
                            imageStyle={{
                              marginTop: 50,
                              height: 160,
                            }}
                            image={emptyIcon}
                            description={<span>暂无数据</span>}
                          ></Empty>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Drawer
        title="美容卡详情"
        placement="right"
        width="auto"
        onClose={handleCloseCard}
        visible={timeCardDetailVisible}
        destroyOnClose={true}
      >
        <TimeCardDetail type={timeCardType} deductionId={deductionId} />
      </Drawer>
      <Drawer
        title={petId ? '编辑宠物档案' : '添加宠物档案'}
        placement="right"
        width="auto"
        onClose={handleClosePetEdit}
        visible={showPetEdit}
        destroyOnClose={true}
      >
        <MemberPetEdit
          handleCancel={handleClosePetEdit}
          onSuccess={handleSuccess}
          petId={petId}
          userId={props.userId}
        />
      </Drawer>
    </Spin>
  )
}

export default connect(null, {
  getMemberDetail,
  getDeductionUserList,
  getUserPetList,
})(Detail)
