import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Drawer, Checkbox, Space, Button, Empty } from 'antd'
import Icon from '@/components/Icon'
import './appointmentCard.less'
import { connect } from 'react-redux'
import { getCardList } from '@/redux/actions/order'
function ChooseCard(props) {
  const [cardList, setCardList] = useState([])
  const [list, setList] = useState([])
  const [selectedCardList, setSelectedCardList] = useState([])

  useEffect(() => {
    let data = {
      userId: props.userId,
      skuIdList: props.userDeductionSkuROList,
    }
    props.getCardList(data).then((res) => {
      console.log(res.data, "oooo");
      setCardList(res.data)
    })
  }, [props.userDeductionSkuROList])
  const onChangeCard = (card) => {
    console.log('card', card)
    const tempSelectedCardList = [...selectedCardList]
    const skuId = card.serviceId
    const userDeductionId = card.id
    const index = tempSelectedCardList.findIndex((item) => item.skuId === skuId)
    if (index > -1) {
      tempSelectedCardList.splice(index, 1)
    } else {
      tempSelectedCardList.push({
        skuId,
        userDeductionId,
      })
    }
    setSelectedCardList(tempSelectedCardList)
  }
  const submitCard = () => {
    props.handleCardId(selectedCardList)
  }
  const handleClose = () => {
    setList([])
    props.handleClose()
    props.handleCardId(list)
  }

  const statusText = useCallback((status) => {
    return status === 0
      ? '未生效'
      : status === 1
      ? '生效中'
      : status === 2
      ? '已过期'
      : status === 3
      ? '已失效'
      : ''
  }, [])

  const isCheckDisabled = useCallback(
    (cardId, currentCardList) => {
      const hasChecked = selectedCardList.some((selectedCardItem) =>
        currentCardList.some(
          (currentCard) => currentCard.id === selectedCardItem.userDeductionId
        )
      )
      if (hasChecked) {
        // 禁用未选中并且当前sku美容卡组内的checkbox
        return selectedCardList.findIndex(selectedCard => selectedCard.userDeductionId === cardId) === -1
      }
      return false
    },
    [selectedCardList]
  )
  const isChecked = useCallback(
    (cardId) => {
      return (
        selectedCardList.findIndex((card) => card.userDeductionId === cardId) >
        -1
      )
    },
    [selectedCardList]
  )
  const userCardList = useMemo(() => {
    return cardList.reduce((prev, next) => prev.concat(next.userDeductionCardVOList), [])
  }, [cardList])
  return (
    <Drawer
      title="选择美容卡"
      placement="right"
      width="350px"
      onClose={() => handleClose()}
      visible={props.visible}
      destroyOnClose={true}
    >
      {cardList.map((cardSection) => {
        return (
          <div className="card-group-data">
            {cardSection.userDeductionCardVOList.length > 0 && (
              <p className="card-title">{cardSection.title}</p>
            )}
            {cardSection.userDeductionCardVOList.map((card, index) => {
              return (
                <div className="card-data" key={index} >
                  <div className="card-list" style={{ background: `url(${card.backgroundImg}) no-repeat`, backgroundSize: "cover"}}>
                    <Space className="card-sapce">
                      <Icon className="icon" type="iconlogo1" />
                      <span>{card.title}</span>
                    </Space>
                    <p className="card-list-content">{card.serviceName}</p>
                    <Space className="card-list-num">
                      <span className="time">总次数{card.totalCount}</span>
                      <span className="">{statusText(card.status)}</span>
                    </Space>
                    <Space className="card-list-num">
                      <span className="time">剩余次数{card.residueCount}</span>
                      <span className="">{statusText(card.status)}</span>
                    </Space>
                  </div>

                  <div className="card-checkbox">
                    <Checkbox
                      onChange={(e) => onChangeCard(card)}
                      checked={isChecked(card.id)}
                      disabled={isCheckDisabled(
                        card.id,
                        cardSection.userDeductionCardVOList
                      )}
                    ></Checkbox>
                  </div>
                </div>
              )
            })}
            <div className="card-footer-btn-plac"></div>
            <div className="card-footer-btn">
              <Button type="primary" onClick={() => submitCard()}>
                确定
              </Button>
            </div>
          </div>
        )
      })}
      {userCardList.length === 0 && (
        <Empty description="暂无可用美容卡" style={{ marginTop: 50 }} />
      )}
    </Drawer>
  )
}

export default connect((state) => ({}), {
  getCardList,
})(ChooseCard)
