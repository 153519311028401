import PubSub from 'pubsub-js'
import { checkIsNumberKey } from '@/utils'

let code = ''
let lastTime = null
let nextTime = null
let lastCode = null
let nextCode = null
let timer = null
let firstChar = ''
export function onKeyPress(e) {
  let { keyCode, key } = e
  // 只允许数字和字母
  if (!/^[0-9a-zA-Z.]$/.test(key) && keyCode !== 8 && keyCode !== 13) {
    return
  }

  nextCode = keyCode
  nextTime = new Date().getTime()

  if (
    (lastCode !== null &&
      lastTime !== null &&
      document.activeElement.nodeName !== 'INPUT' &&
      nextTime - lastTime <= 30)
  ) {
    if (key !== 'Enter') code += key
  } else if (
    lastCode !== null &&
    lastTime !== null &&
    nextTime - lastTime > 100
  ) {
    code = ''
  }
  if (firstChar === '') {
    firstChar = key
  }

  lastCode = nextCode
  lastTime = nextTime
  if (keyCode === 13 && code !== '') {
    code = code.replace(/[Enter]/g, '')
    PubSub.publishSync('GLOBAL_SCAN', {
      text: firstChar + code
    })
    code = ''
    key = ''
    firstChar = ''
  } else {
    // 防止扫码影响正常输入
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      if (key !== '') {
        // 如果是扫码，lastCode可能为13，key不为Enter
        if (lastCode === 13 && key !== 'Enter') return

        PubSub.publishSync('GLOBAL_KEYDOWN', {
          text: key,
          code: lastCode,
          isNumberKey: checkIsNumberKey(lastCode),
        })
        firstChar = ''
      }
    }, 30)
  }
}