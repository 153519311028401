import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Input, message } from 'antd'
import { refundFrameInfo, refundBoardOrder } from '@/redux/actions/order'
import NumericInput from '@/components/NumericInput'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import './Refund.less'

function Refund(props) {
  const [refundDetail, setRefundDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [refundExplain, setRefundExplain] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const handleConfirm = () => {
    if (typeof props.handleConfirm === 'function') {
      props.handleConfirm({ refundExplain, refundAmount })
      return
    }
    if (refundDetail.surplusNum === 0) {
      message.error('当前寄养剩余0天，不可退款')
      return
    }
    if (refundAmount === '') {
      message.warning('请输入退款金额')
      return
    }
    if (loading) return
    setLoading(true)
    props
      .refundBoardOrder({
        adminRemark: refundExplain,
        refundPrice: refundAmount,
        orderId: props.orderId,
      })
      .then(() => {
        message.success('退款成功')
        handleSuccess()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onRefundAmountChange = (value) => {
    if (Number(value) > refundDetail.payPrice) {
      setRefundAmount(refundDetail.payPrice)
      return
    }
    setRefundAmount(value)
  }
  const handleCancel = () => {
    setRefundExplain('')
    setRefundAmount('')
    props.onCancel && props.onCancel()
  }
  const handleSuccess = () => {
    setRefundExplain('')
    setRefundAmount('')
    props.onSuccess && props.onSuccess()
  }

  useEffect(() => {
    if (!props.orderNo || !props.visible) {
      return
    }
    props
      .refundFrameInfo({
        orderNo: props.orderNo,
      })
      .then((res) => {
        setRefundDetail(res.data)
        if (props.disabled) {
          setRefundAmount(res.data.payPrice)
        }
      })
  }, [props.orderNo, props.visible])

  const skuListDetail = useMemo(() => {
    return (
      <div className="sku-list-con">
        <a href="javascript:;" onClick={() => setIsShowMore(!isShowMore)}>
          明细 {isShowMore ? <UpOutlined /> : <DownOutlined />}
        </a>
        {isShowMore && (
          <div className="sku-list">
            {refundDetail &&
              refundDetail.skuInfoList.map((item) => (
                <div className={`sku-item ${item.status === 0 ? 'off' : 'on'}`}>
                  <span className="tag">
                    {item.status === 0 ? '未住' : '住'}
                  </span>
                  <span className="date">
                    {item.dateString} ¥{item.price}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }, [isShowMore, refundDetail])
  return (
    <Modal
      visible={props.visible}
      onCancel={handleCancel}
      confirmLoading={loading}
      onOk={handleConfirm}
      destroyOnClose={true}
      width="39.375rem"
      wrapClassName="ant-modal-content-custom board-refund-modal"
      title="订单退款"
    >
      <div className="board-refund-modal-con">
        <div className="row">
          <div className="label">总金额</div>
          <div className="value">¥{refundDetail.actualPrice}</div>
        </div>
        <div className="row">
          <div className="label">房费总额</div>
          <div className="value sku-list-item">
            <span>¥{refundDetail.actualSkuTotalPrice}</span>
            {skuListDetail}
          </div>
        </div>
        <div className="row">
          <div className="label">实付款</div>
          <div className="value">
            <span className="primary-color">¥{refundDetail.payPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="label">退款金额</div>
          <div className="value input-con">
            <span>¥</span>
            <NumericInput
              type="amount"
              disabled={props.disabled}
              placeholder="请输入退款金额"
              onChange={onRefundAmountChange}
              value={refundAmount}
            />
          </div>
        </div>
        <div className="row row-padding" style={{ marginBottom: 37 }}>
          <div className="label">退款说明</div>
          <div className="value">
            <Input
              placeholder="请输入退款说明"
              bordered={false}
              maxLength="200"
              onChange={(e) => setRefundExplain(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default connect(null, {
  refundFrameInfo,
  refundBoardOrder,
})(Refund)
