import { Button } from 'antd'
import './index.less'
export default function ErrorFallback({ error, resetErrorBoundary, user }) {
  return (
    <div className="error-fallback-container">
      <div>
        <span>抱歉，出现了一些问题</span>
        <Button type="primary" onClick={resetErrorBoundary}>
          刷新
        </Button>
      </div>
    </div>
  )
}
