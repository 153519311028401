import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  getDetailData,
  agreeRefund,
  accordRefund,
} from '../../../redux/actions/cashier'
import Icon from '../../../components/Icon'
import { Button, Modal, Spin, message } from 'antd'
import { PAY_TYPE } from '../../../utils/enum'
import * as printer from '@/utils/print'
import './index.less'
import moment from 'moment'
import { getLabelByValueFromEnum } from '@/utils'
import { fixAmount } from '@/utils/index'
function Detail(props) {
  // state
  const [detail, setDetail] = useState({})
  const [loading, setLoading] = useState(false)
  // 解构props
  const { tempOrder, getDetailData, agreeRefund, accordRefund } = props

  // methods
  // 获取详情数据
  const getDetail = () => {
    setLoading(true)
    getDetailData({
      orderId: tempOrder.orderId,
      orderNo: tempOrder.orderNo,
      orderType: tempOrder.orderType,
      orderSource: tempOrder.orderSource,
    })
      .then((res) => {
        console.log('dfsdsaasas', res.data)
        let data = res.data
        if (tempOrder.orderType === 'APPOINT') {
          data = dealAppointOrderDetail(data)
        } else if (tempOrder.orderType === 'BOARD') {
          data = dealBoardOrderDetail(data)
        } else if (tempOrder.orderType === 'DEDUCTION') {
          data = dealDeductionOrderDetail(data)
        } else {
          data = dealGoodsOrderDetail(data)
        }
        console.log('dfsdsaasas', res.data, res.data.discountedPrice)
        setDetail({ ...data })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const dealAppointOrderDetail = (data) => {
    return {
      ...data,
      skus: data.appointDetailSkuV2VOList.map((item) => ({
        title: item.projectName,
        spuTitle: item.serviceName,
        price: item.totalPrice,
        discountedPrice: item.payPrice,
        num: 1,
        priceSubtotal: item.payPrice,
      })),
      totalSkuNum: data.appointDetailSkuV2VOList.length,
    }
  }
  const dealBoardOrderDetail = (data) => {
    return {
      ...data,
      skus: [
        {
          title: data.skuTitle,
          spuTitle: data.spuTitle,
          price: data.price,
          boardTime: `${moment(data.actualBoardStartTime).format(
            'YYYY.MM.DD'
          )} - ${moment(data.actualBoardEndTime).format('YYYY.MM.DD')}`,
          num: `${
            moment(data.actualBoardEndTime).diff(
              data.actualBoardStartTime,
              'day'
            ) + 1
          }`,
          priceSubtotal: `${fixAmount(
            data.price *
              (moment(data.actualBoardEndTime).diff(
                data.actualBoardStartTime,
                'day'
              ) +
                1)
          )}`,
          type: 'board',
        },
      ],
      skuTotalPrice: `${fixAmount(
        data.price *
          (moment(data.actualBoardEndTime).diff(
            data.actualBoardStartTime,
            'day'
          ) +
            1)
      )}`,
      payPrice: data.discountAmount,
      discountedPrice: fixAmount(data.skuTotalPrice - data.discountedPrice),
      totalSkuNum: `${
        moment(data.actualBoardEndTime).diff(data.actualBoardStartTime, 'day') +
        1
      }`,
      totalNum: `${
        moment(data.actualBoardEndTime).diff(data.actualBoardStartTime, 'day') +
        1
      }`,
      receivableAmount: data.skuTotalPrice,
      skuPrice: data.price,
    }
  }
  const dealGoodsOrderDetail = (data) => {
    return {
      ...data,
      skus: data.skus.map((item) => ({
        ...item,
        priceSubtotal: item.price * item.num,
      })),
    }
  }
  const dealDeductionOrderDetail = (data) => {
    return {
      ...data,
      skus: data.skus.map((item) => ({
        ...item,
        num: 1,
        type: 'deduction',
      })),
      totalSkuNum: 1,
      payAmount: data.actualAmount,
    }
  }
  // 退款
  const handleRefund = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '撤单后付款金额将原路退回会员用户支付账户',
      onOk() {
        if (detail.orderType === 'STORE') {
          agreeRefund({
            adminId: detail.adminId,
            orderId: detail.id,
            refundInfo: detail.paymentRecords,
          }).then((res) => {
            message.success('退款成功')
            getDetail()
          })
        } else {
          accordRefund({
            orderId: detail.id,
          }).then((res) => {
            message.success('退款成功')
            getDetail()
          })
        }
      },
      onCancel() {},
    })
  }
  // 打印小票
  const handlePrint = () => {
    printer.printByOrderDetail({
      orderNo: tempOrder.orderNo,
      memberDetail: detail,
    })
  }
  const isAppoint = useMemo(
    () => tempOrder.orderType === 'APPOINT',
    [tempOrder]
  )
  const headerRightBottomElem = useMemo(() => {
    if (isAppoint) {
      return (
        <div className="detail-top-right">
          <span>预约时间：</span>
          <span>
            {moment(detail.appointStartTime).format('YYYY-MM-DD HH:mm')}
          </span>
        </div>
      )
    }
    return null
  }, [isAppoint, detail])
  const depositPriceElem = useMemo(() => {
    if (typeof detail.depositPrice === 'number' && detail.depositPrice > 0) {
      return (
        <div className="price-detail-item">
          <div className="label">定金</div>
          <div className="value">¥{detail.depositPrice}</div>
        </div>
      )
    }
    return null
  }, [detail])
  useEffect(() => {
    getDetail()
  }, [])
  // 页面渲染
  return (
    <div className="cashier-detail">
      <Spin spinning={loading}>
        <div className="cashier-detail-warp">
          <div className="detail-top">
            <div className="detail-top-left">
              <span>订单编号</span>
              <span>{detail.orderNo}</span>
            </div>
            <div className="detail-top-right">
              <Icon type="iconriqi" />
              <span>{moment(detail.gmtPay).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
            <div className="detail-top-left">
              <span>订单来源</span>
              <span>
                {tempOrder.orderSource === 'MP' ? '小程序' : '收银系统'}
              </span>
            </div>
            {headerRightBottomElem}
          </div>
          <div className="good-box">
            {detail.skus &&
              detail.skus.map((item, index) => {
                return (
                  <div className="good-item" key={index}>
                    <div className="good-title">
                      {item.activityTag && (
                        <div className="tag">{item.activityTag}</div>
                      )}
                      <div className="title">
                        {item.spuTitle}
                        {' ' + item.title}
                      </div>
                    </div>
                    <div className="good-info">
                      <span>零售价 ¥{item.price}</span>
                      {item.type !== 'board' && item.type !== 'deduction' && (
                        <p>折扣价 ¥{item.discountedPrice}</p>
                      )}
                      {item.type === 'board' && (
                        <span>
                          {item.boardTime}({item.num}天)
                        </span>
                      )}
                      {item.type !== 'board' && <p>x{item.num}</p>}
                      <div className="good-info-total">
                        <span>小计</span>
                        <strong>
                          <span>¥</span>
                          {item.priceSubtotal}
                        </strong>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="price-detail">
            <div className="price-detail-item">
              <div className="label">总金额</div>
              <div className="value">¥{detail.skuTotalPrice}</div>
            </div>
            {detail.serviceDoorPrice > 0 && (
              <div className="price-detail-item">
                <div className="label">接送费用</div>
                <div className="value">¥{detail.serviceDoorPrice}</div>
              </div>
            )}
            {depositPriceElem}
            <div className="price-detail-item">
              <div className="label">会员优惠</div>
              {detail.orderType === 'APPOINT' ? (
                <div className="value">
                  -¥{fixAmount(detail.skuTotalPrice - detail.discountAmount)}
                </div>
              ) : (
                <div className="value">-¥{detail.discountAmount}</div>
              )}
            </div>
            <div className="price-detail-item">
              <div className="label">优惠券</div>
              <div className="value">-¥{detail.couponPrice}</div>
            </div>
            <div className="price-detail-item">
              <div className="label">积分折扣</div>
              <div className="value">-¥{detail.commissionDeduct}</div>
            </div>
            <div className="price-detail-item">
              <div className="label">美容卡折扣</div>
              <div className="value">-¥{detail.deductionPrice}</div>
            </div>
            <div className="price-detail-item">
              <div className="label">商家优惠</div>
              <div className="value">-¥{detail.businessDiscount}</div>
            </div>
            <div className="line"></div>
            <div className="price-detail-item">
              <div className="label">支付方式</div>
              <div className="value">
                {detail.paymentRecords &&
                  detail.paymentRecords
                    .map((ele) => {
                      return getLabelByValueFromEnum(ele.payChannel, PAY_TYPE)
                    })
                    .join(',')}
              </div>
            </div>
          </div>
          <div className="detail-total">
            <div className="detail-total-top">
              <div className="detail-total-left">实付款</div>
              <div className="detail-total-right">
                <span>共{detail.totalSkuNum}件</span>
                <span>¥{detail.payAmount}</span>
              </div>
            </div>
            <div className="detail-total-box">
              <div className="detail-total-item">
                <div className="detail-total-label">销售人员</div>
                <div className="detail-total-value">
                  {detail.saleAdmin || '-'}
                </div>
              </div>
              <div className="detail-total-item">
                <div className="detail-total-label">备注</div>
                <div className="detail-total-value">
                  {detail.settleRemark || '-'}
                </div>
              </div>
            </div>
          </div>
          <div className="detail-action">
            {/* <Button
              className="btn btn1"
              onClick={handlePrint}
            >
              打印小票
            </Button> */}
            {detail.orderType === 'STORE' &&
              detail.status === 50 &&
              detail.canRefund && (
                <Button className="btn btn2" onClick={handleRefund}>
                  退款
                </Button>
              )}
            {detail.orderType === 'APPOINT' && detail.refundButton && (
              <Button className="btn btn2" onClick={handleRefund}>
                退款
              </Button>
            )}
            {detail.orderType === 'BOARD' && detail.status === 2100 && (
              <Button className="btn btn2" onClick={handleRefund}>
                退款
              </Button>
            )}
            {detail.orderType === 'BOARD' && detail.status === 2300 && (
              <Button className="btn btn2" onClick={handleRefund}>
                退款
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default connect(
  (state) => ({
    printInfo: state.user.printInfo,
  }),
  { getDetailData, agreeRefund, accordRefund }
)(Detail)
