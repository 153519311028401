import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import MSearch from './Search'
import MemberDetail from './MemberDetail'
import MemberCreate from '@/components/Member/Create'
import Icon from '../../Icon'
import './member.less'
import { setMemberDetail, setISMember, setMemberHistory, setIsAction } from '@/redux/actions/home'
import { HomeContext } from '@/components/Home/context-manager'
function Member (props) {
  // 是否显示会员详情
  const [isShowDetail, setIsShowDetail] = useState(false)
  // 是否添加会员
  const [isAddMember, setIsAddMember] = useState(false)
  // memberList
  const [memberList, setMemberList] = useState([])
  // 搜索条件
  const [keyword, setKeyword] = useState('')
  // userid
  const [userId, setUserId] = useState('')
  // 模块是否缩小
  const [isAction, setIsAction] = useState(false)

  const { isClearMemberList, setIsClearMemberList } = useContext(HomeContext)

  // 会员详情
  const getMemberDetail = (val) => {
    if (val) {
      setUserId(val)
      setIsShowDetail(true)
    } else {
      setIsShowDetail(false)
    }
  }
  // 添加会员
  const addMember = (val) => {
    if (val) {
      setIsAddMember(true)
    } else {
      setIsAddMember(false)
    }
  }
  // 关闭
  const onClose = () => {
    setIsShowDetail(false)
    setIsAddMember(false)
    props.setMemberDetail(null)
    props.setISMember(false)
    props.setMemberHistory([])
  }

  const showRender = () => {
    if (isAddMember) {
      // <MemberAdd onClose={onClose} />
      return <MemberCreate handleCancel={onClose} onSuccess={onClose} />
    } else {
      if (isShowDetail) {
        return <MemberDetail onClose={onClose} userId={userId} setUserId={setUserId} />
      } else {
        return (
          <MSearch
            openMemberDetail={getMemberDetail}
            addMember={addMember}
            memberList={memberList}
            setMemberList={setMemberList}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        )
      }
    }
  }
  // 收缩
  const onActionAr = () => {
    props.setIsAction(!isAction)
    setIsAction(!isAction)
  }

  useEffect(() => {
    if (props.isMember) {
      getMemberDetail(props.memberDetail && props.memberDetail.id)
    }
  }, [props.isMember])

  useEffect(() => {
    setIsAction(props.isAction)
  }, [props.isAction])

  useEffect(() => {
    if (props.memberDetail) {
      getMemberDetail(props.memberDetail.id)
    } else {
      onClose()
    }
  }, [props.memberDetail])

  useEffect(() => {
    if (memberList.length === 1) {
      getMemberDetail(memberList[0].id)
    }
  }, [memberList])

  useEffect(() => {
    if (isClearMemberList) {
      setMemberList([])
      setKeyword('')
      setIsClearMemberList(false)
    }
  }, [isClearMemberList])

  return (
    <div className={`home-member ${isAction ? 'actionAr' : ''}`}>
      {
        showRender()
      }
      <div className="right-action" onClick={onActionAr}>
        <Icon className={`icon ${isAction ? 'isAction' : ''}`} type="iconxiangyou"></Icon>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    isMember: state.home.isMember,
    memberDetail: state.home.memberDetail,
    isAction: state.home.isAction
  }),
  {
    setMemberDetail,
    setISMember,
    setMemberHistory,
    setIsAction
  }
)(Member)
