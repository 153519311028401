import React, { useEffect, useState } from 'react'
import { getPrintInfo, getAdminInfo, setStoreInfo } from '@/redux/actions/user'
import { connect, useDispatch } from 'react-redux'

function PrintTicket(props) {
  const dispatch = useDispatch()
  const [fullPath] = useState('./print.html')
  const [webviewId] = useState('__WEBVIEW_ELECTRON')
  useEffect(() => {
    if (props.user.token) {
      
      getAdminInfo({ adminId: props.user.id }, dispatch).then((infoData) => {
        dispatch(
          setStoreInfo({
            ...infoData,
          })
        )
      })

      if (props.user.id) {
        props.getPrintInfo()
      }
    }
  }, [])
  return (
    <div>
      <webview
        src={fullPath}
        id={webviewId}
        nodeintegration="true"
        nodeintegrationinsubframes="true"
      ></webview>
    </div>
  )
}

export default connect((state) => ({ user: state.user }), {
  getPrintInfo,
  getAdminInfo,
})(PrintTicket)
