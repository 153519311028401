import React from 'react';
import { Input } from 'antd';

export default function NumericInput(props) {
  const onChange = (e) => {
    const { value } = e.target;
    const regObj = {
      amount: /^-?\d*(\.\d{0,2})?$/,
      int: /^-?\d*$/,
      float: /^-?\d*(\.\d{0,3})?$/,
    };
    const reg = regObj[props.type] || regObj.amount;
    let newVal = String(value).replace(/[^\d^.]+/g, '');
    if (newVal === '' || newVal === '-') {
      props.onChange && props.onChange(newVal);
      return;
    }
    if (!isNaN(newVal) && reg.test(newVal)) {
      // 限制最小数
      if (props.min !== undefined) {
        newVal = newVal < props.min ? props.min : newVal;
      }
      // 限制最大数
      if (props.max !== undefined) {
        newVal = newVal > props.max ? props.max : newVal;
      }
      props.onChange && props.onChange(newVal);
    }
  };
  return <Input {...props} onChange={onChange} />;
}
