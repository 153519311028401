import React from 'react'
import PropTypes from 'prop-types'
import deleteImage from '@/assets/common/tuige.svg'
import './index.less'

export default function NumberKeyboard(props) {
  const handleInput = (text) => {
    if (text === '.' && props.noDot) {
      return
    }
    props.handleInput && props.handleInput(text)
  }
  const handleChange = (text) => {
    props.handleChange && props.handleChange(text)
  }
  return (
    <div className="number-keyboard">
      <div className="number-line">
        <div className="line">
          <div className="btn" onClick={() => handleInput('1')}>
            1
          </div>
          <div className="btn" onClick={() => handleInput('2')}>
            2
          </div>
          <div className="btn" onClick={() => handleInput('3')}>
            3
          </div>
          <div className="btn" onClick={props.handleDelete}>
            <img src={deleteImage} alt="" />
          </div>
        </div>
        <div className="line">
          <div className="btn" onClick={() => handleInput('4')}>
            4
          </div>
          <div className="btn" onClick={() => handleInput('5')}>
            5
          </div>
          <div className="btn" onClick={() => handleInput('6')}>
            6
          </div>
          <div className="btn warning-color" onClick={() => handleChange('50')}>
            50
          </div>
        </div>
        <div className="line">
          <div className="btn" onClick={() => handleInput('7')}>
            7
          </div>
          <div className="btn" onClick={() => handleInput('8')}>
            8
          </div>
          <div className="btn" onClick={() => handleInput('9')}>
            9
          </div>
          <div
            className="btn warning-color"
            onClick={() => handleChange('100')}
          >
            100
          </div>
        </div>
        <div className="line">
          <div className="btn" onClick={() => handleInput('0')}>
            0
          </div>
          <div className="btn" onClick={() => handleInput('00')}>
            00
          </div>
          <div className="btn" onClick={() => handleInput('.')}>
            {props.noDot ? '' : '.'}
          </div>
          <div
            className="btn warning-color"
            onClick={() => handleChange('200')}
          >
            200
          </div>
        </div>
      </div>
      <div className="btn-con">
        <div className="btn btn-4 confirm" onClick={props.handleConfirm}>
          完成
        </div>
      </div>
    </div>
  )
}

NumberKeyboard.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}
