import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Drawer, message } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  getAppointOrderPageListNew,
  getAppointOrderDetail,
  updateBonusAdmin,
  appointOrderCancel,
  appointOrderConfirm,
  appointRefundAccord,
  appointOrderDelete,
} from '@/redux/actions/order'
import { getAdminAll } from '@/redux/actions/user'
import ADetail from '../List/Detail'
import Cash from '@/components/Cash'
import './index.less'
function DetailAndCash(props) {
  const history = useHistory()
  //
  const [adminAll, setAdminAll] = useState([])
  const [orderDetail, setOrderDetail] = useState({})
  // 结算
  const [skuId, setSkuId] = useState('')
  const [userId, setUserId] = useState('')
  const [orderNo, setOrderNo] = useState(null)
  const [cashVisible, setCashVisible] = useState(false)
  // 退款
  const [refundVisible, setRefundVisible] = useState(false)

  // 服务店员
  const handleChangeBonus = (val) => {
    const adminDTOList = val.map((iv) => {
      return {
        adminId: iv,
      }
    })
    const params = {
      adminDTOList,
      orderTypeEnum: orderDetail.orderType,
      orderNo: orderDetail.orderNo,
    }
    props.updateBonusAdmin(params).then(() => {
      loadDetailData({ orderNo: params.orderNo })
    })
  }
  // 取消预约
  const onCancelAppoint = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '确认取消该预约订单吗？',
      onOk() {
        props
          .appointOrderCancel({
            id: orderDetail.id,
          })
          .then(async () => {
            loadDetailData({ orderNo: orderDetail.orderNo })
            props.onSuccess && props.onSuccess()
            message.success('取消成功')
          })
      },
      onCancel() {},
    })
  }
  // 核销
  const onWriteOff = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '已到店服务，确认核销预约服务。',
      onOk() {
        props
          .appointOrderConfirm({ barCode: orderDetail.barCode })
          .then((res) => {
            loadDetailData({ orderNo: orderDetail.orderNo })
            props.onSuccess && props.onSuccess()
            message.success('核销成功')
          })
      },
      onCancel() {},
    })
  }
  // 退款
  const onRefund = () => {
    setRefundVisible(true)
  }
  const onRefundCancel = () => {
    setRefundVisible(false)
  }
  const onRefundOk = (val) => {
    props.appointRefundAccord(val).then(() => {
      message.success('退款成功')
      loadDetailData({ orderNo: orderDetail.orderNo })
      props.onSuccess && props.onSuccess()
      onRefundCancel()
    })
  }
  //
  // 结算
  const onSettl = () => {
    setSkuId(orderDetail.skuId)
    setUserId(orderDetail.userId)
    setOrderNo(orderDetail.orderNo)
    setCashVisible(true)
  }
  // 结算成功
  const handleCashSuccess = () => {
    loadDetailData({ orderNo: orderDetail.orderNo })
    props.onSuccess && props.onSuccess()
    setCashVisible(false)
  }

  const onCashClose = () => {
    loadDetailData({ orderNo: orderDetail.orderNo })
    props.onCancel && props.onCancel()
    setCashVisible(false)
  }
  //
  // 删除
  const onAppointOrderDelete = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '确认删除该预约订单吗？',
      onOk() {
        props
          .appointOrderDelete({
            orderNo: orderDetail.orderNo,
          })
          .then(() => {
            message.success('删除成功')
            onDetailClose()
            props.setOrderId('')
            props.onSuccess && props.onSuccess()
          })
      },
      onCancel() {},
    })
  }

  // 去处理
  const handleDealOrder = () => {
    // orderPhone=13525791048&orderNo=A101202103041921231012&orderType=APPOINT&orderSource=CRS
    const { orderType, phone, orderNo, orderSource } = orderDetail
    history.push({
      pathname: '/refund',
      state: {
        orderPhone: phone,
        orderNo,
        orderSource,
        orderType,
      },
    })
  }

  const onDetailClose = () => {
    props.setDetailVisisble(false)
  }

  // 详情
  const loadDetailData = (params) => {
    props.getAdminAll().then((res) => {
      let list = res.data || []
      setAdminAll(list)
    })
    props
      .getAppointOrderDetail(params)
      .then((res) => {
        let detail = res.data
        if (detail.bonusAdminList) {
          detail.bonusAdminList.map((iv) => {
            return iv.adminId
          })
        }

        setOrderDetail(detail)
      })
  }

  useEffect(() => {
    if (props.orderId) {
      loadDetailData({
        orderId: props.orderId,
      })
    }
  }, [props.detailVisisble])

  useEffect(() => {
    if (props.orderNo) {
      loadDetailData({
        orderNo: props.orderNo,
      })
    }
  }, [props.orderNo])

  return (
    <div className="detail-and-cash">
      {/* 结算 */}
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={onCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          skuId={skuId}
          userId={userId}
          orderNo={orderNo}
          orderType="APPOINT"
          onSuccess={handleCashSuccess}
        />
      </Drawer>
      {/* 预约详情 */}
      <Drawer
        title="预约详情"
        placement="right"
        width="auto"
        onClose={onDetailClose}
        destroyOnClose={true}
        visible={props.detailVisisble}
      >
        <ADetail
          orderDetail={orderDetail}
          handleChangeBonus={handleChangeBonus}
          adminAll={adminAll}
          onCancelAppoint={onCancelAppoint}
          onSettl={onSettl}
          onRefund={onRefund}
          onWriteOff={onWriteOff}
          refundVisible={refundVisible}
          onRefundCancel={onRefundCancel}
          onRefundOk={onRefundOk}
          onAppointOrderDelete={onAppointOrderDelete}
          handleDealOrder={handleDealOrder}
          className="modal-detail"
        />
      </Drawer>
    </div>
  )
}
export default connect((state) => ({}), {
  getAppointOrderPageListNew,
  getAppointOrderDetail,
  getAdminAll,
  updateBonusAdmin,
  appointOrderCancel,
  appointOrderConfirm,
  appointRefundAccord,
  appointOrderDelete,
})(DetailAndCash)
