import React, { useState, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react'
import { DatePicker, Select, Calendar, Spin } from 'antd'
import { BOARD_ORDER_STATUS_MAP } from '@/utils/enum'
import { getCalendarData } from '@/redux/actions/order'
import moment from 'moment'
import './Calendar.less'
import { connect } from 'react-redux'

const isShowStatusList = [2100, 2200, 2300, 2500]
const dateType = {
  end: '寄养结束',
  start: '寄养中',
  refund: '售后处理中',
  wait: '待寄养',
}
function MyCalendar(props, ref) {
  const [date, setDate] = useState(moment())
  const [dateList, setDateList] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const statusList = useMemo(
    () =>
      BOARD_ORDER_STATUS_MAP.filter(
        (item) => isShowStatusList.indexOf(item.value) > -1
      ),
    []
  )
  const statusOptions = useMemo(
    () =>
      BOARD_ORDER_STATUS_MAP.filter(
        (item) => isShowStatusList.indexOf(item.value) > -1
      ),
    []
  )

  const handleDateChange = (m) => {
    setDate(m)
  }
  const handleDateSelect = (value) => {
    const startTime = value.format('YYYY-MM-DD 00:00:00')
    const endTime = value.format('YYYY-MM-DD 23:59:59')
    props.handleDateSelect && props.handleDateSelect({ startTime, endTime, status })
  }
  const handleStatusChange = (status) => {
    // 后端设置为售后处理中入参为1000
    const s = status === 2500 ? 1000 : status
    setStatus(s)
  }

  const getListData = (value) => {
    const date = value.format('YYYY-MM-DD')
    let dateItem = dateList.find((item) => item.nowDate === date)
    if (dateItem) {
      const keys = Object.keys(dateItem).filter((item) => item !== 'nowDate')
      return keys
        .map((key) => ({ type: key, num: dateItem[key], text: dateType[key] }))
        .filter((item) => item.num !== null && item.num > 0)
    }
    return []
  }

  const dateCellRender = (value) => {
    const listData = getListData(value)
    return (
      <div className="date-list">
        {listData.map((item, index) => (
          <div key={index} className={`date-item date-item-${item.type}`}>
            {item.text}
            {item.num}单
          </div>
        ))}
      </div>
    )
  }

  const loadData = () => {
    setLoading(true)
    props
      .getCalendarData({
        status,
        startTime: date.startOf('M').format('YYYY-MM-DD'),
        endTime: date.endOf('M').format('YYYY-MM-DD')
      })
      .then((res) => {
        setDateList(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadData()
  }, [date, status])

  useImperativeHandle(ref, () => ({
    loadData: loadData
  }))

  return (
    <div className="calendar-con">
      <div className="sidebar-con">
        <div className="top"></div>
        <div className="bottom">
          <p className="title">状态</p>
          {statusList.map((item) => (
            <div className="status-item" key={item.value}>
              <span className={`tag status-${item.value}`}></span>
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="content-con">
        <Spin spinning={loading} delay={300}>
          <Calendar
            className="calendar"
            value={date}
            dateCellRender={dateCellRender}
            onSelect={handleDateSelect}
            headerRender={() => (
              <div className="header-con">
                <DatePicker
                  allowClear={false}
                  picker="month"
                  format="YYYY年MM月"
                  defaultValue={date}
                  className="date-con"
                  onChange={handleDateChange}
                />
                <Select
                  className="select-con"
                  placeholder="寄养状态"
                  allowClear
                  onChange={handleStatusChange}
                >
                  {statusOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          />
        </Spin>
      </div>
    </div>
  )
}

const ForwardMyCalendar = forwardRef(MyCalendar)

export default connect(
  null,
  {
    getCalendarData,
  },
  null,
  { forwardRef: true }
)(ForwardMyCalendar)
