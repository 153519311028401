import React, { useState, useEffect } from 'react'
import { Collapse, Image, Button } from 'antd'
import './PetDetail.less'
import {
  PET_GENDER_MAP,
  PET_NEUTER_STATUS_MAP,
  PET_BODY_TYPE_MAP,
} from '../../../utils/enum'
import { getLabelByValueFromEnum } from '@/utils'
import { defaultPetImage } from '@/utils/default-image'
import moment from 'moment'
const { Panel } = Collapse
// header
const PetHeader = (props) => {
  const [isExpand, setIsExpand] = useState(false)
  useEffect(() => {
    const isFlag = props.expandPetIds.find((iv) => iv * 1 === props.id)
    if (isFlag) {
      setIsExpand(true)
    } else {
      setIsExpand(false)
    }
  }, [props.expandPetIds])
  return (
    <div className={`p-header ${isExpand ? '' : 'active'}`}>
      <div className="p-h-t">
        <div className="pet-id">宠物ID {props.identityNo}</div>
        <div className="p-h-t1">{isExpand ? '收起详情' : '展开详情'}</div>
      </div>
      <div className="p-h-content">
        <div className="c-l">
          <Image className="head-image" src={props.image || defaultPetImage} />
          <div className="c-l-t">
            <h3>{props.name}</h3>
            <p>{props.breed}</p>
          </div>
        </div>
        <div className="c-r">
          <div className="c-r-row">
            <div className="c-r-col-3">
              <span className="label">宠物性别</span>
              <span className="value">
                {getLabelByValueFromEnum(props.gender, PET_GENDER_MAP)}
              </span>
            </div>
            <div className="c-r-col-3">
              <span className="label">宠物毛色</span>
              <span className="value">{props.hairColor}</span>
            </div>
            <div className="c-r-col-3">
              <span className="label">宠物体重</span>
              <span className="value">
                {props.weight || props.weight === 0
                  ? `${props.weight}kg`
                  : '--'}
              </span>
            </div>
          </div>
          <div className="c-r-row">
            <div className="c-r-col-3">
              <span className="label">是否绝育</span>
              <span className="value">
                {getLabelByValueFromEnum(
                  props.neuterStatus,
                  PET_NEUTER_STATUS_MAP
                )}
              </span>
            </div>
            <div className="c-r-col-3">
              <span className="label">宠物体型</span>
              <span className="value">
                {getLabelByValueFromEnum(props.bodyType, PET_BODY_TYPE_MAP)}
              </span>
            </div>
            <div className="c-r-col-3">
              <span className="label">宠物血统</span>
              <span className="value">{props.blood}</span>
            </div>
          </div>
          <div className="c-r-row">
            <div className="c-r-col-3">
              <span className="label">宠物年龄</span>
              <span className="value">{props.age}</span>
            </div>
            <div className="c-r-col-3">
              <span className="label">出生日期</span>
              <span className="value">
                {props.birthday
                  ? moment(props.birthday).format('YYYY.MM.DD')
                  : '--'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function PetDetail(props) {
  return (
    <div className="pet-detail-container">
      <Collapse
        activeKey={props.expandPetIds}
        expandIconPosition="right"
        onChange={(val) => {
          props.setExpandPetIds(val)
        }}
      >
        <Panel header={PetHeader(props)} key={props.id}>
          <div className="p-content">
            <div className="pet-imgs">
              <div className="c-l"></div>
              <div className="c-r">
                <div className="c-r-row">
                  <div className="c-r-col-1" style={{ display: 'flex' }}>
                    <span className="label">宠物图片</span>
                    <span className="value" style={{ flex: 1 }}>
                      {props.imgList &&
                        props.imgList.map((img, index) => (
                          <Image className="pet-image" key={index} src={img} />
                        ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pet-memo">
              <div className="c-l"></div>
              <div className="c-r">
                <div className="c-r-row memo">
                  <div className="c-r-col-1" style={{ display: 'flex' }}>
                    <span className="label">
                      <span>留</span>
                      <span>言</span>
                    </span>
                    <span className="value">
                      {props.message ? props.message : '--'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pet-edit-btns">
              <Button
                type="text"
                className="b-edit"
                onClick={() => props.handleOpenPetEdit(props)}
              >
                编辑
              </Button>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default PetDetail
