import React, { useState } from 'react'
import { Form, Input, DatePicker, Select, Button, message } from 'antd'
import MemberLevelSelect from '@/components/Member/Level/index'
import Icon from '@/components/Icon'
import { createMember } from '@/redux/actions/user'
import { GENDER_MAP, MEMBER_STATUS_MAP } from '@/utils/enum'
import moment from 'moment'
import SelectCustom from '@/components/MySelect/SelectCustom'
import { connect } from 'react-redux'
import './index.less'

function Create(props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    typeof props.handleCancel === 'function' && props.handleCancel()
  }
  const handleSubmit = () => {
    form
      .validateFields()
      .then((fields) => {
        // 会员期限默认长期
        fields.userTerm = fields.userTerm
          ? fields.userTerm.format('YYYY-MM-DD')
          : '2099-12-31'
        fields.birthday = fields.birthday
          ? fields.birthday.format('YYYY-MM-DD')
          : ''
        setLoading(true)
        return props.createMember({
          ...fields,
        })
      })
      .then(() => {
        message.success('添加会员成功')
        form.resetFields()
        typeof props.onSuccess === 'function' && props.onSuccess()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const birthdayDisabledDate = (current) => {
    if (current > moment(Date.now())) {
      return true
    }
  }

  return (
    <div className="member-create-con">
      <div className="block-con">
        <div className="title">
          <h2>添加会员</h2>
          <Icon
            className="icon"
            type="iconguanbi"
            onClick={handleCancel}
          ></Icon>
        </div>
        <Form form={form} initialValues={{ gender: 0, level: 0, status: 1 }}>
          <Form.Item
            label="会员名称"
            name="realname"
            className="form-item"
            rules={[
              { required: true, message: '请输入会员名称' },
              { max: 25, message: '会员名称最长25个字符' },
            ]}
          >
            <Input
              className="form-item-right"
              bordered={false}
              placeholder="请输入会员名称"
              name="realname"
            ></Input>
          </Form.Item>
          <Form.Item
            label="手机号"
            className="form-item"
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: /^1\d{10}$/,
                message: '请输入格式正确的手机号',
              },
            ]}
            validateTrigger={['onBlur']}
          >
            <Input
              className="form-item-right"
              type="tel"
              maxLength={11}
              bordered={false}
              placeholder="请输入手机号"
              name="phone"
            ></Input>
          </Form.Item>
          <Form.Item label="会员生日" className="form-item" name="birthday">
            <DatePicker
              className="form-item-right"
              bordered={false}
              placeholder="请选择会员生日"
              disabledDate={birthdayDisabledDate}
              name="birthday"
            ></DatePicker>
          </Form.Item>
          <Form.Item
            label="性别"
            className="form-item"
            name="gender"
            rules={[
              {
                required: true,
                message: '请选择性别',
              },
            ]}
          >
            <Select
              className="form-item-right"
              bordered={false}
              placeholder="请选择性别"
              name="gender"
            >
              {GENDER_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="会员等级"
            className="form-item"
            name="level"
            rules={[
              {
                required: true,
                message: '请选择会员等级',
              },
            ]}
          >
            <MemberLevelSelect
              className="form-item-right"
              bordered={false}
              placeholder="请选择会员等级"
              name="level"
            ></MemberLevelSelect>
          </Form.Item>
          <Form.Item
            label="会员来源"
            className="form-item"
            name="sourceStr"
            rules={[
              {
                required: true,
                message: '请输入来源',
              },
            ]}
          >
            <SelectCustom
              className="form-item-right"
              bordered={false}
              placeholder="请输入来源"
              name="sourceStr"
              onChange={(value) => form.setFieldsValue({ sourceStr: value })}
            />
          </Form.Item>
          <Form.Item
            label="会员状态"
            className="form-item"
            name="status"
            rules={[
              {
                required: true,
                message: '请选择会员状态',
              },
            ]}
          >
            <Select
              className="form-item-right"
              bordered={false}
              placeholder="请选择会员状态"
              name="status"
            >
              {MEMBER_STATUS_MAP.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="会员期限" className="form-item" name="userTerm">
            <DatePicker
              className="form-item-right"
              bordered={false}
              placeholder="未选择日期默认长期"
              name="userTerm"
            ></DatePicker>
          </Form.Item>
          <Form.Item
            label="邮箱地址"
            className="form-item"
            name="email"
            rules={[
              {
                type: 'email',
                message: '请输入格式正确的邮箱',
              },
            ]}
          >
            <Input
              className="form-item-right"
              type="email"
              bordered={false}
              maxLength={64}
              placeholder="请输入邮箱地址"
              name="email"
            ></Input>
          </Form.Item>
          <Form.Item
            label="联系地址"
            className="form-item"
            name="contactAddress"
          >
            <Input.TextArea
              className="form-item-right"
              type="text"
              bordered={false}
              placeholder="请输入联系地址"
              name="contactAddress"
              maxLength={60}
            ></Input.TextArea>
          </Form.Item>
          <Form.Item label="备注" className="form-item" name="tenantRemark">
            <Input.TextArea
              className="form-item-right"
              type="text"
              bordered={false}
              placeholder="请填写备注，不超过200字"
              maxLength={200}
              autoSize
              name="tenantRemark"
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </div>
      <div className="handle-con">
        <Button className="btn btn-cancel" onClick={handleCancel}>
          取消
        </Button>
        <Button
          type="primary"
          className="btn btn-submit"
          onClick={handleSubmit}
          loading={loading}
        >
          提交
        </Button>
      </div>
    </div>
  )
}

export default connect(null, {
  createMember,
})(Create)
