// user
export const SET_TOKEN = 'set_token'
export const SET_IS_LOGIN = 'set_is_login'
export const SET_STORE_INFO = 'set_store_info'
export const SET_PRINT_INFO = 'set_print_info'
export const SET_AUTH_ROUTE_LIST = 'set_auth_route_list'

// home
// 获取会员列表
export const GET_MEMBER_LIST = 'get_member_list'
// 获取会员详情
export const GET_MEMBER_DETAIL = 'get_member_detail'
// 获取会员历史
export const GET_MEMBER_HISTORY = 'get_member_history'
// 获取商品
export const GET_HOME_GOODS = 'get_home_history'
// 获取服务
export const GET_HOME_SERVE = 'get_home_serve'
// 添加商品至收银台
export const ADD_GOODS_CASHIER = 'add_goods_cashier'
// 修改数量
export const CHANGE_DESK_ITEMS_NUM = 'change_desk_items_num'
// 是否设置会员
export const IS_SET_MEMBER = 'is_set_member'
// 设置全部数据
export const SET_ALL_MEMBER_DATA = 'set_all_memeber_data'
// 设置操作搜索
export const SET_IS_ACTION = 'set_is_action'

// appointment
export const SET_APPOINTMENT_KEYWORD = 'set_appointment_keyword'
export const SET_APPOINTMENT_PARAMS = 'set_appointment_params'

// order
export const SET_ORDER_STATUS = 'set_order_status'
