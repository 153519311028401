import React, { useState } from 'react'
import { MEMBER_SOURCE_MAP } from '@/utils/enum'
import { Select } from 'antd'
import './index.less'

function SelectCustom(props) {
  const [record, setRecord] = useState('')
  //用来记录输入的选项
  const onSearchSelect = (value) => {
    if (!!value) {
      setRecord(value)
    }
  }
  const onBlurSelect = () => {
    if (!!record) {
      onChangeSelect(record)
    }
  }
  const onChangeSelect = (value) => {
    setRecord(value)
    props.onChange && props.onChange(value)
  }

  return (
    <>
      <Select
        {...props}
        allowClear
        showSearch
        className={`c-m-s ${props.className}`}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => onChangeSelect(value)}
        onSearch={(value) => onSearchSelect(value)}
        onBlur={() => onBlurSelect()}
      >
            
        {MEMBER_SOURCE_MAP.map((item) => (
          <Select.Option value={item.label} key={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default SelectCustom
