import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Input, Checkbox, message, Tabs } from 'antd'
import Icon from '../../components/Icon'
import {
  setUsernameByStorage,
  setPasswordByStorage,
  getPasswordByStorage,
  getUsernameByStorage,
} from '@/utils/auth'
import {
  userLogin,
  getPrintInfo,
  getCodeData,
  codeLogin,
  getUserInfo,
} from '@/redux/actions/user'
import logo1 from '../../assets/passport/logo1.png'
import { BASE_PRM_URL } from '@/config/api'
import './login.less'
const { TabPane } = Tabs
let timer = undefined
function Login(props) {
  const [username, setUsername] = useState(getUsernameByStorage())
  const [password, setPassword] = useState(getPasswordByStorage())
  const [isSavePassword, setIsSavePassword] = useState(password !== '')
  const [loading, setLoading] = useState('')
  const history = useHistory()
  // 验证码登录
  const [codeTxt, setCodeTxt] = useState('获取验证码')
  const [smsLoading, setSmsLoading] = useState(false)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [codeSubmitLoading, setCodeSubmitLoading] = useState(false)
  // 设置验证码倒计时
  const codeTime = () => {
    let num = 60
    setCodeTxt(`${num}s`)
    timer = setInterval(() => {
      num = num -= 1
      if (num <= 0) {
        clearInterval(timer)
        setCodeTxt('获取验证码')
        return
      }
      setCodeTxt(`${num}s`)
    }, 1000)
  }
  // 获取验证码时，首先校验当前手机号是否注册
  const getPhoneInfo = () => {
    setSmsLoading(true)
    props
      .getUserInfo({ keyword: phone })
      .then((res) => {
        if (res.data.id) {
          getSmsAndTime()
        } else {
          message.warning('手机号尚未注册，请先进行注册')
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }
  // 获取验证码接口+倒计时
  const getSmsAndTime = () => {
    props
      .getCodeData({
        phone,
        type: 'CRS_LOGIN',
      })
      .then(() => {
        codeTime()
      })
      .finally(() => {
        setSmsLoading(false)
      })
  }
  // 获取验证码
  const getSms = () => {
    if (!phone) {
      message.warning('请输入手机号')
      return
    }
    const reg_tel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (!reg_tel.test(phone)) {
      message.warning('请输入正确格式的手机号')
      return
    }
    getPhoneInfo()
  }
  // 验证码登录
  const handleCodeLogin = () => {
    if (phone === '') {
      message.error('请输入手机号')
    } else if (code === '') {
      message.error('请输入短信验证码')
    } else {
      setCodeSubmitLoading(true)
      props
        .codeLogin({
          phone,
          code,
          smsTemplateTypeEnum: 'LOGIN_CONFIRM_VERIFICATION_CODE',
        })
        .then(({ firstRoutePath }) => {
          if (firstRoutePath) {
            props.getPrintInfo()
            history.replace(firstRoutePath)
          } else {
            message.warning('暂无登录权限，请联系管理员')
          }
        })
        .catch(() => {
          setCodeSubmitLoading(false)
        })
    }
  }
  // 跳转注册
  const handleRegister = () => {
    let url = ''
    if (process.env.REACT_APP_ENV === 'dev') {
      url = 'http://localhost:8000'
    } else {
      url = BASE_PRM_URL
    }
    window.open(`${url}/#/register?form=crs`, '_self')
  }

  const handleLogin = () => {
    if (username === '') {
      message.error('请输入用户名')
    } else if (password === '') {
      message.error('请输入密码')
    } else {
      setLoading(true)
      props
        .userLogin({
          username,
          password,
        })
        .then(({ firstRoutePath }) => {
          setUsernameByStorage(username)
          if (isSavePassword) {
            setPasswordByStorage(password)
          } else {
            setPasswordByStorage(null)
          }
          if (firstRoutePath) {
            props.getPrintInfo()
            history.replace(firstRoutePath)
          } else {
            message.warning('暂无登录权限，请联系管理员')
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleLogin()
    }
  }
  const onChangeSavePassword = (e) => {
    setIsSavePassword(e.target.checked)
  }
  return (
    <div className="passport-login-con">
      <div className="login-box">
        <div className="banner"></div>
        <div className="form-con">
          <div className="title-con">
            <p>欢迎登录，</p>
            <p>青松管门店收银系统</p>
          </div>
          <div className="input-box">
            <Icon className="input-icon" type="iconzhanghao" />
            <Input
              className="input-style"
              placeholder="账号"
              value={username}
              size="large"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-box">
            <Icon className="input-icon" type="iconmima" />
            <Input
              className="input-style"
              type="password"
              placeholder="密码"
              size="large"
              value={password}
              onKeyPress={handleKeyPress}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <Checkbox checked={isSavePassword} onChange={onChangeSavePassword}>
              记住密码
            </Checkbox>
          </div>
          <Button
            onClick={handleLogin}
            size="large"
            type="primary"
            loading={loading}
            className="submit-btn"
          >
            登录
          </Button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  userLogin,
  getPrintInfo,
  getCodeData,
  codeLogin,
  getUserInfo,
})(Login)
