import React from 'react'
import { connect } from 'react-redux'
import { versionLog, version } from '@/config'
import './index.less'
function Update() {
  return (
    <div className="setting-update">
      <div className="row">
        <span className="title">版本历史记录</span>
      </div>
      <div className="update-info">
        <div className="edition">v{version}</div>
        <div className="value">
          {versionLog.list.map((item) => (
            <div className="item">
              -{item.title}
              {item.text}
            </div>
          ))}
        </div>
        <div className="date">{versionLog.date}</div>
      </div>
    </div>
  )
}

export default connect(null, {
})(Update)
