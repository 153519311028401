import { SET_STORE_INFO, SET_TOKEN, SET_PRINT_INFO, SET_AUTH_ROUTE_LIST, SET_IS_LOGIN } from '../constant'

const initState = {
  token: '',
  isLogin: false,
  tenantName: '',
  realname: '',
  userRole: '',
  username: '',
  tenantId: '',
  printInfo: {},
  authRouteList: []
}
export default function createPerson(perState = initState, action) {
  const { type, data } = action
  switch (type) {
    case SET_TOKEN:
      return { ...perState, token: data }
    case SET_IS_LOGIN:
      return { ...perState, isLogin: data }
    case SET_STORE_INFO:
      return { ...perState, ...data }
    case SET_PRINT_INFO:
      return { ...perState, printInfo: { ...data } }
    case SET_AUTH_ROUTE_LIST:
      return { ...perState, authRouteList: [ ...data ] }
    default:
      return perState
  }
}
