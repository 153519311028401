import React, { useState, useEffect } from 'react'
import {
  Slider,
  Switch,
  Button,
  Space,
  Radio,
  Modal,
  Steps,
  Progress,
  message,
} from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import Icon from '../../Icon'
import { setPrintLineHeight, getPrintLineHeight } from '../../../utils/print'
import * as printer from '../../../utils/print'
import { PRINT_WIDTH, NOT_NOTIFY_VOICE, NOT_PRINT_QRCODE } from '@/utils/constant'
import { inApp } from '../../../utils/device'
import printTicket from '@/utils/printTicket'
import './index.less'
import duankai from '@/assets/setting/print/duankai.png'
import lianjiezhong from '@/assets/setting/print/lianjiezhong.png'
import faxian from '@/assets/setting/print/faxian.png'
import lianjieshibai from '@/assets/setting/print/lianjieshibai.png'
import lianjiechenggong from '@/assets/setting/print/lianjiechenggong.png'
const { Step } = Steps
export default function General() {
  // 初始化步骤条
  const steps = [
    {
      title: '1',
    },
    {
      title: '2',
    },
    {
      title: '3',
    },
    {
      title: '4',
    },
  ]
  // 初始化状态
  const [silderCount, setSilderCount] = useState(0)
  const [silderTempCount, setSilderTempCount] = useState(silderCount)
  const [isShow, setIsShow] = useState(false)
  const [isShowPrintWidth, setIsShowPrintWidth] = useState(false)
  const [printWidth, setPrintWidth] = useState(58)
  const [hasVoice, setHasVoice] = useState(false)
  const [hasQrCode, setHasQrCode] = useState(false)
  const [printText, setPrintText] = useState('打票机未连接')
  const [isShowPrint, setIsShowPrint] = useState(false)
  const [printList, setPrintList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [current, setCurrent] = useState(0)
  const [progressCount, setProgressCount] = useState(0)
  const [s, setS] = useState(0)
  const [timer, setTimer] = useState(null)
  const [isConnect, setIsConnect] = useState(false)
  const [radioValue, setRadioValue] = useState(null)
  const [initPrintName, setInitPrintName] = useState('')
  const [nextBtnTxt, setNextBtnTxt] = useState('下一步')

  // 生命周期
  useEffect(() => {
    setPrintWidth(printer.getPrintWidth())
    return () => {
      if (timer) clearInterval(timer)
      console.log('页面关闭')
    }
  }, [])
  // 间距
  useEffect(() => {
    setHasVoice(window.localStorage.getItem(NOT_NOTIFY_VOICE) !== 'true')
    setHasQrCode(window.localStorage.getItem(NOT_PRINT_QRCODE) !== 'true')
    setSilderCount(getPrintLineHeight())
    setSilderTempCount(getPrintLineHeight())
  }, [])
  // 初始化时连接打票机
  useEffect(() => {
    const printType = printer.getPrintType()
    // 内置打票机
    if (printType === 'app-inside') {
      try {
        const sunmiPrinter = api.require('sunmiPrinter')
        sunmiPrinter.startService(function (ret, err) {
          refreshConnectStatus(
            ret.status || (err && err.msg === '打印机服务已启动')
          )
        })
      } catch (error) {}
    } else {
      const printName = printer.getPrintName()
      printer.checkPrintStatus(printName).then((status) => {
        refreshConnectStatus(status)
      })
      getPrintList(printName)
      // 设置初始化的打票机名称
      setInitPrintName(printName)
    }
  }, [])
  // 初始化时打票机如果连接成功，设置打票机列表的默认选中
  useEffect(() => {
    if (initPrintName) {
      const index = printList.findIndex((item) => item.label === initPrintName)

      if (index > -1) setRadioValue(printList[index].value)
    }
  }, [initPrintName, printList])

  // 检测当前步骤条是否走到第二个
  useEffect(() => {
    console.log('当前的current', current)
    if (current === 1) {
      console.log('判断进来了吗')
      onStep()
    }
    if (current === 2 && isConnect) {
      setNextBtnTxt('下一步')
    }
    if (current === 2 && !isConnect) {
      setNextBtnTxt('关闭')
    }
    if (current === 3) {
      setNextBtnTxt('完成')
    }
  }, [current])
  // 每次关闭安卓打票机弹窗时，重置数据
  useEffect(() => {
    if (isModalVisible) return
    setCurrent(0)
    setNextBtnTxt('下一步')
  }, [isModalVisible])
  // 检测当前倒计时是否结束，结束清理定时器
  useEffect(() => {
    if (progressCount >= 100) {
      setS(0)
      clearInterval(timer)
      setCurrent(2)
    }
  }, [progressCount])
  // 检测打票机是否连接
  useEffect(() => {
    if (isConnect) {
      setCurrent(2)
    }
  }, [isConnect])

  // methods
  // 取消滑块操作
  const handleCanel = () => {
    setIsShow(false)
    setSilderTempCount(silderCount)
  }
  // 保存打印行间距
  const handleSave = () => {
    setSilderCount(silderTempCount)
    setPrintLineHeight(silderTempCount)
    setIsShow(false)
  }
  const handlePrintWidthCanel = () => {
    setIsShowPrintWidth(false)
    setPrintWidth(localPrintWidth)
  }
  const handlePrintWidthSave = () => {
    window.localStorage.setItem(PRINT_WIDTH, printWidth)
    setIsShowPrintWidth(false)
  }
  // 打开/关闭消息提示声音
  const onNoticeChange = (checked) => {
    if (checked) {
      window.localStorage.removeItem(NOT_NOTIFY_VOICE)
    } else {
      window.localStorage.setItem(NOT_NOTIFY_VOICE, 'true')
    }
    setHasVoice(checked)
  }
  const onQrCodeChange = (checked) => {
    if (checked) {
      window.localStorage.removeItem(NOT_PRINT_QRCODE)
    } else {
      window.localStorage.setItem(NOT_PRINT_QRCODE, 'true')
    }
    setHasQrCode(checked)
  }
  // 点击选择打票机，判断当前是pc还是安卓
  const connectPrint = () => {
    if (!inApp) {
      // Electron
      setIsShowPrint(!isShowPrint)
    } else {
      if (isConnect) {
        message.warning(
          '当前已连接打票机，如需更换打票机，请先断开当前已连接的打票机'
        )
      } else {
        // 安卓
        setCurrent(0)
        setIsModalVisible(!isModalVisible)
      }
    }
  }
  // 关闭打票机列表
  const handleCanelPrint = () => {
    setIsShowPrint(false)
  }
  // 选择打票机
  const handleSavePrint = () => {
    setIsShowPrint(false)
  }
  // 测试打印的函数
  const refreshConnectStatus = (status) => {
    setIsConnect(status)
    setPrintText(status ? '打票机已连接' : '打票机未连接')
  }
  // 获取打票机
  const getPrintList = (printName) => {
    setPrintList(
      printer.getPrintList().map((item) => ({
        label: item.name,
        value: item.name,
      }))
    )
    printer.checkPrintStatus(printName).then((status) => {
      if (!status) {
        setPrintText('打票机未连接')
        printer.removePrint()
      }
    })
  }
  // 选择打票机
  const printerChange = (e) => {
    const index = printList.findIndex((item) => item.value === e.target.value)
    console.log(printList[index].label)
    printer.setPrintName(printList[index].label)
    const printName = printer.getPrintName()
    printer.checkPrintStatus(printName).then((status) => {
      refreshConnectStatus(status)
    })
    setRadioValue(e.target.value)
  }
  /**
   * 设置打印纸宽度
   */
  const printerWidthChange = (e) => {
    setPrintWidth(e.target.value)
  }
  // 点击测试打印
  const clickPrint = () => {
    printTicket('测试打印')
  }
  // 安卓添加打印机modal
  const modalHandleOk = () => {
    // 判断当前是否在第三步骤，并且没有连接
    if (!isConnect && current === 2) {
      setIsModalVisible(false)
      return
    }
    if (current === 3) {
      setIsModalVisible(false)
      return
    }
    if (!isConnect && current === 1) return
    if (!isConnect && current === 2) return
    const tempCurrent = current + 1
    setCurrent(tempCurrent)
  }
  const modalHandleCancel = () => {
    setIsModalVisible(false)
  }
  const checkConnectStatusInApp = (timer) => {
    // 获取usb打印机地址
    printer.getUsbPrintersAddressInApp().then((addressList) => {
      if (addressList.length) {
        printer.setPrintUSBAddressInAppByLocal(addressList[0].address)
        refreshConnectStatus(true)
        clearInterval(timer)
      }
    })
  }
  // 进度条
  const onStep = () => {
    if (current === 1) {
      setProgressCount(0)
      setS(30)
      setTimer()
      const timer = setInterval(() => {
        console.log('计时器执行')
        setProgressCount((pre) => (pre += 100 / 30))
        setS((pre) => (pre -= 1))
        checkConnectStatusInApp(timer)
      }, 1000)
      setTimer(timer)
    }
  }

  return (
    <div className="setting-general">
      <div className="top">
        <div className="row">
          <span className="title">通用设置</span>
        </div>
        <div
          className={!isShow ? 'row borderNone testPrint' : 'row testPrint'}
          onClick={setIsShow.bind(this, !isShow)}
        >
          <div className="label">打印行间距</div>
          <div className="value">
            <span className="count">{silderCount}</span>
            <Icon type="icondakai1" />
          </div>
        </div>
        {isShow && (
          <div className={isShow ? 'row borderNone' : 'row'}>
            <div className="label addWidth">
              设置打印行间距
              <div className="silder">
                <Slider
                  defaultValue={silderCount}
                  onChange={(val) => setSilderTempCount(val)}
                />
                <span>{silderTempCount}</span>
              </div>
            </div>
            <div className="value">
              <Space>
                <Button onClick={handleCanel}>取消</Button>
                <Button type="primary" onClick={handleSave}>
                  保存
                </Button>
              </Space>
            </div>
          </div>
        )}
        <div
          className={
            !isShowPrintWidth ? 'row borderNone print-width' : 'row print-width'
          }
          onClick={setIsShowPrintWidth.bind(this, !isShowPrintWidth)}
        >
          <div className="label">打印纸宽度</div>
          <div className="value">
            <span className="count">{printWidth}</span>
            <Icon type="icondakai1" />
          </div>
        </div>
        {isShowPrintWidth && (
          <div className={isShowPrintWidth ? 'row borderNone' : 'row'}>
            <div className="label addWidth">
              设置打印纸宽度
              <div className="radioSelect">
                <Radio.Group
                  onChange={(e) => printerWidthChange(e)}
                  value={printWidth}
                >
                  <Radio value={58}>58mm</Radio>
                  <Radio value={80}>80mm</Radio>
                </Radio.Group>
              </div>
            </div>
            <div className="value">
              <Space>
                <Button onClick={handlePrintWidthCanel}>取消</Button>
                <Button type="primary" onClick={handlePrintWidthSave}>
                  保存
                </Button>
              </Space>
            </div>
          </div>
        )}
      </div>
      <div className="con">
        <div className="row">
          <div className="label">消息提示声音</div>
          <div className="value">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={hasVoice}
              onChange={onNoticeChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="label">小程序码显示</div>
          <div className="value">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={hasQrCode}
              onChange={onQrCodeChange}
            />
          </div>
        </div>
        <div className="row testPrint" onClick={connectPrint.bind(this)}>
          <div className="label">USB打票机</div>
          <div className="value">
            <span className={isConnect ? 'count' : 'count connectFalse'}>
              {printText}
            </span>
            <Icon type="icondakai1" />
          </div>
        </div>
        {isShowPrint && (
          <div className="row">
            <div className="label addWidth">
              请选择打票机
              <div className="radioSelect">
                {printList.length > 0 ? (
                  <Radio.Group
                    onChange={(e) => printerChange(e)}
                    value={radioValue}
                  >
                    {printList.map((item, index) => {
                      return (
                        <Radio value={item.value} key={item.value}>
                          {item.label}
                        </Radio>
                      )
                    })}
                  </Radio.Group>
                ) : (
                  <span>
                    未找到打印机，请确认打票机USB已链接及
                    <a
                      href="https://yuque.com/chang-qnafj/ropywz/xs23ab"
                      target="_blank"
                      rel="noreferrer"
                      className="helpWord"
                    >
                      打票机插件
                    </a>
                    已下载好。
                  </span>
                )}
              </div>
            </div>
            <div className="value">
              <Space>
                <Button onClick={handleCanelPrint}>取消</Button>
                <Button type="primary" onClick={handleSavePrint}>
                  保存
                </Button>
              </Space>
            </div>
          </div>
        )}
        <div className="row testPrint" onClick={clickPrint}>
          <div className="label">打印测试单据</div>
          <div className="value">
            <span className="test">打印</span>
            <Icon type="iconxiangyou1" />
          </div>
        </div>
      </div>
      {/* 安卓端连接打印机需要步骤操作，获取打印机连接 */}
      <Modal
        className={current === 1 ? 'noneBtn delFooter' : 'noneBtn'}
        title="添加USB打票机"
        visible={isModalVisible}
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        okText={nextBtnTxt}
        centered
        width="650px"
      >
        <div className="box">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>
          <div className="content">
            {current === 0 && (
              <div className="step-1">
                <img src={duankai} alt="" />
                <p>请先断开您需要连接的USB打票机</p>
              </div>
            )}
            {current === 1 && (
              <div className="step-2">
                <div className="circlee-con">
                  <Progress
                    strokeColor={{
                      '0%': '#86CE65',
                      '100%': '#86CE65',
                    }}
                    type="circle"
                    steps={30}
                    percent={progressCount}
                  />
                  <img src={lianjiezhong} alt="" />
                  <p className="countdown">{s}S</p>
                </div>
                <p>请插入您要连接的USB打票机，并等待系统检测</p>
              </div>
            )}
            {current === 2 && isConnect && (
              <div className="step-3">
                <img src={faxian} alt="" />
                <p>发现打票机！</p>
              </div>
            )}
            {current === 2 && !isConnect && (
              <div className="step-3">
                <img src={lianjieshibai} alt="" />
                <p>
                  抱歉，找不到您的USB打票机！您可以换一个USB口或者换一台打印机重试
                </p>
              </div>
            )}
            {current === 3 && (
              <div className="step-4">
                <img src={lianjiechenggong} alt="" />
                <p>恭喜，已经连接USB打票机</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
