import React, {createRef} from 'react'
import './sidebar.less'
import Icon from '../../../Icon'
import { connect } from 'react-redux'
function Sidebar(props) {
  const {selectIndex = 0} = props
  const gsContent = createRef()
  const onAction = (type) => {
    if (type === 'add') {
      gsContent.current.scrollTop = gsContent.current.scrollTop + 150
    } else if (type === 'reduce') {
      gsContent.current.scrollTop = gsContent.current.scrollTop - 150
    }
    
  }
  return (
    <div className="g-sidebar">
      <div className="action" onClick={() => onAction('reduce')}>
        <Icon type="iconxiangshang" />
      </div>
      <div className="g-s-content" ref={gsContent}>
        {
          props.category.map((iv, index) => (
            <div className={`item ${selectIndex === index ? 'active' : ''}`} key={iv.id} onClick={() => props.onGetList(iv, index)}>
              {iv.title}
            </div>
          ))
        }
      </div>
      <div className="action bottom" onClick={() => onAction('add')}>
        <Icon type="iconxiangxia" />
      </div>
    </div>
  )
}
Sidebar.defaultProps = {
  category: [],
  selectIndex: 0
}
export default connect(
  state => ({}),
  {
  }
)(Sidebar)
