import React from 'react'
import './items.less'
import { ossProcess } from '@/utils'
import { Image } from 'antd'
import defalutGoodImg from '@/assets/home/goods.png'
export default function Items (props) {

  // 按规格选择商品/直接添加
  const onChooseGoods = (iv) => {
    props.onChooseGoods(iv)
  }

  const isRenderStockNode = (iv) => {
    if (iv.skus && iv.skus.length > 1) {
      const nArr = iv.skus.filter(i => i.stock <= 0)
      return nArr.length === iv.skus.length ? (
        <div className="no-stock">
          库存不足
        </div>
      ) : ''
    }
    return ''
  }

  return (
    <>
      {
        <div className={`item ${props.img || props.spuImg ? '' : 'noImg'}`} onClick={() => onChooseGoods(props)} key={props.index}>
          {
            isRenderStockNode(props)
          }
          <Image className="g-img" src={props.img || props.spuImg ? ossProcess(props.img || props.spuImg) : defalutGoodImg} preview={false} fallback={defalutGoodImg} />
          <div className="g-img-mask">
            {
              props.isSearch ? (
                <div className="sku_t" title={props.spuTitle}>
                  {props.spuTitle} {props.title}
                </div>
              ) :
                <div className="sku_t" title={props.title}>
                  {props.title}
                </div>
            }

          </div>
          <div className="info">
            <span className="dis-price">¥{props.price || 0}</span>
            <span className="ori-price">¥{props.originalPrice || 0}</span>
          </div>
        </div>
      }
    </>
  )
}
Items.defaultProps = {
  goodsList: []
}
