import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
import ASearch from './Search'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import { BOARD_ORDER_STATUS_MAP } from '@/utils/enum'
import { getLabelByValueFromEnum } from '@/utils'
import { Spin, Empty } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './list.less'
import ADetail from './Detail'
import {
  getBoardOrderPageList,
  getBoardOrderDetail,
} from '@/redux/actions/order'
import { cloneDeep } from 'lodash'

function AList(props, ref) {
  const history = useHistory()
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [orderList, setOrderList] = useState([])
  const [orderDetail, setOrderDetail] = useState({})
  const [detailLoading, setDetailLoading] = useState(false)
  const [params, setParams] = useState({
    phoneOrPetName: '',
    startTime: '',
    endTime: '',
    pageNo: 1,
    pageSize: 20,
  })
  const handleLoadMore = () => {
    setLoading(true)
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }
  /**
   * 详情中操作成功回调
   */
  const handleSuccess = (type, orderNo) => {
    const list = cloneDeep(orderList)
    const index = list.findIndex((item) => item.orderNo === orderNo)
    if (index === -1) {
      return
    }
    if (type === 'delete') {
      list.splice(index, 1)
    } else if (type === 'cancel') {
      list[index].status = 2700
    } else if (type === 'refund') {
      list[index].status = 2600
    } else if (type === 'start') {
      list[index].status = 2200
    } else if (type === 'end') {
      list[index].status = 2300
    } else if (type === 'pay') {
      list[index].status = 2100
    } else if (type === 'extend') {
      list[index].status = 2200
    }
    setOrderList(dealOrderList(list))
    loadDetailData(orderNo)
  }
  // list
  const loadData = () => {
    if (!hasMore) return

    setLoading(true)
    props
      .getBoardOrderPageList({
        ...params,
      })
      .then((res) => {
        const data = res.data
        setOrderList(orderList.concat(dealOrderList(data.items)))
        const totalPageNo = Math.ceil(data.count / params.pageSize)
        console.log('data', data, params, params.pageNo, totalPageNo)
        setHasMore(params.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const dealOrderList = (list) => {
    return (
      list &&
      list.map((item) => {
        if (item.goRefund && item.refundStatus === 1000) {
          return {
            ...item,
            statusText: '售后处理中',
          }
        }
        if (
          item.status === 2100 &&
          item.refundStatus === 1400 &&
          item.goRefundRecord > 0
        ) {
          return {
            ...item,
            statusText: '待寄养(商家已拒绝)',
          }
        }

        return {
          ...item,
          statusText: getLabelByValueFromEnum(
            item.status,
            BOARD_ORDER_STATUS_MAP
          ),
        }
      })
    )
  }
  // 详情
  const loadDetailData = (orderNo) => {
    setDetailLoading(true)
    props
      .getBoardOrderDetail({
        orderNo,
      })
      .then((res) => {
        setOrderDetail(res.data)
      })
      .finally(() => {
        setDetailLoading(false)
      })
  }
  // search
  const onSearch = (searchParams) => {
    if (searchParams.startTime && typeof searchParams.startTime !== 'string') {
      searchParams.startTime = searchParams.startTime.format(
        'YYYY-MM-DD 00:00:00'
      )
    }
    if (searchParams.endTime && typeof searchParams.endTime !== 'string') {
      searchParams.endTime = searchParams.endTime.format('YYYY-MM-DD 23:59:59')
    }

    setOrderList([])
    setHasMore(true)
    setParams({
      ...searchParams,
      pageSize: 10,
      pageNo: 1,
    })
  }

  useEffect(() => {
    if (history.location.state) {
      setHasMore(true)
      setOrderList([])
      setParams({
        ...params,
        phoneOrPetName: history.location.state.orderNo,
        startTime: '',
        endTime: '',
      })
      history.replace({ ...history.location, state: undefined })
    } else if (
      props.defaultParams.startTime !== '' ||
      props.defaultParams.endTime !== '' ||
      props.defaultParams.phoneOrPetName !== ''
    ) {
      setHasMore(true)
      setOrderList([])
      setParams({
        ...props.defaultParams,
        pageNo: 1,
        pageSize: 10,
      })
      props.handleDefaultParamsNull && props.handleDefaultParamsNull()
    } else {
      console.log('params', params)
      loadData()
    }
  }, [params, props.defaultParams, history.location.state])

  useEffect(() => {
    if (orderList.length) {
      if (selectedIndex > orderList.length - 1) {
        setSelectedIndex(0)
        loadDetailData(orderList[0].orderNo)
      } else {
        loadDetailData(orderList[selectedIndex].orderNo)
      }
    }
  }, [selectedIndex, orderList])

  useImperativeHandle(ref, () => ({
    search: onSearch,
  }))

  return (
    <div className="board-list-mode" ref={ref}>
      <div className="a-list-left">
        <ASearch onSearch={onSearch} {...params} />
        <InfiniteScrollList
          className="order-list"
          handleOnLoad={handleLoadMore}
          hasMore={hasMore}
          loading={loading}
        >
          {orderList.map((item, index) => (
            <div
              className={`item ${selectedIndex === index ? 'selected' : ''}`}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              <div className="item-bottom">
                <p className="title">{item.petName || ''}</p>
                <p className="status">¥{item.payPrice || 0}</p>
              </div>
              <div className="item-bottom">
                <p className="date">{item.nickname || item.phone}</p>
                <p className="price">{item.statusText}</p>
              </div>
              <h2 className="orderno">{item.appointStartTime}</h2>
            </div>
          ))}
        </InfiniteScrollList>
      </div>
      <div className="a-list-right">
        <Spin spinning={detailLoading} delay={300}>
          {orderList.length ? (
            <ADetail orderDetail={orderDetail} handleSuccess={handleSuccess} />
          ) : (
            <div className="no-data-con">
              <Empty
                imageStyle={{
                  height: 120,
                }}
                description={<span>暂无数据</span>}
              ></Empty>
            </div>
          )}
        </Spin>
      </div>
    </div>
  )
}

const ForwardAList = forwardRef(AList)

export default connect(
  null,
  {
    getBoardOrderPageList,
    getBoardOrderDetail,
  },
  null,
  { forwardRef: true }
)(ForwardAList)
