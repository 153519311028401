import React, { useState, useEffect } from 'react'
import { DatePicker, Empty } from 'antd'
import moment from 'moment'
import './DatePicker.less'

export default function MyDatePicker(props) {
  const [selectIndex, setSelectIndex] = useState(0)

  const disabledDate = (current) => {
    if (current && current < moment().subtract(1, 'd').endOf('day')) {
      return true
    }

    const disabledDate = props.disabledBoardDateArray.find(
      (iv) => iv.date === current.format('YYYY-MM-DD')
    )
    if (disabledDate && !disabledDate.appoint) {
      return true
    }
  }

  const handleATime = (item, index) => {
    if (!item.appoint) return
    setSelectIndex(index)
  }

  const renderExtraFooter = () => {
    return (
      <div className="time-day">
        <ul className="t_ul">
          {props.appointmentTime.length > 0 ? (
            props.appointmentTime.map((item, index) => (
              <li
                className={`t_li ${selectIndex === index ? 'active' : ''} ${
                  !item.appoint ? 'man' : ''
                }`}
                v-for="(item, index) in "
                key={index}
                onClick={() => handleATime(item, index)}
              >
                {item.appoint ? (
                  <>
                    <div className="t_t">{item.timeStr}</div>
                    <div className="t_sp">剩余{item.peopleNum}位</div>
                  </>
                ) : (
                  <>
                    <span>{item.timeStr}</span>
                    <div className="man_txt">满</div>
                  </>
                )}
              </li>
            ))
          ) : (
            <Empty
              className="empty"
              description={
                <>
                  <p style={{ color: '#959BA2' }}>暂无可预约项目</p>
                  <p style={{ color: '#959BA2' }}>请预约其他日期</p>
                </>
              }
              imageStyle={{
                marginTop: 0,
                height: 74,
              }}
            />
          )}
          {/* <li className="t_li active" >
            <span>10:00</span>
          </li>
          <li className="t_li man">
            <span>10:00</span>
            <div className="man_txt">满</div>
          </li>
          <li className="t_li">
            <div className="t_t">10:00</div>
            <div className="t_sp">剩余1位</div>
          </li>
          <li className="t_li active">
            <div className="t_t">10:00</div>
            <div className="t_sp">剩余1位</div>
          </li> */}
        </ul>
      </div>
    )
  }

  const handleOnOk = () => {
    const time = props.appointmentTime[selectIndex]
    props.handleOnOk(time)
  }

  useEffect(() => {
    if (props.appointmentTime.length > 0) {
      const index = props.appointmentTime.findIndex((iv) => iv.appoint === true)
      setSelectIndex(index)
    }
  }, [props.appointmentTime])

  return (
    <DatePicker
      bordered={false}
      showNow={false}
      disabledDate={disabledDate}
      dateRender={(current) => {
        const disabledDate = props.disabledBoardDateArray.find(
          (iv) => iv.date === current.format('YYYY-MM-DD')
        )
        const isFull = disabledDate && !disabledDate.appoint
        return (
          <div
            className="ant-picker-cell-inner"
            onClick={() => props.onClick(current)}
          >
            {current.date()}
            {isFull && <span className="full">满</span>}
          </div>
        )
      }}
      renderExtraFooter={renderExtraFooter}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onOpenChange={props.onOpenChange}
      onOk={handleOnOk}
      dropdownClassName="appoint-date-picker"
      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
      inputReadOnly={true}
      allowClear={false}
    />
  )
}

MyDatePicker.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
}
