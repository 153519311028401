import moment from 'moment'
import QrcodeDecoder from 'qrcode-decoder'
import { inApp, inElectron } from './device'

export const fixAmount = (amount) => {
  const res = Math.floor(Number(amount) * 100 + 0.000001) / 100
  if (Number.isNaN(res)) {
    return 0
  }
  return res
}
/**
 * 检验ascii码是否是数字
 * @returns
 */
export function checkIsNumberKey(keycode, isNumber = true) {
  if (keycode === '*') {
    return true
  }
  if (
    (keycode >= 48 && keycode <= 57) ||
    (keycode >= 96 && keycode <= 105) ||
    (keycode >= 65 && keycode <= 90) ||
    keycode === 110 ||
    keycode === 190
  ) {
    if (isNumber && !(keycode >= 65 && keycode <= 90)) {
      return true
    } else if (!isNumber) {
      return true
    }
  }
  return false
}
/**
 * 会员折扣转换
 * @returns
 */
export const discountConvert = (dis) => `${fixAmount(dis * 10)}折`
export function checkNewFeatureVersion(version, remoteVersion) {
  const reg = /(\d+).(\d+).(\d+)/g
  reg.test(version)
  const v1 = Number(RegExp.$1)
  const v2 = Number(RegExp.$2)
  reg.test(remoteVersion)
  reg.test(remoteVersion)
  const rv1 = Number(RegExp.$1)
  const rv2 = Number(RegExp.$2)
  return rv1 > v1 || (rv1 === v1 && rv2 > v2)
}
export function onInputByNumberInput(amount, value, maxValue = 999999) {
  amount = amount + ''
  if (amount === '0' && value !== '.' && value !== '00') {
    return value
  }

  if (
    (value === '.' && amount.indexOf('.') > -1) ||
    (amount.indexOf('.') > -1 && amount.length - amount.indexOf('.') >= 3)
  ) {
    return amount
  }

  let total = amount + '' + value
  if (Number(total) > maxValue) {
    total = maxValue.toString()
  }
  if (!/^[0-9]?[1-9]*\.[0-9]?$/.test(total) && Number(total) === 0) {
    total = '0'
  }

  return total
}

export function base64ToFile(base64, filename) {
  var arr = base64.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  const file = new File([u8arr], filename, { type: mime })
  Object.assign(file, { uid: file.lastModified })
  return file
}

export function onDeleteByNumberInput(amount) {
  amount = amount + ''
  amount = amount.substring(0, amount.length - 1)
  if (amount === '') {
    amount = '0'
  }
  return amount
}

export const getLabelByValueFromEnum = (value, enumData) => {
  const item = enumData.find((item) => item.value === value)
  if (item) {
    return item.label
  }
  return ''
}
// 判断是支付宝支付码还是微信支付码
export const checkPayCode = (code) => {
  const isALIPayCode = /^(?:2[5-9]|30)\d{14,22}$/.test(code)
  const isWXPayCode = /^1[0-5]\d{16}$/.test(code)
  return isALIPayCode ? 'ALI' : isWXPayCode ? 'WX' : ''
}

export const openURL = (url) => {
  if (inApp) {
    api.openApp(
      {
        androidPkg: 'android.intent.action.VIEW',
        uri: url,
      },
      function (ret, err) {
        // 未找到浏览器，使用app内置浏览器打开
        if (err) {
          api.openWin({
            name: 'win_show2',
            url: url,
            rect: {
              x: 0,
              y: 0,
            },
          })
        }
      }
    )
  } else if (inElectron) {
    try {
      window.open(url)
    } catch (error) {
      console.log('error', error)
    }
  } else {
    return window.open(url)
  }
}

export const dateFormat = (date, format = 'YYYY.MM.DD HH:mm') => {
  if (!date) {
    return ''
  }
  return moment(date).format(format)
}

/**
 * 将图片链接转换为base64
 * @param {string} qrUrl 图片链接
 * @returns string base64
 */
export const url2base64 = (qrUrl) => {
  return new Promise((resolve) => {
    const image = new Image()
    image.crossOrigin = ''
    image.width = 329
    image.height = 374
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0)
      const base64 = canvas.toDataURL()
      resolve(base64)
    }
    image.src = qrUrl
  })
}

export const ossProcess = (value, size = 'h_256,w_256') => {
  if (!value) return ''
  // oss取关键帧会添加如下参数
  if (value.indexOf('?x-oss-process=video/snapshot') > -1)
    return value.replace('w_750,h_750', size)
  return `${value}?x-oss-process=image/resize,${size},m_fill`
}

export const qrdecode = (url) => {
  return new Promise((resolve, reject) => {
    const qr = new QrcodeDecoder()
    const img = new Image()
    img.crossOrigin = ''
    img.onload = () => {
      console.log('img onload')
      qr.decodeFromImage(img).then((res) => {
        console.log(res)
        resolve(res.data)
      })
    }
    img.onerror = reject
    img.src = url
  })
}

export const arrayObjectToFormatSampleObject = (arrayObject) => {
  let obj = {}
  for (let k of arrayObject) {
    obj[k.value] = k.label
  }
  return obj
}

export const disabledStartDate = (current, endTime) =>
  endTime ? moment(endTime).isBefore(current) : false

export const disabledEndDate = (current, startTime) =>
  startTime ? moment(startTime).isAfter(current) : false

export const checkScanKey = (scanKey) => {
  if (!scanKey) {
    return null
  }
  if (scanKey.substring(0, 2) === 'HY') {
    return {
      type: 'member',
      value: scanKey.substring(scanKey.length - 11),
    }
  } else if (scanKey.substring(0, 2) === 'FW') {
    return {
      type: 'service',
      value: scanKey.substring(2),
    }
  } else {
    return {
      type: 'search',
      value: scanKey,
    }
  }
}
export const getOrderTypeByOrderNo = (orderNo) => {
  if (!orderNo) {
    return ''
  }
  const oneChar = orderNo.substring(0, 1)
  const twoChar = orderNo.substring(0, 2)
  if (oneChar === 'A') {
    return 'APPOINT'
  } else if (oneChar === 'B') {
    return 'BOARD'
  } else if (oneChar === 'C') {
    return 'DEDUCTION'
  } else if (twoChar === 'RD') {
    return 'REFUND'
  } else if (oneChar === 'G' || oneChar === 'D') {
    return 'GOODS'
  } else if (oneChar === 'T') {
    return 'STORE'
  }
  return ''
}
export const throttle = (fn, wait) => {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(context, args)
        timer = null
      }, wait)
    }
  }
}
