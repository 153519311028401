import React, { useState, useEffect } from 'react'
import {
  Spin,
  Tabs,
  Button,
  Input,
  DatePicker,
  Drawer,
  Image,
  message,
} from 'antd'
import { TimeCardList } from '@/components/Home/TimeCardList'
import TimeCardItem from '@/components/Home/TimeCardItem'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import Refund from './Refund'
import Cash from '@/components/Cash'
import moment from 'moment'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  deleteStoreOrder,
  getDeductionCardList,
  deductionUserCreate,
  deductionRefundBack,
  deductionRecordlist,
  getDeductionUserList,
} from '@/redux/actions/order'
import { timeCardValidity } from '@/utils/enum'
import './index.less'
import { getOrderTypeByOrderNo } from '@/utils/index'
const { TabPane } = Tabs
const { TextArea } = Input

// 扣卡记录 list
const RecordList = (props) => {
  const history = useHistory()
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 20,
    item: {
      userDeductionId: props.deductionId,
      recordType: 0,
    },
  })
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const handleLoadMore = () => {
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }
  const queryList = () => {
    setLoading(true)
    setHasMore(true)
    props
      .deductionRecordlist({
        ...params,
        item: {
          ...params.item,
          startTime: props.startTime
            ? moment(props.startTime).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          endTime: props.endTime
            ? moment(props.endTime).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          userId: props.userId,
        },
      })
      .then((res) => {
        const data = res.data
        const newList = data.items || []
        if (params.pageNo === 1) {
          setList(newList)
        } else {
          setList(list.concat(newList))
        }
          const totalPageNo = Math.ceil(data.count / params.pageSize)
          setHasMore(params.pageNo < totalPageNo)
      })
      .finally((res) => {
        setLoading(false)
        setHasMore(false)
      })
  }

  const handlePage = (iv) => {
    let orderType = getOrderTypeByOrderNo(iv.orderNo)
    history.push({
      pathname: '/cashier',
      state: {
        word: iv.orderNo,
        orderType,
      },
    })
  }

  useEffect(() => {
    queryList()
  }, [params, props.startTime, props.endTime])

  return (
    <InfiniteScrollList
      className="c-r-list"
      hasMore={hasMore}
      handleOnLoad={handleLoadMore}
      loading={loading}
    >
      {list.map((iv, index) => (
        <div className="c-r-item" key={index}>
          <div className="c-r-item-row" onClick={() => handlePage(iv)}>
            <span className="t">交易订单号 {iv.orderNo || '--'}</span>
            <span className="t">
              实付款{' '}
              {iv.payPrice || iv.payPrice === 0 ? `¥${iv.payPrice}` : '--'}
            </span>
          </div>
          <div className="c-r-item-row">
            <span className="r">
              {iv.gmtUpdate
                ? moment(iv.gmtCreate).format('YYYY-MM-DD HH:mm:ss')
                : '--'}
            </span>
            <span className="r">
              抵扣{' '}
              {iv.deductionPrice || iv.deductionPrice === 0
                ? `¥${iv.deductionPrice}`
                : '--'}
            </span>
          </div>
        </div>
      ))}
    </InfiniteScrollList>
  )
}

function TimeCardDetail(props) {
  const [cashVisible, setCashVisible] = useState(false)
  const [createOrderLoading, setCreateOrderLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [params, setParams] = useState(false)
  const [timeCardList, setTimeCardList] = useState([])
  const [cardDetail, setCardDetail] = useState({})
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')

  const [deskList, setDeskList] = useState([])
  const [remark, setRemark] = useState('')
  const [orderNo, setOrderNo] = useState('')

  const [refundVisible, setRefundVisible] = useState(false)
  const [refundSubmitLoading, setRefundSubmitLoading] = useState(false)

  const handleChooseItem = (item, index) => {
    setCurrentIndex(index)
    setCardDetail(timeCardList[index])
  }
  const handleCashClose = () => {
    // props.deleteStoreOrder({ id: orderNo }).then((res) => {
    setCashVisible(false)
    setOrderNo('')
    // })
  }
  const handleCashSubmitClose = () => {
    setCashVisible(false)
    setRemark('')
    message.success('开卡成功')
    setParams({
      pageNo: 1,
      pageSize: 20,
    })
  }
  const handleRefundClose = () => {
    setRefundVisible(false)
    setParams({
      pageNo: 1,
      pageSize: 20,
    })
  }
  // 开通美容卡
  const handleCreateCard = () => {
    if (cardDetail.buyCount === 0)
      return message.warning('美容卡可购买次数已用完')
    const params = {
      remark,
      userId: props.memberDetail && props.memberDetail.id,
      deductionId: cardDetail.id,
    }
    setCreateOrderLoading(true)
    props
      .deductionUserCreate(params)
      .then((res) => {
        if (res.data) {
          setOrderNo(res.data.orderNo)
          setCashVisible(true)
          setDeskList([
            {
              num: 1,
              price: cardDetail.salePrice,
              spuTitle: '',
              title: cardDetail.title,
            },
          ])
        }
      })
      .finally(() => {
        setCreateOrderLoading(false)
      })
  }
  // 退卡
  const handleRefundCard = (params) => {
    setRefundSubmitLoading(true)
    props
      .deductionRefundBack(params)
      .then((res) => {
        if (res.data) {
          handleRefundClose()
        }
      })
      .finally(() => {
        setRefundSubmitLoading(false)
      })
  }

  // 获取未绑定的美容卡
  const getDeductionCardList = (params) => {
    let pms = {
      ...params,
      item: {
        title: params.keyword,
        userId: props.memberDetail && props.memberDetail.id,
      },
    }
    delete pms.keyword
    return props.getDeductionCardList(pms).then((res) => {
      const data = res.data
      const list = data.items || []
      if (pms.pageNo === 1) {
        setTimeCardList(list)
        setCurrentIndex(0)
        setCardDetail(list.length > 0 ? list[0] : {})
      } else {
        setTimeCardList(timeCardList.concat(list))
      }
      const totalPageNo = Math.ceil(data.count / params.pageSize)
      return {
        list,
        pageObj: {
          totalPageNo,
        },
      }
    })
  }

  // 获取已绑定的美容卡
  const getDeductionUserList = (params) => {
    let pms = {
      ...params,
      item: {
        userId: props.memberDetail && props.memberDetail.id,
        id: props.deductionId,
      },
    }
    delete pms.keyword
    return props.getDeductionUserList(pms).then((res) => {
      const data = res.data
      const list = data.items || []
      if (pms.pageNo === 1) {
        setTimeCardList(list)
        setCurrentIndex(0)
        setCardDetail(list.length > 0 ? list[0] : {})
      } else {
        setTimeCardList(timeCardList.concat(list))
      }
      const totalPageNo = Math.ceil(data.count / params.pageSize)
      return {
        list,
        pageObj: {
          totalPageNo
        },
      }
    })
  }

  const getValidType = () => {
    if (cardDetail.validType || cardDetail.validType === 0) {
      if (cardDetail.validType === 0) {
        return timeCardValidity[cardDetail.validType]
      } else {
        if (cardDetail.userId) {
          return `${moment(cardDetail.startTime).format(
            'YYYY.MM.DD'
          )} - ${moment(cardDetail.endTime).format('YYYY.MM.DD')}`
        } else {
          if (cardDetail.validType === 1) {
            return `${moment(cardDetail.startTime).format(
              'YYYY.MM.DD'
            )} - ${moment(cardDetail.endTime).format('YYYY.MM.DD')}`
          } else if (cardDetail.validType === 2) {
            return `${cardDetail.validDay}天`
          }
        }
      }
    }
    return '--'
  }

  const disabledStartDate = (startValue) => {
    if (!startValue || !endTime) {
      return false
    }
    return startValue.valueOf() > endTime.valueOf()
  }
  const disabledEndDate = (endValue) => {
    if (!endValue || !startTime) {
      return false
    }
    return endValue.valueOf() <= startTime.valueOf()
  }

  useEffect(() => {
    setCardDetail({})
  }, [])

  return (
    <div className="c-timeCard-Detail">
      <div className="search-container">
        <TimeCardList
          type={props.type}
          onClick={handleChooseItem}
          currentIndex={currentIndex}
          fetchOption={
            props.type === 'detail'
              ? getDeductionUserList
              : getDeductionCardList
          }
          refundVisible={refundVisible}
          params={params}
        />
      </div>
      <>
        <div className="t-detail-container">
          <div className="banner-caontainer">
            <div className="b-c-timerCard">
              <TimeCardItem {...cardDetail} />
            </div>
          </div>
          <div className="info-container">
            <Tabs
              defaultActiveKey="1"
              tabBarStyle={{
                padding: '0 16px',
                background: '#fff',
                margin: 0,
              }}
            >
              <TabPane tab="会员卡信息" key="1">
                <div className="c-card-detail-container">
                  <Spin spinning={false}>
                    <div className="c-card-detail">
                      <div className="c-d-row start">
                        <span className="c-l">美容卡名称</span>
                        <span className="c-r">{cardDetail.title || '--'}</span>
                      </div>
                      <div className="c-d-row start">
                        <span className="c-l">适用服务</span>
                        <span className="c-r">
                          {cardDetail.serviceName || '--'}
                        </span>
                      </div>
                      <div className="c-d-row">
                        <span className="c-l">有效期设置</span>
                        <span className="c-r">{getValidType()}</span>
                      </div>
                      {props.type === 'base' ? (
                        <div className="c-d-row">
                          <span className="c-l">可购买次数</span>
                          <span className="c-r">
                            {!cardDetail.astrictBuy
                              ? '--'
                              : cardDetail.buyCount || cardDetail.buyCount === 0
                              ? `${cardDetail.buyCount}次`
                              : '--'}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="c-d-row">
                        <span className="c-l">可用次数</span>
                        <span className="c-r">
                          {cardDetail.userId
                            ? cardDetail.residueCount === null
                              ? '--'
                              : `${cardDetail.residueCount}次` || 0
                            : !cardDetail.astrictUse
                            ? '--'
                            : cardDetail.useCount || cardDetail.useCount === 0
                            ? `${cardDetail.useCount}次`
                            : '--'}
                        </span>
                      </div>
                      <div className="c-d-row">
                        <span className="c-l">
                          {props.type === 'base'
                            ? '销售价格'
                            : props.type === 'detail'
                            ? '实付款'
                            : '销售价格'}
                        </span>
                        <span className="c-r">
                          {props.type === 'base'
                            ? cardDetail.salePrice || cardDetail.salePrice === 0
                              ? `¥${cardDetail.salePrice}`
                              : '--'
                            : props.type === 'detail'
                            ? cardDetail.payPrice || cardDetail.payPrice === 0
                              ? `¥${cardDetail.payPrice}`
                              : '--'
                            : '--'}
                        </span>
                      </div>
                      <div className="c-d-row start">
                        <span className="c-l">美容卡备注</span>
                        <span className="c-r ">
                          {cardDetail.userId
                            ? cardDetail.deductionRemark || '--'
                            : cardDetail.remark || '--'}
                        </span>
                      </div>
                      <div className="bd"></div>
                      {props.type === 'detail' ? (
                        <div className="c-d-row start">
                          <span className="c-l">开卡备注</span>
                          <span className="c-r ">
                            {cardDetail.remark || '--'}
                          </span>
                        </div>
                      ) : (
                        <div className="c-d-row textarea">
                          <span className="c-l">开卡备注</span>
                          <span className="c-r">
                            <TextArea
                              rows={3}
                              placeholder="请输入开卡备注"
                              maxLength={50}
                              value={remark}
                              disabled={cardDetail.status === 1}
                              onChange={(e) => {
                                const val = e.target.value
                                setRemark(val)
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                    {props.type === 'detail' && cardDetail.backTime ? (
                      <div
                        className="c-card-detail dt"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <div className="c-d-row">
                          <span className="c-l">退款金额</span>
                          <span className="c-r">
                            {cardDetail.backPrice || cardDetail.backPrice === 0
                              ? `¥${cardDetail.backPrice}`
                              : '--'}
                          </span>
                        </div>
                        <div className="c-d-row">
                          <span className="c-l">服务人员</span>
                          <span className="c-r">
                            {cardDetail.backAdminName || '--'}
                          </span>
                        </div>
                        <div className="c-d-row">
                          <span className="c-l">退款时间</span>
                          <span className="c-r">
                            {cardDetail.backTime || '--'}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {props.type === 'detail' && !cardDetail.backTime ? (
                      <Button
                        danger
                        className="c-btn danger"
                        onClick={() => setRefundVisible(true)}
                      >
                        退卡
                      </Button>
                    ) : props.type === 'base' && cardDetail.status === 0 ? (
                      <Button
                        type="primary"
                        className="c-btn"
                        onClick={handleCreateCard}
                        loading={createOrderLoading}
                      >
                        去开卡
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Spin>
                </div>
              </TabPane>
              {props.type === 'detail' ? (
                <TabPane tab="扣卡记录" key="2">
                  <div className="card-record-container">
                    <div className="c-r-search">
                      <DatePicker
                        placeholder="请选择起始时间"
                        style={{ width: '200px', marginBottom: 10 }}
                        disabledDate={disabledStartDate}
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        }}
                        vlaue={startTime}
                        onChange={(val) => {
                          setStartTime(val)
                        }}
                      />
                      <span style={{ margin: '0 10px' }}>至</span>
                      <DatePicker
                        style={{ width: '200px' }}
                        placeholder="请选择结束时间"
                        disabledDate={disabledEndDate}
                        showTime={{
                          defaultValue: moment('23:59:59', 'HH:mm:ss'),
                        }}
                        vlaue={endTime}
                        onChange={(val) => {
                          setEndTime(val)
                        }}
                      />
                      {/* <Button type="primary">搜索</Button> */}
                    </div>
                    <div className="c-r-list-wrapper">
                      <RecordList
                        {...props}
                        deductionId={cardDetail.id}
                        userId={cardDetail.userId}
                        startTime={startTime}
                        endTime={endTime}
                      />
                    </div>
                  </div>
                </TabPane>
              ) : (
                <></>
              )}
            </Tabs>
          </div>
        </div>
      </>

      <Refund
        visible={refundVisible}
        onOk={handleRefundCard}
        onCancel={handleRefundClose}
        loading={refundSubmitLoading}
        orderDetail={cardDetail}
      />
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={handleCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={props.memberDetail && props.memberDetail.id}
          orderNo={orderNo}
          orderType="DEDUCTION"
          deskList={deskList}
          onSuccess={handleCashSubmitClose}
        />
      </Drawer>
    </div>
  )
}

export default connect(
  (state) => ({
    memberDetail: state.home.memberDetail,
  }),
  {
    deleteStoreOrder,
    getDeductionCardList,
    deductionUserCreate,
    deductionRefundBack,
    deductionRecordlist,
    getDeductionUserList,
  }
)(TimeCardDetail)
