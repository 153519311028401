import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import './List.less'
import { Tabs, Badge, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  getNotifyList,
  getNotifyNum,
  readBatch,
  readAll,
} from '@/redux/actions/user'
import { setParams } from '@/redux/actions/appointment';

import InfiniteScrollList from '@/components/InfiniteScrollList'
import Icon from '@/components/Icon'
import { dateFormat } from '@/utils'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

// 这两个类型可跳转详情
const isLinkArray = ['NEW_MP_APPOINT_ORDER', 'NEW_MP_BOARD_ORDER', 'MP_APPOINT_WILL_START']
const linkUrlArray = {
  NEW_MP_APPOINT_ORDER: '/appointment',
  MP_APPOINT_WILL_START: '/appointment',
  NEW_MP_BOARD_ORDER: '/board',
}

function List(props, ref) {
  const [tabs, setTabs] = useState([
    { title: '全部消息', count: '--', isRead: undefined, key: '0' },
    { title: '未读消息', count: '--', isRead: false, key: '1' },
    { title: '已读消息', count: '--', isRead: true, key: '2' },
  ])
  const history = useHistory()
  const [hasMore, setHasMore] = useState(true)
  const [accessKey, setAccessKey] = useState('1')
  const [loading, setLoading] = useState(false)
  const [readLoading, setReadLoading] = useState(false)
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    isRead: false,
  })
  const [list, setList] = useState([])
  const handleLoadMore = () => {
    setLoading(true)
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }
  const getList = () => {
    setLoading(true)
    props
      .getNotifyList({
        ...params
      })
      .then((res) => {
        const data = res.data
        setList(
          list.concat(
            data.items.map((item) => ({
              ...item,
              link: isLinkArray.indexOf(item.messageType) > -1, // 可跳转
              birthday: item.messageType === 'PET_BIRTH_SMS_NOTIFY',
              content: item.content.replace(
                /\$\{([^}]+)\}/g,
                '<span class="primary-color">$1</span>'
              ),
              gmtCreate: dateFormat(item.gmtCreate),
            }))
          )
        )
        setHasMore(Math.ceil(data.count / params.pageSize) > params.pageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const getNotifyNum = () => {
    props.getNotifyNum().then((res) => {
      const data = res.data
      props.handleReadBatch && props.handleReadBatch(data.unReadNum)
      setTabs([
        { title: '全部消息', count: data.allNum, isRead: undefined },
        { title: '未读消息', count: data.unReadNum, isRead: false },
        { title: '已读消息', count: data.readNum, isRead: true },
      ])
    })
  }

  const handleDetail = (item, index) => {
    // 未读设置为已读
    if (!item.isRead) {
      props
        .readBatch({
          ids: [item.id],
        })
        .then(() => {
          const temp = cloneDeep(list)
          // 如果在未读消息tab，就删除
          if (accessKey === '1') {
            temp.splice(index, 1)
          } else {
            temp[index].isRead = true
          }
          setList([...temp])
        })
    }
    // 不需要跳转详情
    if (!item.link) return

    const path = linkUrlArray[item.messageType]
    let navigateTo = history.push
    if (history.location.pathname === path) {
      navigateTo = history.replace
    }
    console.log('path', path)
    if (path === '/appointment') {
      console.log('item', item)
      props.setParams({
        keyWord: item.orderNo,
        orderSource: item.orderSource || 'MP'
      })
    }
    navigateTo({
      pathname: path,
      state: {
        orderNo: item.orderNo,
        orderSource: item.orderSource,
        source: 'message',
      },
    })

    props.handleLinkClick && props.handleLinkClick()
  }
  const onTabClick = (activeKey) => {
    setAccessKey(activeKey + '')
    const isRead = tabs[activeKey].isRead
    setList([])
    setParams({
      isRead,
      pageSize: 10,
      pageNo: 1,
    })
  }

  const handleReadAll = () => {
    setReadLoading(true)
    props
      .readAll()
      .then(() => {
        if (params.isRead === false) {
          setList([])
          const temp = cloneDeep(tabs)
          temp[1].count = 0
          setTabs([...temp])
          // 如果在未读消息tab，就删除数据
          if (accessKey === '1') {
            setList([])
          } else {
            const tempList = list.map(item => ({ ...item, isRead: true }))
            setList(tempList)
          }
        }
        message.success('已设置全部已读')
      })
      .finally(() => {
        setReadLoading(false)
      })
  }

  const getMessageIcon = React.useCallback((item) => {
    if (item.birthday) {
      return 'iconshengritixing'
    }
    if (item.link) {
      return 'icondingdantongzhi'
    }
    return 'icontixingfuwu'
  }, [])

  useEffect(() => {
    getNotifyNum()
  }, [list])

  useEffect(() => {
    getList()
  }, [params])

  useImperativeHandle(ref, () => ({
    getList,
    getNotifyNum,
    onTabClick
  }))

  return (
    <div className="messager-list">
      <Tabs
        activeKey={accessKey}
        tabBarExtraContent={{
          right: (
            <Button
              type="primary"
              loading={readLoading}
              onClick={handleReadAll}
            >
              全部已读
            </Button>
          ),
        }}
      >
        {tabs.map((item, index) => (
          <Tabs.TabPane
            key={index + ''}
            tab={
              index === 1 ? (
                <Badge
                  style={{ backgroundColor: '#FFA725' }}
                  count={item.count}
                >
                  <span onClick={() => onTabClick(index)} className="tab-item">{item.title}</span>
                </Badge>
              ) : (
                <span onClick={() => onTabClick(index)} className="tab-item">{item.title}</span>
              )
            }
          >
            <InfiniteScrollList
              className="order-list"
              handleOnLoad={handleLoadMore}
              hasMore={hasMore}
              loading={loading}
            >
              {list.map((item, index) => (
                <div
                  className={`message-item ${!item.isRead && 'off'} ${
                    item.link && 'link'
                  }`}
                  key={index}
                  onClick={() => handleDetail(item, index)}
                >
                  <Icon
                    className="icon"
                    type={getMessageIcon(item)}
                  />
                  <div className="content">
                    <h3 dangerouslySetInnerHTML={{ __html: item.content }}></h3>
                    <div className="bottom">
                      <span>{item.messageTypeDescription}</span>
                      <span>{item.gmtCreate}</span>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScrollList>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}

const ForwardList = forwardRef(List)

export default connect(null, {
  getNotifyList,
  getNotifyNum,
  readBatch,
  setParams,
  readAll,
}, null, { forwardRef: true})(ForwardList)
