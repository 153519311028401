import React, { useMemo } from 'react'
import Icon from '@/components/Icon'
import { Button, Space, Row, Col, Image, Table } from 'antd'
import './detail.less'
import defaultLogo from '@/assets/menu/defaultLogo.png'
import defaultPeople from '@/assets/member/people.png'
import {
  EMUN_APPOINT_STATUS,
  ORDER_SOURCE_MAP,
  PET_NEUTER_STATUS_MAP,
  PET_GENDER_MAP,
} from '@/utils/enum'
import { arrayObjectToFormatSampleObject, dateFormat } from '@/utils'
import Refund from '../Refund'

const order_source_map = arrayObjectToFormatSampleObject(ORDER_SOURCE_MAP)
const pet_neuter_status_map = arrayObjectToFormatSampleObject(
  PET_NEUTER_STATUS_MAP
)
const { Column } = Table
const pet_gender_map = arrayObjectToFormatSampleObject(PET_GENDER_MAP)
export default function ADetail(props) {
  const { orderDetail = {} } = props
  const columns = [
    {
      title: '服务类目',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: '服务项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '美容师',
      dataIndex: 'workerName',
      key: 'workerName',
    },
    {
      title: '服务费用',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 200,
      render: (text) => <div className="price">￥{text}</div>,
    },
    {
      title: '服务店员',
      dataIndex: 'bonusAdminList',
      key: 'bonusAdminList',
      width: 200,
      render: (array) => (
        <div className="admin-list">
          {array && array.map((item) => item.username).join('、')}
        </div>
      ),
    },
  ]
  const renderBtnCRS = () => {
    let html = []
    const status = orderDetail.status
    if (orderDetail.goRefund) {
      html.push(
        <Button type="primary" onClick={props.handleDealOrder}>
          去处理
        </Button>
      )
    }
    if (status === 100) {
      html.push(
        <>
          <Button onClick={props.onCancelAppoint}>取消预约</Button>
          <Button type="primary" onClick={props.onSettl}>
            去结算
          </Button>
        </>
      )
    } else if (status === 200) {
      html.push(
        <Button type="primary" onClick={props.onWriteOff}>
          核销
        </Button>
      )
    } else if (status === 200 && !orderDetail.goRefund) {
      html.push(
        <Button
          type="primary"
          className="warning"
          onClick={props.onRefund}
          ghost
        >
          退款
        </Button>
      )
    } else if (status >= 700) {
      html.push(
        <Button
          type="primary"
          className="warning"
          ghost
          onClick={props.onAppointOrderDelete}
        >
          删除
        </Button>
      )
    }
    return html
  }
  const renderBtnMP = () => {
    let html = []
    const status = orderDetail.status
    if (orderDetail.goRefund) {
      html.push(
        <Button type="primary" onClick={props.handleDealOrder}>
          去处理
        </Button>
      )
    }
    if (status === 150) {
      html.push(
        <Button type="primary" onClick={props.onSettl}>
          去结算
        </Button>
      )
    } else if (status === 200) {
      html.push(
        <Button type="primary" onClick={props.onWriteOff}>
          核销
        </Button>
      )
    } else if (status >= 700) {
      html.push(
        <Button
          type="primary"
          className="warning"
          ghost
          onClick={props.onAppointOrderDelete}
        >
          删除
        </Button>
      )
    }
    return html
  }
  const isPickUp = useMemo(() => {
    return orderDetail.serviceType === '2'
  }, [orderDetail])
  return (
    <div className={`appoint-detail ${props.className}`}>
      {/* 订单信息 */}
      <div className="order-info">
        <h2 className="order-title">
          <span className="orderNo">订单编号&nbsp;{orderDetail.orderNo}</span>
          <span className="orderTime">
            {orderDetail.gmtPay && <Icon type="iconshijian" className="icon" />}
            &nbsp;{orderDetail.gmtPay}
          </span>
        </h2>
        {/* 状态 */}
        <div className="o-info">
          <div
            className={`o-i-status ${
              orderDetail.goRefund ? `s-600` : `s-${orderDetail.status}`
            }`}
          >
            <Icon className="s-icon" type="icondaifukuan"></Icon>
            {orderDetail.goRefund
              ? EMUN_APPOINT_STATUS()[600]
              : EMUN_APPOINT_STATUS()[orderDetail.status]}
          </div>
          <div className="o-info-box">
            <Row className="row-1" gutter={20}>
              <Col span={12} className="col-1">
                <span className="t">订单状态</span>
                {orderDetail.goRefund ? (
                  <span className="i">{EMUN_APPOINT_STATUS(2)[600]}</span>
                ) : (
                  <span className="i">
                    {EMUN_APPOINT_STATUS(2)[orderDetail.status]}
                  </span>
                )}
              </Col>
              <Col span={12} className="col-1">
                <span className="t">支付方式</span>
                <span className="i">
                  {orderDetail.payChannelDescription || '--'}
                </span>
              </Col>
              <Col span={12} className="col-1">
                <span className="t">成交时间</span>
                <span className="i">
                  {orderDetail.gmtPay || '--'}
                </span>
              </Col>
              <Col span={12} className="col-1">
                <span className="t">核销时间</span>
                <span className="i">
                  {orderDetail.gmtConfirm || '--'}
                </span>
              </Col>
              <Col span={12} className="col-1">
                <span className="t">订单来源</span>
                <span className="i">
                  {order_source_map[orderDetail.orderSource]}
                </span>
              </Col>
              <Col span={24} className="col-1">
                <span className="t">
                  备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注
                </span>
                <span className="i">{orderDetail.settleRemark || '无'}</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* 预约信息 */}
      <div className="appoint-info">
        <h2 className="a-i-title">预约人信息</h2>
        <div className="a-i-box">
          <div className="user-info">
            <div className="user">
              <Image
                className="ava"
                src={orderDetail.avatarUrl || defaultPeople}
                preview={false}
                fallback={defaultPeople}
              />
              <div className="u-info">
                <div className="u-i-1 flex1">
                  <span className="u-i-t" title={orderDetail.nickname}>
                    {orderDetail.nickname || '--'}
                  </span>
                  <span className={orderDetail.userLevelName ? `u-i-v` : ''}>
                    {orderDetail.userLevelName}
                  </span>
                </div>
                <div className="flex u-i-1">
                  <span className="u-i-p">{orderDetail.phone || '--'}</span>
                </div>
              </div>
            </div>
            <div className="title">用户信息</div>
          </div>
        </div>
      </div>
      {/* 订单详情 */}
      <div className="order-detail">
        <div className="a-i-title">预约信息</div>
        <div className="o-d-box">
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">预约时间</span>

              <span className="i">
                {dateFormat(orderDetail.appointStartTime)}
              </span>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={orderDetail.appointDetailSkuV2VOList}
            rowKey="id"
            pagination={false}
          >
          </Table>
          <Row className="row-1" style={{ marginTop: '10px' }}>
            <Col span={18} className="col-1">
              <span className="t">备注</span>
              <span className="i">{orderDetail.mono || '无'}</span>
            </Col>
          </Row>
        </div>
      </div>
      {/* 支付信息 */}
      <div className="pay-info-plac"></div>
      <div className="pay-info">
        <div className="l">
          <div className="item">
            <span>
            服务总价 <span> </span>¥{orderDetail.skuTotalPrice || 0}
            </span>
          </div>
          <div className="item">
            <span>
              积分抵扣 <span> </span>-¥{orderDetail.commissionDeduct || 0}
            </span>
          </div>
          <div className="item">
            <span>
              美容卡抵扣 <span> </span>-¥{orderDetail.deductionPrice || 0}
            </span>
          </div>
          <div className="item">
            <span>
              会员优惠 <span> </span>-¥{orderDetail.discountPrice || 0}
            </span>
          </div>
          <div className="item">
            <span>
              商家优惠 <span> </span>-¥{orderDetail.businessDiscount || 0}
            </span>
          </div>
          <div className="item">
            <span>
              优惠券 <span> </span>-¥{orderDetail.couponPrice || 0}
            </span>
          </div>
        </div>
        <div className="r">
          {orderDetail.status >= 200 ? (
            <span>
              实付款 <span> </span>¥{orderDetail.payPrice || 0}
            </span>
          ) : orderDetail.status === 100 || orderDetail.status === 150 ? (
            <span>
              待付款 <span> </span>¥{orderDetail.waitPayPrice || 0}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* 操作 */}
      <div className="pay-action">
        <span></span>
        <Space>
          {props.closeModal && <Button onClick={props.closeModal}>关闭</Button>}
          {orderDetail.orderSource === 'CRS' ? renderBtnCRS() : renderBtnMP()}
        </Space>
      </div>
      <Refund
        visible={props.refundVisible}
        onOk={props.onRefundOk}
        onCancel={props.onRefundCancel}
        orderDetail={props.orderDetail}
      />
    </div>
  )
}

ADetail.defaultProps = {
  closeModal: null,
  onRefundOk: null,
  onRefundCancel: null,
  refundOrderDetail: {},
  refundVisible: false,
}
