import React from 'react'
import './index.less'
import Icon from '@/components/Icon'
import { timeCardStaus, timeCardValidity } from '@/utils/enum'
import moment from 'moment'
function TimeCardItem(props) {
  const getStatus = () => {
    if (props.status || props.status === 0) {
      if (props.userId) {
        return timeCardStaus[props.status]
      } else {
        return props.status === 0 ? '生效中' : '已失效'
      }
    }
    return ''
  }
  const getValidType = () => {
    if (props.validType || props.validType === 0) {
      if (props.validType === 0) {
        return timeCardValidity[props.validType]
      } else {
        if (props.userId) {
          const sTime = props.startTime
            ? moment(props.startTime).format('YYYY.MM.DD')
            : ''
          const eTime = props.endTime
            ? moment(props.endTime).format('YYYY.MM.DD')
            : ''
          if (sTime && eTime) return `${sTime} - ${eTime}`
        } else {
          if (props.validType === 1) {
            const sTime = props.startTime
              ? moment(props.startTime).format('YYYY.MM.DD')
              : ''
            const eTime = props.endTime
              ? moment(props.endTime).format('YYYY.MM.DD')
              : ''
            if (sTime && eTime) return `${sTime} - ${eTime}`
          } else if (props.validType === 2) {
            return `${props.validDay}天`
          }
        }
      }
    }
    return ''
  }
  return (
    <div
      className={`timecard-item ${props.className} ${
        props.userId
          ? props.status === 2 || props.status === 3
            ? 'failure'
            : ''
          : props.status === 1
          ? 'failure'
          : ''
      }`}
      style={{
        backgroundImage: `url(${props.backgroundImg})`,
      }}
      onClick={() => props.onClick && props.onClick(props)}
    >
      {props.id ? (
        <>
          <div className="card-title">
            <Icon className="icon" type="iconlogo1" />
            <span>{props.title || ''}</span>
          </div>
          <div className="subTitle">{props.serviceName || ''}</div>
          <div className="info-row">
            <div className="info-col fz">
              {props.userId ? (
                <span className="time">
                  总次数{' '}
                  {props.totalCount === null ? '不限' : props.totalCount || 0}
                </span>
              ) : (
                <span className=""></span>
              )}
              <span>{getStatus()}</span>
            </div>
            <div className="info-col fz">
              {props.userId ? (
                <span className="time">
                  剩余次数{' '}
                  {props.residueCount === null
                    ? '不限'
                    : props.residueCount || 0}
                </span>
              ) : (
                <span className="time">
                  总次数{' '}
                  {props.astrictUse === false ? '不限' : props.useCount || 0}
                </span>
              )}
              <span>{getValidType()}</span>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
TimeCardItem.defaultProps = {
  className: '',
}

export default TimeCardItem
