import React, { useState, useEffect } from 'react'
import ASearch from './Search'
import InfiniteScrollList from '../../InfiniteScrollList'
import Cash from '@/components/Cash'
import { Spin, Modal, Drawer, message, Empty } from 'antd'
import { connect } from 'react-redux'
import './list.less'
import ADetail from './Detail'
import { withRouter } from 'react-router-dom'
import {
  getAppointOrderPageListNew,
  getAppointOrderDetail,
  updateBonusAdmin,
  appointOrderCancel,
  appointOrderConfirm,
  appointRefundAccord,
  appointOrderDelete,
} from '@/redux/actions/order'
import { setParams } from '@/redux/actions/appointment';
import { EMUN_APPOINT_STATUS } from '@/utils/enum'

function AList(props) {
  // list
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [orderList, setOrderList] = useState([])
  //
  const [adminAll, setAdminAll] = useState([])
  const [orderDetail, setOrderDetail] = useState({})
  const [detailLoading, setDetailLoading] = useState(false)
  // 结算
  const [skuId, setSkuId] = useState('')
  const [userId, setUserId] = useState('')
  const [orderNo, setOrderNo] = useState(null)
  const [cashVisible, setCashVisible] = useState(false)
  // 退款
  const [refundVisible, setRefundVisible] = useState(false)
  //
  const [pagin, setPagin] = useState({
    pageNo: 1,
    pageSize: 10
  })
  const handleLoadMore = () => {
    if (!hasMore) return

    setPagin({
      ...pagin,
      pageNo: pagin.pageNo + 1
    })
  }
  // list
  const loadData = () => {
    if (loading) return

    setLoading(true)
    props
      .getAppointOrderPageListNew({
        ...props.params,
        appointStartTime: props.params.appointStartTime && props.params.appointStartTime.format('YYYY-MM-DD 00:00:00'),
        appointEntTime: props.params.appointEntTime && props.params.appointEntTime.format('YYYY-MM-DD 23:59:59'),
        ...pagin
      })
      .then((res) => {
        const data = res.data || []
        if (data.pageNo === 1) {
          setOrderList(data.items)
        } else {
          setOrderList(orderList.concat(data.items))
        }
        const totalPageNo = Math.ceil(data.count / pagin.pageSize)
        setHasMore(pagin.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  // 详情
  const loadDetailData = (orderNo) => {
    setDetailLoading(true)
    props
      .getAppointOrderDetail({
        orderNo,
      })
      .then((res) => {
        let data = res.data
        data.bonusAdminList = data.bonusAdminList ? data.bonusAdminList.map((iv) => {
          return iv.adminId
        }) : []
        setOrderDetail(data)
      })
      .finally(() => {
        setDetailLoading(false)
      })
  }
  // search
  const onSearch = (searchParams) => {
    setSelectedIndex(0)
    props.setParams({
      ...props.params,
      ...searchParams
    })
    initParams()
  }
  //
  const initParams = () => {
    setOrderList([])
    setHasMore(true)
    setLoading(false)
    setPagin({
      pageNo: 1,
      pageSize: 10
    })
  }
  // 服务店员
  const handleChangeBonus = (val) => {
    const adminDTOList = val.map((iv) => {
      return {
        adminId: iv,
      }
    })
    const params = {
      adminDTOList,
      orderTypeEnum: orderDetail.orderType,
      orderNo: orderDetail.orderNo,
    }
    props.updateBonusAdmin(params).then(() => {
      loadDetailData(params.orderNo)
    })
  }
  // 取消预约
  const onCancelAppoint = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '确认取消该预约订单吗？',
      confirmLoading: true,
      onOk() {
        props
          .appointOrderCancel({
            id: orderDetail.id,
          })
          .then(async () => {
            initParams()
            message.success('取消成功')
          })
      },
      onCancel() {},
    })
  }
  // 核销
  const onWriteOff = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '已到店服务，确认核销预约服务。',
      onOk() {
        props
          .appointOrderConfirm({ barCode: orderDetail.barCode })
          .then((res) => {
            initParams()
            message.success('核销成功')
          })
      },
      onCancel() {},
    })
  }
  // 退款
  const onRefund = () => {
    setRefundVisible(true)
  }
  const onRefundCancel = () => {
    setRefundVisible(false)
  }
  const onRefundOk = (val) => {
    props.appointRefundAccord(val).then(() => {
      message.success('退款成功')
      initParams()
      onRefundCancel()
    })
  }
  //

  // 结算
  const onSettl = () => {
    setSkuId(orderDetail.skuId)
    setUserId(orderDetail.userId)
    setOrderNo(orderDetail.orderNo)
    setCashVisible(true)
  }
  // 结算成功
  const handleCashSuccess = () => {
    setSelectedIndex(0)
    initParams()
    setCashVisible(false)
  }

  const onCashClose = () => {
    setSelectedIndex(0)
    initParams()
    setCashVisible(false)
  }
  // 删除订单
  const onAppointOrderDelete = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '确认删除该预约订单吗？',
      onOk() {
        props
          .appointOrderDelete({
            orderNo: orderDetail.orderNo,
          })
          .then(() => {
            message.success('删除成功')
            setSelectedIndex(0)
            initParams()
          })
      },
      onCancel() {},
    })
  }
  // 去处理
  const handleDealOrder = () => {
    // orderPhone=13525791048&orderNo=A101202103041921231012&orderType=APPOINT&orderSource=CRS
    const { orderType, phone, orderNo, refundOrderNo } = orderDetail
    props.history.push({
      pathname: '/refund',
      state: {
        orderPhone: phone,
        orderNo,
        orderType,
        refundOrderNo,
      },
    })
  }

  useEffect(() => {
    console.log('props.params', props.params)
    loadData()
  }, [pagin, props.params])

  useEffect(() => {
    if (selectedIndex > -1 && Array.isArray(orderList) && orderList.length && selectedIndex < orderList.length) {
      loadDetailData(orderList[selectedIndex].orderNo)
    }
  }, [selectedIndex, orderList])

  useEffect(() => {
    if (props.isRefresh) {
      initParams()
      props.setIsRefresh(false)
    }
  }, [props.isRefresh])

  return (
    <div className="appoint-list-mode">
      <div className="a-list-left">
        <ASearch onSearch={onSearch} />
        <InfiniteScrollList
          className="order-list"
          handleOnLoad={handleLoadMore}
          hasMore={hasMore}
          loading={loading}
        >
          {orderList.map((item, index) => (
            <div
              className={`item ${selectedIndex === index ? 'selected' : ''}`}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              <div className="item-bottom">
                <p className="title" title={item.nickname}>
                  {item.nickname || '--'}
                </p>
                {item.goRefund ? (
                  <p className="status">{EMUN_APPOINT_STATUS()[600]}</p>
                ) : (
                  <p className="status">{EMUN_APPOINT_STATUS()[item.status]}</p>
                )}
              </div>
              <div className="item-bottom">
                <p className="date">{item.petName || '--'}</p>
                <p className="price">¥{item.payPrice || 0}</p>
              </div>
              <h2 className="orderno">{item.appointStartTime}</h2>
            </div>
          ))}
        </InfiniteScrollList>
      </div>
      <div className="a-list-right">
        <Spin spinning={detailLoading} delay={300}>
          {orderList.length ? (
            <ADetail
              orderDetail={orderDetail}
              handleChangeBonus={handleChangeBonus}
              adminAll={adminAll}
              onCancelAppoint={onCancelAppoint}
              onSettl={onSettl}
              onRefund={onRefund}
              onWriteOff={onWriteOff}
              refundVisible={refundVisible}
              onAppointOrderDelete={onAppointOrderDelete}
              onRefundCancel={onRefundCancel}
              onRefundOk={onRefundOk}
              handleDealOrder={handleDealOrder}
            />
          ) : (
            <div className="no-data-con">
              <Empty
                imageStyle={{
                  height: 120,
                }}
                description={<span>暂无数据</span>}
              ></Empty>
            </div>
          )}
        </Spin>
      </div>
      {/* 结算 */}
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={onCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={userId}
          skuId={skuId}
          orderNo={orderNo}
          orderType="APPOINT"
          onSuccess={handleCashSuccess}
        />
      </Drawer>
    </div>
  )
}

export default connect((state) => ({ params: state.appointment.params }), {
  getAppointOrderPageListNew,
  getAppointOrderDetail,
  updateBonusAdmin,
  appointOrderCancel,
  appointOrderConfirm,
  appointRefundAccord,
  appointOrderDelete,
  setParams
})(withRouter(AList))
