import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './index.less'
import moment from 'moment'
import { Input, Form, DatePicker } from 'antd'
function Serve(props) {
  const [form] = Form.useForm()
  useEffect(() => {
    props.onFormChange && props.onFormChange(form)
  }, [form, props.onFormChange])
  const disabledDate = (current) => {
    return current < moment().startOf('hours')
  }
  return (
    <div className="setForm form-line">
      <Form form={form} className="form">
        <Form.Item label="姓名" name="name" rules={[{ message: '请输入姓名' }]}>
          <Input placeholder="请输入姓名" maxLength={8} />
        </Form.Item>
        <Form.Item
          label="电话"
          name="phone"
          rules={[
            {
              pattern: /^1\d{10}$/,
              message: '请输入格式正确的手机号',
            },
          ]}
        >
          <Input placeholder="请输入电话" type="tel" maxLength={11} />
        </Form.Item>
        <Form.Item
          label="宠物名称"
          name="petName"
          rules={[
            {
              message: '请输入宠物名称',
            },
          ]}
        >
          <Input placeholder="请输入宠物名称" maxLength={8} />
        </Form.Item>
        <Form.Item
          label="服务方式"
          name="serviceName"
          rules={[
            {
              message: '请输入服务方式',
            },
          ]}
        >
          <Input placeholder="请输入服务方式" maxLength={8} />
        </Form.Item>
        <Form.Item label="时间" name="backlogTime">
          <DatePicker
            showTime
            disabledDate={disabledDate}
            placeholder="请选择时间"
            format="YYYY-MM-DD HH:mm"
            dropdownClassName="todo-dropdown"
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
          rules={[
            {
              message: '请输入备注',
            },
          ]}
        >
          <Input placeholder="请输入备注" maxLength={24} />
        </Form.Item>
      </Form>
    </div>
  )
}

export default connect(null, {})(Serve)
