import React from 'react'
import aboutPic from '@/assets/setting/about.jpg'
import { version } from '@/config'
import './index.less'
export default function Help() {
  return (
    <div className="setting-about">
      <div className="row">
        <span className="title">关于青松管</span>
      </div>
      <div className="row row-padding">
        <div className="box">
          <img src={aboutPic} alt="" />
          <p>
          青松管是杭州宛新品牌管理有限公司下面专注于大健康美容行业，致力于打造行业优质的门店管理系统，广泛用于美容会所、美容院、美容门店等场合，一般就有会员管理、套餐管理、员工管理、会员积分管理、营销活动、事件提醒、会员卡设置等功能，方便的票据打印，简洁实用的库存管理，带有客户回访功能，能计算员工每月的提成金额，详细完备的统计报表，计查询功能，每天的收款情况，客流量都可以清楚的统计出来，提供多种提醒功能，如每周提醒，每月提醒、一次性提醒、生日提醒等，让员工操作实用，让客户使用方便，让老板管理靠谱。
          </p>
        </div>
      </div>
      {/* <div className="row">
        <div className="label">公司官网</div>
        <div className="value">
          了解更多请访问：
          <a href="https://www.zhuayinline.com" target="blank">
            https://www.zhuayinline.com
          </a>
        </div>
      </div> */}
    </div>
  )
}
