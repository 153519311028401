import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { scriptUrl } from '../../config'
const IconFont = createFromIconfontCN({
  scriptUrl,
});
function Icon(props) {
  return (
    <IconFont {...props} />
  )
}

export default Icon
