let env = 'prod'
try {
  env = process.env.REACT_APP_ENV
} catch (error) {
  
}
console.log('env', env)
const domain = {
  dev: '/localApi',
  test: '',
  preprod: 'api-store.xueyu-biotech.com',
  prod: '',
};
const prmDomain = {
  dev: 'http://localhost:8080',
  test: '/prm',
  preprod: 'https://zhuayin-admin.moxuy.com',
  prod: '/prm',
}
const host = domain[env];
const httpProtocol = '';
const wxProtocol = httpProtocol.replace('http', 'ws');
export const BASE_URL = `${httpProtocol}${host}/api`;
export const BASE_WS = `${wxProtocol}${host}/api/user-center/websocket`;
export const BASE_PRM_URL = prmDomain[env]
export const BASE_UPLOAD_URL = `${httpProtocol}${host}/api/oss-center/upload/singleUpload`
