import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Input, InputNumber, Form, Select, Button, Drawer, Checkbox, message } from 'antd'
import {
  calcBoardOrderAmount,
  takeBoardOrder,
  deleteBoardOrder,
} from '@/redux/actions/order'
import { getUserPetInfo, configAppoint } from '@/redux/actions/user'
import {
  getBoardType,
  getBoardProject,
  getBoardPayConfigInfo,
} from '@/redux/actions/goods'
import {
  PET_GENDER_MAP,
  PET_BODY_TYPE_MAP,
  PET_NEUTER_STATUS_MAP,
} from '@/utils/enum'
import DebounceSelect from '@/components/DebounceSelect'
import Cash from '@/components/Cash'
import BoardDatePicker from '@/components/Board/DatePicker'
import cloneDeep from 'lodash/cloneDeep'
import './Create.less'
import { connect } from 'react-redux'
import { fixAmount } from '@/utils/index'

let searchMemberList = []
let timer = null
function Create(props) {
  const [form] = Form.useForm()
  const [selectedMember, setSelectedMember] = useState({
    petInfos: [],
  })
  const [pickConfig, setPickConfig] = useState({ pickUp: false })
  const [paySetting, setPaySetting] = useState({})
  const [spuIdOptions, setSpuIdOptions] = useState([]) // 寄养房型
  const [cashVisible, setCashVisible] = useState(false)
  const [spuId, setSpuId] = useState('')
  const [userId, setUserId] = useState(null)
  const [orderNo, setOrderNo] = useState(null)
  const [isAddressChecked, setIsAddressChecked] = useState(false)
  const [boardInfoDateArray] = useState({})
  const [boardDateArray, setBoardDateArray] = useState(null)
  const [loading, setLoading] = useState(false)
  const [cashInfo, setCashInfo] = useState({
    num: '--',
    price: '--',
    amount: '--',
  })

  /**
   * 搜索会员
   */
  const handleSearchChange = (value) => {
    const memberItem = searchMemberList.find((item) => item.id === value)
    if (!memberItem) {
      return
    }
    memberItem.nickName = memberItem.realName || memberItem.nickName
    setSelectedMember(memberItem)
    setUserId(memberItem.id)
    form.setFieldsValue({
      ...memberItem,
    })
  }

  /**
   * 选择宠物
   */
  const handlePetChange = (value) => {
    const petItem = selectedMember.petInfos.find((item) => item.id === value)
    const formValue = form.getFieldsValue()
    form.setFieldsValue({
      ...formValue,
      ...petItem,
      petId: petItem.id,
    })
  }

  async function fetchUserList(value) {
    if (value === '') {
      return new Promise((resolve) => {
        resolve([])
      })
    }
    return props
      .getUserPetInfo({
        userNameOrPhone: value,
      })
      .then((res) => {
        searchMemberList = cloneDeep(res.data)
        return res.data.map((item) => ({
          label: `${item.realName || item.nickName}(${item.phone})`,
          value: item.id,
        }))
      })
  }

  const handleSpuIdChange = (spuId) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dateStr: undefined,
    })
    setCashInfo({})
    setSpuId(spuId)
    setBoardDateArray(null)
  }

  const handleBoardDateChange = (val) => {
    if (!val || val.length === 0) {
      setBoardDateArray(null)
      return
    }
    setBoardDateArray(val)
  }
  const onFormValueChange = (values) => {
    console.log('values', values)
    if (Array.isArray(values.dateStr) && values.dateStr.length) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        calcOrderAmount(isAddressChecked)
      }, 300)
    }
  }

  const calcOrderAmount = (pickUpFlag = false) => {
    form.validateFields().then((formData) => {
      const dateArray = formData.dateStr.map((m) => m.format('YYYY-MM-DD'))
      props
        .calcBoardOrderAmount({
          ...formData,
          pickUpFlag: pickUpFlag ? 1 : 0,
          boardType: '0',
          startDate: dateArray[0],
          endDate: dateArray[1],
        })
        .then((res) => {
          setCashInfo(res.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
    })
  }
  const onAddressCheckedChange = (e) => {
    const checked = e.target.checked
    setIsAddressChecked(checked)
    calcOrderAmount(checked)
  }
  const createOrder = () => {
    if (isAddressChecked && !form.getFieldValue('userAddress')) {
      message.warning('请输入接送地址')
      return
    }
    form.validateFields().then(() => {
      setLoading(true)
      const formData = form.getFieldsValue()
      const dateArray = boardDateArray.map((m) => m.format('YYYY-MM-DD'))
      setUserId(formData.userId)
      props
        .takeBoardOrder({
          ...formData,
          pickUpFlag: isAddressChecked ? 1 : 0,
          boardType: '0',
          boardPayConfigType: paySetting.type,
          startDate: dateArray[0],
          endDate: dateArray[1],
          channel: 'CRS',
        })
        .then((res) => {
          setOrderNo(res.data.orderNo)
          setCashVisible(true)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const handleCashSuccess = () => {
    setCashVisible(false)
    props.onSuccess && props.onSuccess()
  }
  const handleCashClose = () => {
    setCashVisible(false)
    // 未支付返回后需要删除订单
    props.deleteBoardOrder({
      orderNo,
    })
  }

  const canPickUp = useMemo(() => {
    return pickConfig.pickUp
  }, [pickConfig])

  useEffect(() => {
    // 获取寄养类型
    props.getBoardType().then((res) => {
      setSpuIdOptions(
        res.data.map((item) => ({
          ...item,
          label: item.spuTitle,
          value: item.spuId,
        }))
      )
    })
    props.getBoardPayConfigInfo().then((res) => {
      setPaySetting(res.data)
    })
  }, [])

  useEffect(() => {
    props.configAppoint().then((res) => {
      setPickConfig(res.data)
    })
  }, [])

  return (
    <div className="board-create-con">
      <Form form={form} className="form" onValuesChange={onFormValueChange}>
        <div className="cell-con">
          <div className="cell-title">账号信息</div>
          <div className="search-con">
            <Form.Item name="userId">
              <DebounceSelect
                showSearch
                placeholder="请输入会员名称或手机号"
                fetchOptions={fetchUserList}
                onChange={handleSearchChange}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </div>
          <div className="form-group">
            <div className="form-line">
              <Form.Item
                label="姓 名"
                name="nickName"
                rules={[{ required: true, message: '请输入会员姓名' }]}
              >
                <Input placeholder="请输入姓名" bordered={false} />
              </Form.Item>
              <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: /^1\d{10}$/,
                    message: '请输入格式正确的手机号',
                  },
                ]}
              >
                <Input placeholder="请输入手机号" type="tel" bordered={false} />
              </Form.Item>
            </div>
            <div className="form-line">
              <Form.Item label="选择宠物" name="petId">
                <Select
                  bordered={false}
                  placeholder="请选择宠物"
                  onChange={handlePetChange}
                >
                  {selectedMember.petInfos &&
                    selectedMember.petInfos.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.petName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="cell-con">
          <div className="cell-title">宠物信息</div>
          <div className="form-group">
            <div className="form-line">
              <Form.Item
                label="宠物昵称"
                name="petName"
                rules={[
                  {
                    required: true,
                    message: '请输入宠物昵称',
                  },
                  {
                    max: 25,
                    message: '宠物昵称最长25个字符',
                  },
                ]}
              >
                <Input placeholder="请输入宠物昵称" bordered={false} />
              </Form.Item>
              <Form.Item
                label="宠物性别"
                name="petGender"
              >
                <Select bordered={false} placeholder="请选择宠物性别">
                  {PET_GENDER_MAP.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="form-line">
              <Form.Item
                label="宠物体型"
                name="petBodyType"
              >
                <Select bordered={false} placeholder="请选择宠物体型">
                  {PET_BODY_TYPE_MAP.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="宠物毛色"
                name="petHairColor"
              >
                <Input
                  placeholder="请输入宠物毛色"
                  bordered={false}
                  maxLength={6}
                />
              </Form.Item>
            </div>
            <div className="form-line">
              <Form.Item
                label="是否绝育"
                name="petNeuterStatus"
              >
                <Select bordered={false} placeholder="请选择是否绝育">
                  {PET_NEUTER_STATUS_MAP.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="宠物品种"
                name="petBreed"
              >
                <Input
                  placeholder="请输入宠物品种"
                  bordered={false}
                  maxLength={10}
                />
              </Form.Item>
            </div>
            <div className="form-line">
              <Form.Item
                label="体重（KG）"
                name="petWeight"
              >
                <InputNumber
                  placeholder="请输入宠物体重"
                  style={{ width: '100%'}}
                  bordered={false}
                  max={9999.99}
                />
              </Form.Item>
            </div>
          </div>
          <div className="cell-title">寄养方式</div>
          <div className="form-group">
            {canPickUp && (
              <div className="form-line oneline">
                <Form.Item
                  label={
                    <Checkbox
                      checked={isAddressChecked}
                      onChange={onAddressCheckedChange}
                    >
                      上门接送
                    </Checkbox>
                  }
                  name="userAddress"
                >
                  <Input
                    bordered={false}
                    disabled={!isAddressChecked}
                    placeholder="请输入接送地址"
                  ></Input>
                </Form.Item>
              </div>
            )}
            <div className="form-line">
              <Form.Item
                label="寄养房型"
                name="spuId"
                rules={[
                  {
                    required: true,
                    message: '请选择寄养房型',
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="请选择寄养房型"
                  onChange={handleSpuIdChange}
                >
                  {spuIdOptions &&
                    spuIdOptions.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="寄养时间"
                name="dateStr"
                rules={[
                  {
                    required: true,
                    message: '请选择寄养时间',
                  },
                ]}
              >
                <BoardDatePicker
                  spuId={spuId}
                  userId={userId}
                  value={boardDateArray}
                  boardInfoDateArray={boardInfoDateArray}
                  onChange={handleBoardDateChange}
                />
              </Form.Item>
            </div>
            <div className="form-line oneline">
              <Form.Item label="留言" name="mono">
                <Input.TextArea
                  bordered={false}
                  placeholder="请输入留言"
                  maxLength={150}
                ></Input.TextArea>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="block-con">
        {cashInfo.totalNum > 0 && (
          <div className="order-info">
            <div className="l">
              <span>寄养：{cashInfo.totalNum}天</span>
              <span>
                均价：¥{fixAmount(cashInfo.skuTotalPrice / cashInfo.totalNum)}
              </span>
              {canPickUp && <span>接送费用：¥{cashInfo.pickUpPrice}</span>}
              <span>总额：¥{cashInfo.totalPrice}</span>
            </div>
            <div className="r">
              <p>
                待付款
                <span className="price primary-color">
                  ¥{cashInfo.actualPrice}
                </span>
              </p>
            </div>
          </div>
        )}
        <div className="handle-con">
          <Button
            type="primary"
            className="submit"
            loading={loading}
            onClick={createOrder}
          >
            去结算
          </Button>
        </div>
      </div>
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={handleCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={userId}
          spuId={spuId}
          orderNo={orderNo}
          orderType="BOARD"
          onSuccess={handleCashSuccess}
        />
      </Drawer>
    </div>
  )
}

Create.propTypes = {
  onSuccess: PropTypes.func,
}

export default connect(null, {
  getUserPetInfo,
  getBoardType,
  getBoardProject,
  calcBoardOrderAmount,
  getBoardPayConfigInfo,
  takeBoardOrder,
  deleteBoardOrder,
  configAppoint,
})(Create)
