import React, {} from 'react'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import './menuItem.less'
import IconFont from '../../Icon'
export function MenuItem (props) {
  const { path, name, icon, selectIcon, exact, location } = props
  return (
    <div className="menu-item">
      <NavLink to={path} exact={exact}>
        <IconFont className="icon" type={location.pathname === path ? selectIcon : icon} />
        {name}
      </NavLink> 
    </div>
  )
}

export default withRouter(MenuItem)
