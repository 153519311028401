import React, { useState, useEffect, createRef, useRef } from 'react'
import './calendar.less'
import Icon from '@/components/Icon'
import { Button, Input, DatePicker, Empty, Spin, Drawer } from 'antd'
import { delBacklogList } from '@/redux/actions/user'
import CItem from './Item'
import TodoItem from './TodoItem'
import DetailAndCash from '@/components/Appointment/DetailAndCash'
import { throttle } from '@/utils'
import Task from '@/components/Appointment/Task'
import { connect } from 'react-redux'
import moment from 'moment'
const Search = Input.Search
function ACalendar(props) {
  let startX = 0
  let initScrollX = 0
  const isMousedown = useRef(false)
  const scrollBox = createRef()
  const scrollMouseEl = createRef()
  const dateListRef = createRef()
  const [boxWidth, setBoxWidth] = useState(0)
  const [isShowAction, setIsShowAction] = useState(false)
  //待办详情
  const [backlogVisisble, setBacklogVisisble] = useState(false)
  // 预约详情
  const [detailVisisble, setDetailVisisble] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [todoDetails, setTodoDetails] = useState()
  const onSearch = (keyWord) => {
    props.onSearch(keyWord)
  }
  const init = () => {
    initUlX()
  }
  const initUlX = () => {
    const liNodes = dateListRef.current && dateListRef.current.children
    if (!liNodes) {
      setIsShowAction(false)
      return
    }
    let wd = 0
    for (const k of Array.from(liNodes)) {
      const kNode = window.getComputedStyle(k)
      wd +=
        kNode.width.replace('px', '') * 1 +
        kNode.marginLeft.replace('px', '') * 1 +
        10
    }
    const wdbx =
      (scrollBox.current && scrollBox.current.getBoundingClientRect().width) ||
      0
    const flag = wd > wdbx ? true : false
    setIsShowAction(flag)
    setBoxWidth(wd)
  }

  const onUlMousedown = (e) => {
    e.preventDefault()
    isMousedown.current = true
    if (scrollBox.current) {
      startX = e.clientX
      initScrollX = scrollBox.current.scrollLeft
    }
  }
  const onUlMouseup = (e) => {
    e.preventDefault()
    isMousedown.current = false
  }
  const onUlMousemove = (e) => scrollCallback(e)

  const scrollCallback = throttle((e) => {
    e.preventDefault()
    if (isMousedown.current && scrollBox.current) {
      const nowX = e.clientX
      const moveLeft =
        startX - nowX + initScrollX > 0 ? startX - nowX + initScrollX : 0
      scrollBox.current.scrollLeft = moveLeft
    }
  }, 16)

  const onClickScrollLeft = () => {
    scrollBox.current.scrollLeft = scrollBox.current.scrollLeft + 150
  }
  const openOrderDetail = (val) => {
    setOrderId(val.id)
    setDetailVisisble(true)
  }
  // 查看待办详情
  const openOrderServeDetail = (val) => {
    setTodoDetails(val)
    setBacklogVisisble(true)
  }
  const onCloseTask = () => {
    setBacklogVisisble(false)
  }
  //删除待办
  const deleteToDoInfo = (v) => {
    props.delBacklogList(v).then((res) => {
      setBacklogVisisble(false)
      props.onDeleteToDoSuccess && props.onDeleteToDoSuccess()
    })
  }

  useEffect(() => {
    init()
  }, [props.appointList])

  useEffect(() => {}, [props.keyWord])

  return (
    <div className="a-calendar">
      <nav className="a-nav">
        <div className="nav-item">
          <Button
            type="primary"
            className="adjust-btn"
            onClick={() => props.setAdjustVisible(true)}
          >
            单日预约调整
          </Button>
        </div>
        <div className="nav-item center">
          <Search
            placeholder="请输入会员名称、手机号、名称"
            className="search-btn search-button"
            prefix={<Icon type="iconsousuo" />}
            enterButton="搜索"
            onSearch={onSearch}
            defaultValue={props.keyword}
          />
        </div>
        <div className="nav-item right">
          <ul className="time-lists">
            {props.timeLists.map((iv, index) => (
              <li
                className={`item ${
                  index === props.timeListIndex ? 'active' : ''
                }`}
                key={index}
                onClick={() => props.setTimeListIndex(index)}
              >
                {iv.value !== -1 ? (
                  iv.label
                ) : (
                  <>
                    <DatePicker
                      suffixIcon=""
                      allowClear={false}
                      bordered={false}
                      placeholder="选择日期"
                      value={props.dateTime}
                      onChange={props.onChangeDate}
                    />
                    <Icon className="icon" type="iconxiangyou" />
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <Spin spinning={props.appointListLoading} delay={300}>
        {isShowAction ? (
          <div className="action" onClick={onClickScrollLeft}>
            <Icon type="iconxiangyou" />
          </div>
        ) : (
          <></>
        )}
        <div className="a-content-box">
          <div className="date-list-bg"></div>
          <div className="a-content" ref={scrollBox}>
            <div
              className="a-c-box"
              ref={scrollMouseEl}
              onMouseDown={onUlMousedown}
              onMouseUp={onUlMouseup}
              onMouseMove={onUlMousemove}
              style={{ width: boxWidth > 0 ? `${boxWidth}px` : '100%' }}
            >
              <ul className="date-list" ref={dateListRef}>
                {props.appointList.map((iv, index) => (
                  <li className="item" key={index}>
                    <span className="time">
                      {iv.appointStartTime}-{iv.appointEndTime}
                    </span>
                    &nbsp;({iv.peopleNumSure}/{iv.peopleNum}人)
                  </li>
                ))}
              </ul>
              <div className="a-c-list">
                {props.appointList && props.appointList.length > 0 ? (
                  props.appointList.map((iv, index) => (
                    <div className="c-date-item" key={iv.id}>
                      {iv.appointSectionInfoVOS &&
                        iv.appointSectionInfoVOS.map((v, childIndex) => {
                          return v.type === 1 ? (
                            <CItem
                              {...v}
                              key={childIndex}
                              openOrderDetail={openOrderDetail}
                            />
                          ) : (
                            <TodoItem
                              {...v}
                              key={childIndex}
                              openOrderServeDetail={openOrderServeDetail}
                            />
                          )
                        })}
                    </div>
                  ))
                ) : (
                  <Empty
                    imageStyle={{
                      marginTop: 50,
                      height: 120,
                    }}
                    description={<span>暂无数据</span>}
                  ></Empty>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <DetailAndCash
        detailVisisble={detailVisisble}
        orderId={orderId}
        setOrderId={setOrderId}
        onSuccess={props.onDeleteToDoSuccess}
        onCancel={props.onDeleteToDoSuccess}
        setDetailVisisble={setDetailVisisble}
      />
      {/* 待办详情 */}
      <Drawer
        title="待办详情"
        placement="right"
        width="500px"
        onClose={onCloseTask}
        visible={backlogVisisble}
      >
        <Task
          onClose={onCloseTask}
          todoDetails={todoDetails}
          handleDelete={(detail) => deleteToDoInfo(detail)}
        />
      </Drawer>
    </div>
  )
}
ACalendar.defaultProps = {
  appointList: {
    appointSectionInfoVOS: [],
  },
}
export default connect(state => ({ keyword: state.appointment.keyword }), {
  delBacklogList,
})(ACalendar)
