import { inApp, inElectron } from './device'
import {
  PRINT_WIDTH,
  LINE_HEIGHT_KEY,
  SAVE_KEY,
} from '@/utils/constant'
import {
  getPrintUSBAddressInAppByLocal,
  checkPrintStatus,
  getPrintType,
} from '@/utils/print'
import { message } from 'antd'
import { qrdecode, url2base64 } from '@/utils'
let webviewElectronElem = null
export default class TicketPrinter {
  constructor(deviceType) {
    // 'app-inside' Android内置打票机
    // 'app-usb' Android USB外接打票
    // 'electron-usb' PC使用escpos协议打印
    // 'electron-html' PC使用网页打印
    this.deviceType = deviceType || getPrintType()
    this.printWidthScale = null
    this.ticketWidth = null
    this.totalWidth = null
    this.lineHeight = null
    this.lineSplitText = null
    this.printName = null
    this.printer = null
    this.printData = []
  }
  _init() {
    // 小票宽度
    this.ticketWidth = Number(window.localStorage.getItem(PRINT_WIDTH)) || 58
    // 字符总长度
    this.totalWidth = this.ticketWidth === 58 ? 32 : 48
    if (this.deviceType === 'app-inside') {
      this.totalWidth = this.ticketWidth === 58 ? 30 : 45
    }
    // 行间距
    this.lineHeight = Number(window.localStorage.getItem(LINE_HEIGHT_KEY)) || 35
    // 行分割线
    this.lineSplitText =
      this.ticketWidth === 58
        ? '--------------------------------'
        : '------------------------------------------------'
    // 打印宽度倍数
    this.printWidthScale = this.ticketWidth === 58 ? 1 : 1.5
    // 打印数据
    this.printData = []
  }
  ready(success) {
    this._init()
    if (this.deviceType === 'electron-usb') {
      const device = new window.escpos.USB()
      const options = { encoding: 'GB18030', width: this.totalWidth }
      this.printer = new window.escpos.Printer(device, options)
      let lastTime = 0
      device.open(async () => {
        if (Date.now() - lastTime < 300) {
          return
        }
        lastTime = Date.now()
        await this.printer.font('a')
        await this.printer.lineSpace(this.lineHeight)
        await this.printer.style('NORMAL')
        await this.printer.size(0.5, 0.5)
        success && success()
      })
    } else if (this.deviceType === 'app-inside') {
      this.printer = api.require('sunmiPrinter')
      this.printer.startService(async function (ret, err) {
        if (err && err.msg !== '打印机服务已启动') {
          alert(JSON.stringify(err))
          return
        }
        success && success()
      })
    } else if (this.deviceType === 'app-usb') {
      this.printer = api.require('posPrinter')
      const usbAddress = getPrintUSBAddressInAppByLocal()
      if (!usbAddress) {
        message.error('未连接打票机，请先前往系统设置连接打票机')
        return
      }
      success && success()
    } else if (this.deviceType === 'electron-html') {
      const printName = getPrintName()
      if (!printName) {
        message.error('未找到打票机')
        return
      } else {
        const canPrint = checkPrintStatus(printName)
        if (!canPrint) {
          message.error('打票机不可用')
          return
        }
      }
      webviewElectronElem = document.querySelector('#__WEBVIEW_ELECTRON')
      webviewElectronElem &&
        webviewElectronElem.removeEventListener(
          'ipc-message',
          this._startHtmlPrint
        )
      webviewElectronElem &&
        webviewElectronElem.addEventListener(
          'ipc-message',
          this._startHtmlPrint
        )
      success && success()
    } else {
      message.error('当前环境不支持热敏打印机，请前往官网下载客户端')
    }
  }
  _convertAlignTypeIfAppTable(type = 'LEFT') {
    return type === 'LEFT'
      ? 0
      : type === 'RIGHT'
      ? 2
      : type === 'CENTER'
      ? 1
      : type
  }
  async _align(type = 'L') {
    let textAlign = type
    if (this.deviceType === 'electron-usb') {
      textAlign =
        type === 'L' ? 'LT' : type === 'R' ? 'RT' : type === 'C' ? 'CT' : type
      await this.printer.align(textAlign)
    } else if (this.deviceType === 'app-inside') {
      // 对齐方式 0--居左 , 1--居中, 2--居右
      textAlign = type === 'L' ? 0 : type === 'R' ? 2 : type === 'C' ? 1 : type
      this.printData.push({
        rowtype: 'setAlignment', // 设置对齐模式，对之后打印有影响，除非初始化
        alignment: textAlign,
      })
    }
  }
  async text(text, align = 'L') {
    if (this.deviceType === 'electron-usb') {
      await this._align(align)
      await this.printer.text(text)
      // 还原
      await this._align('L')
    } else if (this.deviceType === 'app-inside') {
      await this._align(align)
      this.printData.push({
        rowtype: 'printText',
        text: text + '\n',
      })
      await this._align('L')
    } else if (this.deviceType === 'app-usb') {
      const alignText =
        align === 'L'
          ? 'LEFT'
          : align === 'R'
          ? 'RIGHT'
          : align === 'C'
          ? 'CENTER'
          : align
      this.printData.push(getTextLineIfAppUsb(text, alignText, this.totalWidth))
    } else if (this.deviceType === 'electron-html') {
      this.printData.push(
        `<div class="text-line ${align}">${typeof text === 'string' ? text.replace(/\s/g, '&ensp;') : ''}</div>`
      )
    }
  }
  /**
   *
   * @param {array} array [{ text: '', align: 'CENTER', width: 小数占比}]
   */
  async tableCustom(array) {
    const tableData = await printerTableCustom(array, this.totalWidth)
    for (let index = 0; index < tableData.length; index++) {
      await this.text(tableData[index])
    }
  }
  async image(url) {
    if (this.deviceType === 'electron-usb') {
      await this._align('C')
      await printImageByUrl(this.printer, url)
      await this._align('L')
    } else if (this.deviceType === 'app-inside') {
      const image = await url2imageByApiCloud(url)
      await this.feed()
      await this._align('C')
      this.printData.push({
        image,
        rowtype: 'printBitmap',
        size: 240 * this.printWidthScale,
      })
      await this.feed()
      await this._align('L')
    } else if (this.deviceType === 'app-usb') {
      const qrCode = await qrdecode(url)
      this.printData.push(`<C><QR>(350)${qrCode}</QR></C>`)
    } else if (this.deviceType === 'electron-html') {
      const base64 = await url2base64(url)
      this.printData.push(`<img class="qrcode" src="${base64}" />`)
    }
  }
  async drawLine() {
    if (this.deviceType === 'electron-usb') {
      await this.printer.drawLine()
    } else if (this.deviceType === 'electron-html') {
      this.printData.push('<div class="hr"></div>')
    } else {
      await this.text(this.lineSplitText)
    }
  }
  async feed() {
    if (this.deviceType === 'electron-usb') {
      await this.printer.feed()
    } else if (this.deviceType === 'app-inside') {
      this.printData.push({
        rowtype: 'printText',
        text: '\n',
      })
    } else if (this.deviceType === 'app-usb') {
      this.printData.push('<BR>')
    } else if (this.deviceType === 'electron-html') {
      this.printData.push('<div class="feed"></div>')
    }
  }
  async cut() {
    if (this.deviceType === 'electron-usb') {
      await this.printer.cut()
    } else if (this.deviceType === 'app-inside') {
      this.printData.push({
        rowtype: 'cutPaper',
      })
    } else if (this.deviceType === 'app-usb') {
      this.printData.push('<CUT>')
    }
  }
  async close() {
    if (this.deviceType === 'electron-usb') {
      await this.printer.close()
    } else if (this.deviceType === 'app-inside') {
      // alert(JSON.stringify(this.printData))
      // return
      this.printer.printData(
        {
          data: this.printData,
        },
        function (ret, err) {}
      )
    } else if (this.deviceType === 'app-usb') {
      this.printer.print({
        taskList: [
          {
            content: this.printData.join('<BR>'),
            printerAddr: getPrintUSBAddressInAppByLocal(),
          },
        ],
      })
    } else if (this.deviceType === 'electron-html') {
      try {
        webviewElectronElem.send('set-html', this.printData.join(''))
      } catch (error) {
        console.log('webviewElem.current.send error:', error)
      }
    }
  }
  /**
   * 打印机自检
   */
  async selfChecking() {
    if (
      this.deviceType === 'electron-usb' ||
      this.deviceType === 'app-usb' ||
      this.deviceType === 'electron-html'
    ) {
      await this.feed()
      await this.text('测试打印')
      await this.feed()
      await this.text('测试打印')
      await this.feed()
      await this.text('测试打印')
      await this.feed()
      await this.cut()
      await this.close()
    } else if (this.deviceType === 'app-inside') {
      this.printer.printerSelfChecking(function (ret, err) {
        if (err) {
          alert(JSON.stringify(err))
        }
      })
    }
  }
  _startHtmlPrint() {
    const options = {
      silent: true,
      margins: {
        marginType: 'none',
      },
      deviceName: getPrintName(),
    }
    if (!options.deviceName) {
      return
    }
    setTimeout(() => {
      webviewElectronElem && webviewElectronElem.print(options)
    }, 0)
  }
}

/**
 * electron-usb 打印网络图片
 * @param {} printer
 * @param {*} url
 */
function printImageByUrl(printer, url) {
  return new Promise((resolve, reject) => {
    window.escpos.Image.load(url, (image) => {
      if (image instanceof Error) {
        reject(new Error('图片无法读取'))
      } else {
        printer.image(image, 'd24').then(() => {
          resolve()
        })
      }
    })
  })
}
function getTextLineIfAppUsb(text, align = 'LEFT', totalWidth, width = 1) {
  // 0到1之间
  width = width >= 1 ? 1 : width <= 0 ? 0 : width
  const itemWidth = totalWidth * width
  const charLength = _getCharLength(text)
  let res = ''
  let leftSpace = ''
  let rightSpace = ''
  let spaceLength = ''
  if (align === 'CENTER') {
    res = `<C>${text}</C>`
  } else if (align === 'RIGHT') {
    spaceLength = itemWidth - charLength
    leftSpace = _createSpaceText(spaceLength)
    res = `${leftSpace}${text}`
  } else if (align === 'LEFT') {
    spaceLength = itemWidth - charLength
    rightSpace = _createSpaceText(spaceLength)
    res = `${text}${rightSpace}`
  }
  return res
}
/**
 * 封装Printer.TableCustom方法，width分为32份，一个汉字占2份
 * align: CENTER LEFT RIGHT
 * @param {array} array [{ text: '', align: 'CENTER', width: 6/32}]
 */
function printerTableCustom(array, totalWidth) {
  let textArray = []
  let text = ''
  let arrayTemp = [...array]
  let textListTemp = []
  while (arrayTemp.length) {
    arrayTemp.forEach((item) => {
      text += _getTextLine(item.text, item.align, item.width)
    })
    if (textListTemp.some((item) => item.text.replace(/\s/g, '') !== '')) {
      arrayTemp = [...textListTemp]
    } else {
      arrayTemp = []
    }
    textListTemp = []
    textArray.push(text)
    text = ''
  }
  return textArray
  function _getTextLine(text, align = 'LEFT', width = 1) {
    // 0到1之间
    width = width >= 1 ? 1 : width <= 0 ? 0 : width
    let textTemp = text + ''
    let itemWidth = parseInt(totalWidth * width)
    let charLength = _getCharLength(textTemp)
    let res = ''
    let leftSpace = ''
    let rightSpace = ''
    let spaceLength = ''
    if (charLength > itemWidth || textTemp.indexOf('\n') > -1) {
      // 计算超过长度
      let diffLength = charLength - itemWidth
      let textTempArray = []
      if (textTemp.indexOf('\n') > -1) {
        textTempArray = textTemp.split('\n')
        textListTemp.push({
          text: textTempArray[1],
          align,
          width,
        })
        textTemp = textTempArray[0]
      } else {
        // 当前行打印，这里做个缓存，需要给下一行做替换
        const currentTextTemp = _substringDoubleChar(
          textTemp,
          0,
          charLength - diffLength
        )
        // 下一行打印
        textListTemp.push({
          text: textTemp.replace(currentTextTemp, ''),
          align,
          width,
        })
        // 当前行打印
        textTemp = currentTextTemp
      }

      charLength = _getCharLength(textTemp)
    } else {
      textListTemp.push({
        text: _createSpaceText(width),
        align,
        width,
      })
    }
    if (align === 'CENTER') {
      spaceLength = (itemWidth - charLength) / 2
      leftSpace = _createSpaceText(spaceLength)
      res = `${leftSpace}${textTemp}${leftSpace}`
    } else if (align === 'RIGHT') {
      spaceLength = itemWidth - charLength
      leftSpace = _createSpaceText(spaceLength)
      res = `${leftSpace}${textTemp}`
    } else if (align === 'LEFT') {
      spaceLength = itemWidth - charLength
      rightSpace = _createSpaceText(spaceLength)
      res = `${textTemp}${rightSpace}`
    }
    return res
  }

  function _substringDoubleChar(text, start, end) {
    end = end || _getCharLength(text)
    const array = text.split('')
    let spliceText = ''
    let totalLen = 0
    array.forEach((item) => {
      if (totalLen >= start) {
        return
      }
      spliceText += item
      totalLen += _getCharLength(item)
    })
    const str = text.replace(spliceText, '')
    return substr(str, end - start)

    function substr(str, len) {
      if (!str || !len) {
        return ''
      }
      //预期计数：中文2字节，英文1字节
      var a = 0
      //循环计数
      var i = 0
      //临时字串
      var temp = ''
      for (i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
          //按照预期计数增加2
          a += 2
        } else {
          a++
        }
        //如果增加计数后长度大于限定长度，就直接返回临时字符串
        if (a > len) {
          return temp
        }
        //将当前内容加到临时字符串
        temp += str.charAt(i)
      }
      //如果全部是单字节字符，就直接返回源字符串
      return str
    }
  }
}
function _createSpaceText(len, text = ' ') {
  len = len < 0 ? 0 : len
  len = Math.floor(len + 0.5)
  return new Array(len).fill(text).join('')
}
/**
 * 获取字节长度，中文为两个字节
 * @param {string} text
 */
function _getCharLength(text) {
  text = text + ''
  /*eslint no-control-regex: "off"*/
  const doubleCharArray = text.match(/[^\x00-\xff]+/g)
  let len = text.length
  // 双字节
  if (Array.isArray(doubleCharArray) && doubleCharArray.length) {
    len += doubleCharArray.join('').length
  }
  return len
}
/**
 * 缓存网络图片
 * @param {string} url 图片地址
 * @returns 本地文件缓存地址
 */
function url2imageByApiCloud(url) {
  return new Promise((resolve, reject) => {
    try {
      api.imageCache(
        {
          url,
        },
        function (ret, err) {
          if (err) {
            reject(err)
            return
          }
          resolve(ret.url)
        }
      )
    } catch (error) {
      reject(error)
    }
  })
}
export const canUseTicketPrint = () => inApp || inElectron

/**
 * 如果是app中，返回打票机usb地址
 */
function getPrintName() {
  if (inApp) {
    return getPrintUSBAddressInAppByLocal()
  }
  return window.localStorage.getItem(SAVE_KEY)
}
