import React from 'react'
import { Spin, Empty } from 'antd'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import emptyIcon from '@/assets/common/moren.png'
import './index.less'

Index.propTypes = {
  handleOnLoad: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
}

export default function Index(props) {
  const handleLoadMore = () => {
    if (props.loading) {
      return
    }
    props.handleOnLoad && props.handleOnLoad()
  }
  return (
    <div className={`${props.className + ' ' || ''} infinite-scroll-con`}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={!props.loading && props.hasMore}
        useWindow={false}
      >
        {props.children}
        {props.loading && props.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
        {!props.loading && !props.hasMore && !props.children.length && (
          <Empty
            imageStyle={{
              marginTop: 50,
              height: 120,
            }}
            image={emptyIcon}
            description={<span>{props.emptyText || '暂无数据'}</span>}
          ></Empty>
        )}
      </InfiniteScroll>
    </div>
  )
}
