import React, { useState, useEffect } from 'react'
import { Input, Form, DatePicker } from 'antd'
import { disabledStartDate, disabledEndDate } from '@/utils'
import Icon from '@/components/Icon'
import './search.less'
import moment from 'moment'
const Search = Input.Search
export default function ASearch (props) {
  const [params, setParams] = useState({
    endTime: '',
    startTime: '',
    phoneOrPetName: ''
  })
  const [form] = Form.useForm()
  const handleSearch = (phoneOrPetName) => {
    
    setParams({
      ...form.getFieldsValue(),
      phoneOrPetName
    })
  }
  const onStartChange = (current) => {
    setParams({
      ...form.getFieldsValue(),
      startTime: current && current.format('YYYY-MM-DD 00:00')
    })
  }
  const onEndChange = (current) => {
    setParams({
      ...form.getFieldsValue(),
      endTime: current && current.format('YYYY-MM-DD 23:59')
    })
  }

  useEffect(() => {
    props.onSearch(params)
  }, [params])

  useEffect(() => {
    let fieldValue = {
      phoneOrPetName: '', // 日历跳转过来需要清空搜索输入框
      startTime: props.startTime ? moment(props.startTime) : '',
      endTime: props.endTime ? moment(props.endTime) : ''
    }
    form.setFieldsValue({
      ...fieldValue
    })
  }, [props.startTime])
  return (
    <div className="board-a-l-search">
      <Form
        className="form"
        form={form}
      >
        <Form.Item
          name="phoneOrPetName"
        >
          <Search placeholder="手机号或宠物昵称"
            allowClear
            className="search-button"
            prefix={<Icon type="iconsousuo" />}
            enterButton="搜索"
            onSearch={handleSearch}
          />
        </Form.Item>
        <Form.Item
          label="起始时间"
          name="startTime"
          colon={false}
          className="form-item"
        >
          <DatePicker
            bordered={false}
            disabledDate={current => disabledStartDate(current, params.endTime)}
            placeholder="请选择起始时间"
            format="YYYY-MM-DD 00:00"
            onChange={onStartChange}
          />
        </Form.Item>
        <Form.Item
          label="结束时间"
          name="endTime"
          colon={false}
          className="form-item"
        >
          <DatePicker
            bordered={false}
            disabledDate={current => disabledEndDate(current, params.startTime)}
            placeholder="请选择结束时间"
            format="YYYY-MM-DD 23:59"
            onChange={onEndChange}
          />
        </Form.Item>
      </Form>

    </div>
  )
}
