import React, { useState, useEffect } from 'react'
import { Image } from 'antd'
import './specs.less'
import { ossProcess } from '@/utils'

export default function Specs(props) {
  const [skusList, setSkusList] = useState([])
  const [currentSku, setCurrentSku] = useState([])
  const [selectIndex, setSelectIndex] = useState(0)

  const onClickActive = (item, index) => {
    setSelectIndex(index)
    setCurrentSku(item)
    props.getSureItem(index)
  }
  const init = () => {
    const list = props.itemDetail.skus
    setSkusList(list)
    setCurrentSku(list[0])
    setSelectIndex(0)
    props.getSureItem(0)
  }
  const hasVipPrice = React.useMemo(
    () => props.itemDetail.discountScope.indexOf(3) > -1,
    [props.itemDetail.discountScope]
  )
  useEffect(() => {
    init()
  }, [props.itemDetail])

  return (
    <div className="Specs">
      <div className="s-info">
        <Image
          className="img"
          src={
            ossProcess(currentSku.img) || ossProcess(props.itemDetail.img) || ''
          }
          preview={false}
        />
        <div className="s-r-info">
          <h2 className="t">{`${props.itemDetail.title} ${currentSku.title}`}</h2>
          <div className="r-box">
            <span className="r-p">¥{currentSku.price}</span>
            {hasVipPrice ? (
              <>
                <span className="r-v">会员价</span>
                <span className="r-v-p">
                  ¥{currentSku.vipPrice !== null ? currentSku.vipPrice : 0}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="r-o-s">
            <span className="r-o">原价 ¥{currentSku.originalPrice}</span>
            {currentSku.type !== 'SERVICE' ? (
              <span className="r-s">库存 {currentSku.stock}</span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="s-box">
        <h2 className="t">选择规格</h2>
        <ul className="s-list">
          {skusList.map((iv, index) => (
            <li
              className={`s-item ${selectIndex === index ? 'active' : ''}`}
              key={index}
              onClick={() => onClickActive(iv, index)}
            >
              {iv.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

Specs.defaultProps = {
  itemDetail: {},
}
