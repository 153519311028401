import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import './index.less'
function Index(props) {
  // 初始化state
  const [detail, setDetail] = useState({})
  const [loading, setLoading] = useState(false)
  // 生命周期
  useEffect(() => {
    setLoading(true)
    console.log('props.userId', props.userId);
    props
      .getAdminInfo({ adminId: props.userId })
      .then((res) => {
        res.data.roleNames = res.data.roleNames.join('、')
        setDetail(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // methods
  // 退出
  const loginOut = () => {
    window.sessionStorage.removeItem('persist:root')
    window.location.reload(true)
  }

  return (
    <Spin spinning={loading} size="large" delay={500}>
      <div className="setting-account">
        <div className="top">
          <div className="row">
            <span className="title">账号信息</span>
          </div>
          <div className="row">
            <div className="item">
              <div className="label">店铺名称</div>
              <div className="value">{detail.tenantName}</div>
            </div>
            <div className="line"></div>
            <div className="item">
              <div className="label">店员名称</div>
              <div className="value">{detail.realname}</div>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <div className="label">手机号码</div>
              <div className="value">{detail.phone}</div>
            </div>
            <div className="line"></div>
            <div className="item">
              <div className="label">店员角色</div>
              <div className="value">{detail.roleNames}</div>
            </div>
          </div>
        </div>
        <div className="con">
          <div className="row">
            <span className="title">安全设置</span>
          </div>
          <div className="row">
            <div className="item">
              <div className="label">登录账号</div>
              <div className="value">{detail.username}</div>
            </div>
            <div className="line"></div>
            <div className="item">
              <div className="label indent">密 码</div>
              <div className="value">******</div>
            </div>
          </div>
        </div>
        <div className="loginOut" onClick={loginOut}>
          退 出
        </div>
      </div>
    </Spin>
  )
}
export default connect(state => ({ userId: state.user.id }))(Index)