export const PRINT_WIDTH = 'zy-crs-print-width'
export const SAVE_KEY = 'printName'
export const WIDTH_KEY = 'printWidth'
export const LINE_HEIGHT_KEY = 'printLineHeight'
export const APP_USB_ADDRESS = 'app-usb-address'
/**
 * 声音提示
 */
export const NOT_NOTIFY_VOICE = 'not-notify-voice'
/**
 * 小票 - 小程序码
 */
export const NOT_PRINT_QRCODE = 'not-print-qrcode'
// 用户主动设置不打印小票
export const USER_IS_NOT_PRINT = 'user-is-not-print'