import React, { useMemo } from 'react'
import './menu.less'
import Icon from '@/components/Icon'
import { BASE_PRM_URL } from '@/config/api'
import MenuItem from './MenuItem'
import { openURL } from '@/utils'
import { Image } from 'antd'
import { connect } from 'react-redux'

function Menu(props) {
  const toManageUrl = () => {
    const t = Date.now()
    const url = `${BASE_PRM_URL}/?t=${t}#/login?token=${props.user.token}`
    openURL(url)
  }
  const menuList = useMemo(() => {
    return props.user.authRouteList || []
  }, [props.user.authRouteList])

  return (
    <div className="home-menu">
      {props.user.avatarUrl ? (
        <Image
          className="logo"
          src={props.user.avatarUrl}
          alt=""
          preview={false}
        />
      ) : (
        <div className="ava">
          <div className="ava-box">{props.user.username.substring(0, 2)}</div>
        </div>
      )}
      {menuList.map((menu) => (
        <MenuItem {...menu} key={menu.path} />
      ))}

      <Icon className="icon-logo" type="iconlogo" />
    </div>
  )
}

export default connect((state) => ({ user: state.user }))(Menu)
