import React, { useMemo, useState } from 'react'
import { Upload, Modal, Input, message } from 'antd'
import lrz from 'lrz'
import { PlusOutlined } from '@ant-design/icons'
import { BASE_UPLOAD_URL } from '@/config/api'
import { boardDynamicAdd } from '@/redux/actions/order'
import { base64ToFile } from '@/utils'
import './index.less'
import { connect } from 'react-redux'

function TimeLinePublish(props) {
  const headers = useMemo(() => ({ accessToken: props.user.token }), [])
  const [loading, setLoading] = useState(false)
  const [dynamicText, setDynamicText] = useState('')
  const [previewParams, setPreviewParams] = useState({
    url: '',
    visible: false,
  })
  const [fileList, setFileList] = useState([])

  const handleChange = ({ fileList }) => {
    console.log(fileList)
    setFileList(fileList)
  }
  const uploadButton = useMemo(
    () => (
      <div>
        <PlusOutlined />
      </div>
    ),
    []
  )
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  const handlePreview = async (file) => {
    if (!file.data && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewParams({
      url: file.data || file.preview,
      visible: true,
    })
  }
  const beforeUpload = (file) => {
    return new Promise((resolve) => {
      lrz(file, {
        width: 1920,
      }).then((res) => {
        const file = base64ToFile(res.base64, res.origin.name)
        resolve(file)
      })
    })
  }

  const handleSubmit = () => {
    if (loading) {
      return
    }
    if (dynamicText === '') {
      message.error('请输入留言')
      return
    }
    setLoading(true)
    console.log('fileList', fileList)
    debugger
    let imgList = []
    try {
      imgList = fileList.length ? fileList.map((item) => item.response.data) : []
    } catch (error) {
      message.error('请等待图片上传完成')
      return
    }
    props
      .boardDynamicAdd({
        imgList,
        orderNo: props.orderDetail.orderNo,
        dynamic: dynamicText,
      })
      .then(() => {
        message.success('记录宠物动态成功')
        setDynamicText('')
        setFileList([])
        props.onSuccess && props.onSuccess()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <Modal
        title="记录宠物动态"
        width="590px"
        wrapClassName="ant-modal-content-custom timeline-publish-modal"
        visible={props.visible}
        onOk={handleSubmit}
        destroyOnClose={true}
        onCancel={props.onCancel}
      >
        <div className="content">
          <div className="form-item">
            <span className="title">今日留言</span>
            <div className="input-con">
              <Input.TextArea
                placeholder="请输入留言"
                bordered={false}
                onChange={(e) => setDynamicText(e.target.value)}
                value={dynamicText}
              ></Input.TextArea>
            </div>
          </div>
          <div className="form-item">
            <span className="title">宠物图片</span>
            <div className="upload-con">
              <Upload
                headers={headers}
                action={BASE_UPLOAD_URL}
                listType="picture-card"
                onChange={handleChange}
                beforeUpload={beforeUpload}
                fileList={fileList}
                maxCount={3}
                multiple={true}
                onPreview={handlePreview}
                accept="image/*"
              >
                {fileList.length >= 3 ? null : uploadButton}
              </Upload>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={previewParams.visible}
        title="预览图片"
        footer={null}
        onCancel={() => setPreviewParams({ ...previewParams, visible: false })}
      >
        <img alt="example" style={{ width: '100%' }} src={previewParams.url} />
      </Modal>
    </>
  )
}

export default connect((state) => ({ user: state.user }), {
  boardDynamicAdd,
})(TimeLinePublish)
