import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Spin, Checkbox, message, Input, Button, Drawer } from 'antd'
import Recharge from '@/components/Member/Recharge'
import CouponDrawer from './components/coupon'
import PubSub from 'pubsub-js'
import Icon from '@/components/Icon'
import * as printer from '@/utils/print'
import ClientDisplay from '@/utils/ClientDisplay'
import NumberKeyboard from '@/components/NumberKeyboard'
import PayStatusModal from '@/components/PayStatusModal'
import { getMemberDetail, getAdminAll } from '@/redux/actions/user'
import { getDetailData as getSettlementOrderDetail } from '@/redux/actions/cashier'
import {
  orderPay,
  calcAmount,
  setOrderStatus,
  getBoardOrderDetail,
  getAppointOrderDetail,
  getCrsOrderDetail,
  updateOrder,
} from '@/redux/actions/order'
import AdminIdSelect from '@/components/MySelect/AdminIdSelect'
import MemberCard from '@/components/Member/Card'
import './index.less'
import { connect, useDispatch } from 'react-redux'
import {
  fixAmount,
  onDeleteByNumberInput,
  onInputByNumberInput,
  checkPayCode,
} from '../../utils'
import { canUseClinetDisplay } from '@/utils/device'
import { USER_IS_NOT_PRINT } from '../../utils/constant'
const payTypeList = [
  {
    icon: 'iconxianjinzhifu',
    title: '余额支付',
    value: 'BALANCE',
    type: 'online',
    scan: false,
  },
  {
    icon: 'iconweixin',
    title: '微信',
    value: 'WX',
    type: 'online',
    scan: true,
  },
  // {
  //   icon: 'iconzhifubao',
  //   title: '支付宝',
  //   value: 'ALI',
  //   type: 'online',
  //   scan: true,
  // },
  {
    icon: 'iconxianjin',
    title: '现金支付',
    value: 'CASH',
    type: 'offline',
    scan: false,
  },
]
let keydownToken
let scanToken

function Cash(props) {
  const dispatch = useDispatch()
  const [orderAmountType, setOrderAmountType] = useState(1)
  const [clientDisplay, setClientDisplay] = useState(null)
  const [memberDetailLoading, setMemberDetailLoading] = useState(true)
  const [settleRemark, setSettleRemark] = useState('')
  const [couponId, setCouponId] = useState(null)
  const [isRemarkFocus, setIsRemarkFocus] = useState(false)
  const [isNumberKeyboardRight, setIsNumberKeyboardRight] = useState(true)
  const [rechargeVisible, setRechargeVisible] = useState(false)
  const [couponVisible, setCouponVisible] = useState(false)
  const [numberKeyboardPosition, setNumberKeyboardPosition] = useState({
    x: 0,
    y: 0,
  })
  const [isPrintTicket, setIsPrintTicket] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isPaying, setIsPaying] = useState(false)
  const [payIndex, setPayIndex] = useState(
    props.userId && props.orderType !== 'DEDUCTION' ? 0 : 1
  )
  const [isCombPay, setIsCombPay] = useState(false)
  const [discount, setDiscount] = useState(100)
  const [commission, setCommission] = useState(0)
  const [deductionPrice, setDeductionPrice] = useState(0)
  const [receivablePrice, setReceivablePrice] = useState(0)
  const [memeberDiscountAmount, setMemeberDiscountAmount] = useState(0) // 会员优惠金额
  const [skuTotalPrice, setSkuTotalPrice] = useState(0)
  const [couponPrice, setCouponPrice] = useState(0) // 优惠券抵扣金额
  const [actualAmount, setActualAmount] = useState(0) // 实收
  const [outAmount, setOutAmount] = useState(0) // 找零
  const [payAmount, setPayAmount] = useState(0) // 支付金额
  const [payAmount2, setPayAmount2] = useState(0) // 支付金额2，组合支付
  const [focusIndex, setFocusIndex] = useState(0)
  const [adminIds, setAdminIds] = useState([])
  const [firstClickInputIndex, setFirstClickInputIndex] = useState(0)
  const [memberDetail, setMemberDetail] = useState({
    userId: '',
    phone: '',
  })
  // 选择组合支付
  // const onCombChange = (e) => {
  //   if (e.target.checked && currentPayType.isBalance) {
  //     // 如果当前选中的是第一项，就需要重置为第二项，默认展示余额支付 + 微信支付
  //     setPayIndex(1)
  //   }
  //   setPayAmount(actualAmount)
  //   setPayAmount2('0')

  //   if (e.target.checked) {
  //     setOutAmount('0')
  //   }

  //   setIsCombPay(e.target.checked)
  // }

  const handleAdminIdsChange = (values) => {
    if (values.length > 5) {
      message.warning('最多选择5位服务人员')
    }
    setAdminIds(values.slice(0, 5))
  }

  const onCouponChange = (couponId) => {
    setCouponId(couponId)
    setCouponVisible(!couponId)
  }

  const getPayTypeByIndex = (index) => {
    const payType = payTypeList[index]
    const isBalance = payType.value === 'BALANCE'
    // 收款记账
    const isOffline = payType.type === 'offline'
    const isScan = payType.scan
    return { ...payType, isBalance, isOffline, isScan }
  }
  const currentPayType = useMemo(() => getPayTypeByIndex(payIndex), [payIndex])
  // 是否美容卡结算
  const isCountCard = useMemo(
    () => props.orderType === 'DEDUCTION',
    [props.orderType]
  )
  const handlePayIndexClick = (index) => {
    const { isBalance, isOffline } = getPayTypeByIndex(index)

    // 组合支付不需要再选择余额支付
    if (isBalance && !props.userId) {
      message.warning('散客无法选择余额支付')
      return
    }

    if (isCombPay && isBalance) {
      return
    }
    setPayIndex(index)

    if (!isCombPay) {
      setOutAmount(0)
      setPayAmount(actualAmount)
    } else {
      if (payAmount2 + payAmount > actualAmount && payIndex !== 3) {
        setPayAmount2(actualAmount - payAmount)
        setOutAmount(0)
      }
    }

    // 快捷支付无法修改金额，将焦点转移至实收金额
    if (!isOffline && focusIndex === 2 && !isCombPay) {
      setFirstClickInputIndex(1)
      setFocusIndex(1)
    }
  }
  const handleFocusClick = (payAmountIndex) => {
    if (!currentPayType.isOffline && payAmountIndex === 2 && !isCombPay) {
      message.warning('快捷支付无法修改金额')
      return
    }
    if (!canUseDiscount && payAmountIndex === 1) {
      message.warning('该订单无法修改实收总额')
      return
    }
    setFocusIndex(payAmountIndex)
    setFirstClickInputIndex(payAmountIndex)
  }

  const hasOutAmount = useMemo(
    () => currentPayType.isOffline && !isCombPay,
    [currentPayType, isCombPay]
  )

  const handleDelete = () => {
    if (focusIndex < 0) return

    let isReplace = firstClickInputIndex > -1
    const amountArray = [
      0,
      actualAmount,
      payAmount,
      payAmount2,
      '',
      discount,
      commission,
    ]
    let amount = isReplace
      ? '0'
      : onDeleteByNumberInput(amountArray[focusIndex])

    if (focusIndex === 1) {
      // 实收总额
      setActualAmount(amount)
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2('0')
      }
    } else if (focusIndex === 2) {
      // 支付金额1
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2(fixAmount(Number(actualAmount) - Number(amount)))
      }
      // 现金支付
      if (hasOutAmount) {
        setOutAmount(fixAmount(Number(amount) - Number(actualAmount)))
      }
    } else if (focusIndex === 3) {
      // 支付金额2
      setPayAmount2(amount)
      setPayAmount(fixAmount(Number(actualAmount) - Number(amount)))
    } else if (focusIndex === 5) {
      // 整单折扣
      handleDiscountChange(amount === '' ? 0 : amount)
    } else if (focusIndex === 6) {
      // 积分抵扣
      setCommission(amount === '' ? 0 : amount)
    }

    setFirstClickInputIndex(-1)
  }
  // 键盘操作
  const handleInput = (text) => {
    if (focusIndex < 0) return
    let isReplace = firstClickInputIndex > -1
    const amountArray = [
      0,
      actualAmount,
      payAmount,
      payAmount2,
      '',
      discount,
      commission,
    ]
    let amount
    console.log('text', text)
    if (isReplace) {
      amount = text
    } else {
      amount = onInputByNumberInput(amountArray[focusIndex], text)
    }
    if ((focusIndex === 2 || focusIndex === 3) && !hasOutAmount) {
      amount = Number(amount) > Number(actualAmount) ? actualAmount : amount
    }
    if (focusIndex === 1) {
      // 实收总额
      setActualAmount(amount)
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2('0')
      }
    } else if (focusIndex === 2) {
      // 支付金额1
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2(fixAmount(Number(actualAmount) - Number(amount)))
      }
      // 现金支付
      if (hasOutAmount) {
        setOutAmount(fixAmount(Number(amount) - Number(actualAmount)))
      }
    } else if (focusIndex === 3) {
      // 支付金额2
      setPayAmount2(amount)
      setPayAmount(fixAmount(Number(actualAmount) - Number(amount)))
    } else if (focusIndex === 5) {
      // 整单折扣
      handleDiscountChange(Number(amount) > 100 ? 100 : Number(amount))
    } else if (focusIndex === 6) {
      // 积分抵扣
      const price = skuTotalPrice - couponPrice - deductionPrice
      const commissionAvailable = memberDetail.commissionAvailable
      const maxValue = commissionAvailable < price ? commissionAvailable : price
      const minValue = 0
      const newValue =
        Number(amount) > maxValue
          ? maxValue
          : Number(amount) < minValue
          ? minValue
          : Number(amount)
      // 积分不能有小数，所以向上取整
      setCommission(Math.ceil(newValue))
    }
    setFirstClickInputIndex(-1)
  }
  const handleChange = (text) => {
    if (focusIndex < 0) return

    let amount = text

    if ((focusIndex === 2 || focusIndex === 3) && !hasOutAmount) {
      // 不是现金支付
      amount = Number(amount) > Number(actualAmount) ? actualAmount : amount
    }

    if (focusIndex === 1) {
      // 实收总额
      setActualAmount(amount)
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2('0')
      }
    } else if (focusIndex === 2) {
      // 支付金额1
      setPayAmount(amount)
      if (isCombPay) {
        setPayAmount2(fixAmount(Number(actualAmount) - Number(amount)))
      } else if (hasOutAmount) {
        setOutAmount(fixAmount(Number(amount) - Number(actualAmount)))
      }
    } else if (focusIndex === 3) {
      // 支付金额2
      setPayAmount2(amount)
      setPayAmount(fixAmount(Number(actualAmount) - Number(amount)))
    } else if (focusIndex === 5) {
      // 整单折扣
      handleDiscountChange(amount > 100 ? 100 : amount)
    } else if (focusIndex === 6) {
      // 积分抵扣
      const price = skuTotalPrice - couponPrice - deductionPrice
      const commissionAvailable = memberDetail.commissionAvailable
      const maxValue = commissionAvailable < price ? commissionAvailable : price
      const minValue = 0
      const newValue =
        Number(amount) > maxValue
          ? maxValue
          : Number(amount) < minValue
          ? minValue
          : Number(amount)
      // 积分不能有小数，所以向上取整
      setCommission(Math.ceil(newValue))
    }

    setFirstClickInputIndex(-1)
  }
  const handleNumberConfirm = () => {
    setFocusIndex(-1)
    setFirstClickInputIndex(-1)
  }
  const handleConfirm = () => {
    if (loading) {
      return
    }
    if (couponId || commission) {
      const { orderType, orderNo, userId } = props
      props
        .updateOrder({
          orderType,
          orderNo,
          userId,
          userCouponId: couponId,
          commissionDeduct: commission,
        })
        .then((res) => {
          if (res.data) {
            startConfirm()
          } else {
            message.warning('网络繁忙，请重试')
          }
        })
    } else {
      startConfirm()
    }
  }
  const startConfirm = () => {
    if (currentPayType.isScan) {
      dispatch(setOrderStatus(null))
    } else {
      dispatch(setOrderStatus(20))
      handleSubmit()
    }
    // 展示支付状态
    setIsPaying(true)
  }
  const handleSubmit = (authCode) => {
    if (loading) return

    setLoading(true)
    let payChannel = payTypeList[payIndex].value
    const params = {
      authCode,
      payChannel,
      adminIds, // 服务人员
      settleRemark, // 备注
      userCouponId: couponId, // 优惠券Id
      commissionDeduct: commission, // 积分抵扣
      payAmount: actualAmount, // 实收金额
      discountedAmount: payAmount, // 折后金额
      useGoodsDiscount: true,
      useServiceDiscount: true,
      useVipPrice: true,
      userId: props.userId || '',
      orderType: props.orderType || 'STORE',
      orderNo: props.orderNo,
      wholeDiscounted: discount,
    }
    if (isCombPay) {
      payChannel = payTypeList[0].value
      const payChannel2 = currentPayType.value

      // 不相等后端会提示：单次组合支付金额不能与订单金额相等
      params.discountedAmount = params.payAmount
      params.records = [
        {
          payChannel,
          amount: payAmount,
        },
        {
          payChannel: payChannel2,
          amount: payAmount2,
        },
      ]
    }
    props
      .orderPay(params)
      .then((res) => {
        setTimeout(() => {
          setIsPaying(false)
          props.onSuccess && props.onSuccess()
        }, 1000)
        isPrintTicket && startPrintTicket()
      })
      .catch((err) => {
        setIsPaying(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDiscountChange = (value) => {
    setDiscount(value)
  }
  const handleInputFocus = React.useCallback((index) => {
    handleFocusClick(index)
    setFirstClickInputIndex(index)
  }, [])
  /**
   * 打印小票
   */
  const startPrintTicket = () => {
    let newMemberDetail = {
      ...memberDetail,
    }
    if (isCombPay) {
      newMemberDetail.walletBalance = fixAmount(
        Number(memberDetail.walletBalance) - Number(payAmount)
      )
    } else if (currentPayType.isBalance) {
      newMemberDetail.walletBalance = fixAmount(
        Number(memberDetail.walletBalance) - Number(actualAmount)
      )
    }
    // 服务端处理订单比较慢
    setTimeout(() => {
      printer.printByOrderDetail({
        memberDetail: newMemberDetail,
        orderNo: props.orderNo,
      })
    }, 3000)
  }

  const handlePrintTicketCheck = (e) => {
    const checked = e.target.checked
    setIsPrintTicket(checked)
    if (!checked) {
      window.localStorage.setItem(USER_IS_NOT_PRINT, 'true')
    } else {
      window.localStorage.removeItem(USER_IS_NOT_PRINT)
    }
  }
  const checkPrintStatus = () => {
    const printType = printer.getPrintType()
    const userIsNotPrint =
      window.localStorage.getItem(USER_IS_NOT_PRINT) === 'true'
    if (printType === 'app-inside') {
      try {
        const sunmiPrinter = api.require('sunmiPrinter')
        sunmiPrinter.startService(function (ret, err) {
          const status = ret.status || (err && err.msg === '打印机服务已启动')
          setIsPrintTicket(status && !userIsNotPrint)
        })
      } catch (error) {}
    } else {
      const printName = printer.getPrintName()
      printer.checkPrintStatus(printName).then((status) => {
        setIsPrintTicket(status && !userIsNotPrint)
      })
    }
  }

  const handleRecharge = () => {
    setRechargeVisible(true)
  }
  const onRechargeSuccess = () => {
    setRechargeVisible(false)
    setMemberDetailLoading(true)
    props
      .getMemberDetail(props.userId)
      .then((data) => {
        setMemberDetail(data)
      })
      .finally(() => {
        setMemberDetailLoading(false)
      })
  }
  const onCloseRecharge = () => {
    setRechargeVisible(false)
  }
  const handlePayStatusClose = () => {
    setIsPaying(false)
    setLoading(false)
    dispatch(setOrderStatus(null))
  }
  const commissionDiscountAmount = useMemo(
    () => fixAmount(commission),
    [commission]
  )
  const isMember = useMemo(() => !!props.userId, [memberDetail])
  // 预约和寄养需要在收银二次支付时，不能使用优惠抵扣
  const canUseDiscount = useMemo(() => orderAmountType === 1, [orderAmountType])
  useEffect(() => {
    setActualAmount(
      fixAmount(((receivablePrice * discount) / 100.0).toFixed(2))
    )
    setPayAmount(fixAmount(((receivablePrice * discount) / 100.0).toFixed(2)))
    setPayAmount2('0')
  }, [discount, commission, couponPrice])
  useEffect(() => {
    keydownToken && PubSub.unsubscribe(keydownToken)
    keydownToken = PubSub.subscribe('GLOBAL_KEYDOWN', (msg, data) => {
      // 退格键
      if (data.code === 8) {
        handleDelete()
      } else if (data.code === 13) {
        if (focusIndex > 0) {
          // 修改价格
          handleNumberConfirm()
        } else if (isRemarkFocus) {
          // 修改备注
        } else {
          handleConfirm()
        }
      } else if (data.isNumberKey) {
        handleInput(data.text)
      }
    })

    // 监听扫码，用于微信支付宝扫码支付
    //test
    window.__handleSubmit = handleSubmit
    //test
    scanToken && PubSub.unsubscribe(scanToken)
    scanToken = PubSub.subscribe('GLOBAL_SCAN', (msg, data) => {
      const code = data.text
      if (code && isPaying) {
        // 判断扫码是否是当前支付方式
        const payCodeType = checkPayCode(code)
        if (currentPayType.value === payCodeType) {
          handleSubmit(code)
        } else {
          message.error(`请扫描${currentPayType.title}的支付码`)
        }
      }
    })

    return () => {
      keydownToken && PubSub.unsubscribe(keydownToken)
      scanToken && PubSub.unsubscribe(scanToken)
    }
  }, [
    actualAmount,
    payAmount,
    payAmount2,
    payIndex,
    isPaying,
    loading,
    focusIndex,
    isRemarkFocus,
    firstClickInputIndex,
    commission,
  ])

  useEffect(() => {
    // 获取会员详情
    if (props.userId) {
      props
        .getMemberDetail(props.userId)
        .then((data) => {
          setMemberDetail(data)
        })
        .finally(() => {
          setMemberDetailLoading(false)
        })
    } else {
      setMemberDetailLoading(false)
    }
    // 计算金额
    props
      .calcAmount({
        orderNo: props.orderNo,
        orderType: props.orderType,
        userId: props.userId,
        useGoodsDiscount: true,
        useServiceDiscount: true,
        useVipPrice: true,
        wholeDiscounted: discount,
        userCouponId: couponId,
        commissionDeduct: commission,
      })
      .then((res) => {
        const {
          receivablePrice,
          // skuTotalPrice,
          totalPrice,
          deductionPrice,
          couponPrice,
          commissionDeduct,
          type,
        } = res.data
        setCouponPrice(fixAmount(couponPrice)) // 优惠券金额
        setReceivablePrice(receivablePrice) // 应收总额
        setSkuTotalPrice(totalPrice) // 合计总额
        setPayAmount(fixAmount((receivablePrice * discount) / 100)) // 实收总额 = 应收总额 * 整单折扣
        setActualAmount(fixAmount((receivablePrice * discount) / 100)) // 实收总额 = 应收总额 * 整单折扣
        setDeductionPrice(deductionPrice) // 美容卡抵扣
        setOrderAmountType(type)
        // 二次支付的订单，可能在小程序就使用了积分
        if (type === 0 && Number(commission) !== commissionDeduct) {
          setCommission(commissionDeduct)
        }
        if (memeberDiscountAmount <= 0) {
          setMemeberDiscountAmount(
            fixAmount(
              totalPrice -
                receivablePrice -
                deductionPrice -
                couponPrice -
                commission
            )
          )
        }
      })

    // 检查是否连接打票机
    checkPrintStatus()

    if (canUseClinetDisplay()) {
      setClientDisplay(new ClientDisplay())
    }
  }, [couponId, commission])
  useEffect(() => {
    const elem = document.querySelector('.input-con.focus')
    if (elem && focusIndex > 0) {
      let x = elem.getBoundingClientRect().left + elem.offsetWidth * 1.2
      const y = elem.getBoundingClientRect().top - elem.offsetHeight * 1.7
      const w =
        document.querySelector('.number-keyboard-con')?.clientWidth ||
        window.innerWidth / 4.268
      if (x + w > document.documentElement.clientWidth) {
        x = x - w - elem.clientWidth - 20
        setIsNumberKeyboardRight(false)
      } else {
        setIsNumberKeyboardRight(true)
      }
      setNumberKeyboardPosition({
        x,
        y,
      })
    } else {
      setNumberKeyboardPosition({
        x: -9999,
        y: -9999,
      })
    }
    // setIsShowNumberKeyboard(focusIndex > 0)
  }, [focusIndex])

  useEffect(() => {
    clientDisplay &&
      clientDisplay.open().then(() => {
        console.log('客显串口已连接')
      })
    return () => {
      clientDisplay && clientDisplay.clear()
    }
  }, [clientDisplay])

  useEffect(() => {
    if (clientDisplay) {
      clientDisplay.setPrice(actualAmount)
      clientDisplay.setType(3)
    }
  }, [actualAmount])

  useEffect(() => {
    /**
     * 是否包含className
     * @param {element} e click返回的元素对象
     * @param {string} classNames 类名
     * @returns
     */
    function hasClassNameElem(e, classNames) {
      let elem = e.target
      let res = false
      while (elem && elem.className && typeof elem.className === 'string') {
        if (classNames.some((item) => elem.className.indexOf(item) > -1)) {
          res = true
          break
        }
        elem = elem.parentNode
      }
      return res
    }
    function handleDocumentClick(e) {
      if (!hasClassNameElem(e, ['input-con', 'number-keyboard-con'])) {
        setFocusIndex(0)
        setFirstClickInputIndex(0)
      }
    }
    document.addEventListener('click', handleDocumentClick, false)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  return (
    <>
      <div className="cash-con">
        <div className="block-con">
          <div>
            <Spin spinning={memberDetailLoading}>
              <div className="member-con">
                <MemberCard
                  detail={memberDetail}
                  handleRecharge={handleRecharge}
                  isShowCommission={true}
                  hasRecharge={true}
                />
              </div>
            </Spin>
          </div>
          <div className="bill-list">
            <div className="bill-line">
              <div className="bill-title">合计总额</div>
              <div className="bill-value">¥{skuTotalPrice}</div>
            </div>
            {deductionPrice ? (
              <div className="bill-line">
                <div className="bill-title">美容卡抵扣</div>
                <div className="bill-value discount">-¥{deductionPrice}</div>
              </div>
            ) : null}
            {memeberDiscountAmount > 0 ? (
              <div className="bill-line">
                <div className="bill-title">会员优惠</div>
                <div className="bill-value discount">
                  -¥{memeberDiscountAmount}
                </div>
              </div>
            ) : null}
            {isMember && !isCountCard && canUseDiscount && (
              <div className="bill-line">
                <div className="bill-title">优惠券</div>
                <div
                  className="bill-value error-color"
                  onClick={() => setCouponVisible(true)}
                  style={{ cursor: 'pointer' }}
                >
                  {couponPrice ? (
                    <span>-¥{couponPrice} </span>
                  ) : (
                    <span className="grey-color">未选择</span>
                  )}
                  <span>
                    <Icon style={{ fontSize: 14 }} type="iconxiangyou1" />
                  </span>
                </div>
              </div>
            )}
            {isMember && !isCountCard && canUseDiscount && (
              <div className="bill-line">
                <div className="bill-title">积分抵扣</div>
                <div className="__df">
                  <div
                    className={`discount input-con ${
                      focusIndex === 6 && 'focus'
                    } ${firstClickInputIndex === 6 && 'first-click'}`}
                    onClick={() => handleInputFocus(6)}
                  >
                    <div className="input">{commission}</div>
                  </div>
                  <span className="primary-color" style={{ marginLeft: 6 }}>
                    可抵扣 ¥{commissionDiscountAmount}
                  </span>
                </div>
              </div>
            )}
            <div className="bill-hr"></div>
            <div className="bill-line bold">
              <div className="bill-title">应收总额</div>
              <div className="bill-value">¥{receivablePrice}</div>
            </div>
            {canUseDiscount && (
              <div className="bill-line bold">
                <div className="bill-title">整单折扣</div>
                <div
                  className={`discount input-con ${
                    focusIndex === 5 && 'focus'
                  } ${firstClickInputIndex === 5 && 'first-click'}`}
                  onClick={() => handleInputFocus(5)}
                >
                  <div className="input">{discount}</div>
                  <span className="unit">%</span>
                </div>
              </div>
            )}
          </div>
          <div className="bottom-con">
            <div className="grid-con">
              <div className="line-con">
                <div className="item" onClick={(e) => handleFocusClick(1, e)}>
                  <div
                    className="title"
                    style={{ fontWeight: 'bold', color: '#000' }}
                  >
                    实收总额
                  </div>
                  <div
                    className={`input-con ${focusIndex === 1 && 'focus'} ${
                      firstClickInputIndex === 1 && 'first-click'
                    }`}
                  >
                    <span className="unit">¥</span>
                    <div className="input">{actualAmount}</div>
                  </div>
                </div>
                <div className="item" onClick={(e) => handleFocusClick(2, e)}>
                  <div className="title">
                    {isCombPay ? '余额支付' : currentPayType.title}
                  </div>
                  <div
                    className={`input-con ${focusIndex === 2 && 'focus'} ${
                      firstClickInputIndex === 2 && 'first-click'
                    }`}
                  >
                    <span className="unit">¥</span>
                    <div className="input">{payAmount}</div>
                  </div>
                </div>
                {isCombPay && (
                  <div className="item" onClick={(e) => handleFocusClick(3, e)}>
                    <div className="title">{currentPayType.title}</div>
                    <div
                      className={`input-con ${focusIndex === 3 && 'focus'} ${
                        firstClickInputIndex === 3 && 'first-click'
                      }`}
                    >
                      <span className="unit">¥</span>
                      <div className="input">{payAmount2}</div>
                    </div>
                  </div>
                )}
                <div className="item out-amount">
                  <div className="title">找零</div>
                  <div className="input-con">
                    <span className="unit">¥</span>
                    <div className="input">{outAmount}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pay-type-con">
              <div className="pay-type-title-list">
                <div className="title">
                  支付方式
                </div>
              </div>
              <div
                className={`pay-type ${isCombPay ? 'is-comb' : ''} ${
                  isCountCard ? 'is-countcard' : ''
                }`}
              >
                {payTypeList.map((item, index) =>
                  isCountCard && item.value === 'BALANCE' ? null : (
                    <>
                      <div
                        className={`pay-item-con ${
                          index === 0 && !props.userId ? 'disabled' : ''
                        } pay-item-con-${item.value}`}
                        key={item.value}
                        onClick={() => handlePayIndexClick(index)}
                      >
                        <div
                          className={`pay-item ${
                            (payIndex === index ||
                              (isCombPay && index === 0)) &&
                            'selected'
                          }`}
                        >
                          <Icon className="icon" type={item.icon} />
                          <span>{item.title}</span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
            <div className="remark-con">
              <span className="title">备注</span>
              <Input.TextArea
                className="textarea"
                placeholder="请输入备注，可输入100字"
                maxLength={100}
                value={settleRemark}
                onChange={(e) => setSettleRemark(e.target.value)}
                onFocus={() => setIsRemarkFocus(true)}
                onBlur={() => setIsRemarkFocus(false)}
                autoSize
              ></Input.TextArea>
            </div>
            <div
              className={`number-keyboard-con ${
                isNumberKeyboardRight && 'right'
              }`}
              style={{
                top: numberKeyboardPosition.y,
                left: numberKeyboardPosition.x,
              }}
            >
              <NumberKeyboard
                handleInput={handleInput}
                handleDelete={handleDelete}
                handleConfirm={handleNumberConfirm}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="footer-con">
          {/* <Checkbox checked={isPrintTicket} onChange={handlePrintTicketCheck}>
            打印小票
          </Checkbox> */}
          <div className="r">
            <div className="amount">
              实收总额 <span>¥{actualAmount}</span>
            </div>
            <Button type="primary" className="btn" onClick={handleConfirm}>
              完成结算
            </Button>
          </div>
        </div>
        <PayStatusModal visible={isPaying} handleClose={handlePayStatusClose} />
        {/* 余额充值 */}
        <Drawer
          title="余额充值"
          placement="right"
          onClose={onCloseRecharge}
          destroyOnClose={true}
          width="auto"
          visible={rechargeVisible}
        >
          <Recharge userId={props.userId} onSuccess={onRechargeSuccess} />
        </Drawer>
      </div>
      <CouponDrawer
        userId={props.userId}
        couponId={couponId}
        orderType={props.orderType}
        orderNo={props.orderNo}
        skuList={props.deskList}
        onChange={onCouponChange}
        visible={couponVisible}
        onCancel={() => setCouponVisible(false)}
      />
    </>
  )
}

Cash.propTypes = {
  userId: PropTypes.number,
  orderType: PropTypes.string,
  orderNo: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  deskList: PropTypes.array,
}

Cash.defaultProps = {
  userId: '',
  orderType: 'STORE',
  deskList: [],
}

export default connect(
  (state) => ({
    printInfo: state.user.printInfo,
  }),
  {
    getMemberDetail,
    orderPay,
    calcAmount,
    getAdminAll,
    getCrsOrderDetail,
    getBoardOrderDetail,
    getAppointOrderDetail,
    getSettlementOrderDetail,
    updateOrder,
  }
)(Cash)
