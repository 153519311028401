import React, { useState, useEffect, useContext } from 'react'
import Icon from '../../../Icon'
import { Row, Col, Button, Checkbox, Drawer, Image, Space } from 'antd'
import {
  getMemberHistory,
  setISMember,
  setMemberDetail,
} from '@/redux/actions/home'
import { addAsyncGoodsList } from '@/redux/actions/goods'
import { getMemberDetail } from '@/redux/actions/user'
import ShoppingHistory from '@/components/Member/ShoppingHistory'
import Recharge from '@/components/Member/Recharge'
import Detail from '@/components/Member/Detail'
import { connect } from 'react-redux'
import './memberDetail.less'
import defalutGoodImg from '@/assets/home/goods.png'
import defalutPeopleImg from '@/assets/member/people.png'
import MemberPetEdit from '@/components/Member/PetEdit'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import { HomeContext } from '@/components/Home/context-manager'
import TimeCardDetail from '@/components/Home/TimeCardDetail'

export function MemberDetail(props) {
  // 是否选中会员
  const [checked, setChecked] = useState(true)
  // 会员详情
  const [memberDetail, setMemberDetail] = useState({})
  // 会员一个月历史list
  const [memberHistoryList, setMemberHistoryList] = useState([])
  // 显示全部会员历史list
  const [historyVisible, setHistoryVisible] = useState(false)
  // 余额充值
  const [rechargeVisible, setRechargeVisible] = useState(false)
  // 会员详情明细
  const [memberDetailedVisible, setMemberDetailedVisible] = useState(false)
  const [isShowCreatePet, setIsShowCreatePet] = useState(false)
  const [historyMemberLoading, setHistoryMemberLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const [timeCardDetailVisible, setTimeCardDetailVisible] = useState(false)
  const [timeCardType, setTimeCardType] = useState('base')

  const { setDisableSearchScan, setIsAddItem, setUserClear } =
    useContext(HomeContext)
  // close
  const onClose = () => {
    props.onClose()
    setUserClear(true)
  }
  // 查询详情
  const getMemberDetail = () => {
    props.getMemberDetail(props.userId).then((data) => {
      props.setMemberDetail(data)
      setMemberDetail(data)
      props.setISMember(true)
    })
  }
  // 查询历史
  const getMemberHistory = () => {
    setMemberHistoryList([])
    setHistoryMemberLoading(true)
    setHasMore(true)
    props
      .getMemberHistory({
        userId: props.userId,
      })
      .then((res) => {
        const skus = (res && res.skus) || []
        setMemberHistoryList(skus)
      })
      .finally(() => {
        setHistoryMemberLoading(false)
        setHasMore(false)
      })
  }
  //
  const onAddDeskList = (item) => {
    const newItem = { ...item }
    newItem.discountedPrice = item.price
    newItem.num = 1
    newItem.subtotal = item.price * 1
    props.addAsyncGoodsList(newItem)
    setIsAddItem(true)
  }
  // 是否选中会员
  const onChangeCheckbox = (e) => {
    const checked = e.target.checked
    props.setISMember(checked)
    setChecked(checked)
    if (!checked) {
      setUserClear(true)
    } else {
      setUserClear(null)
    }
  }
  // 余额充值
  const onRechanrge = () => {
    setRechargeVisible(true)
    setDisableSearchScan(true)
  }

  const onSuccess = () => {
    setRechargeVisible(false)
    setDisableSearchScan(false)
    init()
  }

  const onCloseRecharge = () => {
    setRechargeVisible(false)
    setDisableSearchScan(false)
  }
  const handleShowCreatePet = () => {
    setIsShowCreatePet(true)
  }
  const onCreatePetSuccess = () => {
    setIsShowCreatePet(false)
  }
  // 开通美容卡
  const openCard = () => {
    setTimeCardType('base')
    setTimeCardDetailVisible(true)
  }
  const handleCloseCard = () => {
    setTimeCardDetailVisible(false)
    init()
  }

  const init = () => {
    getMemberDetail()
    getMemberHistory()
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <div className="m-detail">
      <nav className="title">
        <span className="t">会员信息</span>
        <Icon className="icon" onClick={onClose} type="iconguanbi1" />
      </nav>
      {/* mian */}
      <main className="main">
        <div className="user">
          <Image
            className="user-avatar"
            src={memberDetail.avatarUrl || defalutPeopleImg}
            preview={false}
            fallback={defalutPeopleImg}
          />
          <div className="info">
            <h2
              className="info_t"
              title={memberDetail.realname || memberDetail.nickname}
            >
              {memberDetail.realname || memberDetail.nickname}
            </h2>
            <p className="info_p">
              <span>{memberDetail.phone}</span>
              <span className="level">
                {memberDetail.levelName && `（${memberDetail.levelName}）`}
              </span>
            </p>
          </div>
        </div>
        <Row className="deinfo">
          <Col span={12} className="col">
            微信昵称
          </Col>
          <Col span={12} className="col">
            {memberDetail.nickname ? (
              memberDetail.nickname
            ) : (
              <span style={{ color: '#767b84' }}>未绑定</span>
            )}
          </Col>
          <Col span={12} className="col">
            积分
          </Col>
          <Col span={12} className="col">
            {memberDetail.commissionAvailable}
          </Col>
          <Col span={12} className="col">
            余额
          </Col>
          <Col span={12} className="col">
            ¥{memberDetail.walletBalance || 0}
          </Col>
          <Col span={24} style={{ display: 'flex' }}>
            <Space>
              <Button
                type="primary"
                className="btn"
                onClick={openCard}
              >
                开通美容卡
              </Button>
              <Button
                type="primary"
                className="btn"
                onClick={onRechanrge}
              >
                余额充值
              </Button>
            </Space>
          </Col>
        </Row>
        <div className="bod"></div>
        <div className="history">
          <h2 className="t">最近三十天消费记录</h2>
          <div className="list">
            <InfiniteScrollList
              className="list"
              hasMore={hasMore}
              handleOnLoad={() => ({})}
              loading={historyMemberLoading}
            >
              {memberHistoryList.map((iv) => (
                <div
                  className="item"
                  key={iv.id}
                  onClick={() => onAddDeskList(iv)}
                >
                  <Image
                    className="pet-img"
                    src={iv.img || defalutGoodImg}
                    preview={false}
                    fallback={defalutGoodImg}
                  />
                  <div className="item-r">
                    <h3
                      className="supTitle"
                      title={`${iv.spuTitle} ${iv.title}`}
                    >{`${iv.spuTitle} ${iv.title}`}</h3>
                    <div className="r_info">
                      <span className="price">¥{iv.price || 0}</span>
                      <div>
                        {iv.type !== 'SERVICE' ? (
                          <span
                            className={`stock ${iv.stock < 0 ? 'minus' : ''}`}
                          >
                            库存&nbsp;{iv.stock}
                          </span>
                        ) : (
                          <></>
                        )}
                        &nbsp;
                        <Icon
                          className="stock-add-btn"
                          type="iconzengjia"
                        ></Icon>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScrollList>
          </div>
        </div>
      </main>
      <Row className="footer">
        <Col span={6} className="col">
          <Button
            type="text"
            className="btn"
            onClick={() => setMemberDetailedVisible(true)}
          >
            详情
          </Button>
        </Col>
        <Col span={6} className="col" onClick={() => setHistoryVisible(true)}>
          <Button type="text" className="btn">
            历史
          </Button>
        </Col>
        <Col span={12} className="col">
          <Checkbox onChange={onChangeCheckbox} checked={checked}>
            选中会员
          </Checkbox>
        </Col>
      </Row>
      {/* 会员历史 */}
      <Drawer
        title="会员消费历史"
        placement="right"
        width="auto"
        destroyOnClose={true}
        onClose={() => setHistoryVisible(false)}
        visible={historyVisible}
      >
        <ShoppingHistory userId={props.userId} />
      </Drawer>
      {/* 余额充值 */}
      <Drawer
        title="余额充值"
        placement="right"
        onClose={onCloseRecharge}
        destroyOnClose={true}
        width="auto"
        visible={rechargeVisible}
      >
        <Recharge userId={props.userId} onSuccess={onSuccess} />
      </Drawer>
      {/* 会员明细 */}
      <Drawer
        title="会员详情"
        placement="right"
        onClose={() => setMemberDetailedVisible(false)}
        destroyOnClose={true}
        width="auto"
        visible={memberDetailedVisible}
      >
        <Detail userId={props.userId} />
      </Drawer>
      {/* 美容卡详情 */}
      <Drawer
        title="美容卡详情"
        placement="right"
        width="auto"
        onClose={handleCloseCard}
        visible={timeCardDetailVisible}
        destroyOnClose={true}
      >
        <TimeCardDetail type={timeCardType} />
      </Drawer>
      <Drawer
        title="添加宠物档案"
        placement="right"
        width="auto"
        onClose={() => setIsShowCreatePet(false)}
        visible={isShowCreatePet}
        destroyOnClose={true}
      >
        <MemberPetEdit
          handleCancel={() => setIsShowCreatePet(false)}
          onSuccess={onCreatePetSuccess}
          userId={props.userId}
        />
      </Drawer>
    </div>
  )
}

export default connect((state) => ({}), {
  getMemberDetail,
  getMemberHistory,
  setISMember,
  setMemberDetail,
  addAsyncGoodsList,
})(MemberDetail)
