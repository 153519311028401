import request from '../../utils/request'
import { store } from '@/redux/store'
import { SET_ORDER_STATUS } from '../constant'
const area = '/crs'
export const setOrderStatus = (data) => ({ type: SET_ORDER_STATUS, data })

/**
 * 获取会员消费记录
 * @param {*} params
 * @returns
 */
export const getListUserConsumeHistory = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/baseOrder/listUserConsumeHistory`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询物流详情
 * @param {*} params
 * @returns
 */
export const getFreightDetail = ({ orderNo }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsOrder/detail/freight/${orderNo}`,
        method: 'get',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询收银订单详情
 * @param {*} params
 * @returns
 */
export const getCrsOrderDetail = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsOrder/getDetail`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询寄养订单详情
 * @param {*} params
 * @returns
 */
export const getBoardOrderDetail = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/boardOrder/detail`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询预约订单详情
 * @param {*} params
 * @returns
 */
export const getAppointOrderDetail = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointOrder/v2/detail`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * 删除商品订单
 * @returns
 */
export const deleteStoreOrder = (data) => () =>
  request({
    url: `${area}/crsOrder/deleteOrder`,
    method: 'post',
    data,
  })
/**
 * 查询寄养订单列表
 * @param {*} params
 * @returns
 */
export const getBoardOrderPageList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/boardOrder/pageList`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询取单模块的订单
 * @param {*} params
 * @returns
 */
export const getCrsPendingOrderPageList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsPendingOrder/pageList`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * 挂单
 * @param {*} params
 * @returns
 */
export const getCrsPendingOrderCreate = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsPendingOrder/create`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 处理支付结果
 */
const dealPayResult = (res, successCallback, errorCallback, dispatch) => {
  const { status } = res.data
  if (status === 20) {
    dispatch(setOrderStatus(status))
    getOrderInfo(res.data.payReqNo, (res) => {
      dispatch(setOrderStatus(res.data.status))
      successCallback(res)
    })
  } else if (status === 30) {
    // 支付成功
    dispatch(setOrderStatus(status))
    successCallback()
  } else {
    // 支付失败
    dispatch(setOrderStatus(null))
    errorCallback()
  }
}

/**
 * 会员充值
 * @param {*} params
 * @returns
 */
export const rechargeAmount = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // 支付中
      dispatch(setOrderStatus(20))
      request({
        url: `${area}/recharge/crs`,
        method: 'post',
        data,
      })
        .then((res) => {
          dealPayResult(
            res,
            () => {
              resolve(res)
            },
            (errorRes) => {
              reject(errorRes)
            },
            dispatch
          )
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * 取单/修改
 * @param {*} params
 * @returns
 */
export const getCrsPendingOrderUpdate = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsPendingOrder/update`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 订单支付
 * @param {*} params
 * @returns
 */
export const orderPay = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // 支付中
      dispatch(setOrderStatus(20))
      const postName =
        Array.isArray(data.records) && data.records.length
          ? 'combinedPay'
          : 'singlePay'
      request({
        url: `/crs/crsOrder/pay/${postName}`,
        method: 'post',
        data,
      })
        .then((res) => {
          dealPayResult(
            res,
            (successRes) => {
              resolve(successRes)
            },
            (errorRes) => {
              reject(errorRes)
            },
            dispatch
          )
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 取单/删除
 * @param {*} params
 * @returns
 */
export const getCrsPendingOrderDelete = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsPendingOrder/delete/${id}`,
        method: 'post',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 查询订单信息
 * @param {*} payReqNo 支付订单编号
 * @returns
 */
export const getOrderInfo = (payReqNo, successCallback, errorCallback) => {
  request({
    url: `/crs/crsOrder/pay/getPayResult?payReqNo=${payReqNo}`,
    method: 'get',
  })
    .then((res) => {
      const { status } = res.data
      if (status === 20) {
        // 退出支付页面，orderStatus为null
        if (store.getState().order.orderStatus === null) {
          return
        }
        // 支付中，继续轮询
        setTimeout(() => {
          getOrderInfo(payReqNo, successCallback, errorCallback)
        }, 3000)
      } else if (status === 30) {
        // 支付成功
        successCallback && successCallback(res)
      } else {
        // 支付失败
        errorCallback && errorCallback(res)
      }
    })
    .catch((err) => {
      errorCallback && errorCallback(err)
    })
}

/* 结算
 * @param {*} params
 * @returns
 */
export const getCrsOrderCreateOrder = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/crsOrder/createOrderNew`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 获取修改的商品价格
 * @param {*} params
 * @returns
 */
export const getCrsOrderGetCalculateAmount = (data) => () =>
  request({
    url: `${area}/crsOrder/getCalculateAmount`,
    method: 'post',
    data,
  })

/* 获取订单金额
 * @param {*} params
 * @returns
 */
export const calcAmount = (data) => () =>
  request({
    url: `${area}/crsOrder/calcDiscountOrder`,
    method: 'post',
    data,
  })

/* 获取寄养订单金额
 * @param {*} params
 * @returns
 */
export const calcBoardOrderAmount = (data) => () =>
  request({
    url: `${area}/boardOrder/calcOrderAmount`,
    method: 'post',
    data,
  })
/* 寄养延期
 * @param {*} params
 * @returns
 */
export const postponeBoard = (data) => () =>
  request({
    url: `${area}/boardOrder/postponeBoard`,
    method: 'put',
    data,
  })
/* 提交寄养订单
 * @param {*} params
 * @returns
 */
export const takeBoardOrder = (data) => () =>
  request({
    url: `${area}/boardOrder/takeOrder`,
    method: 'post',
    data,
  })
/**
 * 取消寄养订单
 * @param {*} params
 * @returns
 */
export const cancelBoardOrder = (params) => () =>
  request({
    url: `${area}/boardOrder/cancel`,
    method: 'get',
    params,
  })
/**
 * 删除寄养订单
 * @param {*} params
 * @returns
 */
export const deleteBoardOrder = (params) => () =>
  request({
    url: `${area}/boardOrder/adminDelete`,
    method: 'get',
    params,
  })
/**
 * 开始寄养订单
 * @param {*} params
 * @returns
 */
export const startBoardOrder = (data) => () =>
  request({
    url: `${area}/boardOrder/startBoard`,
    method: 'post',
    data,
  })
/**
 * 结束寄养订单
 * @param {*} params
 * @returns
 */
export const endBoardOrder = (data) => () =>
  request({
    url: `${area}/boardOrder/endBoard`,
    method: 'post',
    data,
  })
/**
 * 添加寄养动态
 * @param {*} params
 * @returns
 */
export const boardDynamicAdd = (data) => () =>
  request({
    url: `${area}/board/dynamic/add`,
    method: 'post',
    data,
  })
/**
 * 获取寄养动态
 * @param {*} params
 * @returns
 */
export const getBoardDynamicList = (params) => () =>
  request({
    url: `${area}/board/dynamic/get`,
    method: 'get',
    params,
  })
/**
 * 删除寄养动态
 * @param {*} params
 * @returns
 */
export const deleteBoardDynamicList = (params) => () =>
  request({
    url: `${area}/board/dynamic/del`,
    method: 'get',
    params,
  })
/**
 * 退款详情信息
 * @param {*} params
 * @returns
 */
export const refundFrameInfo = (params) => () =>
  request({
    url: `${area}/refund/refundFrameInfo`,
    method: 'get',
    params,
  })

/**
 * 获取寄养日历数据
 * @param {*} params
 * @returns
 */
export const getCalendarData = (params) => () =>
  request({
    url: `${area}/boardOrder/getCalendarData`,
    method: 'get',
    params,
  })

/**
 * 寄养订单退款
 * @param {*} params
 * @returns
 */
export const refundBoardOrder = (data) => () =>
  request({
    url: `${area}/refund/accord`,
    method: 'post',
    data,
  })

/* 获取预约订单
 * @param {*} params
 * @returns
 */
export const getNowDayAppointInfo = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointOrder/getNowDayAppointInfo`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 获取预约list订单
 * @param {*} params
 * @returns
 */
export const getAppointOrderPageListNew = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointOrder/pageListNew`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 获取预约满的时间
 * @param {*} params
 * @returns
 */
export const getAppointOrderDate = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointOrder/getAppointmentDate`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 获取预约订单金额
 * @param {*} params
 * @returns
 */
export const calcAppointOrderAmount = (data) => () =>
  request({
    url: `${area}/appointOrder/calcOrderAmount`,
    method: 'post',
    data,
  })
/* 提交预约订单
 * @param {*} params
 * @returns
 */
export const takeAppointOrder = (data) => () =>
  request({
    url: `${area}/appointOrder/appointTakeOrder`,
    method: 'post',
    data,
  })
/* 获取预约时间
 * @param {*} params
 * @returns
 */
export const getAppointmentTime = (params) => () =>
  request({
    url: `${area}/appointOrder/getAppointmentTime`,
    method: 'get',
    params,
  })
/* 修改服务店员
 * @param {*} params
 * @returns
 */
export const updateBonusAdmin = (data) => () =>
  request({
    url: `${area}/bonus/updateBonusAdmin`,
    method: 'post',
    data,
  })
/* 取消预约订单
 * @param {*} params
 * @returns
 */
export const appointOrderCancel = (data) => () =>
  request({
    url: `${area}/appointOrder/cancel`,
    method: 'post',
    data,
  })
/* 预约核销
 * @param {*} params
 * @returns
 */
export const appointOrderConfirm = (data) => () =>
  request({
    url: `${area}/appointOrder/confirm`,
    method: 'post',
    data,
  })
/* 预约退款
 * @param {*} params
 * @returns
 */
export const appointRefundAccord = (data) => () =>
  request({
    url: `${area}/refund/accord`,
    method: 'post',
    data,
  })
/* 预约删除
 * @param {*} params
 * @returns
 */
export const appointOrderDelete = (params) => () =>
  request({
    url: `${area}/appointOrder/delete`,
    method: 'get',
    params,
  })
/* 获取用户绑定的美容卡
 * @param {*} params
 * @returns
 */

export const getDeductionUserList = (data) => () =>
  request({
    url: `${area}/deduction/user/list`,
    method: 'POST',
    data,
  })
/* 获取没绑定的美容卡
 * @param {*} params
 * @returns
 */
export const getDeductionCardList = (data) => () =>
  request({
    url: `${area}/deduction/card/listUser`,
    method: 'POST',
    data,
  })
/* 根据服务获取可抵扣的美容卡
 * @param {*} params
 * @returns
 */
export const getDeductionUserUseCard = (params) => () =>
  request({
    url: `${area}/deduction/user/get/use/card`,
    method: 'GET',
    params,
  })
/* 开通美容卡
 * @param {*} params
 * @returns
 */
export const deductionUserCreate = (params) => () =>
  request({
    url: `${area}/deduction/user/create`,
    method: 'GET',
    params,
  })
/* 退卡
 * @param {*} params
 * @returns
 */
export const deductionRefundBack = (data) => () =>
  request({
    url: `${area}/deduction/user/back`,
    method: 'POST',
    data,
  })
/* 查看服务skuId是否有美容卡
 * @param {*} params
 * @returns
 */
export const deductionCardService = (data) => () =>
  request({
    url: `${area}/deduction/card/listUseService`,
    method: 'POST',
    data,
  })
/* 扣卡记录
 * @param {*} params
 * @returns
 */
export const deductionRecordlist = (data) => () =>
  request({
    url: `${area}/deduction/record/list`,
    method: 'POST',
    data,
  })
/* 优惠券列表
 * @param {*} params
 * @returns
 */
export const couponList =
  ({ userId, ...data }) =>
  () =>
    request({
      url: `${area}/crsOrder/chooseCoupon/${userId}`,
      method: 'POST',
      data,
    })
export const updateOrder = (data) => () =>
  request({
    url: `${area}/crsOrder/updateOrder`,
    method: 'POST',
    data,
  })
/* 余额充值套餐list
 * @param {*} params
 * @returns
 */
export const getRechargeList = (data) => () =>
  request({
    url: `${area}/recharge/list`,
    method: 'POST',
    data,
  })
/* 余额订单查询
 * @param {*} params
 * @returns
 */
export const getRechargeDetail = (params) => () =>
  request({
    url: `${area}/crsOrder/getRechargeDetail`,
    method: 'GET',
    params,
  })

// 预约计算金额---新
export const calculatePrice = (data) => () =>
  request({
    url: `${area}/appointOrder/v2/calcOrderAmount`,
    method: 'POST',
    data,
  })

// 提交订单--新
export const submitOrder = (data) => () =>
  request({
    url: `${area}/appointOrder/v2/appointTakeOrder`,
    method: 'POST',
    data,
  })

// 获取美容卡 列表
export const getCardList = (data) => () =>
  request({
    url: `${area}/deduction/user/get/use/cards`,
    method: 'POST',
    data,
  })
