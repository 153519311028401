import React, { useState, useEffect } from 'react'
import './appointment.less'
import { Tabs, Modal, Button, Space, Drawer, message } from 'antd'
import Icon from '@/components/Icon'
import Cash from '@/components/Cash'
import ACalendar from '@/components/Appointment/Calendar'
import AAdjust from '@/components/Appointment/Adjust'
import AppointList from '@/components/Appointment/List'
import OrderCheckIn from '@/components/OrderCheckIn'
import AppointCreate from '@/components/Appointment/Create'

import Serve from '@/components/Appointment/Serve'
import {
  updatePeopleNumByDetailId,
  AddBacklog,
  getBacklogList,
} from '@/redux/actions/user'
import {
  getNowDayAppointInfo,
  appointOrderConfirm,
  getAppointOrderDetail,
} from '@/redux/actions/order'
import { setParams } from '@/redux/actions/appointment'

import moment from 'moment'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
const { TabPane } = Tabs

function Appointment(props) {
  let onChangeDate
  // tabIndex
  const [tabType, setTabType] = useState('calendar')
  const [cashVisible, setCashVisible] = useState(false)
  // 单日预约调整
  const [adjustVisible, setAdjustVisible] = useState(false)
  const [adjustLoading, setAdjustLoading] = useState(false)
  const [orderDetail, setOrderDetail] = useState({})
  const [deskList, setDeskList] = useState([])
  // 添加预约
  const [addAppointVisible, setAddAppointVisible] = useState(false)
  // 添加待办
  const [addServeVisible, setAddServeVisible] = useState(false)

  const [paramsAdjust, setParamsAdjust] = useState()
  const [dateTime, setDateTime] = useState()
  const [timeLists, setTimeLists] = useState([
    {
      label: '今天',
      value: moment(new Date()).format('YYYY-MM-DD'),
    },
    {
      label: '明天',
      value: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
    },
    {
      label: '后天',
      value: moment(new Date()).add(2, 'days').format('YYYY-MM-DD'),
    },
    {
      label: '',
      value: -1,
    },
  ])
  const [timeListIndex, setTimeListIndex] = useState(0)
  const [appointList, setAppointList] = useState([])
  const [appointListLoading, setAppointListLoading] = useState(false)
  // 服务核销
  const [checkInVisible, setCheckInVisible] = useState(false)
  const history = useHistory()
  const [formData, setFormData] = useState(false)
  const onFormChange = (form) => {
    setFormData(form)
  }

  const onAddServer = (form) => {
    let temp = formData.getFieldsValue()
    if (!temp.backlogTime) {
      message.warning('请选择时间')
      return
    }
    temp = {
      ...temp,
      backlogTime: temp.backlogTime.format('YYYY-MM-DD HH:mm:ss'),
    }
    props
      .AddBacklog(temp)
      .then((res) => {
        setAddServeVisible(false)
        getNowDayAppointInfo(timeLists[timeListIndex].value)
      })
      .finally(() => {
        setAdjustLoading(false)
      })
  }
  const onTabs = (val) => {
    if (val === 'calendar') {
      getNowDayAppointInfo(timeLists[timeListIndex].value)
    }
    setTabType(val)
  }
  onChangeDate = (val) => {
    setDateTime(val)
    if (!val) return
    getNowDayAppointInfo(val.format('YYYY-MM-DD'))
  }
  // 单日预约调整
  const handleAdjustOk = () => {
    setAdjustLoading(true)
    props
      .updatePeopleNumByDetailId(paramsAdjust)
      .then((res) => {
        getNowDayAppointInfo(timeLists[timeListIndex].value)
        handleAdjustCancel()
        message.success('调整成功')
      })
      .finally(() => {
        setAdjustLoading(false)
      })
  }
  const handleAdjustCancel = () => {
    setAdjustVisible(false)
  }
  // 查询
  const getNowDayAppointInfo = (dateStr) => {
    if (dateStr === -1) dateStr = dateTime.format('YYYY-MM-DD')
    setAppointListLoading(true)
    props
      .getNowDayAppointInfo({
        dateStr,
      })
      .then((res) => {
        const list = res.data || []
        setAppointList(list)
      })
      .finally(() => {
        setAppointListLoading(false)
      })
  }
  // 防抖
  const debounce = (fn, delay) => {
    let timer = null
    return function () {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn()
      }, delay)
    }
  }
  //
  const onClose = () => {
    setAddAppointVisible(false)
  }
  const onSuccess = () => {
    setAddAppointVisible(false)
    refreshData()
  }

  const tabSlot = (
    <Space>
      <Button
        type="primary"
        ghost
        icon={<Icon className="icon a" type="icontianjia" />}
        onClick={() => setAddAppointVisible(true)}
      >
        添加预约
      </Button>
      &nbsp;
      <Button
        type="primary"
        icon={<Icon className="icon" type="iconhexiaofuwu1" />}
        onClick={() => {
          setCheckInVisible(true)
        }}
      >
        服务核销
      </Button>
      {/* <Button
        type="primary"
        ghost
        icon={<Icon className="icon a" type="icontianjia" />}
        onClick={() => setTaskVisible(true)}
      >
        待办详情
      </Button> */}
    </Space>
  )

  const onSearch = (val) => {
    setTabType('list')
    props.setParams({
      keyWord: val
    })
  }
  const refreshData = () => {
    // 刷新数据
    console.log('刷新数据')
    if (tabType === 'calendar') {
      getNowDayAppointInfo(timeLists[timeListIndex].value)
    } else {
      props.setParams({
        ...props.params
      })
    }
  }

  // 服务核销
  const onSubmit = (barCode, callback) => {
    if (!barCode) return

    barCode = barCode.replace(/F?W|\dW/g, '')
    props
      .appointOrderConfirm({ barCode })
      .then((res) => {
        const data = res.data
        setTabType('list')
        props.setParams({
          keyWord: data.orderNo,
          orderSource: data.orderSource
        })
        if (data.orderType === 0) {
          message.success('核销成功')
        } else {
          props
            .getAppointOrderDetail({
              orderNo: data.orderNo,
            })
            .then((res) => {
              setOrderDetail(res.data)
              setDeskList(
                res.data.appointDetailSkuV2VOList.map((item) => ({
                  skuId: item.skuId,
                  num: 1,
                }))
              )
              setCashVisible(true)
            })
        }
        setCheckInVisible(false)
      })
      .finally(() => {
        callback && callback()
      })
  }
  const handleCashClose = () => {
    setCashVisible(false)
  }

  useEffect(() => {
    if (window.onresize) {
      window.onresize = null
    }
    window.onresize = debounce(() => {
      getNowDayAppointInfo(timeLists[timeListIndex].value)
    }, 400)
    return () => {
      if (window.onresize) {
        window.onresize = null
      }
    }
  }, [])

  useEffect(() => {
    if (timeListIndex !== timeLists.length - 1) {
      setDateTime('')
      getNowDayAppointInfo(timeLists[timeListIndex].value)
    }
  }, [timeListIndex])

  useEffect(() => {
    // 从外部跳转
    if (props.location.state) {
      setTabType('list')
      if (
        props.location.state.source &&
        props.location.state.source === 'ticket'
      ) {
        setCheckInVisible(true)
        history.replace({ ...history.location, state: undefined })
      }
    }
  }, [props.location.state])

  return (
    <div className="appointment">
      <Tabs
        tabBarExtraContent={{ right: tabSlot }}
        defaultActiveKey={tabType}
        activeKey={tabType}
        onChange={onTabs}
        className="a-tab"
      >
        <TabPane tab="日历模式" key="calendar" forceRender={true}>
          <ACalendar
            timeLists={timeLists}
            appointList={appointList}
            appointListLoading={appointListLoading}
            setTimeLists={setTimeLists}
            timeListIndex={timeListIndex}
            setTimeListIndex={setTimeListIndex}
            onChangeDate={onChangeDate}
            dateTime={dateTime}
            setAdjustVisible={setAdjustVisible}
            onSearch={onSearch}
            onDeleteToDoSuccess={refreshData}
            addAppointVisible={addAppointVisible}
            addServeVisible={addServeVisible}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        </TabPane>
        <TabPane tab="列表模式" key="list" forceRender={true}>
          {tabType === 'list' ? (
            <AppointList
              addAppointVisible={addAppointVisible}
              addServeVisible={addServeVisible}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          ) : (
            ''
          )}
        </TabPane>
      </Tabs>
      {/* 单日预约调整 */}
      <Modal
        title="单日预约调整"
        centered
        visible={adjustVisible}
        destroyOnClose={true}
        onCancel={handleAdjustCancel}
        onOk={handleAdjustOk}
        wrapClassName="ant-modal-content-custom"
        width="auto"
        footer={[
          <Button key="back" onClick={handleAdjustCancel}>
            关闭
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={adjustLoading}
            onClick={handleAdjustOk}
          >
            确定
          </Button>,
        ]}
      >
        <AAdjust setParamsAdjust={setParamsAdjust} />
      </Modal>
      {/* 添加预约 */}
      <Drawer
        title="添加预约"
        placement="right"
        width="auto"
        onClose={onClose}
        destroyOnClose={true}
        visible={addAppointVisible}
      >
        <AppointCreate
          handleCancel={onClose}
          onSuccess={onSuccess}
          onClose={refreshData}
        />
      </Drawer>
      {/* 请扫码或输入服务券码 */}
      <OrderCheckIn
        onCancel={() => {
          setCheckInVisible(false)
        }}
        onSubmit={onSubmit}
        visible={checkInVisible}
      />
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={handleCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={orderDetail.userId}
          orderNo={orderDetail.orderNo}
          deskList={deskList}
          orderType="APPOINT"
          onSuccess={handleCashClose}
        />
      </Drawer>
      {/* 待办 */}
      <Modal
        title="添加待办"
        centered
        destroyOnClose={true}
        onCancel={() => {
          setAddServeVisible(false)
        }}
        width="500px"
        visible={addServeVisible}
        footer={[
          <Button key="back" onClick={() => setAddServeVisible(false)}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={onAddServer}>
            确定
          </Button>,
        ]}
      >
        <Serve onFormChange={onFormChange} />
      </Modal>
    </div>
  )
}
export default connect((state) => ({}), {
  updatePeopleNumByDetailId,
  getNowDayAppointInfo,
  appointOrderConfirm,
  getAppointOrderDetail,
  AddBacklog,
  setParams,
  getBacklogList,
})(Appointment)
