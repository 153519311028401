import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getAdminAll } from '@/redux/actions/user'

export default function AdminIdSelect(props) {
  const [adminIdOptions, setAdminIdOptions] = useState([])
  useEffect(() => {
    getAdminAll()().then((res) => {
      setAdminIdOptions(res.data)
    })
  }, [])
  return (
    <Select {...props}>
      {adminIdOptions.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.username}
        </Select.Option>
      ))}
    </Select>
  )
}
