import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { dateFormat } from '@/utils'
import { getFreightDetail } from '@/redux/actions/order'
import './Detail.less'

function LogisticsDetail(props) {
  const [detail, setDetail] = useState({
    address: '--',
    city: '--',
    consignee: '--',
    county: '--',
    details: [],
    phone: '--',
    province: '--',
    shipName: '--',
    shipPhone: '--',
  })
  useEffect(() => {
    if (!props.shipNo) {
      return
    }
    props
      .getFreightDetail({
        orderNo: props.orderNo
      })
      .then((res) => {
        setDetail(res.data)
      })
  }, [props.shipNo])
  return (
    <div className="logistics-detail-con">
      <div className="logistics-detail">
        <div className="section-list">
          <div className="section">
            <div className="section-title">物流信息</div>
            <div className="info-con">
            <div className="info-item">
                <div className="info-title">运单号码</div>
                <div className="info-value">{detail.shipNo}</div>
              </div>
              <div className="info-item">
                <div className="info-title">发货时间</div>
                <div className="info-value">{dateFormat(detail.gmtShip)}</div>
              </div>
              <div className="info-item">
                <div className="info-title">物流公司</div>
                <div className="info-value">{detail.shipName}</div>
              </div>
              <div className="info-item">
                <div className="info-title">客服电话</div>
                <div className="info-value">{detail.shipPhone}</div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title">收货人信息</div>
            <div className="info-con">
              <div className="info-item">
                <div className="info-title">收货人</div>
                <div className="info-value">{detail.consignee}</div>
              </div>
              <div className="info-item">
                <div className="info-title">联系电话</div>
                <div className="info-value">{detail.phone}</div>
              </div>
              <div className="info-item oneline">
                <div className="info-title">收货地址</div>
                <div className="info-value">
                  {detail.province}
                  {detail.city}
                  {detail.county}
                  {detail.address}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="log-con">
          {detail.details && detail.details.map((item) => (
            <div className="log-item">
              <div className="log-status">{item.status}</div>
              {
                <div
                  className={`icon ${item.status === '签收' && 'complete'}`}
                ></div>
              }
              <div className="date">{item.time}</div>
              <div className="content">{item.context}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({}), {
  getFreightDetail,
})(LogisticsDetail)
