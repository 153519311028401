import React, { useState, useMemo } from 'react'
import { Upload, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BASE_UPLOAD_URL } from '@/config/api'
import { base64ToFile } from '@/utils'
import { useSelector } from 'react-redux'
import './PetUpload.less'
import lrz from 'lrz'
function PetUpload(props) {
  const state = useSelector((state) => state)
  const headers = useMemo(() => ({ accessToken: state.user.token }), [])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  // 上传图片
  const beforeUpload = (newFile) => {
    return new Promise((resolve, reject) => {
      if (
        ['image/jpeg', 'image/jpg', 'image/png'].indexOf(newFile.type) === -1
      ) {
        message.error({
          message: '提示',
          description: '请上传png或者jpg图片',
          duration: 2,
        })
        return false
      }
      lrz(newFile, {
        width: 1920,
      }).then((res) => {
        const file = base64ToFile(res.base64, res.origin.name)
        resolve(file)
      })
    })
  }
  const handleCancel = () => setPreviewVisible(false)
  const handlePreview = async (file) => {
    if (!file.data && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage(file.data || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.data.substring(file.data.lastIndexOf('/') + 1)
    )
  }
  const handleChange = ({ fileList }) => {
    props.onChange && props.onChange(fileList)
  }
  const uploadButton = (
    <div>
      <PlusOutlined className="a-icon-up" />
    </div>
  )
  const fileList = useMemo(() => {
    return props.value || []
  }, [props.value])
  return (
    <div className="pet-upload-container">
      <Upload
        headers={headers}
        action={BASE_UPLOAD_URL}
        listType="picture-card"
        fileList={fileList}
        showUploadList={props.showUploadList}
        onPreview={handlePreview}
        onChange={handleChange}
        className="pet-u-c"
        multiple={props.multiple}
        beforeUpload={beforeUpload}
        maxCount={9}
      >
        {props.multiple
          ? fileList.length >= 9
            ? null
            : uploadButton
          : fileList.length === 1
          ? null
          : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

PetUpload.propTypes = {}

export default PetUpload
