import React, { useEffect, useState } from 'react'
import Icon from '@/components/Icon'
import CouponItem from './coupon-item'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { couponList } from '@/redux/actions/order'
import './coupon.less'
function Index(props) {
  const [loading, setLoading] = useState(false)
  const [couponList, setCouponList] = useState([])
  const onSearch = (value) => {
    getCouponList(value)
  }
  const handleClick = (couponId) => {
    props.onChange && props.onChange(couponId)
  }
  const getCouponList = (title) => {
    setLoading(true)
    props
      .couponList({
        title,
        userId: props.userId,
        orderType: props.orderType,
        skuList: skuList,
        orderNo: props.orderNo,
      })
      .then((res) => {
        setCouponList(res.data.enabledList)
      }).finally(() => {
        setLoading(false)
      })
  }
  const skuList = React.useMemo(() => {
    if (Array.isArray(props.skuList) && props.skuList.length) {
      return props.skuList.filter((item) => item.skuId)
    }
    return undefined
  }, [props.skuList])
  useEffect(() => {
    if (!props.userId) {
      return
    }
    
    if (
      (props.orderType === 'BOARD' && props.orderNo) ||
      (props.orderType !== 'BOARD' && skuList && skuList.length)
    ) {
      getCouponList()
    }
  }, [props.userId, props.orderNo, props.orderType, skuList])
  return (
    <div className={`coupon-drawer ${props.visible && 'visible'}`}>
      <span className="patch patch-top"></span>
      <span className="patch patch-bottom"></span>
      <div className="title-con">
        <span onClick={props.onCancel}>收起</span>
      </div>
      <div className="search-con">
        <Input.Search
          placeholder="请输入优惠券名称"
          allowClear
          className="search-button"
          prefix={<Icon type="iconsousuo" />}
          enterButton="搜索"
          onSearch={onSearch}
          loading={loading}
        />
      </div>
      <div className="list">
        {couponList.map((item) => (
          <CouponItem
            couponId={props.couponId}
            detail={item}
            onClick={handleClick}
          />
        ))}
      </div>
    </div>
  )
}
export default connect(null, {
  couponList,
})(Index)
