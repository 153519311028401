import React, { useEffect, useState } from 'react'
import { DatePicker, Button, message } from 'antd'
import { getPriceByDays } from '@/redux/actions/goods'
import moment from 'moment'
import './DatePicker.less'
import { connect } from 'react-redux'
function MyDatePicker(props) {
  const [boardInfoDateArray, setBoardInfoDateArray] = useState({})
  const [priceStartDate, setPriceStartDate] = useState(undefined)
  const [priceEndDate, setPriceEndDate] = useState(undefined)
  const [isNoPrice, setIsNoPrice] = useState(false)
  const [nearlyDates, setNearlyDates] = useState(['', ''])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const disabledDate = (current) => {
    if (
      props.single &&
      props.startDate &&
      current < moment(props.startDate).subtract(1, 'd').endOf('day')
    ) {
      return true
    }
    // 禁止今天之前的日期
    if (current && current <= moment().subtract(1, 'd').endOf('day')) {
      return true
    }

    // 禁止一年之后的日期
    if (
      current &&
      current > moment().add(1, 'y').subtract(1, 'd').endOf('day')
    ) {
      return true
    }

    if (checkFull(current)) {
      return true
    }

    if (!nearlyDates[0] && !nearlyDates[1]) {
      return false
    }

    return nearlyDates[0]
      ? current.isAfter(nearlyDates[0])
      : current.isBefore(nearlyDates[1])
  }

  const handleCalendarChange = (dates) => {
    if (!dates || dates.length === 0) {
      setNearlyDates(['', ''])
      return
    }
    if (dates[0]) {
      setStartDate(dates[0])
      const date = getDisabledNearlyDate(dates[0], 'left')
      setNearlyDates([date, nearlyDates[1]])
    }
    if (dates[1]) {
      setEndDate(dates[1])
      const date = getDisabledNearlyDate(dates[1], 'right')
      setNearlyDates([nearlyDates[0], date])
    }
  }

  const getDisabledNearlyDate = (current, dire) => {
    const res = disabledBoardDateArray.filter((date) =>
      dire === 'left'
        ? current.diff(moment(date)) < 0
        : current.diff(moment(date)) > 0
    )
    if (res.length) {
      return res[0]
    }
    return null
  }
  const disabledBoardDateArray = React.useMemo(() => {
    if (!boardInfoDateArray) {
      return []
    }
    const dates = Object.keys(boardInfoDateArray)
    return dates
      .filter((date) => boardInfoDateArray[date].fullFlag === 1)
      .map(
        (date) =>
          `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
            6,
            8
          )}`
      )
  }, [boardInfoDateArray])
  // 是否已已预约
  const checkFull = (current) =>
    disabledBoardDateArray.indexOf(current.format('YYYY-MM-DD')) > -1

  const dateRender = (current) => {
    if (!boardInfoDateArray) {
      return <div className="ant-picker-cell-inner">{current.date()}</div>
    }
    const formatDate = current.format('YYYYMMDD')
    const item = boardInfoDateArray[formatDate] || {}
    const isFull = item.fullFlag === 1
    const price = item.price
    return (
      <div className="ant-picker-cell-inner">
        {current.date()}
        {isFull ? (
          <span className="ed">已预约</span>
        ) : (
          <span className="price">{price ? `¥${price}` : ''}</span>
        )}
      </div>
    )
  }
  const dateStr = React.useMemo(() => {
    if (!startDate || !endDate) {
      return ''
    }
    return `${startDate.format('MM.DD')}-${endDate.format('MM.DD')}`
  }, [startDate, endDate])
  const onPanelChange = (values) => {
    console.log('values', values)
    const startValue = props.single ? values : values[0]
    const startTime = moment(startValue.format('YYYY-MM-DD'))
    if (
      startTime.isBefore(moment()) ||
      startTime.isAfter(moment().add(1, 'y'))
    ) {
      return
    }
    const startTimeStr = startTime.format('YYYYMM01')
    let endTimeStr = startTime.add(2, 'M').endOf('month').format('YYYYMMDD')
    if (startTime.add(2, 'M').isAfter(startTime.add(1, 'y'))) {
      endTimeStr = startTime.add(1, 'y').format('YYYYMMDD')
    }
    if (boardInfoDateArray[endTimeStr]) {
      return
    }
    setPriceStartDate(startTimeStr)
    setPriceEndDate(endTimeStr)
  }
  const handleConfirm = () => {
    console.log('isNoPrice', isNoPrice)
    if (isNoPrice) {
      message.warning('当前寄养房间已被删除，无法延期')
      return
    }
    props.handleConfirm && props.handleConfirm()
  }
  const onChange = (value) => {
    props.onChange && props.onChange(value, boardInfoDateArray)
  }
  useEffect(() => {
    if (!priceStartDate || !priceEndDate || !props.spuId) {
      return
    }
    props
      .getPriceByDays({
        spuId: props.spuId,
        skuId: props.skuId,
        userId: props.userId,
        startDay: priceStartDate,
        endDay: priceEndDate,
      })
      .then((res) => {
        const data = res.data
        console.log('Object.keys(boardInfoDateArray)', Object.keys(boardInfoDateArray), data)
        if (data) {
          setBoardInfoDateArray({
            ...boardInfoDateArray,
            ...data,
          })
          setIsNoPrice(false)
        } else if (Object.keys(boardInfoDateArray).length === 0 && data === null) {
          setIsNoPrice(true)
        }
      })
  }, [priceStartDate, priceEndDate, props.spuId, props.skuId, props.userId])
  useEffect(() => {
    setPriceStartDate(moment().format('YYYYMMDD'))
    setPriceEndDate(moment().add(2, 'M').format('YYYYMMDD'))
    setBoardInfoDateArray({})
  }, [props.spuId, props.userId])
  const delayDateNum = React.useMemo(() => {
    if (!props.startDate || !props.value) {
      return 0
    }
    return props.value.diff(moment(props.startDate), 'd') + 1
  }, [props.startDate, props.value])
  const ExtraFooter = () => {
    return props.isShowExtraFooter ? (
      <div className="board-range-picker-dropdown-footer">
        <span className="primary-color">{dateStr}</span>
        <div className="input-con">
          <span>延期</span>
          {delayDateNum}
          <span>天</span>
        </div>
        <Button type="primary" onClick={handleConfirm}>
          确定
        </Button>
      </div>
    ) : undefined
  }
  return props.single ? (
    <DatePicker
      dropdownClassName="board-range-picker-dropdown single"
      bordered={false}
      onOpenChange={props.onOpenChange}
      onPanelChange={onPanelChange}
      onCalendarChange={handleCalendarChange}
      disabledDate={disabledDate}
      defaultValue={props.defaultValue}
      dateRender={dateRender}
      open={props.open}
      value={props.value}
      onChange={onChange}
      showToday={false}
      renderExtraFooter={ExtraFooter}
    />
  ) : (
    <DatePicker.RangePicker
      dropdownClassName="board-range-picker-dropdown"
      bordered={false}
      onOpenChange={props.onOpenChange}
      onPanelChange={onPanelChange}
      onCalendarChange={handleCalendarChange}
      disabledDate={disabledDate}
      dateRender={dateRender}
      defaultValue={props.defaultValue}
      open={props.open}
      value={props.value}
      onChange={props.onChange}
      renderExtraFooter={ExtraFooter}
    />
  )
}
export default connect(null, {
  getPriceByDays,
})(MyDatePicker)
