import React from 'react'
import { Image } from 'antd'
import '../Item/item.less'
import seal from '@/assets/appointment/seal.png'
export default function CItem(props) {
  return (
    <div className="c-item" onClick={() => props.openOrderServeDetail(props)}>
      <div className="seal">
        {props.status === 500 ? (
          <Image className="img" src={seal} preview={false} fallback={seal} />
        ) : (
          ''
        )}
      </div>
      <div className="title">
        <span className="t">{props.name}</span>
        <div className="s">
          <p className="status todo">待办服务</p>
        </div>
      </div>
      <p className="phone">{props.phone}</p>
      <p className="phone">{props.serviceName}</p>
      {props.remark && <h2 className="remark">{props.remark}</h2>}
    </div>
  )
}
