import React from 'react'
import { Drawer } from 'antd'
import { connect } from 'react-redux'
import TimeCardList from '../TimeCardList'
import { getDeductionUserUseCard } from '@/redux/actions/order'
const ChooseTimeCard = (props) => {
  const getDeductionUserUseCard = (params) => {
    let pms = {
      ...params,
      userId: props.userId,
      skuId: props.chooseGoodsItem && props.chooseGoodsItem.skuId,
      title: params.keyword,
    }
    delete pms.keyword
    delete pms.pageSize
    delete pms.pageNo
    return props.getDeductionUserUseCard(pms).then((res) => {
      const list = res.data || []
      return {
        list,
        pageObj: {
          totalPageNo: -2,
        },
      }
    })
  }
  return (
    <Drawer
      title="选择美容卡"
      placement="right"
      width="auto"
      onClose={props.handleClose}
      visible={props.visible}
      destroyOnClose={true}
    >
      <TimeCardList
        {...props}
        fetchOption={getDeductionUserUseCard}
        currentIndex={
          props.chooseGoodsItem && props.chooseGoodsItem.userDeductionId
        }
        onClick={props.onClick}
      />
    </Drawer>
  )
}

export default connect((state) => ({}), {
  getDeductionUserUseCard,
})(ChooseTimeCard)
