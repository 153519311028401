import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NumberKeyboard from '@/components/NumberKeyboard'
import './index.less'

export const useSmallKeyboard = (props) => {
  // 小键盘
  const [isNumberKeyboardRight, setIsNumberKeyboardRight] = useState(true)
  const [numberKeyboardPosition, setNumberKeyboardPosition] = useState({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    const elem = document.querySelector(props.selector)
    if (elem && props.inputIndex >= 0) {
      let x = elem.getBoundingClientRect().left + elem.offsetWidth * 1.2
      const y = elem.getBoundingClientRect().top - elem.offsetHeight * 1.7
      const w =
        document.querySelector('.number-keyboard-con')?.clientWidth ||
        window.innerWidth / 4.268
      if (x + w > document.documentElement.clientWidth) {
        x = x - w - elem.clientWidth - 40
        setIsNumberKeyboardRight(false)
      } else {
        setIsNumberKeyboardRight(true)
      }
      setNumberKeyboardPosition({
        x,
        y,
      })
    } else {
      setNumberKeyboardPosition({
        x: -9999,
        y: -9999,
      })
    }
    console.log(props.value)
  }, [props.inputIndex, props.value])

  return {
    isNumberKeyboardRight,
    setIsNumberKeyboardRight,
    numberKeyboardPosition,
    setNumberKeyboardPosition,
  }
}
function SmallKeyboard(props) {
  const useSmallKeyboardHook = useSmallKeyboard(props)
  return (
    <div
      className={`number-keyboard-con ${
        useSmallKeyboardHook.isNumberKeyboardRight && 'right'
      }`}
      style={{
        top: useSmallKeyboardHook.numberKeyboardPosition.y,
        left: useSmallKeyboardHook.numberKeyboardPosition.x,
      }}
    >
      <NumberKeyboard
        handleInput={props.handleInput}
        handleDelete={props.handleDelete}
        handleConfirm={props.handleNumberConfirm}
        handleChange={props.handleChange}
      />
    </div>
  )
}

SmallKeyboard.propTypes = {
  handleInput: PropTypes.func,
  handleNumberConfirm: PropTypes.func,
  handleChange: PropTypes.func,
  inputIndex: PropTypes.number.isRequired,
}
SmallKeyboard.defaultProps = {
  handleInput: () => {},
  handleNumberConfirm: () => {},
  handleChange: () => {},
  inputIndex: -9999,
}

export default SmallKeyboard
