import React, { useState, useRef, useEffect } from 'react'
import { Badge, Drawer } from 'antd'
import './index.less'
import messageIcon from '../../assets/home/message.svg'
import MessageList from './List'
import { BASE_WS } from '@/config/api'
import { connect } from 'react-redux'
import { NOT_NOTIFY_VOICE } from '@/utils/constant'
console.log('BASE_WS', BASE_WS)

let isMouseDown = false
let h = 0
let w = 0
let bodyW = window.innerWidth
let bodyH = window.innerHeight
let isMove = false
let lastPointX = Number(window.localStorage.getItem('LAST_POINT_X')) || -1000
let lastPointY = Number(window.localStorage.getItem('LAST_POINT_Y')) || -1000
let timer = null
let websocket = null
let websocketTimer = null
function Messager(props) {
  let [elemPointX, setElemPointX] = useState(lastPointX)
  let [elemPointY, setElemPointY] = useState(lastPointY)
  const [audioUrl, setAudioUrl] = useState('')
  const audioElem = useRef(null)
  const [notifyNum, setNotifyNum] = useState(0)
  const [visiable, setVisiable] = useState(false)
  const msgr = useRef(null)
  const messageListRef = useRef(null)

  useEffect(() => {
    if (
      lastPointX === -1000 ||
      lastPointX > bodyW - msgr.current.offsetWidth - 20 ||
      lastPointY > bodyH - msgr.current.offsetHeight - 100
    ) {
      setElemPointX(bodyW - msgr.current.offsetWidth - 20)
      setElemPointY(bodyH - msgr.current.offsetHeight - 100)
    }
    // startWebsocketService()

    return () => {
      // stopWebsocketService()
    }
  }, [props.user.tenantId])

  useEffect(() => {
    // 保存图标位置
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      window.localStorage.setItem('LAST_POINT_X', elemPointX)
      window.localStorage.setItem('LAST_POINT_Y', elemPointY)
    }, 1000)
  }, [elemPointX, elemPointY])

  const onMouseDown = () => {
    isMouseDown = true
    w = msgr.current.offsetWidth
    h = msgr.current.offsetHeight
  }

  const onMouseMove = (e) => {
    if (isMouseDown) {
      let clientX
      let clientY
      if (e.touches) {
        clientX = e.touches[0].clientX
        clientY = e.touches[0].clientY
      } else {
        clientX = e.clientX
        clientY = e.clientY
      }
      setElemPointX(clientX - w / 2)
      setElemPointY(clientY - h / 2)
      isMove = true
    }
  }
  const onMouseUp = (e) => {
    isMouseDown = false
    if (bodyW < elemPointX + w + 20) {
      setElemPointX(bodyW - w - 20)
    }
    if (elemPointX < 20) {
      setElemPointX(20)
    }
    if (bodyH < elemPointY + h + 20) {
      setElemPointY(bodyH - h - 20)
    }
    if (elemPointY < 20) {
      setElemPointY(20)
    }
  }

  const handleClick = () => {
    if (isMove) {
      isMove = false
      return
    }
    setVisiable(true)
  }

  const handleReadBatch = (unReadNum) => {
    setNotifyNum(unReadNum)
  }

  const startWebsocketService = () => {
    const tenantId = props.user.tenantId
    console.log('tenantId', tenantId)
    if (!tenantId) return
    const user = `${tenantId}_${Date.now()}`
    const url = `${BASE_WS}?user=${user}`
    console.log('url', url)
    websocket = new WebSocket(url)
    // 打开连接通道
    websocket.onopen = () => {
      console.log(`webSoket open: ${url}`)
      try {
        websocket && websocket.send('')
        websocketTimer && clearInterval(websocketTimer)
        websocketTimer = setInterval(() => {
          websocket && websocket.send('heartbeat')
        }, 40 * 1000)
      } catch (error) {
        console.error('websocket error:', error)
      }
    }
    // 监听服务器是否返回消息
    websocket.onmessage = (res) => {
      console.log('webSoket receive data: ' + res.data)
      const data = JSON.parse(res.data)

      setNotifyNum(data.unReadNum || 0)
      if (data.notifyVoice) setAudioUrl(data.notifyVoice)
    }
    websocket.onclose = function (e) {
      console.log(
        'websocket close: ' + e.code + ' ' + e.reason + ' ' + e.wasClean
      )
      stopWebsocketService(true)
    }
    websocket.onerror = function (e) {
      console.log(`webSoket error: ${JSON.stringify(e)}`)
      stopWebsocketService(true)
    }
  }

  const stopWebsocketService = (isReconnection) => {
    if (!websocket) return
    try {
      websocket.onopen = null
      websocket.onmessage = null
      websocket.onclose = null
      websocket.onerror = null
      websocket.close()
      websocket = null
      if (isReconnection) {
        setTimeout(startWebsocketService, 3 * 1000)
      }
    } catch (error) {}
  }
  const handlePlay = () => {
    if (audioUrl && audioElem && audioElem.current) {
      audioElem.current.pause()
      setTimeout(() => {
        window.localStorage.getItem(NOT_NOTIFY_VOICE) !== 'true' &&
          audioElem.current.play()
          audioElem.current.onended = () => {
            setAudioUrl('')
          }
      }, 150)
    }
  }
  useEffect(() => {
    handlePlay()
  }, [audioUrl])

  useEffect(() => {
    messageListRef && messageListRef.current && messageListRef.current.onTabClick(1)
    messageListRef && messageListRef.current && messageListRef.current.getNotifyNum()
  }, [notifyNum])

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('touchmove', onMouseMove)

  return (
    <>
      <div
        className="messager"
        ref={msgr}
        style={{ left: elemPointX + 'px', top: elemPointY + 'px' }}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onClick={handleClick}
      >
        <Badge count={notifyNum}>
          <img className="icon" src={messageIcon} alt="" />
        </Badge>
      </div>
      <Drawer
        width="auto"
        title="消息通知"
        visible={visiable}
        onClose={() => setVisiable(false)}
      >
        <MessageList
          handleLinkClick={() => setVisiable(false)}
          handleReadBatch={handleReadBatch}
          ref={messageListRef}
        />
      </Drawer>
      <audio ref={audioElem} src={audioUrl} controls="controls" hidden={true}>
        <source src={audioUrl} type="audio/mpeg" />
      </audio>
    </>
  )
}

export default connect((state) => ({ user: state.user }))(Messager)
