import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '@/components/Icon'
import PropTypes from 'prop-types'
import { Button, Space, Row, Col, Image, Modal, message, Drawer } from 'antd'
import {
  PET_GENDER_MAP,
  PET_NEUTER_STATUS_MAP,
  BOARD_ORDER_STATUS_MAP,
  SERVICE_TYPE_MAP,
  ORDER_SOURCE_MAP,
} from '@/utils/enum'
import {
  startBoardOrder,
  cancelBoardOrder,
  deleteBoardOrder,
  endBoardOrder,
  updateBonusAdmin,
  postponeBoard,
} from '@/redux/actions/order'
import { getLabelByValueFromEnum } from '@/utils'
import BoardRefund from '@/components/Board/Refund'
import TimeLine from '@/components/Board/TimeLine'
import Cash from '@/components/Cash'
import AdminIdSelect from '@/components/MySelect/AdminIdSelect'
import ExtendDatePicker from './components/ExtendDatePicker'
import EndOrderModal from './components/EndOrderModal'
import defaultLogo from '@/assets/menu/defaultLogo.png'
import defaultOwnerLogo from '@/assets/member/people.png'
import { connect } from 'react-redux'
import moment from 'moment'
import './detail.less'

function ADetail(props) {
  const history = useHistory()
  const [isShowEndOrder, setIsShowEndOrder] = useState(false)
  const [isShowExtendDate, setIsShowExtendDate] = useState(false)
  const [adminIdLoading, setAdminIdLoading] = useState(false)
  const [payVisible, setPayVisible] = useState(false)
  const [handleLoading] = useState(false)
  const [isShowBoardRefund, setIsShowBoardRefund] = useState(false)
  const [isShowTimeLine, setIsShowTimeLine] = useState(false)
  const { orderDetail = {} } = props
  const [bonusAdminList, setBonusAdminList] = useState([])

  /**
   * 开始寄养
   */
  const handleStartOrder = () => {
    Modal.confirm({
      title: '提示',
      content: '确认开始寄养吗？',
      onOk() {
        return new Promise((resolve, reject) => {
          props
            .startBoardOrder({
              orderNo: orderDetail.orderNo,
            })
            .then((res) => {
              message.success('开始寄养成功')
              props.handleSuccess &&
                props.handleSuccess('start', orderDetail.orderNo)
              resolve()
            })
            .catch(() => {
              reject()
            })
        })
      },
    })
  }
  /**
   * 结束寄养
   */
  const handleShowEndOrder = () => {
    setIsShowEndOrder(true)
  }
  const handleEndOrder = () => {
    props
      .endBoardOrder({
        orderNo: orderDetail.orderNo,
      })
      .then((res) => {
        const data = res.data
        if (data === 0) {
          setPayVisible(true)
        } else {
          message.success('结束寄养成功')
          props.handleSuccess && props.handleSuccess('end', orderDetail.orderNo)
        }
        setIsShowEndOrder(false)
      })
  }
  const handleCancelOrder = () => {
    Modal.confirm({
      title: '提示',
      content: '确认取消该寄养订单吗？',
      onOk() {
        return new Promise((resolve, reject) => {
          props
            .cancelBoardOrder({
              orderNo: orderDetail.orderNo,
            })
            .then((res) => {
              message.success('订单取消成功')
              props.handleSuccess &&
                props.handleSuccess('cancel', orderDetail.orderNo)
              resolve()
            })
            .catch(() => {
              reject()
            })
        })
      },
    })
  }
  const handleDeleteOrder = () => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该寄养订单吗？',
      onOk() {
        return new Promise((resolve, reject) => {
          props
            .deleteBoardOrder({
              orderNo: orderDetail.orderNo,
            })
            .then((res) => {
              message.success('订单删除成功')
              props.handleSuccess &&
                props.handleSuccess('delete', orderDetail.orderNo)
              resolve()
            })
            .catch(() => {
              reject()
            })
        })
      },
    })
  }
  const onBoardRefundSuccess = () => {
    setIsShowBoardRefund(false)
    props.handleSuccess && props.handleSuccess('refund', orderDetail.orderNo)
  }
  const handleRefundVisible = () => {
    setIsShowBoardRefund(true)
  }

  // 当前订单状态
  const currentOrderStatus = useMemo(() => {
    if (orderDetail.goRefund && orderDetail.refundStatus === 1000) {
      return '售后处理中'
    }
    return getLabelByValueFromEnum(orderDetail.status, BOARD_ORDER_STATUS_MAP)
  }, [orderDetail])

  // 当前宠物性别
  const currentPetGender = useMemo(
    () => getLabelByValueFromEnum(orderDetail.petGender, PET_GENDER_MAP),
    [orderDetail]
  )

  const currentOrderSource = useMemo(
    () => getLabelByValueFromEnum(orderDetail.orderSource, ORDER_SOURCE_MAP),
    [orderDetail]
  )
  const currentPetNeuter = useMemo(
    () =>
      getLabelByValueFromEnum(
        orderDetail.petNeuterStatus,
        PET_NEUTER_STATUS_MAP
      ),
    [orderDetail]
  )
  const currentBoardType = useMemo(
    () => getLabelByValueFromEnum(orderDetail.boardType, SERVICE_TYPE_MAP),
    [orderDetail]
  )

  const handleBonusAdminListChange = (values) => {
    if (values.length > 5) {
      message.warning('最多选择5位服务人员')
      return
    }
    setAdminIdLoading(true)
    props
      .updateBonusAdmin({
        adminDTOList: values.map((iv) => ({ adminId: iv })),
        orderNo: orderDetail.orderNo,
        orderTypeEnum: orderDetail.orderType,
      })
      .finally(() => {
        setAdminIdLoading(false)
      })
    setBonusAdminList(values)
  }

  const handleDealOrder = () => {
    const { orderType, orderPhone, orderNo, refundOrderNo } = orderDetail
    history.push({
      pathname: '/refund',
      state: {
        orderPhone,
        orderNo,
        orderType,
        refundOrderNo,
      },
    })
  }

  const handlePay = () => {
    setPayVisible(true)
  }
  const onCashClose = () => {
    setPayVisible(false)
    props.handleSuccess && props.handleSuccess('end', orderDetail.orderNo)
  }

  /**
   * 寄养延期
   */
  const handleExtend = () => {
    setIsShowExtendDate(!isShowExtendDate)
  }
  const onExtendSuccess = () => {
    props.handleSuccess && props.handleSuccess('extend', orderDetail.orderNo)
    setIsShowExtendDate(false)
  }
  const boardDateStr = useMemo(() => {
    const totalNum = moment(orderDetail.endTime).diff(moment(orderDetail.startTime), 'day') + 1
    return `${orderDetail.startTime} - ${orderDetail.endTime}(${totalNum}天)`
  }, [orderDetail])
  const extendStartDate = useMemo(() => {
    let endTime = orderDetail.postponeBoardEndTimeString || orderDetail.endTime
    return moment(endTime).add(1, 'd').format('YYYY-MM-DD')
  }, [orderDetail])
  const extendDateStr = useMemo(() => {
    if (orderDetail.postponeFlag === 1) {
      const start = moment(orderDetail.postponeBoardStartTimeString).format(
        'YYYY.MM.DD'
      )
      const end = moment(orderDetail.postponeBoardEndTimeString).format(
        'YYYY.MM.DD'
      )
      const num =
        moment(orderDetail.postponeBoardEndTimeString).diff(
          moment(orderDetail.postponeBoardStartTimeString),
          'd'
        ) + 1
      return `${start} - ${end}(${num}天)`
    }
    return '--'
  }, [orderDetail])
  const isDepositPay = useMemo(
    () => orderDetail.waitPayPrice > 0 && orderDetail.status < 2300,
    [orderDetail]
  )
  const handlePaySuccess = () => {
    setPayVisible(false)
    props.handleSuccess && props.handleSuccess('end', orderDetail.orderNo)
  }
  // 是否接送
  const isPickUp = useMemo(() => {
    return orderDetail.boardType === '2'
  }, [orderDetail])
  const memberAddressElem = useMemo(() => {
    return isPickUp ? (
      <Col span={12} className="col-2">
        <span className="t">接送地址</span>
        <span className="i address">{orderDetail.address || '无'}</span>
      </Col>
    ) : null
  }, [orderDetail, isPickUp])

  useEffect(() => {
    orderDetail.bonusAdminList &&
      setBonusAdminList(orderDetail.bonusAdminList.map((item) => item.adminId))
  }, [orderDetail])
  console.log(orderDetail, "orderDetail")
  return (
    <div className="board-detail">
      {/* 订单信息 */}
      <div className="order-info">
        <h2 className="order-title">
          <span className="orderNo">订单编号 {orderDetail.orderNo}</span>
          <span className="orderTime">
            <Icon type="iconshijian" className="icon" />
            {orderDetail.gmtCreate}
          </span>
        </h2>
        <div className="o-info">
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">订单状态</span>
              <span className="i">{currentOrderStatus}</span>
            </Col>
            <Col span={12} className="col-2">
              <span className="t">服务店员</span>
              <AdminIdSelect
                style={{ width: '60%' }}
                mode="multiple"
                allowClear
                className="select"
                placeholder="请选择服务店员"
                value={bonusAdminList}
                loading={adminIdLoading}
                onChange={handleBonusAdminListChange}
              />
            </Col>
          </Row>
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">支付方式</span>
              <span className="i">{orderDetail.payChannelDescription}</span>
            </Col>
            <Col span={12} className="col-2">
              <span className="t">订单来源</span>
              <span className="i">{currentOrderSource}</span>
            </Col>
          </Row>
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">成交时间</span>
              <span className="i">{orderDetail.gmtPay || "--"}</span>
            </Col>
          </Row>
          <Row className="row-1">
            <Col span={24} className="col-1">
              <span className="t">备注</span>
              <span className="i remark">
                {orderDetail.settleRemark || '-'}
              </span>
            </Col>
          </Row>
        </div>
      </div>
      {/* 预约信息 */}
      <div className="appoint-info">
        <h2 className="a-i-title">预约人及宠物信息</h2>
        <div className="a-i-box">
          <div className="user-info">
            <div className="user">
              <Image
                className="ava"
                src={orderDetail.avatarUrl || defaultOwnerLogo}
                preview={false}
              />
              <div className="u-info">
                <div className="u-i-1 flex1">
                  <span
                    className="u-i-t"
                    title={
                      orderDetail.orderUserName ||
                      orderDetail.realname ||
                      orderDetail.nickname
                    }
                  >
                    {orderDetail.orderUserName ||
                      orderDetail.realname ||
                      orderDetail.nickname}
                  </span>
                  {orderDetail.userLevelName && (
                    <span className="u-i-v">{orderDetail.userLevelName}</span>
                  )}
                </div>
                <div className="flex u-i-1">
                  <span className="u-i-p">{orderDetail.orderPhone}</span>
                </div>
              </div>
            </div>
            <div className="title">用户信息</div>
          </div>
          <div className="pet-info">
            <div className="user">
              <Image
                className="ava"
                src={orderDetail.petImage || defaultLogo}
                preview={false}
              />
              <div className="p-info">
                <div className="p-item">
                  <div className="p-value" title={orderDetail.petName}>
                    {orderDetail.petName}
                  </div>
                </div>
                <div className="p-item">
                  <div className="p-title">宠物性别</div>
                  <div className="p-value">{currentPetGender}</div>
                </div>
                <div className="p-item">
                  <div className="p-title">宠物毛色</div>
                  <div className="p-value" title={orderDetail.petHairColor}>
                    {orderDetail.petHairColor}
                  </div>
                </div>
                <div className="p-item">
                  <div
                    className="p-value grey-color"
                    title={orderDetail.petBreed}
                  >
                    {orderDetail.petBreed}
                  </div>
                </div>

                <div className="p-item">
                  <div className="p-title">是否绝育</div>
                  <div className="p-value" title={currentPetNeuter}>
                    {currentPetNeuter}
                  </div>
                </div>

                <div className="p-item">
                  <div className="p-title">宠物体型</div>
                  <div className="p-value" title={orderDetail.petBodyType}>
                    {orderDetail.petBodyType}
                  </div>
                </div>
                <div className="p-item">
                  <div className="p-title"></div>
                  <div className="p-value"></div>
                </div>
                <div className="p-item">
                  <div className="p-title">宠物体重</div>
                  <div className="p-value" title={orderDetail.petWeight}>
                    {orderDetail.petWeight ? orderDetail.petWeight + 'kg' : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="title">宠物信息</div>
          </div>
        </div>
      </div>
      {/* 订单详情 */}
      <div className="order-detail">
        <div className="a-i-title">寄养信息</div>
        <div className="o-d-box">
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">寄养时间</span>
              <span className="i">{boardDateStr}</span>
            </Col>
            <Col span={12} className="col-2">
              <span className="t">延期时间</span>
              <span className="i">{extendDateStr}</span>
            </Col>
          </Row>
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">寄养房间</span>
              <span className="i">
                {orderDetail.spuTitle} {orderDetail.skuTitle}
              </span>
            </Col>
            <Col span={12} className="col-2">
              <span className="t">寄养均价</span>
              <span className="i">¥{orderDetail.averagePrice}/天</span>
            </Col>
          </Row>
          <Row className="row-1">
            <Col span={12} className="col-1">
              <span className="t">服务方式</span>
              <span className="i address">{currentBoardType}</span>
            </Col>
            {isPickUp && (
              <Col span={12} className="col-2">
                <span className="t">接送费用</span>
                <span className="i address">
                  ¥{orderDetail.serviceDoorPrice}
                </span>
              </Col>
            )}
          </Row>
          <Row className="row-1 flex-start">
            <Col span={12} className="col-1">
              <span className="t">备 注</span>
              <span className="i remark">{orderDetail.mono || '无'}</span>
            </Col>
            {memberAddressElem}
          </Row>
        </div>
      </div>
      {/* 支付信息 */}
      <div className="pay-info">
        <div className="l">
          <div className="item">
            <span>
            寄养总价 <span> </span>
              {orderDetail.receivableAmount
                ? `¥${orderDetail.receivableAmount}`
                : `¥0`}
            </span>
          </div>
          <div className="item">
            <span>
              会员优惠 <span> </span>-
              {orderDetail.discountedAmount
                ? `¥${orderDetail.discountedAmount}`
                : `¥0`}
            </span>
          </div>
          <div className="item">
            <span>
              商家优惠 <span> </span>-
              {orderDetail.businessDiscount
                ? `¥${orderDetail.businessDiscount}`
                : `¥0`}
            </span>
          </div>
          <div className="item">
            <span>
              积分抵扣 <span> </span>-
              {orderDetail.commissionDeduct
                ? `¥${orderDetail.commissionDeduct}`
                : `¥0`}
            </span>
          </div>
          <div className="item">
            <span>
              优惠券 <span> </span>-
              {orderDetail.couponPrice ? `¥${orderDetail.couponPrice}` : `¥0`}
            </span>
          </div>
          {orderDetail.depositPrice ? (
            <div className="item">
              <span>
                定金 <span> </span>-{`¥${orderDetail.depositPrice}`}
              </span>
            </div>
          ) : null}
        </div>
        <div className="r">
          <span>
            {isDepositPay
              ? `待付款 ¥${orderDetail.waitPayPrice}`
              : `实付款 ¥${orderDetail.payPrice || 0}`}
          </span>
        </div>
      </div>
      {/* 操作 */}
      <div className="pay-action">
        <Space>
          {orderDetail.status === 2000 && orderDetail.orderSource === 'CRS' ? (
            <>
              <Button
                className="btn"
                size="large"
                onClick={handleCancelOrder}
                loading={handleLoading}
              >
                取消订单
              </Button>
              <Button
                className="btn"
                size="large"
                type="primary"
                loading={handleLoading}
                onClick={handlePay}
              >
                去结算
              </Button>
            </>
          ) : orderDetail.status === 2100 ? (
            orderDetail.refundOrderNo &&
            orderDetail.goRefund &&
            orderDetail.refundStatus === 1000 ? (
              <Button
                className="btn"
                size="large"
                type="primary"
                loading={handleLoading}
                onClick={handleDealOrder}
              >
                去处理
              </Button>
            ) : (
              <>
                <Button
                  className="btn"
                  size="large"
                  ghost
                  type="primary"
                  loading={handleLoading}
                  onClick={handleRefundVisible}
                >
                  退款
                </Button>
                <Button
                  className="btn"
                  size="large"
                  type="primary"
                  loading={handleLoading}
                  onClick={handleStartOrder}
                >
                  开始寄养
                </Button>
              </>
            )
          ) : orderDetail.status === 2200 ? (
            <>
              <ExtendDatePicker
                isShowExtraFooter={true}
                startDate={extendStartDate}
                open={isShowExtendDate}
                onSuccess={onExtendSuccess}
                onClose={() => setIsShowExtendDate(false)}
                spuId={orderDetail.spuId}
                skuId={orderDetail.skuId}
                orderNo={orderDetail.orderNo}
              >
                <Button
                  className="btn board-extend-btn"
                  size="large"
                  ghost
                  type="primary"
                  onClick={handleExtend}
                >
                  延期寄养
                </Button>
              </ExtendDatePicker>
              <Button
                className="btn"
                size="large"
                ghost
                type="primary"
                loading={handleLoading}
                onClick={() => setIsShowTimeLine(true)}
              >
                寄养动态
              </Button>
              <Button
                className="btn"
                size="large"
                type="primary"
                loading={handleLoading}
                onClick={handleShowEndOrder}
              >
                结束寄养
              </Button>
            </>
          ) : orderDetail.status === 2300 ? (
            <>
              <Button
                className="btn"
                size="large"
                ghost
                type="primary"
                loading={handleLoading}
                onClick={handleRefundVisible}
              >
                退款
              </Button>
            </>
          ) : orderDetail.status === 2400 || orderDetail.status > 2500 ? (
            <>
              <Button
                className="btn"
                size="large"
                ghost
                type="primary"
                onClick={handleDeleteOrder}
                loading={handleLoading}
              >
                删除订单
              </Button>
            </>
          ) : (
            ''
          )}
        </Space>
      </div>
      <EndOrderModal
        visible={isShowEndOrder}
        boardDateStr={boardDateStr}
        extendDateStr={extendDateStr}
        handleConfirm={handleEndOrder}
        onCancel={() => setIsShowEndOrder(false)}
      />
      <BoardRefund
        orderNo={orderDetail.orderNo}
        orderId={orderDetail.id}
        visible={isShowBoardRefund}
        onSuccess={onBoardRefundSuccess}
        onCancel={() => setIsShowBoardRefund(false)}
      />

      <TimeLine
        visible={isShowTimeLine}
        onCancel={() => setIsShowTimeLine(false)}
        orderDetail={orderDetail}
      />

      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={onCashClose}
        visible={payVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={orderDetail.userId}
          orderNo={orderDetail.orderNo}
          orderType="BOARD"
          onSuccess={handlePaySuccess}
        />
      </Drawer>
    </div>
  )
}

ADetail.propTypes = {
  handleSuccess: PropTypes.func,
}

export default connect(null, {
  cancelBoardOrder,
  deleteBoardOrder,
  startBoardOrder,
  endBoardOrder,
  updateBonusAdmin,
  postponeBoard,
})(ADetail)
