import React, { useState, useEffect, useContext } from 'react'
import './widthdrawal.less'
import { getCrsPendingOrderPageList } from '@/redux/actions/order'
import { connect } from 'react-redux'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import MemberCard from '@/components/Member/Card'
import { dateFormat } from '@/utils'
import { Button, Modal, Drawer, Empty } from 'antd'
import { withRouter } from 'react-router-dom'
import Cars from '../Cashier/cars'
import Cash from '@/components/Cash'
import { addGoodsCashier } from '@/redux/actions/goods'
import { setMemberDetail, setISMember } from '@/redux/actions/home'
import {
  getCrsPendingOrderUpdate,
  getCrsPendingOrderDelete,
  getCrsOrderCreateOrder,
  deductionCardService,
} from '@/redux/actions/order'
import { HomeContext } from '@/components/Home/context-manager'
import { useHome } from '@/components/Home/hooks/useHome'

const { confirm } = Modal
function Withdrawal(props) {
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [settLoading, setSettLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [detailItem, setDetailItem] = useState({})
  const [deskList, setDeskList] = useState([])
  const [settl, setSettl] = useState(0)

  const [orderNo, setOrderNo] = useState('')
  const [userId, setUserId] = useState('')
  const [cashVisible, setCashVisible] = useState(false)
  const { setPendingOrderId } = useContext(HomeContext)
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 20,
  })

  const useHomeHook = useHome({
    ...props,
    addGoodsCashier: setDeskList,
  })

  // 更多
  const handleLoadMore = () => {
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }

  const loadData = () => {
    if (!hasMore) return

    setLoading(true)
    props
      .getCrsPendingOrderPageList({
        ...params,
      })
      .then((res) => {
        const data = res.data
        const list = data.items || []
        const newList = [...orderList, ...list]
        setOrderList(newList)
        if (selectedIndex === 0) {
          if (newList.length > 0) {
            setDetailItem(newList[0])
            setDeskList(newList[0].skus)
            settlCount(newList[0].skus, 'subtotal')
          }
        }
        const totalPageNo = Math.ceil(data.count / params.pageSize)
        setHasMore(params.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  // 合计
  const settlCount = (newDeskList, key) => {
    let settl = 0
    newDeskList.forEach((iv) => {
      settl += iv[key]
    })
    settl = settl < 0 ? 0 : settl.toFixed(2)
    setSettl(settl)
  }

  const onClickItem = (index) => {
    const newDeskList = orderList[index]
    setSelectedIndex(index)
    setDetailItem(newDeskList)
    //
    settlCount(newDeskList.skus, 'subtotal')
    //
    setDeskList(newDeskList.skus)
  }
  // 选择美容卡
  const chooseTimeCard = (item, index) => {
    const list = [...deskList]
    list[index] = item
    setDeskList(list)
  }
  // 增加
  const onAdd = (iv, index) => {
    iv.num += 1
    iv.subtotal = iv.num * iv.discountedPrice
    iv.subtotal = Math.floor(iv.subtotal * 100) / 100
    deskList[index] = iv
    props
      .getCrsPendingOrderUpdate({
        pendingOrderId: detailItem.id,
        skus: deskList,
      })
      .then(() => {
        //
        settlCount(deskList, 'subtotal')
        //
        setDeskList([...deskList])
      })
  }
  // 减少
  const onReduce = (iv, index) => {
    iv.subtotal = iv.num * iv.discountedPrice
    iv.subtotal = Math.floor(iv.subtotal * 100) / 100
    deskList[index] = iv
    props
      .getCrsPendingOrderUpdate({
        pendingOrderId: detailItem.id,
        skus: deskList,
      })
      .then(() => {
        //
        settlCount(deskList, 'subtotal')
        //
        setDeskList([...deskList])
      })
  }
  // 修改数量
  const onChangeNum = (val, skuNumber, index) => {
    deskList[index].num = val
    deskList[index].subtotal =
      deskList[index].num * deskList[index].discountedPrice
    props
      .getCrsPendingOrderUpdate({
        pendingOrderId: detailItem.id,
        skus: deskList,
      })
      .then(() => {
        //
        settlCount(deskList, 'subtotal')
        //
        setDeskList([...deskList])
      })
  }
  // 删除某个商品
  const onDelete = (index) => {
    if (deskList.length === 1) {
      onDelAll()
    } else {
      deskList.splice(index, 1)
      props
        .getCrsPendingOrderUpdate({
          pendingOrderId: detailItem.id,
          skus: [...deskList],
        })
        .then(() => {
          //
          settlCount(deskList, 'subtotal')
          //
          setDeskList([...deskList])
        })
    }
  }
  // 删除
  const onDelAll = () => {
    confirm({
      title: '提示',
      centered: true,
      content: (
        <div>
          {deskList.length === 1
            ? `确认删除该挂单商品吗？`
            : `确认删除该挂单全部商品吗？`}
        </div>
      ),
      onOk() {
        props.getCrsPendingOrderDelete(detailItem.id).then((res) => {
          setOrderList([])
          setDetailItem({})
          setSelectedIndex(0)
          setHasMore(true)
          setParams({
            ...params,
            pageNo: 1,
          })
        })
      },
      onCancel() {},
    })
  }
  // 追加
  const onAddItem = () => {
    props.getCrsPendingOrderDelete(detailItem.id).then(() => {
      props.addGoodsCashier([...deskList])
      props.setISMember(false)
      props.setMemberDetail(null)
      if (detailItem.user) {
        props.setISMember(true)
        props.setMemberDetail({ ...detailItem.user })
        setPendingOrderId(detailItem.id)
      } else {
        props.setISMember(false)
        setPendingOrderId(null)
      }
      props.setWithdrawalVisible(false)
    })
  }

  // 结算
  const onSettl = () => {
    setSettLoading(true)
    const parmas = {
      orderType: 'STORE',
      skus: deskList,
      useGoodsDiscount: true,
      useServiceDiscount: true,
      useVipPrice: true,
      userId: detailItem.user && detailItem.user.id,
      totalPrice: settl,
    }
    props
      .getCrsOrderCreateOrder(parmas)
      .then((res) => {
        setOrderNo(res.data.orderNo)
        setUserId(detailItem.user && detailItem.user.id)
        setCashVisible(true)
      })
      .finally(() => {
        setSettLoading(false)
      })
  }
  const handleCashClose = () => {
    setCashVisible(false)
  }
  const handleCashSuccess = () => {
    props
      .getCrsPendingOrderDelete(detailItem.id)
      .then(() => {
        setOrderList([])
        setDetailItem({})
        setSelectedIndex(0)
        setHasMore(true)
        setParams({
          ...params,
          pageNo: 1,
        })
      })
      .finally(() => {
        setCashVisible(false)
      })
  }

  useEffect(() => {
    loadData()
  }, [params])

  useEffect(() => {
    useHomeHook.deductionCardService(detailItem.skus, detailItem.userId)
  }, [detailItem])

  return (
    <div className="h-withdrawal">
      <div className="h-w-list">
        <h2 className="h-w-t">挂单列表</h2>
        <InfiniteScrollList
          className="order-list"
          handleOnLoad={handleLoadMore}
          hasMore={hasMore}
          loading={loading}
        >
          {orderList.map((item, index) => (
            <div
              className={`item ${selectedIndex === index ? 'selected' : ''}`}
              onClick={() => onClickItem(index)}
              key={index}
            >
              <h2 className="orderno">{item.orderNo}</h2>
              <div className="item-bottom">
                <p className="date">{dateFormat(item.gmtCreate)}</p>
                <p className="price">¥{item.totalPrice}</p>
              </div>
            </div>
          ))}
        </InfiniteScrollList>
      </div>
      <div className="h-w-detail">
        {orderList.length ? (
          <>
            <MemberCard detail={detailItem.user || {}} />
            <div className="d-car-list">
              <div className="l-box">
                <Cars
                  deskList={deskList}
                  isAction={false}
                  onAdd={onAdd}
                  onReduce={onReduce}
                  onChangeNum={onChangeNum}
                  onDelete={onDelete}
                  userId={detailItem?.userId}
                  chooseTimeCard={chooseTimeCard}
                />
              </div>
              <div className="l-sum">
                <span className="l-sum-t">合计</span>
                <span className="l-sum-p">¥{settl}</span>
              </div>
              <div className="l-settl">
                <div className="del">
                  <Button onClick={onDelAll}>删除</Button>
                </div>
                <div className="add" onClick={onAddItem}>
                  追加商品
                </div>
                <Button
                  type="primary"
                  className="settl"
                  loading={settLoading}
                  onClick={onSettl}
                >
                  结算
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="no-data-con">
            <Empty
              imageStyle={{
                height: 120,
              }}
              description={<span>暂无数据</span>}
            ></Empty>
          </div>
        )}
      </div>
      <Drawer
        title="结算"
        placement="right"
        width="auto"
        onClose={handleCashClose}
        visible={cashVisible}
        destroyOnClose={true}
      >
        <Cash
          userId={userId}
          orderNo={orderNo}
          deskList={deskList}
          onSuccess={handleCashSuccess}
        />
      </Drawer>
    </div>
  )
}

export default connect((state) => ({}), {
  getCrsPendingOrderPageList,
  getCrsPendingOrderUpdate,
  getCrsPendingOrderDelete,
  getCrsOrderCreateOrder,
  addGoodsCashier,
  setMemberDetail,
  setISMember,
  deductionCardService,
})(withRouter(Withdrawal))
