const storage = window.localStorage
const USERNAME_KEY = 'username-key'
const PASSWORD_KEY = 'password-key'

export const setUsernameByStorage = (username) => {
  storage.setItem(USERNAME_KEY, username)
}
export const getUsernameByStorage = () => storage.getItem(USERNAME_KEY) || ''

export const setPasswordByStorage = (password) => {
  if (password === null) {
    storage.removeItem(PASSWORD_KEY)
  } else {
    storage.setItem(PASSWORD_KEY, password)
  }
}
export const getPasswordByStorage = () => storage.getItem(PASSWORD_KEY) || ''
