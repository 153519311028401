import { SET_APPOINTMENT_PARAMS } from '../constant'

const initState = {
  params: {
    orderSource: 'CRS',
    keyWord: undefined,
    appointStartTime: undefined,
    appointEndTime: undefined,
  },
}
export const createReduce = (perState = initState, action) => {
  const { type, data } = action
  switch (type) {
    case SET_APPOINTMENT_PARAMS:
      return {
        ...perState,
        params: {
          ...data,
          orderSource: data.orderSource || 'CRS',
        },
      }
    default:
      return perState
  }
}
export default createReduce
