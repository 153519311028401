import React, { useEffect, useState } from 'react'
import { Modal, Button, Input } from 'antd'
import PropTypes from 'prop-types'
import './index.less'

function OrderCheckIn(props) {
  const [orderNo, setOrderNo] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClear = () => {
    setOrderNo('')
  }
  const handleSubmit = (text) => {
    if (loading || text === '') {
      return
    }
    setLoading(true)
    props.onSubmit &&
      props.onSubmit(text, () => {
        setLoading(false)
        setOrderNo('')
      })
  }
  const onCancel = () => {
    setOrderNo('')
    props.onCancel && props.onCancel()
  }
  const onInputChange = (e) => {
    setOrderNo(e.target.value)
  }
  const onPressEnter = (e) => {
    handleSubmit(orderNo)
  }

  return (
    <Modal
      wrapClassName="ant-modal-content-custom order-checkin-modal"
      title="请扫码或输入服务券码"
      visible={props.visible}
      width="740px"
      centered="center"
      onCancel={onCancel}
      destroyOnClose={true}
      modalRender={false}
      footer={
        <Button type="primary" loading={loading} onClick={onPressEnter}>
          核销
        </Button>
      }
    >
      <div className="content">
        <Input
          type="text"
          className="input"
          autoFocus
          value={orderNo}
          onChange={onInputChange}
          onPressEnter={onPressEnter}
        />
      </div>
    </Modal>
  )
}

OrderCheckIn.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool.isRequired,
}

export default OrderCheckIn
