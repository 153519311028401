import { SET_ORDER_STATUS } from '../constant'

const initState = {
  orderStatus: null
}
export default function createOrder(perState = initState, action) {
  const { type, data } = action
  console.log('type', type)
  switch (type) {
    case SET_ORDER_STATUS:
      return { ...perState, orderStatus: data }
    default:
      return perState
  }
}
