import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import Icon from '@/components/Icon'
import NumberKeyboard from '../NumberKeyboard'
import {
  onDeleteByNumberInput,
  onInputByNumberInput,
} from '../../utils'
import './index.less'
let keydownToken
let scanToken
const NoGoods = (props) => {
  const [price, setPrice] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleClear = () => {
    setPrice(0)
  }

  const onCancel = () => {
    setPrice(0)
    props.onCancel && props.onCancel()
  }

  const handleDelete = () => {
    const num = onDeleteByNumberInput(price)
    setPrice(num)
  }
  const handleConfirm = () => {
    handleSubmit(price)
  }
  const handleInput = (text) => {
    let num = onInputByNumberInput(price, text)
    if (num.indexOf('.') === -1 && Number(num) === 0) {
      num = Number(num)
    }
    setPrice(num)
  }
  const handleChange = (text) => {
    setPrice(text)
  }
  const handleSubmit = (text) => {
    if (
      loading ||
      text === '' ||
      (text !== 0 && text.length - 1 === text.indexOf('.'))
    ) {
      return
    }
    setLoading(true)
    props.onSubmit &&
      props.onSubmit(text, () => {
        // 回调，如果请求接口失败需要调用回调才可以清除loading状态
        // onSubmit(orderNo, callback) { callback && callback()}
        setLoading(false)
        setPrice(0)
      })
  }

  useEffect(() => {
    keydownToken && PubSub.unsubscribe(keydownToken)
    keydownToken = PubSub.subscribe('GLOBAL_KEYDOWN', (msg, data) => {
      if (!props.visible) return
      // 退格键
      if (data.code === 8) {
        handleDelete()
      } else if (data.code === 13) {
        handleConfirm()
      } else if (data.isNumberKey) {
        handleInput(data.text)
      }
    })

    return () => {
      keydownToken && PubSub.unsubscribe(keydownToken)
      scanToken && PubSub.unsubscribe(scanToken)
    }
  })

  return (
    <Modal
      wrapClassName="ant-modal-content-custom c-no-goods-modal"
      title="无码商品"
      visible={props.visible}
      width="672px"
      centered="center"
      onCancel={onCancel}
      destroyOnClose={true}
      modalRender={false}
      footer={[]}
    >
      <div className="c-no-goods-container">
        <div className="c-no-goods-content-box">
          <div className="content">
            <span className="p-t">单价:</span>
            <div className="input focus">{price}</div>
            {price !== '' && (
              <div className="close-con" onClick={handleClear}>
                <Icon className="icon" type="iconguanbi1" />
              </div>
            )}
          </div>
        </div>
        <div className="keybord-box">
          <NumberKeyboard
            handleInput={handleInput}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleConfirm={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  )
}

NoGoods.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool.isRequired,
}

export default NoGoods
