import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Input, Tabs } from 'antd'
import Icon from '../../Icon'
import './goods.less'
import InfiniteScrollList from '@/components/InfiniteScrollList'
import Sidebar from './Sidebar'
import Items from './Items'
import HModal from '../Modal'
import HSpecs from './Specs'
import {
  getGoodsAllSecondaryCategory,
  getServiceAllSecondaryCategory,
  getGoodsList,
  getServiceList,
  getAllGoodsToKeyword,
  addAsyncGoodsList,
} from '@/redux/actions/goods'
import { setIsAction } from '@/redux/actions/home'
import PubSub from 'pubsub-js'
import { checkScanKey } from '@/utils'
import { HomeContext } from '@/components/Home/context-manager'

let keydownToken
let scanToken

const Search = Input.Search
const { TabPane } = Tabs
const searchEvents = ['keydown', 'click']

function Goods(props) {
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [tabType, setTabType] = useState('goods')
  const [goodsList, setGoodsList] = useState([])
  const [selectIndex, setSelectIndex] = useState(0)
  // 分类list
  const [category, setCategory] = useState([])
  // modal
  const [visible, setVisible] = useState(false)
  // 设置 搜索结果
  const [isShowSearch, setIsShowSearch] = useState(false)
  // tabs
  const [tabPanes, setTabPanes] = useState([
    {
      label: '商品',
      key: 'goods',
    }
  ])
  // 选中的商品/服务
  const [itemDetail, setItemDetail] = useState({})
  //
  const [sureItemIndex, setSureItemIndex] = useState(0)

  // Search
  const [hasMore, setHasMore] = useState(true)

  const { disableSearchScan, setIsAddItem } = useContext(HomeContext)

  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 50,
    keyword: '',
  })
  //
  const initParams = (searchParams = {}) => {
    setGoodsList([])
    setHasMore(true)
    setParams({
      ...params,
      ...searchParams,
      pageNo: 1,
    })
  }

  const handleLoadMore = () => {
    setLoading(true)
    setParams({
      ...params,
      pageNo: params.pageNo + 1,
    })
  }

  // list
  const loadData = () => {
    if (!hasMore) return
    if (tabType === 'search') {
      getSearchList()
    } else if (tabType === 'goods') {
      getGoodsListS()
    } else if (tabType === 'service') {
      getServiceListS()
    }
  }

  // 搜索
  const onSearch = async (keyword, type) => {
    if (type && type.type && searchEvents.find((i) => i === type.type)) {
      setIsShowSearch(true)
      setTabType('search')
      initParams({ categoryId: null, keyword })
    }
  }

  //start 切换tab
  const onTabChange = (val) => {
    setTabType(val)
    init(val)
  }

  const init = (val) => {
    if (val === 'goods') {
      getGoodsAllSecondaryCategoryS()
    } else if (val === 'service') {
      setCategory([
        {
          id: -1,
          title: '洗护服务',
        },
      ])
      initParams({ categoryId: null, keyword: null, pageNo: 1 })
    } else if (val === 'search') {
      setCategory([
        {
          id: -1,
          title: '全部',
        },
      ])
      initParams({ categoryId: null, keyword, pageNo: 1 })
    }
  }
  // end

  /**
   * search list
   *
   */
  const getSearchList = () => {
    setLoading(true)
    props
      .getAllGoodsToKeyword({
        ...params,
        categoryId: null,
      })
      .then((res) => {
        const data = res.data
        const list = data.items || []
        list.forEach((iv) => {
          iv.isSearch = true
        })
        if (list.length === 1 && params.pageNo === 1) {
          onChooseGoods(list[0])
          cancelKeyword()
        } else {
          handleSearchResult()
          console.log(goodsList.concat(list))
          setGoodsList(goodsList.concat(list))
          const totalPageNo = Math.ceil(data.count / params.pageSize)
          setHasMore(params.pageNo < totalPageNo)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSearchResult = () => {
    setTabType('search')
    setKeyword(keyword)
    reductionOrigin()
    setIsShowSearch(true)
    setCategory([
      {
        id: -1,
        title: '全部',
      },
    ])
    setSelectIndex(0)
    setTabPanes([
      ...tabPanes,
      {
        label: '搜索结果',
        key: 'search',
      },
    ])
  }
  // 商品类目
  const getGoodsAllSecondaryCategoryS = () => {
    props.getGoodsAllSecondaryCategory().then((res) => {
      let list = res.data
      setCategory(list)
      setSelectIndex(0)
      if (list.length > 0) {
        initParams({ categoryId: list[0].id, keyword: null })
      }
    })
  }

  // 商品
  const getGoodsListS = () => {
    if (!params.categoryId) return
    setLoading(true)
    try {
      props
        .getGoodsList({
          ...params,
        })
        .then((res) => {
          const data = res.data
          const list = data.items || []
          setGoodsList(goodsList.concat(list))
          const totalPageNo = Math.ceil(data.count / params.pageSize)
          setHasMore(params.pageNo < totalPageNo)
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error) {}
  }

  // 服务
  const getServiceListS = () => {
    setLoading(true)
    props
      .getServiceList({
        ...params,
      })
      .then((res) => {
        const data = res.data
        const list = data.items || []
        setGoodsList(goodsList.concat(list))
          const totalPageNo = Math.ceil(data.count / params.pageSize)
          setHasMore(params.pageNo < totalPageNo)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  // 点击分类
  const onGetList = (item, index, isSearch = false) => {
    setSelectIndex(index)
    if (isSearch) {
      return
    }
    if (item.id === -1) {
      return
    }
    setGoodsList([])
    // 清除搜索相关
    setKeyword('')
    setIsShowSearch(false)
    reductionOrigin()
    // 切换
    initParams({ categoryId: item.id, keyword: null })
  }
  // 还原tab
  const reductionOrigin = () => {
    if (tabPanes.length > 1) {
      tabPanes.splice(tabPanes.length - 1)
    }
    setTabPanes([...tabPanes])
  }
  //start 规格
  const onChooseGoods = (item) => {
    if (item.skus && item.skus.length > 1) {
      setVisible(true)
      setItemDetail(item)
    } else {
      if (tabType === 'search') {
        const curSku = item
        const discountedPrice = curSku.price
        const good = {
          discountedPrice,
          ...curSku,
          spuTitle: item.spuTitle,
          num: 1,
          subtotal: discountedPrice,
          skuTitle: item.title,
        }
        props.addAsyncGoodsList(good)
        setIsAddItem(true)
      } else {
        const curSku = item.skus[0]
        const discountedPrice = curSku.price
        const good = {
          discountedPrice,
          ...curSku,
          spuTitle: item.title,
          num: 1,
          subtotal: discountedPrice,
          skuTitle: item.title,
        }
        props.addAsyncGoodsList(good)
        setIsAddItem(true)
      }
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }
  const handleOk = () => {
    const curSku = itemDetail.skus[sureItemIndex]
    const discountedPrice = curSku.price
    const good = {
      discountedPrice,
      ...curSku,
      num: 1,
      spuTitle: itemDetail.title,
      subtotal: discountedPrice,
      skuTitle: curSku.title,
    }
    props.addAsyncGoodsList(good)
    setIsAddItem(true)
    setVisible(false)
  }
  // end

  // 取消
  const cancelKeyword = () => {
    if (!isShowSearch) return
    setKeyword('')
    setIsShowSearch(false)
    reductionOrigin()
    setTabType('goods')
    init('goods')
  }
  const getSureItem = (index) => {
    setSureItemIndex(index)
  }

  const onSetIsAction = () => {
    props.setIsAction(!props.isAction)
  }

  useEffect(() => {
    init(tabType)
  }, [])

  useEffect(() => {}, [props.isAction])

  useEffect(() => {
    // 监听扫码，用于微信支付宝扫码支付
    scanToken && PubSub.unsubscribe(scanToken)
    scanToken = PubSub.subscribe('GLOBAL_SCAN', (msg, data) => {
      if (disableSearchScan) return
      if (data.text) {
        const scanKeyObj = checkScanKey(data.text)
        if (scanKeyObj && scanKeyObj.type === 'search') {
          setKeyword(scanKeyObj.value)
          onSearch(scanKeyObj.value, {
            type: 'click',
          })
        }
      }
    })

    return () => {
      keydownToken && PubSub.unsubscribe(keydownToken)
      scanToken && PubSub.unsubscribe(scanToken)
    }
  }, [disableSearchScan])

  useEffect(() => {
    loadData()
  }, [params])

  return (
    <div className="h-goods">
      <div className="head">
        <div className="head-t">
          <Icon
            className={`icon ${props.isAction ? 'isAction' : ''}`}
            type="icondakai"
            onClick={onSetIsAction}
          />
          <span className="t">商品库</span>
        </div>
        <div className="head-r">
          <Search
            placeholder="条码/商品名称"
            className="search-button"
            prefix={<Icon type="iconsousuo" />}
            enterButton="搜索"
            onSearch={onSearch}
            defaultValue={keyword}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          {isShowSearch && <span
            className='concal'
            onClick={cancelKeyword}
          >
            取消
          </span>}
        </div>
      </div>
      <main className="goods-main">
        <Tabs
          defaultActiveKey={tabType}
          activeKey={tabType}
          onChange={onTabChange}
          size="large"
        >
          <TabPane tab="商品" key="goods">
            <div className="box">
              <Sidebar
                onGetList={onGetList}
                category={category}
                selectIndex={selectIndex}
                type="goods"
              />
              <div className="item-box">
                <InfiniteScrollList
                  className={`g-items ${
                    goodsList.length === 0 ? 'noList' : ''
                  }`}
                  handleOnLoad={handleLoadMore}
                  hasMore={hasMore}
                  loading={loading}
                >
                  {goodsList.map((iv, index) => (
                    <Items
                      key={index}
                      {...iv}
                      index={index}
                      onChooseGoods={onChooseGoods}
                    />
                  ))}
                </InfiniteScrollList>
              </div>
            </div>
          </TabPane>
          {isShowSearch ? (
            <TabPane tab="搜索结果" key="search">
              <div className="box">
                <Sidebar
                  onGetList={onGetList}
                  category={category}
                  selectIndex={selectIndex}
                  type="goods"
                />
                <div className="item-box">
                  <InfiniteScrollList
                    className={`g-items ${
                      goodsList.length === 0 ? 'noList' : ''
                    }`}
                    handleOnLoad={handleLoadMore}
                    hasMore={hasMore}
                    loading={loading}
                  >
                    {goodsList.map((iv, index) => (
                      <Items
                        key={index}
                        {...iv}
                        index={index}
                        onChooseGoods={onChooseGoods}
                      />
                    ))}
                  </InfiniteScrollList>
                </div>
              </div>
            </TabPane>
          ) : (
            <></>
          )}
        </Tabs>
      </main>
      <HModal
        visible={visible}
        handleCancel={handleCancel}
        afterClose={handleCancel}
        handleOk={handleOk}
        wrapClassName="ant-modal-content-custom"
        getSureItem={getSureItem}
        childrenRender={(props) => (
          <HSpecs {...props} itemDetail={itemDetail} />
        )}
      />
    </div>
  )
}

export default connect(
  (state) => ({
    isMember: state.home.isMember,
    isAction: state.home.isAction,
  }),
  {
    getGoodsAllSecondaryCategory,
    getServiceAllSecondaryCategory,
    getGoodsList,
    getServiceList,
    getAllGoodsToKeyword,
    addAsyncGoodsList,
    setIsAction,
  }
)(Goods)
