export const useHome = (props) => {
  // 是否有美容卡
  const deductionCardService = (skusList, userId = null) => {
    if (!Array.isArray(skusList)) {
      return
    }
    const skus = skusList
      .filter((item) => item.type === 'SERVICE')
      .map((item) => ({
        skuId: item.skuId,
        hasCard: true
      }))
    if (skus.length === 0) return
    props
      .deductionCardService({
        userId: props.isMember ? props.memberDetail?.id : userId || null,
        deductionServiceVOList: skus,
      })
      .then((res) => {
        let skus = res.data || []
        let copySkuList = JSON.parse(JSON.stringify(skusList))
        copySkuList = copySkuList.map((iv) => {
          const cur = skus.find((sk) => sk.skuId === iv.skuId)
          if (iv.type === 'SERVICE') {
            iv.hasCard = cur.hasCard
          }
          return iv
        })
        props.addGoodsCashier && props.addGoodsCashier(copySkuList)
      })
  }
  return {
    deductionCardService,
  }
}
