import { GET_MEMBER_LIST, GET_MEMBER_DETAIL, GET_MEMBER_HISTORY, IS_SET_MEMBER, SET_ALL_MEMBER_DATA, SET_IS_ACTION } from '../constant'

const initState = { memberList: [], memberDetail: null, historyList: [], isMember: false, isAction: false }
export const setHomeData = (perState = initState, action) => {
  const { type, data } = action
  switch (type) {
    case GET_MEMBER_LIST:
      return { ...perState, memberList: data }
    case GET_MEMBER_DETAIL:
      return { ...perState, memberDetail: data }
    case GET_MEMBER_HISTORY:
      return { ...perState, historyList: data }
    case IS_SET_MEMBER:
      return { ...perState, isMember: data }
    case SET_ALL_MEMBER_DATA:
      return { ...perState, ...data }
    case SET_IS_ACTION:
      return { ...perState, isAction: data }
    default:
      return perState
  }
}
export default setHomeData
