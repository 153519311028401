import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAdminInfo } from '../../redux/actions/setting'
import { Spin } from 'antd'
import { openURL } from '@/utils'
import Icon from '../../components/Icon'
import Account from '../../components/Setting/Account'
import General from '../../components/Setting/General'
import About from '../../components/Setting/About'
import Update from '../../components/Setting/Update'
import Help from '../../components/Setting/Help'
import Notice from '@/components/GlobalNotice/baofu'
import './index.less'
import { BASE_PRM_URL } from '@/config/api'

function Setting(props) {
  // 初始化左侧菜单数据
  const menuList = [
    {
      title: '账号设置',
      icon: 'iconzhanghaoshezhi',
      componentName: 'account',
    },
    // {
    //   title: '通用设置',
    //   icon: 'icontongyongshezhi',
    //   componentName: 'general',
    // },
    {
      title: '关于我们',
      icon: 'iconguanyuwomen',
      componentName: 'about',
    },
    {
      title: '系统公告',
      icon: 'iconxitonggonggao',
      componentName: '',
    },
    {
      title: '开发调试',
      icon: 'kaifa',
      componentName: 'dev',
      hidden: true,
    },
  ]

  // 初始化state数据
  const [tabIndex, setTabIndex] = useState(0)

  // useEffect生命周期
  useEffect(() => {
    // 判断是否是开发测试环境
    // if (this.$store.state.isDebugMode || process.env.VUE_APP_ENV === 'dev') {
    //   toggleMenuItem('开发调试')
    // }
  }, [])

  // 事件操作
  // 左侧tab切换
  const selectTab = (index, item) => {
    if (item.title === '后台管理') {
      toManageUrl()
      return
    }
    setTabIndex(index)
  }
  const toManageUrl = () => {
    openURL(`${BASE_PRM_URL}/?t=${Date.now()}#/login?token=${props.user.token}`)
  }

  // 渲染页面
  return (
    <Spin spinning={false} size="large" delay={500}>
      <div className="setting-main">
        <div className="tabs">
          <div className="tab-list">
            {menuList.map((item, index) => {
              if (item.hidden) return <></>
              return (
                <div
                  className={tabIndex === index ? 'item active' : 'item'}
                  onClick={() => selectTab(index, item)}
                  key={item.title}
                >
                  <Icon type={item.icon} />
                  <span className={tabIndex === index ? 'active' : ''}>
                    {item.title}
                  </span>
                </div>
              )
            })}
          </div>
          {props.user.userRole !== '0' && (
            <div className="tab-prm" onClick={toManageUrl}>
              <span>后台管理</span>
            </div>
          )}
        </div>
        <div className="content">
          {tabIndex === 0 && <Account getAdminInfo={props.getAdminInfo} />}
          {/* {tabIndex === 1 && <General />} */}
          {tabIndex === 1 && <About />}
          {/* {tabIndex === 3 && <Update />} */}
          {tabIndex === 2 && <Help />}
          {tabIndex === 3 && <div className="notice-container"><Notice /></div>}
        </div>
      </div>
    </Spin>
  )
}

export default connect((state) => ({ user: state.user }), { getAdminInfo })(
  Setting
)
