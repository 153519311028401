import request from '../../utils/request'
import { ADD_GOODS_CASHIER } from '../constant'
import { message } from 'antd'

const area = `/crs`

// 添加商品至收银台
export const addGoodsCashier = (data) => ({ type: ADD_GOODS_CASHIER, data })
// 修改商品数量
export const changeAsyncDeskItemNum = (data) => {
  return (dispatch, getState) => {
    const { skuNumber, num } = data
    const deskList = [...getState().goods.goodsCashier]
    const index = deskList.findIndex((item) => item.skuNumber === skuNumber)
    if (index === -1) {
      return
    }

    deskList[index].num = num
    deskList[index].subtotal = num * deskList[index].discountedPrice
    if (
      deskList[index].num > deskList[index].stock &&
      deskList[index].type !== 'SERVICE'
    ) {
      if (!deskList[index].isNoCodeGoods) message.error('库存不足，请及时补货')
    }
    dispatch(addGoodsCashier(deskList))
  }
}
// 添加商品
export const addAsyncGoodsList = (deskItem) => {
  return (dispatch, getState) => {
    if (Array.isArray(deskItem)) {
      dispatch(addGoodsCashier(deskItem))
    } else {
      let index = -1
      const goodsItem = JSON.parse(JSON.stringify(deskItem))
      const goodsList = [...getState().goods.goodsCashier]
      if (goodsItem.skuNumber) {
        index = goodsList.findIndex(
          (item) => item.skuNumber === goodsItem.skuNumber
        )
      }

      if (typeof goodsItem.num === 'undefined') {
        goodsItem.num = 1
      }

      if (typeof goodsItem.categoryText === 'undefined' && goodsItem.type) {
        goodsItem.categoryType = goodsItem.type
      }

      if (index === -1) {
        if (goodsItem.num > goodsItem.stock && goodsItem.type !== 'SERVICE') {
          if (!goodsItem.isNoCodeGoods) message.error('库存不足，请及时补货')
        }
        goodsList.splice(0, 0, goodsItem)
      } else {
        goodsList[index].num += goodsItem.num
        goodsList[index].subtotal =
          goodsList[index].num * goodsList[index].discountedPrice
        if (
          goodsList[index].num > goodsList[index].stock &&
          goodsList[index].type !== 'SERVICE' &&
          !goodsList[index].isNoCodeGoods
        ) {
          message.error('库存不足，请及时补货')
        }
      }
      dispatch(addGoodsCashier(goodsList))
    }
  }
}
// 修改价格
export const updateAsyncDeskItem = (item, index) => {
  return (dispatch, getState) => {
    const deskList = [...getState().goods.goodsCashier]
    deskList[index] = item
    dispatch(addGoodsCashier(deskList))
  }
}
// 删除商品
export const deleteAsyncDeskItem = (index) => {
  return (dispatch, getState) => {
    if (index < 0) {
      return
    }
    const deskList = [...getState().goods.goodsCashier]
    deskList.splice(index, 1)
    dispatch(addGoodsCashier(deskList))
  }
}

// 查询商品类目
export const getGoodsAllSecondaryCategory = (params) => (dispatch) =>
  request({
    url: `${area}/category/allSecondaryCategory`,
    method: 'get',
    params,
  })

// 查询服务类目
export const getServiceAllSecondaryCategory = (params) => (dispatch) =>
  request({
    url: `${area}/serviceCategory/allSecondaryCategory`,
    method: 'get',
    params,
  })

// 查询商品
export const getGoodsList = (params) => (dispatch) =>
  request({
    url: `${area}/goods/pageList`,
    method: 'get',
    params,
  })

// 查询服务
export const getServiceList = (params) => (dispatch) =>
  request({
    url: `${area}/service/pageList`,
    method: 'get',
    params,
  })

// 根据keyword 查询所有
export const getAllGoodsToKeyword = (params) => (dispatch) =>
  request({
    url: `${area}/goods/search`,
    method: 'get',
    params,
  })

/**
 * 获取寄养类型
 * @returns
 */
export const getBoardType = (params) => (dispatch) =>
  request({
    url: `${area}/service/getBoardType`,
    method: 'get',
    params,
  })
/**
 * 获取寄养支付配置
 * @returns
 */
export const getBoardPayConfigInfo = (params) => (dispatch) =>
  request({
    url: `/order-center/mp/boardOrder/pay/config/info`,
    method: 'get',
    params,
  })
/**
 * 寄养房间/服务项目
 * @returns
 */
export const getBoardProject = (params) => (dispatch) =>
  request({
    url: `${area}/service/getBoardProject`,
    method: 'get',
    params,
  })
/**
 * 获取寄养时间
 * @returns
 */
export const getBoardModel = (params) => (dispatch) =>
  request({
    url: `${area}/service/getBoardModel`,
    method: 'get',
    params,
  })
/**
 * 获取寄养日历价格
 * @returns
 */
export const getPriceByDays = (params) => (dispatch) =>
  request({
    url: `${area}/service/board/getPriceByDays`,
    method: 'get',
    params,
  })
/**
 * 预约
 * 获取服务类目
 * @returns
 */
export const getAppointmentCategory = (params) => (dispatch) =>
  request({
    url: `${area}/appoint/category/getAppointmentCategory`,
    method: 'get',
    params,
  })

/**
 * 预约
 * 获取美容师列表
 * @returns
 */
export const getWorkerList = (params) => (dispatch) =>
  request({
    url: `/crs/appoint/service/getWorkerList`,
    method: 'get',
    params,
  })

/**
 * 预约
 * 获取服务类型
 * @returns
 */
export const getAppointmentType = (params) => (dispatch) =>
  request({
    url: `${area}/appoint/service/getAppointmentType`,
    method: 'get',
    params,
  })
/**
 * 预约
 * 获取服务项目
 * @returns
 */
export const getAppointmentProject = (params) => (dispatch) =>
  request({
    url: `/crs/appoint/service/getAppointmentProject`,
    method: 'get',
    params,
  })
// 获取服务列表
export const getServeList = (params) => (dispatch) =>
  request({
    url: `/crs/appoint/service/getAppointmentProject`,
    method: 'get',
    params,
  })
