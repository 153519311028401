import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { getMemberAllLevel } from '@/redux/actions/user'
import './index.less'

function Level(props) {
  const [list, setList] = useState([])

  useEffect(() => {
    props.getMemberAllLevel().then((res) => {
      setList(res.data)
    })
  }, [])

  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      bordered={props.bordered}
      className={props.className}
      name={props.name}
      placeholder={props.placeholder}
    >
      {list.map((item) => (
        <Select.Option value={item.level} key={item.level}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default connect(null, {
  getMemberAllLevel,
})(Level)
