import React from 'react'
import { Modal } from 'antd'
export default function Index(props) {
  const handleEndOrder = () => {
    props.handleConfirm && props.handleConfirm()
  }
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      title="结束寄养"
      wrapClassName="ant-modal-content-custom end-order-modal"
      onOk={handleEndOrder}
    >
      <div className="cell">
        <div className="cell-item">
          <div className="cell-title">预约时间 </div>
          <div className="cell-value">{props.boardDateStr}</div>
        </div>
        <div className="cell-item">
          <div className="cell-title">延期时间 </div>
          <div className="cell-value">{props.extendDateStr}</div>
        </div>
      </div>
    </Modal>
  )
}
