import {
  SET_STORE_INFO,
  SET_TOKEN,
  SET_IS_LOGIN,
  SET_PRINT_INFO,
  SET_AUTH_ROUTE_LIST,
} from '../constant'
import request from '../../utils/request'
import authList from '@/router/authList'
import { getMemberList } from './home'
export const setToken = (data) => ({ type: SET_TOKEN, data })
export const setIsLogin = (data) => ({ type: SET_IS_LOGIN, data })
export const setStoreInfo = (data) => ({ type: SET_STORE_INFO, data })
export const setPrintInfo = (data) => ({ type: SET_PRINT_INFO, data })
export const setAuthRouteList = (data) => ({ type: SET_AUTH_ROUTE_LIST, data })

const area = '/crs'

const dealAuthRouteList = (perms, routeList) => {
  return routeList
    .filter((item) =>
      item.permission.some(
        (item) => perms === '*' || (Array.isArray(perms) && perms.indexOf(item) > -1)
      )
    )
    .filter((item) => !item.hidden)
}

export const userLogin = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `/crs/login/pwdLogin`,
        method: 'post',
        data,
      })
        .then((res) => {
          const data = res.data
          dispatch(setToken(data.TOKEN))
          getAdminInfo(data, dispatch)
            .then((infoData) => {
              dispatch(
                setStoreInfo({
                  ...infoData,
                  userRole: data.userRole,
                  avatarUrl: data.avatarUrl,
                })
              )
              resolve(infoData)
            })
            .catch(reject)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
export const getAdminInfo = (data, dispatch) => {
  console.log('data', data);
  return new Promise((resolve, reject) => {
    request({
      url: `${area}/admin/get/${data.adminId}`,
      method: 'get',
    })
      .then((res) => {
        const data = res.data
        const authRouteList = dealAuthRouteList('*', authList)
        const firstRoutePath = authRouteList.length ? authRouteList[0].path : ''
        dispatch(setIsLogin(true))
        dispatch(setAuthRouteList(authRouteList))
        resolve({ ...data, firstRoutePath: firstRoutePath })
      })
      .catch(() => {
        reject()
      })
  })
}
export const getAdminAll = () => () =>
  request({
    url: `${area}/admin/getAdminAll`,
    method: 'get',
  })

// 获取单个用户信息
export const getUserPetInfo = (params) => () =>
  request({
    url: `${area}/user/getUserPetInfo`,
    method: 'get',
    params,
  })
// 获取打印信息
export const getPrintInfo = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    request({
      url: `${area}/admin/getPrintInfo`,
      method: 'get',
      params,
    })
      .then((res) => {
        dispatch(setPrintInfo(res.data))
        resolve(res)
      })
      .catch(() => {
        reject()
      })
  })
}

/**
 * 获取会员等级
 * @param {*} data
 * @returns
 */
export const getMemberAllLevel = (params) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `/crs/user/allLevel`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 添加会员
 * @param {*} data
 * @returns
 */
export const createMember = (data) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/user/addUser`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * 获取消息数量
 * @param {*} params
 * @returns
 */
export const getNotifyNum = (params) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/tenantNotify/getUnReadNotifyNum`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 获取消息列表
 * @param {*} params
 * @returns
 */
export const getNotifyList = (params) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/tenantNotify/list`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/* 接送设置
 * @param {*} params
 * @returns
 */
export const configAppoint = () => () =>
  request({
    url: `/crs/appoint/config/appoint`,
    method: 'get',
  })
/**
 * 将未读消息设置为已读
 * @param {*} data
 * @returns
 */
export const readBatch = (data) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/tenantNotify/readBatch`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
/**
 * 将全部未读消息设置为已读
 * @param {*} data
 * @returns
 */
export const readAll = (data) => {
  return () => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/tenantNotify/readAll`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 异步获取会员列表
export const getAsyncMemberList = (data) => {
  if (data.keyword === '') {
    return (dispatch) => {
      dispatch(getMemberList([]))
      return Promise.resolve([])
    }
  }
  return async (dispatch) => {
    try {
      const res = await request({
        url: `${area}/user/pageList`,
        method: 'get',
        params: data
      })
      const list = res.data.items || []
      return Promise.resolve(list)
    } catch (error) {
      dispatch(getMemberList([]))
      return Promise.resolve([])
    }
  }
}

// 获取会员详情
export const getMemberDetail = (uid) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/user/detail/${uid}`,
        method: 'get',
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 获取单日预约调整
export const getAppointTemplateAfterToDay = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointTemplate/getAppointTemplateAfterToDay`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 修改单日预约调整
export const updatePeopleNumByDetailId = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/appointTemplate/updatePeopleNumByDetailId`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 获取验证码
export const getCodeData = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `/crs/code/getVerificationCode`,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 验证码登录
export const codeLogin = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/login/crsPhoneLogin`,
        method: 'post',
        data,
      })
        .then((res) => {
          const data = res.data
          dispatch(setToken(data.token))
          getAdminInfo(data, dispatch)
            .then((infoData) => {
              dispatch(
                setStoreInfo({
                  ...infoData,
                  userRole: data.userRole,
                  avatarUrl: data.avatarUrl,
                })
              )
              resolve(infoData)
            })
            .catch(reject)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 根据手机号或用户名查询用户信息
export const getUserInfo = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `/crs/admin/adminuser/getAdminInfoByAdminInfo`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 用户宠物列表
export const getUserPetList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/pet/list/${params.userId}`,
        method: 'get',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 宠物详情
export const getPetDetail = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/pet/detail/${params.id}`,
        method: 'get',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 宠物品种
export const getPetBreed = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/pet/getPetBreed`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
export const petCreate = (data) => (dispatch) =>
  request({
    url: `${area}/pet/create`,
    method: 'POST',
    data,
  })
export const petUpdate = (data) => (dispatch) =>
  request({
    url: `${area}/pet/update`,
    method: 'POST',
    data,
  })
// 添加待办
export const AddBacklog = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/backlog`,
        method: 'POST',
        data,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 待办列表
export const getBacklogList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      request({
        url: `${area}/backlog/list`,
        method: 'get',
        params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
// 删除待办列表
export const delBacklogList = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log('params', params)
      request({
        url: `${area}/backlog/${params.id}`,
        method: 'DELETE',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
