import React, { useMemo } from 'react'
import { Image, Button } from 'antd'
import PropTypes from 'prop-types'
import { discountConvert } from '@/utils'
import defaultPeople from '@/assets/member/people.png'
import './index.less'

Card.propTypes = {
  detail: PropTypes.object.isRequired,
  isShowCommission: PropTypes.bool
}
Card.defaultProps = {
  detail: {
    phone: '--',
    avatarUrl: '',
    levelName: '--',
    serviceDiscount: '--',
    goodsDiscount: '--',
    userLevel: {
      serviceDiscount: '--',
      goodsDiscount: '--',
    },
  },
  isShowCommission: false
}

export default function Card(props) {
  const userLevel = useMemo(() => {
    if (props.detail.levelName) {
      return props.detail.levelName
    } else if (props.detail.userLevel) {
      return props.detail.userLevel.name
    }
    return '普通会员'
  }, [props.detail])

  const goodsDiscount = useMemo(() => {
    if (props.detail.userLevel) {
      return discountConvert(props.detail.userLevel.goodsDiscount)
    } else if (props.detail.goodsDiscount) {
      return discountConvert(props.detail.goodsDiscount)
    }
    return '10折'
  })
  const servicesDiscount = useMemo(() => {
    if (props.detail.userLevel) {
      return discountConvert(props.detail.userLevel.serviceDiscount)
    } else if (props.detail.serviceDiscount) {
      return discountConvert(props.detail.serviceDiscount)
    }
    return '10折'
  })
  return (
    <div className={`d-user ${props.detail.phone ? 'isMember' : ''}`}>
      <div className="user-info">
        {props.detail.phone ? (
          <Image
            className="ava"
            src={props.detail.avatarUrl || defaultPeople}
            preview={false}
          />
        ) : (
          <Image className="ava" src={defaultPeople} preview={false} />
        )}
        {
          <div className="u-info">
            <div className="u-i-1 flex1">
              <span className="u-i-t">
                {props.detail.phone
                  ? props.detail.realname || props.detail.nickname
                  : '散客'}
              </span>
              {props.detail.phone ? (
                <span className="u-i-v">{userLevel}</span>
              ) : (
                ''
              )}
            </div>
            {props.detail.phone && (
              <div className="flex u-i-1">
                <span className="u-i-p">{props.detail.phone}</span>
                <div className="u-i-1 flex">
                  {props.isShowCommission && <div className="u-i-balance">
                    <span className="b">积分</span> <span className="value">{props.detail.commissionAvailable}</span>
                  </div>}
                  <div className="u-i-balance">
                    <span className="b">余额</span> <span className="value">¥{props.detail.walletBalance}</span>
                    {props.hasRecharge && (
                      <Button
                        type="text"
                        className="recharge-btn"
                        onClick={props.handleRecharge}
                      >
                        余额充值
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
      {props.detail.phone ? (
        <div className="user-dis">
          <div>
            <p>
              服务消费折扣
              {servicesDiscount}
            </p>
            <p>
              商品消费折扣
              {goodsDiscount}
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
