import React, { useState, useEffect, useContext } from 'react'
import './cashier.less'
import Icon from '../../Icon'
import Cars from './cars'
import OrderCheckIn from '@/components/OrderCheckIn'
import NoGoods from '@/components/NoGoods'
import { connect } from 'react-redux'
import {
  changeAsyncDeskItemNum,
  addGoodsCashier,
  deleteAsyncDeskItem,
  updateAsyncDeskItem,
  addAsyncGoodsList,
} from '@/redux/actions/goods'
import {
  getCrsPendingOrderCreate,
  getCrsOrderCreateOrder,
  appointOrderConfirm,
} from '@/redux/actions/order'
import {
  setISMember,
  setMemberHistory,
  setMemberDetail,
  getMemberList,
} from '@/redux/actions/home'
import { useHistory } from 'react-router-dom'

import { Button, message, Modal } from 'antd'
import { HomeContext } from '@/components/Home/context-manager'

function Cashier(props) {
  const [loading, setLoading] = useState(false)

  const [qdlaoding, setQdLoading] = useState(false)
  const [sumPrice, setSumPrice] = useState(0)
  const [sumDiscount, setSumDiscount] = useState(0)
  const [sumBeforePrice, setSumBeforePrice] = useState(0)
  // 服务核销
  const [checkInVisible, setCheckInVisible] = useState(false)
  // 无码商品
  const [noGoodsVisible, setNoGoodsVisible] = useState(false)

  const { setDisableSearchScan, setIsClearMemberList, setIsAddItem } =
    useContext(HomeContext)
  const history = useHistory()
  // 挂单
  const getCrsPendingOrderCreate = () => {
    let userId = null
    if (props.isMember) {
      userId = props.memberDetail && props.memberDetail.id
    }
    setQdLoading(true)
    props
      .getCrsPendingOrderCreate({
        userId,
        skus: props.deskList,
        totalPrice: sumPrice,
      })
      .then(() => {
        message.success('挂单成功，不要忘记取单结算，让客户久等哦')
        props.addGoodsCashier([])
        props.setMemberDetail({})
        props.setISMember(false)
        props.setMemberHistory([])
        props.getMemberList([])
        setIsClearMemberList(true)
      })
      .finally(() => {
        setQdLoading(false)
      })
  }
  // start
  // 删除
  const onDelete = (index) => {
    props.deleteAsyncDeskItem(index)
  }

  // 添加
  const onAdd = (iv) => {
    const { num, skuNumber } = iv
    props.changeAsyncDeskItemNum({ num: num + 1, skuNumber })
  }

  // 减少
  const onReduce = (iv) => {
    const { num, skuNumber } = iv
    props.changeAsyncDeskItemNum({ num: num > 1 ? num : 1, skuNumber })
  }
  // 修改数量
  const onChangeNum = (num, skuNumber) => {
    props.changeAsyncDeskItemNum({ num, skuNumber })
  }
  // 修改价格
  const updateAsyncDeskItem = (val, item, key, index) => {
    if (key === 'discountedPrice') {
      item[key] = val
      item.subtotal = val * item.num
    } else {
      item[key] = val
      item.discountedPrice = Math.floor((val / item.num) * 100) / 100
    }
    props.updateAsyncDeskItem(item, index)
  }

  // 选择美容卡
  const chooseTimeCard = (item, index) => {
    props.updateAsyncDeskItem(item, index)
  }
  // end

  const openWithdrawal = () => {
    props.openWithdrawal()
    setDisableSearchScan(true)
  }

  // 结算
  const onSettl = () => {
    if (props.deskList.length === 0) {
      message.error('请选择商品')
      return
    }
    setLoading(true)
    let userId = null
    if (props.isMember) {
      userId = props.memberDetail && props.memberDetail.id
    }
    const parmas = {
      orderType: 'STORE',
      skus: props.deskList,
      useGoodsDiscount: true,
      useServiceDiscount: true,
      useVipPrice: true,
      userId,
      totalPrice: sumPrice,
    }
    props
      .getCrsOrderCreateOrder(parmas)
      .then((res) => {
        props.setOrderNo(res.data.orderNo)
        props.setUserId(userId)
        props.setCashVisible(true)
        setDisableSearchScan(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const openCheckIn = () => {
    history.push({
      pathname: '/appointment',
      state: {
        source: 'ticket',
      },
    })
  }

  // 服务核销
  const onSubmit = (barCode, callback) => {
    barCode = barCode.replace(/[\W]|FW/g, '')
    props
      .appointOrderConfirm({ barCode })
      .then((res) => {
        message.success('核销成功')
        setCheckInVisible(false)
        setDisableSearchScan(false)
      })
      .finally(() => {
        callback && callback()
      })
  }
  // 无码商品
  const handleSubmitNoGoods = (value, callback) => {
    setNoGoodsVisible(false)
    const amount = Number(value)
    const good = {
      discountedPrice: amount,
      spuTitle: '无码商品',
      num: 1,
      subtotal: amount,
      skuTitle: '无码商品',
      skuNumber: `codeless${new Date().getTime()}`,
      price: amount,
      type: 'GOODS',
      isNoCodeGoods: true,
    }
    props.addAsyncGoodsList(good)
    setIsAddItem(true)
    callback && callback()
  }

  const clearDeskList = () => {
    Modal.confirm({
      centered: 'center',
      title: '提示',
      content: '确认删除全部商品吗？',
      onOk() {
        props.addGoodsCashier([])
      },
      onCancel() {},
    })
  }

  const init = () => {
    let sumPrice = 0
    let sumDiscount = 0
    let sumBeforePrice = 0
    props.deskList.forEach((iv) => {
      sumPrice += iv.subtotal
      sumBeforePrice += iv.price * iv.num
    })
    sumPrice = sumPrice < 0 ? 0 : sumPrice
    sumDiscount = sumBeforePrice - sumPrice
    setSumPrice(sumPrice.toFixed(2))
    setSumBeforePrice(sumBeforePrice.toFixed(2))
    setSumDiscount(sumDiscount.toFixed(2))
  }

  useEffect(() => {
    init()
  }, [props.deskList])
  return (
    <div className="h-cashier">
      <div className="head">
        <div className="head-t">
          <span className="t">收银台</span>
        </div>
        <div className="head-r">
          <div className="hx_btn" onClick={openCheckIn}>
            <Icon className="hexiao" type="iconhexiaofuwu" />
            &nbsp;服务核销&nbsp;
            <Icon type="iconxiangyou" className="xy" />
          </div>
          <div className="hx_btn" onClick={() => setNoGoodsVisible(true)}>
            <Icon className="hexiao" type="icona-hexiaofuwubeifen2" />
            &nbsp;无码商品&nbsp;
            <Icon type="iconxiangyou" className="xy" />
          </div>
        </div>
      </div>
      <main className="c-main">
        <Cars
          deskList={props.deskList}
          onDelete={onDelete}
          onAdd={onAdd}
          onReduce={onReduce}
          onChangeNum={onChangeNum}
          updateAsyncDeskItem={updateAsyncDeskItem}
          chooseTimeCard={chooseTimeCard}
        />
      </main>

      <div className="c-btns">
        <div className="c-btn-row">
          <Button className="c-b-col" type="text" onClick={clearDeskList}>
            清空({props.deskList ? props.deskList.length : 0})
          </Button>
          <div className="bord"></div>
          {props.deskList.length > 0 ? (
            <Button
              className="c-b-col"
              type="text"
              size="large"
              loading={qdlaoding}
              onClick={getCrsPendingOrderCreate}
            >
              挂单
            </Button>
          ) : (
            <Button
              className="c-b-col"
              type="text"
              size="large"
              onClick={openWithdrawal}
            >
              取单
            </Button>
          )}
        </div>
        <div className="c-price-info">
          <div className="c-p-i-col">
            <span className="text">折前总额</span>
            <span className="text">¥{sumBeforePrice}</span>
          </div>
          <div className="c-p-i-col">
            <span className="text">
              优<span className="ml">惠</span>
            </span>
            <span className="text">¥{sumDiscount}</span>
          </div>
        </div>
        <Button
          type="text"
          className="c-balance"
          onClick={onSettl}
          loading={loading}
        >
          <div className="c-b-box">
            <span>
              结<span className="ml">算</span> (共
              {props.deskList ? props.deskList.length : 0}件)
            </span>
            <span className="c-text">
              ¥ <span className="rmb">{sumPrice}</span>
            </span>
          </div>
        </Button>
      </div>
      {/* 请扫码或输入服务券码 */}
      <OrderCheckIn
        onCancel={() => setCheckInVisible(false)}
        onSubmit={onSubmit}
        visible={checkInVisible}
      />
      {/* 无码商品 */}
      <NoGoods
        onCancel={() => setNoGoodsVisible(false)}
        onSubmit={handleSubmitNoGoods}
        visible={noGoodsVisible}
      />
    </div>
  )
}

export default connect(
  (state) => ({
    deskList: state.goods.goodsCashier,
    memberDetail: state.home.memberDetail,
    isMember: state.home.isMember,
  }),
  {
    getCrsPendingOrderCreate,
    getCrsOrderCreateOrder,
    changeAsyncDeskItemNum,
    addGoodsCashier,
    deleteAsyncDeskItem,
    updateAsyncDeskItem,
    setISMember,
    setMemberHistory,
    setMemberDetail,
    getMemberList,
    appointOrderConfirm,
    addAsyncGoodsList,
  }
)(Cashier)
