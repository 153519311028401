import React, { Fragment } from 'react'
import { Modal, Button } from 'antd'

export default function HModal (props) {

  const { visible = false, loading = false } = props
  const handleOk = () => {
    props.handleOk()
  }
  const handleCancel = () => {
    props.handleCancel()
  }
  return (
    <Fragment>
      <Modal
        visible={visible}
        title='规格选择'
        onOk={handleOk}
        centered
        width="auto"
        className={props.className}
        wrapClassName={props.wrapClassName}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            关闭
            </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            确定
            </Button>
        ]}
      >
        {
          props.childrenRender(props)
        }
      </Modal>
    </Fragment>
  )
}

HModal.defaultProps = {
  renderContext: () => { }
}
