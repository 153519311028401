import React, { useMemo, useState } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ConfigProvider, Button } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { connect } from 'react-redux'
import authList from './router/authList'
import routeList from './router/list'
import AuthRoute from './components/AuthRoute'
import PassportRoute from './components/PassportRoute'
import { onKeyPress } from '@/utils/keyboardListener'
import PrintTicket from './components/PrintTicket'
import RightClickMenu from './components/RightClickMenu'
import Menu from './components/Menu'
import Messager from '@/components/Messager'
import VersionUpdate from '@/components/VersionUpdate'
import GlobalNotice from '@/components/GlobalNotice'

import './App.less'

moment.locale('zh-cn')

document.addEventListener('keydown', onKeyPress)

function App(props) {
  const [canShowGlobalNotice, setCanShowGlobalNotice] = useState(false)
  const handleLogout = () => {
    window.sessionStorage.removeItem('persist:root')
    window.location.reload()
  }
  const onCanShowGlobalNotice = () => {
    setCanShowGlobalNotice(true)
  }
  const dealAuthRouteList = useMemo(() => {
    return authList.filter(item => props.user.authRouteList.some(authItem => authItem.path === item.path))
  }, [props.user.authRouteList])
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        {/* 登录 */}
        <Switch>
          {routeList.map((route) => (
            <PassportRoute
              key={route.path}
              {...route}
              user={props.user}
            ></PassportRoute>
          ))}
        </Switch>
        {props.user.isLogin ? (
          dealAuthRouteList.length > 0 ? (
            <div className="App-main">
              {/* 菜单 */}
              <Menu />
              <Messager />
              <div className="App-container">
                <Switch>
                  {/* 登录过的路由组件 */}
                  {dealAuthRouteList.map((route) => (
                    <AuthRoute
                      key={route.path}
                      {...route}
                      user={props.user}
                    ></AuthRoute>
                  ))}
                </Switch>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '18px',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p>暂无权限</p>
                <Button type="primary" onClick={handleLogout}>
                  返回
                </Button>
              </div>
            </div>
          )
        ) : (
          <Redirect to="/passport/login"></Redirect>
        )}
        {/* 支持打印小票组件 */}
        <PrintTicket />
        <RightClickMenu />
        {/* <VersionUpdate canShowGlobalNotice={onCanShowGlobalNotice} /> */}
        {canShowGlobalNotice && <GlobalNotice />}
      </ConfigProvider>
    </div>
  )
}

export default connect((state) => ({ user: state.user }))(App)
