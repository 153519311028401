import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import BoardDatePicker from '@/components/Board/DatePicker'
import { postponeBoard } from '@/redux/actions/order'
import './index.less'
import { connect } from 'react-redux'
import moment from 'moment'
function Index(props) {
  const [date, setDate] = useState('')
  const [loading, setLoading] = useState(false)
  const onChange = (value, boardInfoDateArray) => {
    const boardInfoDate =
      boardInfoDateArray[moment(props.startDate).format('YYYYMMDD')]
    if (boardInfoDate && boardInfoDate.fullFlag === 1) {
      message.warning('该房间已约满')
      return
    }
    setDate(value)
  }
  const handleConfirm = () => {
    if (!date) {
      message.warning('请选择延期截止日期')
      return
    }
    const days = date.diff(moment(props.startDate), 'd') + 1
    if (loading) {
      return
    }
    setLoading(true)
    props
      .postponeBoard({
        days,
        orderNo: props.orderNo,
      })
      .then((res) => {
        message.success('延期成功')
        setDate('')
        props.onSuccess && props.onSuccess()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onDocumentClick = (e) => {
    let currentElem = e.target
    let isAntPicker = false
    while (currentElem) {
      isAntPicker = ['ant-picker-dropdown', 'board-extend-btn'].some(
        (item) => currentElem.className.indexOf(item) > -1
      )
      if (isAntPicker) {
        break
      }
      currentElem = currentElem.parentElement
    }
    if (!isAntPicker) {
      props.onClose && props.onClose()
    }
  }
  useEffect(() => {
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('click', onDocumentClick)
    }
  }, [])
  return (
    <div className="board-extend-date">
      <BoardDatePicker
        open={props.open}
        boardInfoDateArray={props.boardInfoDateArray}
        value={date}
        single={true}
        spuId={props.spuId}
        skuId={props.skuId}
        startDate={props.startDate}
        onChange={onChange}
        isShowExtraFooter={props.isShowExtraFooter}
        handleConfirm={handleConfirm}
      />
      {props.children}
    </div>
  )
}
export default connect(null, {
  postponeBoard,
})(Index)
